export const workHistoryData = [
  {
    icon: "/icons/certificate.png",
    title: "Project Manager",
    company: "Amazon",
    startDate: "Apr 2021",
    endDate: "Aug 2023",
    location: "U.S.A",
  },
  {
    icon: "/icons/certificate.png",
    title: "UI/UX Designer",
    company: "Apple",
    startDate: "Apr 2018",
    endDate: "Aug 2020",
    location: "Nigeria"
  },
];
  
export const shortenedYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const educationHistoryData = [
  {
    icon: "/icons/certificate.png",
    title: "Nile University",
    location: "U.S.A",
    certificate: "B.sc",
    startDate: "Apr 2021",
    endDate: "Aug 2023",
    grade: "First Class Honor",
    field: "Economics",
  },
  {
    icon: "/icons/certificate.png",
    title: "Girls college",
    certificate: "SSCE",
    location: "Kaduna, Nigeria",
    startDate: "Apr 2018",
    endDate: "Aug 2020",
    grade: "Pass",
    field: "Physics",
  },
];
export const Activity = [
  {
    name: "Indiana Joannes",
    notification: "Started Introduction to Python Certification",
    period: "3 months",
    reactions: 10,
    icon: "/icons/frameOne.png"
  },
  {
    name: "John Wick",
    notification: "Started UI/UX Certification",
    period: "1 month",
    reactions: 5,
    icon: "/icons/frameOne.png"
  }
];
export const Licence = [
  {
    title: "Fundamentals of Project Management",
    icon: "/icons/frameOne.png",
    state: "Pending",
    period: "Jan 2023",
  },
  {
    title: "Fundamentals of Hacking",
    icon: "/icons/frameOne.png",
    state: "Issued",
    period: "Apr 2025",
  },
];