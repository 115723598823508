import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    email: "",
    password: "",
  },
  isFormValid: false,
  error: {
    email: "",
    password: ""
  },
  isRemembered: false 
};
const loginAdminSlice = createSlice({
  name: "loginAdmin",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = {...state.formData, ...action.payload};
    },
    setErrorData: (state, action) => {
      state.error = {...state.error, ...action.payload};
    },
    setFormValid: (state, action) => {
      state.isFormValid = action.payload;
    },
    setRememberMe: ((state, action) => {
      state.isRemembered = action.payload;
    })
  }
});

export const {setFormData, setErrorData, setFormValid, setRememberMe} = loginAdminSlice.actions;
export default loginAdminSlice.reducer;