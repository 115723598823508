import React, {useState, useEffect } from "react";
import "./culture.style.scss";
import { TextArea } from "../../../Materials/InputFields";
import { useNavigate } from "react-router-dom";
import { putApi, getApi } from "../../../Api/server";
import WarningCard from "../../Cards/WarningCard";
import SuccessCard from "../../Cards/SuccessCard";

const initialValues = {
  culture: "",
  idealWorkEnvironment: ""
};

const Culture = () => {
  const [userForm, setUserForm] = useState(initialValues);
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const countCulture = userForm.culture.trim().split(/\s+/).length;
  const workSpaceCount = userForm.idealWorkEnvironment.trim().split(/\s+/).length;
  const cultureMaxWords = 100;
  const workSpaceMaxWords = 75;
  const navigate = useNavigate();
  
  const handleCulture = (e) => {
    const value = e.target.value;
    setUserForm((prevState) => ({
      ...prevState,
      culture: value
    }));
  };
  const handleWorkSpace = (e) => {
    const value = e.target.value;
    setUserForm((prevState) => ({
      ...prevState,
      idealWorkEnvironment: value,
    }));
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    const charRegex = /[#$%^&*]/;
    if (countCulture < 50 || countCulture > cultureMaxWords)
    {
      setMessage("Your words must be more be between fifty and one hundred");
      setIsOpen(true);
      return;
    }else if (workSpaceCount < 30 || workSpaceCount > workSpaceMaxWords)
    {
      setMessage(
        "Your words must be more be between fifty and seventy-five"
      );
      setIsOpen(true);
      return;
    }
    else if (charRegex.test(userForm.culture) || charRegex.test(userForm.workSpaceEnvironment))
    {
      setMessage(
        "Your words must not contain unnecessary special characters like #$%^&*"
      );
      setIsOpen(true);
    } else
    {
      const data = {
        culture: userForm.culture,
        workSpaceEnvironment:
					userForm.idealWorkEnvironment,
      };
      const response = await putApi(
        "e1/update/job_seeker/profile/culture",
        data
      );
      if (response?.code === 403) {
        navigate("/login");
      }
      if (response?.code !== 200) {
        setMessage(response?.message);
        setIsOpen(true);
        return;
      } else {
        navigate(
          "/job_seeker/profile/verification"
        );
      }
    }
    
    
  };
  const handleContinue = (e, value) => {
    e.preventDefault();
    if (value === "")
    {
      setMessage("Empty field cannot be saved");
      setIsOpen(true);
    } else
    {
      setMessage("Your input is saved");
      setIsSuccess(true);
    }
  };

  useEffect(() => {
    const userData = async () => {
      let response = await getApi(
        "e1/job_seeker/profile/job_preferences"
      );
      if (!response) {
        return;
      } else if (response?.code === 403) {
        navigate("/login");
      } else if (response.code !== 200) {
        return;
      } else {
        const data = response?.data;
        setUserForm((prevState) => ({
          ...prevState,
          culture: data.culture,
          idealWorkEnvironment: data?.workSpaceEnvironment
          
        }));
        
      }
    };
    userData();
  }, []);

  return (
    <div className="CultureContainer">
      {isOpen && (
        <WarningCard
          isOpen={isOpen}
          message={message}
          onClose={setIsOpen}
        />
      )}
      {isSuccess && (
        <SuccessCard
          isOpen={isSuccess}
          message={message}
          onClose={setIsSuccess}
        />
      )}
      <div className="CultureInnerContainer">
        <div className="CultureHeader">
          <h3>Culture</h3>
        </div>
        <div className="CultureTextField">
          <TextArea
            label="What type of role would you like to be in?"
            value={userForm.culture}
            onChange={handleCulture}
            className="CultureTextArea"
            placeholder="Type in here"
            disabled={
              userForm.culture !== ""
                ? true
                : false
            }
          />
          <div
            className="wordCount"
            style={{ textAlign: "right" }}>
            <span>
              {countCulture}/{cultureMaxWords}
            </span>
          </div>
          <button
            onClick={(e) =>
              handleContinue(e, userForm.culture)
            }>
						Save
          </button>
        </div>
        <hr className="CultureDivider" />
        <div className="CultureTextField">
          <TextArea
            label="What is your ideal work environment?"
            value={userForm.idealWorkEnvironment}
            onChange={handleWorkSpace}
            className="CultureTextArea"
            placeholder="Type in here"
            disabled={
              userForm.idealWorkEnvironment !== ""
                ? true
                : false
            }
          />
          <div
            className="wordCount"
            style={{ textAlign: "right" }}>
            <span>
              {workSpaceCount}/{workSpaceMaxWords}
            </span>
          </div>
          <button
            onClick={(e) =>
              handleContinue(
                e,
                userForm.idealWorkEnvironment
              )
            }>
						Save
          </button>
        </div>
        <hr className="CultureDivider" />
        <div className="CultureProceedButton">
          <button onClick={handleSubmit}>
						Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Culture;