import { createSlice} from "@reduxjs/toolkit";

const initialState = {
  openChat: false,
  messages: [],
  typing: false,
  typingImage: false,
  openChatImage: false,
  input: "",
  imageInput: ""
};

const chatbotSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    setOpenChat: (state, action) => {
      state.openChat = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setTyping: (state, action) => {
      state.typing = action.payload;
    },
    setInput: (state, action) => {
      state.input = action.payload;
    },
    setTypingImage: (state, action) => {
      state.typingImage = action.payload;
    },
    setImageInput: (state, action) => {
      state.imageInput = action.payload;
    },
  },
});

export const {setOpenChat, setMessages, setTyping, setInput, setImageInput, setTypingImage} = chatbotSlice.actions;
export default chatbotSlice.reducer;