import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { company, job, relevance } from "../../../data/jobPost";
import "./RecruiterJobAddReview.style.scss";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

const JobReview = () => {
  const [selected, setSelected] = useState(null);
  const [active, setActive] = useState(null);
  const [isTab, setIsTab] = useState(0);
  const navigate = useNavigate;

  const { Criteria, Weights } = relevance.Weight;
  const totalWeight = Weights.reduce((acc, weight) => acc + weight, 0);
  const totalPoints = Object.values(relevance.Quantify.education).reduce((sum, value) => sum + value, 0);
  const totalScore = Object.values(relevance.Quantify.experience).reduce((sum, value) => sum + value, 0);
  
  const activeStyle = {
    borderBottom: "2px solid var(--Foundation-PRI-pri-500, #2A53A9)",
    color: "var(--Foundation-PRI-pri-500, #2A53A9)"
  };

  const click = (index) => {
    if (isTab == index) {
      return setIsTab(null);
    }
    setIsTab(index);
  };
  const handleTabIconClick = (direction) => {
    const numTabs = document.querySelectorAll(".Tab").length;
    let currentIndex = isTab;

    if (currentIndex !== -1) {
      let newIndex = direction === "back" ? currentIndex - 1 : currentIndex + 1;
      newIndex = (newIndex + numTabs) % numTabs; 
      setIsTab(newIndex);
    }
  };
  const toggle = (index) => {
    if (selected == index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  const select = (index) => {
    if (active == index) {
      return setActive(null);
    }
    setActive(index);
  };

  const handleCancelClick = () => {
    navigate("/recruiter/profile/home");
  };


  return (
    <div className="ReviewContainer">
      <div className="ReviewAccordionWrapper">
        <div className="ReviewAccordion">
          <div className="AccordionHeading" onClick={() => toggle(0)}>
            <span>
              <h3>About Company</h3>

            </span>
            <span>
              <MdKeyboardArrowDown className={selected === 0 ? "AccordionIcon rotate" : "AccordionIcon"} />
              {selected === 0 ? <p>Collapse</p> : <p>Expand</p> }
            </span>
          </div>
          <div className={selected === 0 ? "AccordionContent show" : "AccordionContent"}>
            <div className="CompanyInfo">
              <div className="Info">
                <p>Job Department</p>
                <span>
                  {company.department}
                </span>
              </div>
              <div className="Info">
                <p>Company Size</p>
                <span>
                  {company.size}
                </span>
              </div>
            </div>
            <hr className="PageDivider"/>
            <div className="AboutCompany">
              <p>About Company</p>
              <span>
                {company.about}
              </span>
            </div>
          </div>
        </div>
        <hr className="AccordionDivider"/>
        <div className="ReviewAccordion">
          <div className="AccordionHeading" onClick={() => toggle(1)}>
            <span>
              <h3>Job Details</h3>
            </span>
            <span>
              <MdKeyboardArrowDown className={selected === 1 ? "AccordionIcon rotate" : "AccordionIcon"} />
              {selected === 1 ? <p>Collapse</p> : <p>Expand</p> }
            </span>
          </div>
          <div className={selected === 1 ? "AccordionContent show" : "AccordionContent"}>
            <div className="JobInfos">
              <div className="JobInfo">
                <p>Job Title</p>
                <span>
                  {job.title}
                </span>
              </div>
              <div className="JobInfo">
                <p>Job Location</p>
                <span>
                  {job.location}
                </span>
              </div>
            </div>
            <div className="JobInfos">
              <div className="JobInfo">
                <p>Job Type</p>
                <span>
                  {job.type}
                </span>
              </div>
              <div className="JobInfo">
                <p>Salary Range</p>
                <span>
                  {job.salary}
                </span>
              </div>
            </div>
            <div className="JobInfos">
              <div className="JobInfo">
                <p>Work Hours</p>
                <span>
                  {job.hours}
                </span>
              </div>
              <div className="JobInfo">
                <p>Post Duration</p>
                <span>
                  {job.postDuration}
                </span>
              </div>
            </div>
            <div className="JobInfos">
              <div className="JobInfo">
                <p>Experience Level</p>
                <span>
                  {job.experience}
                </span>
              </div>
              <div className="JobInfo">
                <p>Skills</p>
                <span>
                  {job.skills.join(", ")}
                </span>
              </div>
            </div>
            <hr className="PageDivider"/>
            <div className="JobDesc">
              <p>Job Description</p>
              <span>
                {job.description}
              </span>
            </div>
          </div>
        </div>
        <hr className="AccordionDivider"/>
        <div className="ReviewAccordion">
          <div className="AccordionHeading" onClick={() => toggle(2)}>
            <span>
              <h3>Relevance Target</h3>
            </span>
            <span>
              <MdKeyboardArrowDown className={selected === 2 ? "AccordionIcon rotate" : "AccordionIcon"} />
              {selected === 2 ? <p>Collapse</p> : <p>Expand</p> }
            </span>
          </div>
          <div className={selected === 2 ? "AccordionContent show" : "AccordionContent"}>
            <div className="TabContainer">
              <div className="Tabs">
                <button 
                  className="Tab"
                  style={isTab === 0 ? activeStyle : {}}
                  onClick={() => click(0)}
                >
                  Criteria and Weight
                </button>
                <button 
                  className="Tab"
                  style={isTab === 1 ? activeStyle : {}}
                  onClick={() => click(1)}
                >
                  Target Score
                </button>
                <button 
                  className="Tab"
                  style={isTab === 2 ? activeStyle : {}}
                  onClick={() => click(2)}
                >
                  Quantify Criteria
                </button>
              </div>
              <div className="TabNav">
                <div className="IconWrapper" onClick={() => handleTabIconClick("back")}>
                  <MdArrowBackIosNew className="TabIcon" />
                </div>
                <div className="IconWrapper" onClick={() => handleTabIconClick("forward")}>
                  <MdArrowForwardIos className="TabIcon" />
                </div>
              </div>
            </div>
            <div className={isTab === 0 ? "CriteriaContent display" : "CriteriaContent"}>
              <h6>Define Criteria and Weight</h6>
              <p>Set criteria and allocate weight </p>
              <div className="SelectedFields">
                <div className="SelectedField">
                  <p>Criteria</p>
                  {Criteria.map((item, index) => (
                    <div className="SpanWrapper" key={index}>
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
                
                <div className="SelectedField">
                  <p>Weight</p>
                  {Weights.map((weight, index) => (
                    <div className="SpanWrapper" key={index}>
                      <span>{weight}%</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="WeightTotal">
                <h4>Total Weight</h4>
                <span>{totalWeight}%</span>
              </div>
            </div>
            <div className={isTab === 1 ? "TargetContent render" : "TargetContent"}>
              <h6>Set Your Target Score</h6>
              <p>This enables you to set your maximum score and define your target score</p>
              <div className="FieldAreas">
                <div className="ScoreWrapper">
                  <p>Maximum Score</p>
                  <span>
                    What is your maximum score?
                  </span>
                </div>
                <div className="FieldArea">
                  <p>Enter Maximum Score</p>
                  <span>{relevance.TargetScore.maxScore}%</span>
                </div>
              </div>
              <div className="FieldAreas">
                <div className="ScoreWrapper">
                  <p>Target Score</p>
                  <span>
                    What is your target score?
                  </span>
                </div>
                <div className="FieldArea">
                  <p>Enter Target Score</p>
                  <span>{relevance.TargetScore.targetScore}%</span>
                </div>
              </div>
            </div>
            <div className={isTab === 2 ? "QuantifyContent reveal" :"QuantifyContent"}>
              <h6>Quantify your defined criteria</h6>
              <p>Allocate point to your defined criteria</p>
              <span className="Text">
                Note: Your maximum score determines the spread 
                of your points. You can not enter a value that 
                exceeds your maximum score. 
                For a given criteria, the system will consider 
                the highest score as quantified for the criteria
              </span>
              <div className="AccordionContainer">
                <div className="Accordion">
                  <div className="AccordionHead" onClick={() => select(0)}>
                    <h2>Education</h2>
                    <span className="IconWrapper">
                      <MdKeyboardArrowDown className={active === 0 ? "Icon rotate" : "Icon"} />
                      { active === 0 ? <p>Collapse</p> : <p>Expand</p> }
                    </span>
                  </div>
                  <div className={active === 0 ? "AccordionCont view" : "AccordionCont"}>
                    <div className="DataAreas">
                      <div className="Texts">
                        <p>Levels</p>
                        <p>Spread your points from 0 to maximum score</p>
                      </div>
                      {Object.entries(relevance.Quantify.education).map(([degree, value]) => (
                        <div className="SpanArea" key={degree}>
                          <span>{degree}</span>
                          <span>{value}</span>
                        </div>
                      ))}
                    </div>
                    <div className="TotalPoints">
                      <h5>Total Points</h5>
                      <span>{totalPoints}</span>
                    </div>
                  </div>
                </div>
                <div className="Accordion">
                  <div className="AccordionHead" onClick={() => select(1)}>
                    <h2>Experience</h2>
                    <span className="IconWrapper">
                      <MdKeyboardArrowDown className={active === 1 ? "Icon rotate" : "Icon"} />
                      { active === 1 ? <p>Collapse</p> : <p>Expand</p> }
                    </span>
                  </div>
                  <div className={active === 1 ? "AccordionCont view" : "AccordionCont"}>
                    <div className="DataAreas">
                      <div className="Texts">
                        <p>Levels</p>
                        <p>Spread your points from 0 to maximum score</p>
                      </div>
                      {Object.entries(relevance.Quantify.experience).map(([level, value]) => (
                        <div className="SpanArea" key={level}>
                          <span>{level}</span>
                          <span>{value}</span>
                        </div>
                      ))}
                    </div>
                    <div className="TotalPoints">
                      <h5>Total Points</h5>
                      <span>{totalScore}</span>
                    </div>
                  </div>
                </div>
                <div className="Accordion">
                  <div className="AccordionHead" onClick={() => select(2)}>
                    <h2>Skills</h2>
                    <span className="IconWrapper">
                      <MdKeyboardArrowDown className={active === 2 ? "Icon rotate" : "Icon"} />
                      { active === 2 ? <p>Collapse</p> : <p>Expand</p> }
                    </span>
                  </div>
                  <div className={active === 2 ? "AccordionCont view" : "AccordionCont"}>
                    <div className="DataAreas">
                      <div className="Texts">
                        <p>Levels</p>
                        <p>Spread your points from 0 to maximum score</p>
                      </div>
                      {Object.entries(relevance.Quantify.skills).map(([skill, value]) => (
                        <div className="SpanArea" key={skill}>
                          <span>{skill}</span>
                          <span>{value}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="ReviewButtons">
        <button>Cancel</button>
        <button>Publish</button>
      </div>
    </div>
  );
};

export default JobReview;