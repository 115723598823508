import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import PropTypes from "prop-types";
import Avatar from "../../../images/Avatar1.png";
import { TextField, InputButton } from "../../../Materials/InputFields";

import "./MenteeGradingModal.style.scss";

const MenteeGradingModal = ({ onClose }) => {
  return (
    <div className="GradingModalContainer">
      <div className="GradingModal">
        <div className="GradingTopModalSection">
          <p>Mentees Detail</p>
          <IoCloseCircleOutline className="GradingModalCloseIcon" onClick={onClose} />
        </div>
        <div className="GradingBottomModalSection">
          <div className="ModalAvatarWrapper">
            <img src={Avatar} alt="Avatar" />
          </div>
          <div className="MenteeModalDetails">
            <h3>John Melody Doe</h3>
            <div className="MenteeParticulars">
              <div className="ParticularsKeys">
                <p>Phone Number</p>
                <p>Email Address</p>
                <p>Course Enrolled</p>
                <p>Sessions</p>
                <p>Gradings</p>
                <p>Recommendations</p>
              </div>
              <div className="ParticularsValues">
                <p>+234 (0)813 782 003 8</p>
                <p>Johnmelodydoe@gmail.com</p>
                <p>Product Designer</p>
                <p>hours:{" "} <span>300</span>/500</p>
                <p><span>80</span>/120</p>
                <input className="MenteeRecommendations" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div className="ModalFormArea">
          <form>
            <TextField
              name="grading"
              label="Enter gradings here"
              type="text"
              value=""
              className={"GradingTextField"} 
              id="GradingTextField"
            />
            <TextField
              name="recommendation"
              label="Add Recommendations"
              placeholder="Add recommendations here"
              type="text"
              value=""
              className={"RecommendationTextField"} 
              id="RecommendationTextField"
            />
            <div className="FormButtons">
              <InputButton className="GradingModalButton" name={"Save"} />
              <InputButton className={"CancelButton"} name={"Cancel"} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
MenteeGradingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};  
export default MenteeGradingModal;
