import React, { lazy, Suspense } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LinearIndeterminate } from "./Materials/Materials";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MyContextProvider } from "./Utils/ContextProvider";
import JsBarLayout from "./components/JobSeekerProfile/JsBarLayout/JsBarLayout";
import CoachBarLayout from "./components/CoachProfile/CoachBarLayout/CoachBarLayout";
import AdminBarLayout from "./components/AdminProfile/AdminBarLayout/AdminBarLayout";
import RecruiterBarLayout from "./components/RecruiterProfile/RecruiterBarLayout/RecruiterBarLayout";
import SettingsLayout from "./components/CoachSettings/SettingsLayout/SettingsLayout";
import EtherLogo from "./static/images/logo/Ether Logo.png";
import AddResources from "./components/AdminAddResource/AddResources/AddResources";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

const LandingPage = lazy(() => import("./components/LandingPages/LandingPage"));
const RegisterPage = lazy(() =>
  import("./components/RegisterPage/RegisterPage")
);
const ForgetPassword = lazy(() =>
  import("./components/ForgetPassword/ForgetPassword")
);
const ResetPassword = lazy(() =>
  import("./components/ResetPassword/ResetPassword")
);
const ErrorGeneralPage = lazy(() =>
  import("./components/page404/ErrorGeneralPage")
);
const LoginPage = lazy(() => import("./components/Login/Login"));
const SignUpPage = lazy(() => import("./components/JsSignUpPage/JsSignUpPage"));
const CoachSignUpPage = lazy(() =>
  import("./components/CoachSignUpPage/CoachSignUpPage")
);
const CoachLoginPage = lazy(() =>
  import("./components/CoachSignUpPage/CoachLoginPage")
);
const CoachForgetPage = lazy(() =>
  import("./components/CoachSignUpPage/CoachForgetPassword")
);
const CoachResetPage = lazy(() =>
  import("./components/CoachSignUpPage/CoachResetPage")
);
const CoachProfileSetup = lazy(() =>
  import("./components/CoachProfile/CoachProfileSetupPage/ProfileSetUpPage")
);
const CoachHomePage = lazy(() =>
  import("./components/CoachProfile/CoachHomePage/CoachHomePage")
);
const VerificationPage = lazy(() =>
  import("./components/VerificationPage/VerificationPage")
);
const RecruiterVerificationPage = lazy(() =>
  import("./components/RecruiterSignup/RecruiterVerificationPage")
);
const RoadMapSetup = lazy(() =>
  import("./components/CoachProfile/CoachRoadMap/CoachRoadmapFilledSetup")
);
const ProfileOne = lazy(() =>
  import("./components/JobSeekerProfile/JsProfileOne/JsProfileRoot")
);
const JsHomePage = lazy(() =>
  import("./components/JobSeekerProfile/JobSeekerHome/JsHomePage")
);
const JsJobsPage = lazy(() =>
  import("./components/JobSeekerProfile/JobSeekerJobs/JsJobs")
);
const JsAppliedPage = lazy(() =>
  import("./components/JobSeekerProfile/JobSeekerApplied/JsApplied")
);
const JsMessagingPage = lazy(() =>
  import("./components/JobSeekerProfile/JobSeekerMessages/JsMessenger")
);
const JsLibraryPage = lazy(() =>
  import("./components/JobSeekerProfile/JobSeekerLibrary/JsLibrary")
);
const JsLibraryRecentCourses = lazy(() =>
  import(
    "./components/JobSeekerProfile/JobSeekerLibrary/JsLibraryRecentCourses"
  )
);
const JsLibraryCompleteCourses = lazy(() =>
  import("./components/JobSeekerProfile/JobSeekerLibrary/JsLibraryComplete")
);
const JsLibraryWishlist = lazy(() =>
  import("./components/JobSeekerProfile/JobSeekerLibrary/JsLibraryWishlist")
);
const JsLibraryCourseDetails = lazy(() =>
  import(
    "./components/JobSeekerProfile/JobSeekerLibrary/JsLibraryCourseDetails"
  )
);
const JsLibraryCourseContent = lazy(() =>
  import(
    "./components/JobSeekerProfile/JobSeekerLibrary/JsLibraryCourseContent"
  )
);
const JsLibraryCoachRoadmap = lazy(() =>
  import("./components/JobSeekerProfile/JobSeekerLibrary/JsLibraryCoachRoadMap")
);
const JsCoachesPage = lazy(() =>
  import("./components/JobSeekerProfile/JobSeekerCoaches/JsCoaches")
);
const AddEducationPage = lazy(() =>
  import("./components/JobSeekerProfile/AddEducation/AddEducation")
);
const AddExperiencePage = lazy(() =>
  import("./components/JobSeekerProfile/AddExperience/AddExperience")
);
const EditEducationPage = lazy(() =>
  import("./components/JobSeekerProfile/EditEducation/EditEducation")
);
const EditExperiencePage = lazy(() =>
  import("./components/JobSeekerProfile/EditExperience/EditExperience")
);
const JobApplicationPage = lazy(() =>
  import("./components/JobSeekerProfile/JobApplicationPage/JobApplicationPage")
);
const CoachProfileRoadmap = lazy(() =>
  import("./components/CoachProfile/CoachRoadMap/CoachRoadMap")
);
const CoachAccountSettings = lazy(() =>
  import("./components/CoachSettings/CoachAccountSettings/CoachAccountSettings")
);
const AdminPasskey = lazy(() =>
  import("./components/AdminSignup/AdminPasskeyPage")
);
const RegisterAdmin = lazy(() =>
  import("./components/AdminSignup/AdminRegister")
);
const AdminLogin = lazy(() => import("./components/AdminSignup/AdminLogin"));
const AdminForgetPassword = lazy(() =>
  import("./components/AdminSignup/AdminForgetPassword")
);
const AdminResetPassword = lazy(() =>
  import("./components/AdminSignup/AdminResetPassword")
);
const AdminDashboard = lazy(() =>
  import("./components/AdminProfile/AdminDashBoard/AdminDashboard")
);
const AdminResources = lazy(() =>
  import("./components/AdminResources/AdminResources")
);
const AdminUserTable = lazy(() =>
  import("./components/AdminSettings/AdminUser")
);
const RecruiterSignUp = lazy(() =>
  import("./components/RecruiterSignup/RecruiterSignup")
);
const RecruiterLoginPage = lazy(() =>
  import("./components/RecruiterSignup/RecruiterLoginPage")
);
const RecruiterProfileSetup = lazy(() =>
  import(
    "./components/RecruiterProfile/RecruiterProfileSetup/RecruiterProfileSetup"
  )
);
const ResourcesLayoutPage = lazy(() =>
  import("./components/AdminResources/ResourcesLayout")
);
const AddResourcesLayout = lazy(() =>
  import("./components/AdminAddResource/AddResources/AddResourceLayout")
);
const CreateAppointmentPage = lazy(() =>
  import("./components/CoachCalendar/CreateAppointment")
);

// const FilterOptions = lazy(() => import("./components/JobSeekerProfile/JobSeekerLibrary/JsLibraryFilterOptions"));

import JobView from "./components/RecruiterProfile/RecruiterApplicants/JobView";
import ProfileDetail from "./components/JobSeekerProfile/ProfileDetail/ProfileDetail";
import Preferences from "./components/JobSeekerProfile/Preferences/Preferences";
import Culture from "./components/JobSeekerProfile/Culture/Culture";
import Verification from "./components/JobSeekerProfile/Verification/Verification";
import ConfirmVerification from "./components/JobSeekerProfile/ConfirmVerification/ConfirmVerification";
import CoachProfileDetail from "./components/CoachProfile/CoachProfileDetails/CoachProfileDetail";
import CoachPreferences from "./components/CoachProfile/CoachPreference/CoachPreferences";
import CoachCulturePage from "./components/CoachProfile/CoachCulture/CoachCulturePage";
import CoachVerificationPage from "./components/CoachProfile/CoachVerification/CoachVerificationPage";
import CoachConfirmVerification from "./components/CoachProfile/CoachConfirmVerification/CoachConfirmVerification";
import CoachVerified from "./components/CoachProfile/CoachVerified/CoachVerified";
import FilledCoachProfile from "./components/CoachProfile/FilledCoachProfile/FilledCoachProfile";
import CoachMentee from "./components/CoachProfile/CoachMentee/CoachMentee";
import CoachCourseDetails from "./components/CoachProfile/CoachRoadMap/CoachCourseDetails";
import CoachNewRoadMap from "./components/CoachProfile/CoachRoadMap/CoachNewRoadMap";
import CoachCourseResources from "./components/CoachProfile/CoachRoadMap/CoachCourseResources";
import CoachCourseQuiz from "./components/CoachProfile/CoachRoadMap/CoachCourseQuiz";
import CoachCourseSummary from "./components/CoachProfile/CoachRoadMap/CoachCourseSummary";
import CoachFilledResources from "./components/CoachProfile/CoachRoadMap/CoachFilledResources";
import CoachFilledQuiz from "./components/CoachProfile/CoachRoadMap/CoachFilledQuiz";
import JobSeekerProfile from "./components/JobSeekerProfile/JobSeekerProfile/JobSeekerProfile";
import JsArchivedApplied from "./components/JobSeekerProfile/JobSeekerApplied/JsArchivedApplied";
import JsRecentApplied from "./components/JobSeekerProfile/JobSeekerApplied/JsRecentApplied";
import JsMyCoach from "./components/JobSeekerProfile/JobSeekerCoaches/JsMyCoach";
import JsAllCoaches from "./components/JobSeekerProfile/JobSeekerCoaches/JsAllCoaches";
import CoachPage from "./components/JobSeekerProfile/JobSeekerCoaches/CoachPage";
import CoachNavBar from "./components/CoachProfile/CoachNavBar/CoachNavBar";
import CoachMessages from "./components/JobSeekerProfile/JobSeekerCoaches/CoachMessages";
import CoachRoadmap from "./components/JobSeekerProfile/JobSeekerCoaches/CoachRoadmap";
import CoachCalendar from "./components/CoachCalendar/CoachCalendar";
import CoachProgress from "./components/JobSeekerProfile/JobSeekerCoaches/CoachProgress";
import CoachMessage from "./components/JobSeekerProfile/JobSeekerCoaches/CoachMessage";
import CoachNotificationSettings from "./components/CoachSettings/CoachNotificationSettings/CoachNotificationSettings";
import CoachSecuritySettings from "./components/CoachSettings/CoachSecuritySettings/CoachSecuritySettings";
import RecruiterProfileDetail from "./components/RecruiterProfile/RecruiterProfileDetails/RecruiterProfileDetail";
import RecruiterJobPreferences from "./components/RecruiterProfile/RecruiterJobPreferences/RecruiterJobPreferences";
import RecruiterRecruitmentProcess from "./components/RecruiterProfile/RecruiterRecruitmentProcess/RecruiterRecruitmentProcess";
import RecruiterProfileVerification from "./components/RecruiterProfile/RecruiterProfileVerification/RecruiterProfileVerification";
import RecruiterHomepage from "./components/RecruiterProfile/RecruiterHomePage/RecruiterHomepage";
import RecruiterApplicantsPage from "./components/RecruiterProfile/RecruiterApplicants/RecruiterApplicantsPage";
import RecruiterApplicantDetails from "./components/RecruiterProfile/RecruiterApplicants/RecruiterApplicantDetails";
import RecruiterHiringStage from "./components/RecruiterProfile/RecruiterApplicants/RecruiterHiringStage";
import RecruiterJobList from "./components/RecruiterProfile/RecruiterJob/RecruiterJobList";
import RecruiterJobInfo from "./components/RecruiterProfile/RecruiterJob/RecruiterJobDetails";
import RecruiterJobEdit from "./components/RecruiterProfile/RecruiterJob/RecruiterJobEdit";
import RecruiterHiringStatus from "./components/RecruiterProfile/RecruiterJob/RecruiterHiringStatus";
import ResourcesCourseIndex from "./components/AdminResources/ResourcesCourseIndex";
import ResourcesIndex from "./components/AdminResources/ResourcesIndex";
import ResourceQuiz from "./components/AdminResources/ResourceQuiz";
import ResourceCertificate from "./components/AdminResources/ResourceCertificate";
import AddCourseResource from "./components/AdminAddResource/AddResources/AddCourseResource";
import AddQuiz from "./components/AdminAddResource/AddResources/AddQuiz";
import AddCert from "./components/AdminAddResource/AddResources/AddCert";
import AdminSettings from "./components/AdminSettings/AdminSettings";
import RecruiterJobAdd, {
  CompanyDetails,
  JobDetails
} from "./components/RecruiterProfile/RecruiterJob/RecruiterJobAdd";
import RecruiterMessage from "./components/RecruiterProfile/RecruiterMessage/RecruiterMessage";
import AdminUserMgt from "./components/AdminProfile/AdminUsers/AdminUserMgt";
import AdminUserJobseeker from "./components/AdminProfile/AdminUsers/AdminUserJobseeker";
import AdminUserCoach from "./components/AdminProfile/AdminUsers/AdminUserCoach";
import AdminUserRecruiter from "./components/AdminProfile/AdminUsers/AdminUserRecruiter";
import AdminAccountSettings from "./components/AdminSettings/AdminAccountSettings";
import RoleTable from "./components/AdminSettings/AdminRoleTable";
import RelevanceTargetLayout, {
  CriteriaWeight,
  Quantify,
  TargetScore
} from "./components/RecruiterProfile/RecruiterJob/RecruiterRelevanceTarget";
import JobReview from "./components/RecruiterProfile/RecruiterJob/RecruiterJobAddReview";
import RecruiterViewApplicant from "./components/RecruiterProfile/RecruiterJob/RecruiterViewApplicant";
import RecruiterAllApplicants, {
  HighlyRecommendedApplicants,
  NotRecommendedApplicants,
  RecommendedApplicants
} from "./components/RecruiterProfile/RecruiterApplicants/RecruiterAllApplicants";
import TopSection from "./components/RecruiterProfile/RecruiterJob/TopSection";
import RecruiterJobHiringStage from "./components/RecruiterProfile/RecruiterJob/RecruiterJobHiringStage";

const JobSeekerHomePage = () => {
  return (
    <JsBarLayout>
      <JsHomePage />
    </JsBarLayout>
  );
};
const CoachProfile = () => {
  return (
    <CoachBarLayout>
      <CoachProfileSetup tabLink={<CoachProfileDetail />} />
    </CoachBarLayout>
  );
};
const CoachHome = () => {
  return (
    <CoachBarLayout>
      <CoachHomePage />
    </CoachBarLayout>
  );
};
const CoachPreferencePage = () => {
  return (
    <CoachBarLayout>
      <CoachProfileSetup tabLink={<CoachPreferences />} />
    </CoachBarLayout>
  );
};
const CoachCulture = () => {
  return (
    <CoachBarLayout>
      <CoachProfileSetup tabLink={<CoachCulturePage />} />
    </CoachBarLayout>
  );
};
const CoachVerification = () => {
  return (
    <CoachBarLayout>
      <CoachProfileSetup tabLink={<CoachVerificationPage />} />
    </CoachBarLayout>
  );
};
const CoachVerify = () => {
  return (
    <CoachBarLayout>
      <CoachConfirmVerification />
    </CoachBarLayout>
  );
};
const CoachVerifiedPage = () => {
  return (
    <CoachBarLayout>
      <CoachVerified />
    </CoachBarLayout>
  );
};
const CoachCalendarPage = () => {
  return (
    <CoachBarLayout>
      <CoachCalendar />
    </CoachBarLayout>
  );
};
const FilledProfile = () => {
  return (
    <CoachBarLayout>
      <FilledCoachProfile />
    </CoachBarLayout>
  );
};
const Mentee = () => {
  return (
    <CoachBarLayout>
      <CoachMentee />
    </CoachBarLayout>
  );
};
const RoadMap = () => {
  return (
    <CoachBarLayout>
      <CoachProfileRoadmap />
    </CoachBarLayout>
  );
};
const CoachCourse = () => {
  return (
    <CoachBarLayout>
      <RoadMapSetup>
        <CoachCourseDetails />
      </RoadMapSetup>
    </CoachBarLayout>
  );
};
const NewRoadMap = () => {
  return (
    <CoachBarLayout>
      <CoachNewRoadMap />
    </CoachBarLayout>
  );
};
const CourseResources = () => {
  return (
    <CoachBarLayout>
      <CoachCourseResources />
    </CoachBarLayout>
  );
};
const CourseQuiz = () => {
  return (
    <CoachBarLayout>
      <CoachCourseQuiz />
    </CoachBarLayout>
  );
};
const CourseSummary = () => {
  return (
    <CoachBarLayout>
      <CoachCourseSummary />
    </CoachBarLayout>
  );
};
const FilledResources = () => {
  return (
    <CoachBarLayout>
      <RoadMapSetup>
        <CoachFilledResources />
      </RoadMapSetup>
    </CoachBarLayout>
  );
};
const FilledQuiz = () => {
  return (
    <CoachBarLayout>
      <RoadMapSetup>
        <CoachFilledQuiz />
      </RoadMapSetup>
    </CoachBarLayout>
  );
};

const JobSeekerProfilePage = () => {
  return (
    <JsBarLayout>
      <JobSeekerProfile />
    </JsBarLayout>
  );
};
const JobSeekerJobsPage = () => {
  return (
    <JsBarLayout>
      <JsJobsPage />
    </JsBarLayout>
  );
};
const JobSeekerAppliedPage = () => {
  return (
    <JsBarLayout>
      <JsAppliedPage />
    </JsBarLayout>
  );
};
const JobSeekerCoachesPage = () => {
  return (
    <JsBarLayout>
      <CoachPage />
    </JsBarLayout>
  );
};
const JobSeekerRecentAppliedPage = () => {
  return (
    <JsBarLayout>
      <JsAppliedPage>
        <JsRecentApplied />
      </JsAppliedPage>
    </JsBarLayout>
  );
};
const JobSeekerArchievedAppliedPage = () => {
  return (
    <JsBarLayout>
      <JsAppliedPage>
        <JsArchivedApplied />
      </JsAppliedPage>
    </JsBarLayout>
  );
};
const JobSeekerMessagingPage = () => {
  return (
    <JsBarLayout>
      <JsMessagingPage />
    </JsBarLayout>
  );
};
const JobSeekerLibraryPage = () => {
  return (
    <JsBarLayout>
      <JsLibraryPage />
    </JsBarLayout>
  );
};
const JobSeekerCourseModule = () => {
  return (
    <JsBarLayout>
      <JsLibraryCourseContent />
    </JsBarLayout>
  );
};
const JobSeekerLibraryRecent = () => {
  return (
    <JsBarLayout>
      <JsLibraryRecentCourses />
    </JsBarLayout>
  );
};
const JobSeekerCompletedCourses = () => {
  return (
    <JsBarLayout>
      <JsLibraryCompleteCourses />
    </JsBarLayout>
  );
};
const JobSeekerWishList = () => {
  return (
    <JsBarLayout>
      <JsLibraryWishlist />
    </JsBarLayout>
  );
};
const JobSeekerLibraryCoachroadmap = () => {
  return (
    <JsBarLayout>
      <JsLibraryCoachRoadmap />
    </JsBarLayout>
  );
};
const JobSeekerMyCoachesPage = () => {
  return (
    <JsBarLayout>
      <JsCoachesPage>
        <JsMyCoach />
      </JsCoachesPage>
    </JsBarLayout>
  );
};
const JobSeekerAllCoachesPage = () => {
  return (
    <JsBarLayout>
      <JsCoachesPage>
        <JsAllCoaches />
      </JsCoachesPage>
    </JsBarLayout>
  );
};
const JobSeekerCoachMessages = () => {
  return (
    <JsBarLayout>
      <CoachMessages>
        <CoachMessage />
      </CoachMessages>
    </JsBarLayout>
  );
};
const JobSeekerCoachRoadmap = () => {
  return (
    <JsBarLayout>
      <CoachMessages>
        <CoachRoadmap />
      </CoachMessages>
    </JsBarLayout>
  );
};
const JobSeekerCoachProgress = () => {
  return (
    <JsBarLayout>
      <CoachMessages>
        <CoachProgress />
      </CoachMessages>
    </JsBarLayout>
  );
};

const ProfileDetails = () => {
  return (
    <JsBarLayout>
      <ProfileOne tabLink={<ProfileDetail />} />
    </JsBarLayout>
  );
};
const JobPreferences = () => {
  return (
    <JsBarLayout>
      <ProfileOne tabLink={<Preferences />} />
    </JsBarLayout>
  );
};
const JobCulture = () => {
  return (
    <JsBarLayout>
      <ProfileOne tabLink={<Culture />} />
    </JsBarLayout>
  );
};
const JobVerification = () => {
  return (
    <JsBarLayout>
      <ProfileOne tabLink={<Verification />} />
    </JsBarLayout>
  );
};
const ConfirmJobVerification = () => {
  return (
    <JsBarLayout>
      <ProfileOne tabLink={<ConfirmVerification />} />
    </JsBarLayout>
  );
};
const CoachAccount = () => {
  return (
    <SettingsLayout>
      <CoachAccountSettings />
    </SettingsLayout>
  );
};
const CoachNotification = () => {
  return (
    <SettingsLayout>
      <CoachNotificationSettings />
    </SettingsLayout>
  );
};
const CoachSecurity = () => {
  return (
    <SettingsLayout>
      <CoachSecuritySettings />
    </SettingsLayout>
  );
};
const AdminHome = () => {
  return (
    <AdminBarLayout>
      <AdminDashboard />
    </AdminBarLayout>
  );
};
const AdminResourcesPage = () => {
  return (
    <AdminBarLayout>
      <AdminResources />
    </AdminBarLayout>
  );
};
const AdminSettingsPage = () => {
  return (
    <AdminBarLayout>
      <AdminSettings />
    </AdminBarLayout>
  );
};
const AccountAdminSettings = () => {
  return (
    <AdminBarLayout>
      <AdminAccountSettings />
    </AdminBarLayout>
  );
};
const AdminRolesPage = () => {
  return (
    <AdminBarLayout>
      <RoleTable />
    </AdminBarLayout>
  );
};
const AdminUserPage = () => {
  return (
    <AdminBarLayout>
      <AdminUserTable />
    </AdminBarLayout>
  );
};

const ResourcesIndexPage = () => {
  return (
    <AdminBarLayout>
      <ResourcesLayoutPage>
        <ResourcesCourseIndex />
      </ResourcesLayoutPage>
    </AdminBarLayout>
  );
};
const ResourcesPage = () => {
  return (
    <AdminBarLayout>
      <ResourcesLayoutPage>
        <ResourcesIndex />
      </ResourcesLayoutPage>
    </AdminBarLayout>
  );
};
const ResourcesQuizPage = () => {
  return (
    <AdminBarLayout>
      <ResourcesLayoutPage>
        <ResourceQuiz />
      </ResourcesLayoutPage>
    </AdminBarLayout>
  );
};
const ResourceCertificatePage = () => {
  return (
    <AdminBarLayout>
      <ResourcesLayoutPage>
        <ResourceCertificate />
      </ResourcesLayoutPage>
    </AdminBarLayout>
  );
};
const AddResourcesPage = () => {
  return (
    <AdminBarLayout>
      <AddResourcesLayout>
        <AddResources />
      </AddResourcesLayout>
    </AdminBarLayout>
  );
};
const AddCourseResourcesPage = () => {
  return (
    <AdminBarLayout>
      <AddResourcesLayout>
        <AddCourseResource />
      </AddResourcesLayout>
    </AdminBarLayout>
  );
};
const AddQuizPage = () => {
  return (
    <AdminBarLayout>
      <AddResourcesLayout>
        <AddQuiz />
      </AddResourcesLayout>
    </AdminBarLayout>
  );
};
const AddCertPage = () => {
  return (
    <AdminBarLayout>
      <AddResourcesLayout>
        <AddCert />
      </AddResourcesLayout>
    </AdminBarLayout>
  );
};

const AdminUsers = () => {
  return (
    <AdminBarLayout>
      <AdminUserMgt />
    </AdminBarLayout>
  );
};
const UserJobSeeker = () => {
  return (
    <AdminBarLayout>
      <AdminUserJobseeker />
    </AdminBarLayout>
  );
};
const UserCoach = () => {
  return (
    <AdminBarLayout>
      <AdminUserCoach />
    </AdminBarLayout>
  );
};
const UserRecruiter = () => {
  return (
    <adminBarLayout>
      <AdminUserRecruiter />
    </adminBarLayout>
  );
};

const RecruiterProfileEdit = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterProfileSetup tabLink={<RecruiterProfileDetail />} />
    </RecruiterBarLayout>
  );
};
const RecruiterPrefrences = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterProfileSetup tabLink={<RecruiterJobPreferences />} />
    </RecruiterBarLayout>
  );
};
const RecruitmentProcess = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterProfileSetup tabLink={<RecruiterRecruitmentProcess />} />
    </RecruiterBarLayout>
  );
};
const RecruiterVerification = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterProfileSetup tabLink={<RecruiterProfileVerification />} />
    </RecruiterBarLayout>
  );
};
const RecruiterHome = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterHomepage />
    </RecruiterBarLayout>
  );
};
const RecruiterApplicants = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterApplicantsPage />
    </RecruiterBarLayout>
  );
};
const RecruiterApplicantions = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterViewApplicant />
    </RecruiterBarLayout>
  );
};
const AllApplicants = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterAllApplicants />
    </RecruiterBarLayout>
  );
};
const HighlyRecommended = () => {
  return (
    <RecruiterBarLayout>
      <HighlyRecommendedApplicants />
    </RecruiterBarLayout>
  );
};
const Recommended = () => {
  return (
    <RecruiterBarLayout>
      <RecommendedApplicants />
    </RecruiterBarLayout>
  );
};
const NotRecommended = () => {
  return (
    <RecruiterBarLayout>
      <NotRecommendedApplicants />
    </RecruiterBarLayout>
  );
};
const ApplicantDetails = () => {
  return (
    <JobView>
      <RecruiterApplicantDetails />
    </JobView>
  );
};
const ApplicantHiringStage = () => {
  return (
    <JobView>
      <RecruiterHiringStage />
    </JobView>
  );
};
const RecruiterJobs = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterJobList />
    </RecruiterBarLayout>
  );
};
const RecruiterJobDetail = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterJobInfo />
    </RecruiterBarLayout>
  );
};
const RecruiterJobsHiringStatus = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterHiringStatus />
    </RecruiterBarLayout>
  );
};
// const RecruiterJobsApplicantDetails = () => {
//   return (
//     <RecruiterBarLayout>
//       <TopSection>
//         <RecruiterJobApplicantDetails />
//       </TopSection>
//     </RecruiterBarLayout>
//   );
// };
const RecruiterJobsHiringStage = () => {
  return (
    <RecruiterBarLayout>
      <TopSection>
        <RecruiterJobHiringStage />
      </TopSection>
    </RecruiterBarLayout>
  );
};
const JobEdit = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterJobEdit />
    </RecruiterBarLayout>
  );
};
const Company = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterJobAdd>
        <CompanyDetails />
      </RecruiterJobAdd>
    </RecruiterBarLayout>
  );
};
const Details = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterJobAdd>
        <JobDetails />
      </RecruiterJobAdd>
    </RecruiterBarLayout>
  );
};
const RelevanceWeight = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterJobAdd>
        <RelevanceTargetLayout tab={<CriteriaWeight />} />
      </RecruiterJobAdd>
    </RecruiterBarLayout>
  );
};
const RelevanceScore = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterJobAdd>
        <RelevanceTargetLayout tab={<TargetScore />} />
      </RecruiterJobAdd>
    </RecruiterBarLayout>
  );
};
const RelevanceCriteria = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterJobAdd>
        <RelevanceTargetLayout tab={<Quantify />} />
      </RecruiterJobAdd>
    </RecruiterBarLayout>
  );
};
const Review = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterJobAdd>
        <JobReview />
      </RecruiterJobAdd>
    </RecruiterBarLayout>
  );
};
const RecruiterChat = () => {
  return (
    <RecruiterBarLayout>
      <RecruiterMessage />
    </RecruiterBarLayout>
  );
};

function App() {
  return (
    <MyContextProvider>
      <div className="App">
        <ToastContainer />
        <Router>
          <Suspense
            fallback={
              <div className="suspense">
                <img src={EtherLogo} alt="Ether" />
                <LinearIndeterminate />
              </div>
            }
          >
            <Routes>
              <Route path={"/"} exact Component={LandingPage} />
              <Route
                path={"/forgot-password"}
                exact
                Component={ForgetPassword}
              />
              <Route path={"/reset-password"} exact Component={ResetPassword} />
              <Route path={"/register"} exact Component={RegisterPage} />
              <Route
                path={"register/job_seeker"}
                exact
                Component={SignUpPage}
              />
              <Route path={"/login"} exact Component={LoginPage} />
              <Route
                path={"/register/verify_user"}
                exact
                Component={VerificationPage}
              />
              <Route exact path="/linkedin" component={LinkedInCallback} />
              <Route
                path={"/register/coach"}
                exact
                Component={CoachSignUpPage}
              />
              <Route path={"/login/coach"} exact Component={CoachLoginPage} />
              <Route
                path={"/forgot_password/coach"}
                exact
                Component={CoachForgetPage}
              />
              <Route path={"/reset/coach"} exact Component={CoachResetPage} />
              <Route
                path={"/coach/profile/profile_setup"}
                exact
                Component={CoachProfile}
              />
              <Route path={"/coach_navbar"} exact Component={CoachNavBar} />
              <Route
                path={"/coach/profile/preferences"}
                exact
                Component={CoachPreferencePage}
              />
              <Route
                path={"/coach/profile/culture"}
                exact
                Component={CoachCulture}
              />
              <Route
                path={"/coach/profile/verification"}
                exact
                Component={CoachVerification}
              />
              <Route
                path={"/coach/profile/calendar"}
                exact
                Component={CoachCalendarPage}
              />
              <Route
                path={"/coach/profile/confirm_verify"}
                exact
                Component={CoachVerify}
              />
              <Route
                path={"/coach/profile/verified"}
                exact
                Component={CoachVerifiedPage}
              />
              <Route
                path={"/coach/profile/filled"}
                exact
                Component={FilledProfile}
              />
              <Route path={"/coach/home"} exact Component={CoachHome} />
              <Route
                path={"/coach/profile/calendar/create_appointment"}
                exact
                Component={CreateAppointmentPage}
              />
              <Route path={"/coach/profile/mentees"} exact Component={Mentee} />
              <Route
                path={"/coach/profile/roadmap"}
                exact
                Component={RoadMap}
              />
              <Route
                path={"/coach/profile/roadmap/course_details"}
                exact
                Component={CoachCourse}
              />
              <Route
                path={"/coach/profile/roadmap/add_roadmap"}
                exact
                Component={NewRoadMap}
              />
              <Route
                path={"/coach/profile/roadmap/add_resources"}
                exact
                Component={CourseResources}
              />
              <Route
                path={"/coach/profile/roadmap/add_quiz"}
                exact
                Component={CourseQuiz}
              />
              <Route
                path={"/coach/profile/roadmap/course_summary"}
                exact
                Component={CourseSummary}
              />
              <Route
                path={"/coach/profile/roadmap/course_resources"}
                exact
                Component={FilledResources}
              />
              <Route
                path={"/coach/profile/roadmap/course_quiz"}
                exact
                Component={FilledQuiz}
              />
              <Route
                path={"/job_seeker/profile/profile_setup"}
                Component={ProfileDetails}
              />
              <Route
                path={"/job_seeker/profile/job_preferences"}
                Component={JobPreferences}
              />
              <Route
                path={"/job_seeker/profile/culture"}
                Component={JobCulture}
              />
              <Route
                path={"/job_seeker/profile/verification"}
                Component={JobVerification}
              />
              <Route
                path={"/job_seeker/profile/confirm_verification"}
                Component={ConfirmJobVerification}
              />
              <Route
                path={"/job_seeker/profile"}
                Component={JobSeekerProfilePage}
              />
              <Route path={"/job_seeker/home"} Component={JobSeekerHomePage} />
              <Route
                path={"/job_seeker/profile/applied_courses"}
                Component={JobSeekerAppliedPage}
              />
              <Route
                path={"/job_seeker/profile/applied_courses/archieved"}
                Component={JobSeekerArchievedAppliedPage}
              />
              <Route
                path={"/job_seeker/profile/applied_courses/recent"}
                Component={JobSeekerRecentAppliedPage}
              />
              JobApplicationPage
              <Route
                path={"/job_seeker/profile/applied_courses/recent/application"}
                Component={JobApplicationPage}
              />
              <Route
                path={"/job_seeker/profile/jobs"}
                Component={JobSeekerJobsPage}
              />
              <Route
                path={"/job_seeker/profile/messages"}
                Component={JobSeekerMessagingPage}
              />
              <Route
                path={"/job_seeker/profile/library"}
                Component={JobSeekerLibraryPage}
              />
              <Route
                path={"/job_seeker/profile/library/recent"}
                Component={JobSeekerLibraryRecent}
              />
              <Route
                path={"/job_seeker/profile/library/completed"}
                exact
                Component={JobSeekerCompletedCourses}
              />
              <Route
                path={"/job_seeker/profile/library/wishlist"}
                exact
                Component={JobSeekerWishList}
              />
              <Route
                path={"/job_seeker/profile/library/course_details"}
                Component={JsLibraryCourseDetails}
              />
              <Route
                path={"/job_seeker/profile/library/course_modules"}
                Component={JobSeekerCourseModule}
              />
              <Route
                path={"/job_seeker/profile/library/coach_roadmap"}
                Component={JobSeekerLibraryCoachroadmap}
              />
              <Route
                path={"/job_seeker/profile/coaches/all_coaches"}
                Component={JobSeekerAllCoachesPage}
              />
              <Route
                path={"/job_seeker/profile/coaches/my_coaches"}
                Component={JobSeekerMyCoachesPage}
              />
              <Route
                path={"/job_seeker/profile/coach/:coachName"}
                Component={JobSeekerCoachesPage}
              />
              <Route
                path={"/job_seeker/profile/coach/:coachName/messages"}
                Component={JobSeekerCoachMessages}
              />
              <Route
                path={"/job_seeker/profile/coach/:coachName/roadmap"}
                Component={JobSeekerCoachRoadmap}
              />
              <Route
                path={"/job_seeker/profile/coach/:coachName/progress"}
                Component={JobSeekerCoachProgress}
              />
              <Route
                path={"/job_seeker/profile/profile_setup/add_education"}
                Component={AddEducationPage}
              />
              <Route
                path={"/job_seeker/profile/profile_setup/add_experience"}
                Component={AddExperiencePage}
              />
              <Route
                path={
                  "/job_seeker/profile/profile_setup/add_education/update/*"
                }
                Component={EditEducationPage}
              />
              <Route
                path={
                  "/job_seeker/profile/profile_setup/add_experience/update/*"
                }
                Component={EditExperiencePage}
              />
              <Route
                path={"/coach/profile/settings"}
                exact
                Component={CoachAccount}
              />
              <Route
                path={"/coach/profile/settings/notifications"}
                exact
                Component={CoachNotification}
              />
              <Route
                path={"/coach/profile/settings/security"}
                exact
                Component={CoachSecurity}
              />
              <Route
                path={"/register/recruiter"}
                exact
                Component={RecruiterSignUp}
              />
              <Route
                path={"/register/verify_recruiter"}
                exact
                Component={RecruiterVerificationPage}
              />
              <Route
                path={"/login/recruiter"}
                exact
                Component={RecruiterLoginPage}
              />
              <Route
                path={"/recruiter/profile/profile_setup"}
                exact
                Component={RecruiterProfileEdit}
              />
              <Route
                path={"/recruiter/profile/preferences"}
                exact
                Component={RecruiterPrefrences}
              />
              <Route
                path={"/recruiter/profile/process"}
                exact
                Component={RecruitmentProcess}
              />
              <Route
                path={"/recruiter/profile/verification"}
                exact
                Component={RecruiterVerification}
              />
              <Route
                path={"/recruiter/profile/home"}
                exact
                Component={RecruiterHome}
              />
              <Route
                path={"/recruiter/profile/applicants"}
                exact
                Component={RecruiterApplicants}
              />
              <Route
                path={"/recruiter/profile/applicantions"}
                exact
                Component={RecruiterApplicantions}
              />
              <Route
                path={"/recruiter/profile/applicant/job/applicantDetails"}
                exact
                Component={ApplicantDetails}
              />
              <Route
                path={"/recruiter/profile/applicant/job/hiringStage"}
                exact
                Component={ApplicantHiringStage}
              />
              <Route
                path={"/recruiter/profile/jobs"}
                exact
                Component={RecruiterJobs}
              />
              <Route
                path={"/recruiter/profile/jobs/job/details"}
                exact
                Component={RecruiterJobDetail}
              />
              <Route
                path={"/recruiter/profile/jobs/hiring_status"}
                exact
                Component={RecruiterJobsHiringStatus}
              />
              {/* <Route
                path={"/recruiter/profile/jobs/applicant_details"}
                exact
                Component={RecruiterJobsApplicantDetails}
              /> */}
              <Route
                path={"/recruiter/profile/jobs/hiring_stage"}
                exact
                Component={RecruiterJobsHiringStage}
              />
              RecruiterJobsApplicantDetails
              <Route
                path={"/recruiter/profile/jobs/job/edit"}
                exact
                Component={JobEdit}
              />
              <Route
                path={"/coach/profile/settings"}
                exact
                Component={CoachAccount}
              />
              <Route
                path={"/coach/profile/settings/notifications"}
                exact
                Component={CoachNotification}
              />
              <Route
                path={"/coach/profile/settings/security"}
                exact
                Component={CoachSecurity}
              />
              <Route
                path={"/register/recruiter"}
                exact
                Component={RecruiterSignUp}
              />
              <Route
                path={"/login/recruiter"}
                exact
                Component={RecruiterLoginPage}
              />
              <Route
                path={"/recruiter/profile/profile_setup"}
                exact
                Component={RecruiterProfileEdit}
              />
              <Route
                path={"/recruiter/profile/preferences"}
                exact
                Component={RecruiterPrefrences}
              />
              <Route
                path={"/recruiter/profile/process"}
                exact
                Component={RecruitmentProcess}
              />
              <Route
                path={"/recruiter/profile/verification"}
                exact
                Component={RecruiterVerification}
              />
              <Route
                path={"/recruiter/profile/home"}
                exact
                Component={RecruiterHome}
              />
              <Route
                path={"/recruiter/profile/applicants"}
                exact
                Component={RecruiterApplicants}
              />
              <Route
                path={"/recruiter/profile/applicant/job/applicantDetails"}
                exact
                Component={ApplicantDetails}
              />
              <Route
                path={"/recruiter/profile/applicant/job/hiringStage"}
                exact
                Component={ApplicantHiringStage}
              />
              <Route
                path={"/recruiter/profile/jobs"}
                exact
                Component={RecruiterJobs}
              />
              <Route
                path={"/recruiter/profile/jobs/job/details"}
                exact
                Component={RecruiterJobDetail}
              />
              <Route
                path={"/recruiter/profile/jobs/job/edit"}
                exact
                Component={JobEdit}
              />
              <Route
                path={"/recruiter/profile/addjob"}
                exact
                Component={Company}
              />
              <Route
                path={"/recruiter/profile/addjob/details"}
                exact
                Component={Details}
              />
              <Route
                path={"/recruiter/profile/addjob/relevance"}
                exact
                Component={RelevanceWeight}
              />
              <Route
                path={"/recruiter/profile/addjob/relevance/score"}
                exact
                Component={RelevanceScore}
              />
              <Route
                path={"/recruiter/profile/addjob/relevance/criteria"}
                exact
                Component={RelevanceCriteria}
              />
              <Route
                path={"/recruiter/profile/job/all-applicants"}
                exact
                Component={AllApplicants}
              />
              <Route
                path={"recruiter/profile/job/highly-recommended"}
                exact
                Component={HighlyRecommended}
              />
              <Route
                path={"recruiter/profile/job/recommended"}
                exact
                Component={Recommended}
              />
              <Route
                path={"recruiter/profile/job/not-recommended"}
                exact
                Component={NotRecommended}
              />
              <Route
                path={"/recruiter/profile/addjob/review"}
                exact
                Component={Review}
              />
              <Route
                path={"/recruiter/profile/messages"}
                exact
                Component={RecruiterChat}
              />
              <Route path={"/login/admin"} exact Component={AdminLogin} />
              <Route path={"/passkey"} exact Component={AdminPasskey} />
              <Route path={"/register/admin"} exact Component={RegisterAdmin} />
              <Route
                path={"/forget_password/admin"}
                exact
                Component={AdminForgetPassword}
              />
              <Route
                path={"/reset/admin"}
                exact
                Component={AdminResetPassword}
              />
              <Route
                path={"/admin/profile/dashboard"}
                exact
                Component={AdminHome}
              />
              <Route
                path={"/admin/profile/resources"}
                exact
                Component={AdminResourcesPage}
              />
              <Route
                path={"/admin/profile/resources/:index/course_details"}
                exact
                Component={ResourcesIndexPage}
              />
              <Route
                path={"/admin/profile/resources/:index/course_resources"}
                exact
                Component={ResourcesPage}
              />
              <Route
                path={"/admin/profile/resources/:index/course_quiz"}
                exact
                Component={ResourcesQuizPage}
              />
              <Route
                path={"/admin/profile/resources/:index/course_cert"}
                exact
                Component={ResourceCertificatePage}
              />
              <Route
                path={"/admin/profile/resources/add_resources/course_details"}
                exact
                Component={AddResourcesPage}
              />
              <Route
                path={"/admin/profile/settings"}
                exact
                Component={AdminSettingsPage}
              />
              <Route
                path={"/admin/profile/settings/account"}
                exact
                Component={AccountAdminSettings}
              />
              <Route
                path={"/admin/profile/settings/roles"}
                exact
                Component={AdminRolesPage}
              />
              <Route
                path={"/admin/profile/settings/user"}
                exact
                Component={AdminUserPage}
              />
              <Route
                path={"/admin/profile/resources/add_resources/course_resources"}
                exact
                Component={AddCourseResourcesPage}
              />
              <Route
                path={"/admin/profile/resources/add_resources/course_quiz"}
                exact
                Component={AddQuizPage}
              />
              <Route
                path={"/admin/profile/resources/add_resources/course_cert"}
                exact
                Component={AddCertPage}
              />
              <Route path="*" Component={ErrorGeneralPage} />
              <Route
                path={"/admin/profile/dashboard"}
                exact
                Component={AdminHome}
              />
              <Route
                path={"/admin/profile/users"}
                exact
                Component={AdminUsers}
              />
              <Route
                path={"/admin/profile/user-jobseeker"}
                exact
                Component={UserJobSeeker}
              />
              <Route
                path={"/admin/profile/user-coach"}
                exact
                Component={UserCoach}
              />
              <Route
                path={"/admin/profile/user-recruiter"}
                exact
                Component={UserRecruiter}
              />
              <Route path="*" Component={ErrorGeneralPage} />
              {/*
               <Route path={"/options"} Component={FilterOptions} />
              */}
            </Routes>
          </Suspense>
        </Router>
      </div>
    </MyContextProvider>
  );
}

export default App;
