/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useMemo} from "react";
import "./jobapp.style.scss";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import ApplicationPage from "./ApplicationPage";
import JobDescription from "./JobDescription";
import CompanyInfo from "./CompanyInfo";
const JobApplicationPage = ({index}) => {
  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate();
  const hoverStyle = {
    fontSize: "16px",
    fontWeight: "600",
    borderBottom: "solid 2px #2A53A9",
    color: "#001D6C",
  };
  const pathname = useMemo(() => {
    return window.location.pathname;
  }, []);
  const region = window.location.href.split("?").splice(1,2).join("");
  const local = pathname + "?" + region;
  console.log(local);
  
  const handleButtonClick = (button) => {
    setIsActive(button);
    if (isActive === "application") {
      navigate("/job_seeker/profile/applied_courses/archieved?application");
    }
    if (isActive === "jobDescription") {
      navigate(
        "/job_seeker/profile/applied_courses/archieved?jobDescription"
      );
    }
    if (isActive === "companyInfo") {
      navigate(
        "/job_seeker/profile/applied_courses/archieved?companyInfo"
      );
    }
  };
  
  useEffect(() => {
    const currentPath = local;
    console.log(
      local ===
        "/job_seeker/profile/applied_courses/archieved?application"
    );
    
    switch (currentPath) {
    case "/job_seeker/profile/applied_courses/archieved?application":
      setIsActive("application");
      break;
    case "/job_seeker/profile/applied_courses/archieved?jobDescription":
      setIsActive("jobDescription");
      break;
    case "/job_seeker/profile/applied_courses/archieved?companyInfo":
      setIsActive("companyInfo");
      break;
    default:
      setIsActive(null);
      break;
    }
  }, []);

  return (
    <div className="JobApplicationPage">
      <div className="AppliedLinks">
        <Link
          to={"/job_seeker/profile/applied_courses/archieved?application"}
          onClick={() => handleButtonClick("application")}
          style={isActive === "application" ? hoverStyle : { fontSize: "16px" }}
        >
          Application
        </Link>
        <Link
          to={"/job_seeker/profile/applied_courses/archieved?jobDescription"}
          onClick={() => handleButtonClick("jobDescription")}
          style={
            isActive === "jobDescription" ? hoverStyle : { fontSize: "16px" }
          }
        >
          Job Description
        </Link>
        <Link
          to={"/job_seeker/profile/applied_courses/archieved?companyInfo"}
          onClick={() => handleButtonClick("companyInfo")}
          style={isActive === "companyInfo" ? hoverStyle : { fontSize: "14px" }}
        >
          Company Info
        </Link>
      </div>
      <div></div>
      <div>
        {isActive === "application" && <ApplicationPage index={index} />}
        {isActive === "jobDescription" && <JobDescription index={index} />}
        {isActive === "companyInfo" && <CompanyInfo index={index} />}
      </div>
    </div>
  );
};
JobApplicationPage.propTypes = {
  navigation: PropTypes.string,
  index: PropTypes.string
};
export default JobApplicationPage;