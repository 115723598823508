import React, {useState, useEffect} from "react";
import "./RecruiterNavBar.style.scss";
import RecruiterNavLogo from "../../../static/images/logo/Ether Logo.png";
import profileImage from "../../../static/images/icons/user.png";
import { IoIosNotificationsOutline} from "react-icons/io";
import { IoIosArrowDown} from "react-icons/io";
import {Link} from "react-router-dom";
import { settings } from "../../../data/settings";
import AvatarIcon from "../../../static/images/icons/Avatar1.png";
import PropTypes from "prop-types";
import { notification } from "../../../data/notifier";
import { useMyContext } from "../../../Utils/ContextProvider";
const RecruiterNavBar = () => {
  
  const [showCard, setShowCard] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const handleSettings = () => {
    setShowSettings(!showSettings);
  };
  const handleCloseSettings = () => {
    setShowSettings(false);
  };
  const handleNotification = () => {
    setShowCard(!showCard);
  };
  
  const handleCloseNotification = () => {
    setShowCard(false);
  };
  const handleProfile = () => {
    setShowProfile(!showProfile);
    
  };
  const handleCancelProfile = () => {
    setShowProfile(false);
  };
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        showCard &&
        !event.target.closest(".NotificationCard") &&
        !event.target.closest(".jsNavNotificationIcon")
      ) {
        handleCloseNotification();
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [showCard]);
  
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        showSettings &&
        !event.target.closest(".NotificationCard") &&
        !event.target.closest(".jsNavArrowIcon")
      ) {
        handleCloseSettings();
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [showSettings]);
  useEffect(() => {
    const handleDocClick = (event) => {
      if(showProfile && !event.target.closest(".NotificationCard") &&  !event.target.closest(".ProfileSelector")){
        handleCancelProfile();
      }
    };
    document.addEventListener("click", handleDocClick);
    return () => document.removeEventListener("click", handleDocClick);
  }, [showProfile]);

  return (
    <div className="jsNavContainer">
      <div className="jsNavLogo">
        <Link to={"/"}>
          <img src={RecruiterNavLogo} alt="Ether" width={96} height={32} />
        </Link>
      </div>
      <div className="jsNavIcons">
        <IoIosNotificationsOutline
          className="jsNavNotificationIcon"
          onClick={handleNotification}
        />
        <Notificator showCard={showCard} />
        <img src={profileImage} alt="profile" width={30} height={30} onClick={handleProfile} className="ProfileSelector" />
        <ShowRecruiterProfile showProfile={showProfile} />
        <IoIosArrowDown className="RecruiterNavArrowIcon" onClick={handleSettings} />
        <ProfileSettings showSettings={showSettings} />
      </div>
    </div>
  );
};

const Card = ({children, style}) => {
  return (
    <div className="NotificationCard" style={style}>
      {children}
    </div>
  );
};
Card.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  onBlur: PropTypes.func
};

const ProfileSettings = ({showSettings}) => {
  return(
    <div>
      {showSettings && (
        <Card style={{width: "180px", marginLeft: "-120px",}}>
          <ul className="settingsContainer">
            {settings && settings.map((setting, index) => (
              <li key={`setting-${index}`}>
                <Link to={setting.link}>
                  <div>{setting.image}</div>
                  <p>{setting.type}</p>
                </Link>
              </li>
            ))}
          </ul>
        </Card>
      )}
    </div>
  );
};
ProfileSettings.propTypes = {
  showSettings: PropTypes.bool,
  onBlur: PropTypes.func,
};

const Notificator = ({showCard}) => {
  return (
    <div>
      {showCard && (
        <Card>
          <ul className="NotificatorCards">
            {notification.map((element, index) => (
              <li key={index}>
                <Link to="/job_seeker/messages">
                  <div>
                    <p>{element.name}</p>
                    <p>{element.date}</p>
                  </div>
                  <div>{element.message}</div>
                </Link>
              </li>
            ))}
          </ul>
        </Card>
      )}
    </div>
  );
};
Notificator.propTypes={
  showCard: PropTypes.bool,
  onBlur: PropTypes.func
};

const ShowRecruiterProfile = ({showProfile}) => {
  const { data } = useMyContext();
  const name = "Siddiq Lushon";
  const Profession = "Software Tester | Full Stack Engineer ";
  const secondData = {
    name: name,
    profession: Profession,
    image: AvatarIcon,
  };
  const RecruiterData = data?.map((element) => element.RecruiterProfile)[0] || secondData;
  return (
    <div>
      {showProfile && (
        <Card style={{ marginLeft: "-170px" }}>
          <div className="ShowProfile">
            <div className="ShowProfileOne">
              <img src={RecruiterData.image} alt="image" width={70} height={70} />
              <div className="RecruiterDataName">
                <p>{RecruiterData.name}</p>
                <h6>{RecruiterData.profession}</h6>
              </div>
            </div>
            <div className="ShowProfileTwo">
              <button>View Profile</button>
            </div>
            <hr className="ShowProfileBreaker" />
            <div className="ShowProfileThree">
              <p>Account</p>
              <a href="/">Settings & Privacy</a>
              <a href="/">Help</a>
              <a href="/">Language</a>
            </div>
            <hr className="ShowProfileBreaker" />
            <div className="ShowProfileThree">
              <p>Manage</p>
              <a href="/">Posts & Activity</a>
              <a href="/">Assignments & Articles</a>
            </div>
            <hr className="ShowProfileBreaker" />
          </div>
        </Card>
      )}
    </div>
  );
};
ShowRecruiterProfile.propTypes = {
  showProfile: PropTypes.bool,
  onBlur: PropTypes.func,
};

export default RecruiterNavBar;