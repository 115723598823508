import React, {useRef} from "react";
import { useNavigate } from "react-router-dom";
import { UploadIcon } from "../../../svgs/SvgComponent";
import userImage from "../../../static/images/icons/User Thumb.png";
import "./CoachVerification.style.scss";

const CoachVerificationPage = () => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = () => {
    navigate("/coach/profile/confirm_verify");
  };
  return (
    <div className="VerificationContainer">
      <div className="VerificationWrapper">
        <div className="VerificationTopSection">
          <h3>Verification</h3>
          <div className="VerificationProfile">
            
            <div className="ProfileDetails">
              <img src={userImage} alt="" />
              <div className="ProfileParticulars">
                <p>Jennifer Anniston</p>
                <p>Product Designer Coach</p>
              </div>
            </div>
            <div className="AboutMe">
              <p><b>About Me:{" "}</b>Hello! I am a passionate and innovative product designer with over four years of experience in the industry. 
                My journey began with a strong fascination for creating beautiful, functional, and user-centric designs. 
                What sets me apart is my dual role as a coach, where I draw from my design expertise to guide and mentor aspiring designers.
              </p>
            </div>
          </div>
        </div>
        <hr className="VerificationDivider"/>
        <div className="ProfessionalSection">
          <h3>Professional Certificate</h3>
          <div className="CertificateUpload">
            <UploadIcon />
            <p>Please upload your professional certificate</p>
            <button onClick={handleBrowseClick}>Browse File</button>
            <input 
              type="file" 
              accept=".jpg .jpeg .pdf .JPG .JPEG .PDF" 
              hidden
              ref={fileInputRef}
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                console.log("Selected File:", selectedFile);
              }}
            />
          </div>
        </div>
        <hr className="VerificationDivider"/>
        <div className="EducationSection">
          <h3>Educational Certificate</h3>
          <div className="CertificateUpload">
            <UploadIcon />
            <p>Please upload your educational certificate</p>
            <button onClick={handleBrowseClick}>Browse File</button>
            <input 
              type="file" 
              accept=".jpg .jpeg .pdf .JPG .JPEG .PDF" 
              hidden
              ref={fileInputRef}
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                console.log("Selected File:", selectedFile);
              }}
            />
          </div>
        </div>
        <button onClick={handleSubmit}>Verify</button>
      </div>
    </div>
  );
};

export default CoachVerificationPage;