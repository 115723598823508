import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./RecruiterJobAdd.style.scss";
import { ActiveTabIcon, InactiveTabIcon } from "../../../svgs/SvgComponent";
import PropTypes from "prop-types";
import { SelectField, TextArea, TextField } from "../../../Materials/InputFields";
import { Hyphen } from "../../../svgs/svg";
import { BsCheck } from "react-icons/bs";
import MonthPicker from "../../MonthPicker/MonthPicker";

const tabStates = ["Completed", "Active Step", "Incomplete Step"];

const RecruiterJobAdd = ( { children } ) => {

  const [isActive, setIsActive] = useState("company");
  const [tabs, setTabs] = useState({
    company: tabStates[1],
    details: tabStates[2],
    relevance: tabStates[2],
    review: tabStates[2],
  });

  const activeStyle = {
    fontSize: "16px",
    color: "var(--foundation-neutral-black, #2E2C2C)",
    fontWeight: "600",
    borderBottom: "2px solid var(--foundation-pri-pri-500, #2A53A9)",
    
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log(currentPath);
    switch (currentPath) {
      
    case "/recruiter/profile/addjob":
      setIsActive("company");
      break;
    case "/recruiter/profile/addjob/details":
      setTabs((prevTabs) => ({ ...prevTabs, company: tabStates[0], details: tabStates[1] }));
      setIsActive("details");
      break;
    case "/recruiter/profile/addjob/relevance":
      setTabs((prevTabs) => ({ ...prevTabs, company: tabStates[0], details: tabStates[0], relevance: tabStates[1] }));
      setIsActive("relevance");
      break;
    case "/recruiter/profile/addjob/review":
      setTabs((prevTabs) => ({ ...prevTabs, company: tabStates[0], details: tabStates[0], relevance: tabStates[0], review: tabStates[0] }));
      setIsActive("review");
      break;

    default:
      setIsActive("company");
      break;
    }
  }, [history]);
  
  const ButtonClass = (tab) => {
    if (tab === tabStates[0]) return "completedTab";
    if (tab === tabStates[1]) return "activeTab";
    return "incompleteTab";
  };
  
  return (
    <div className="JobAddContainer">
      <div className="JobAddTopSection">
        <button>Build Job Post</button>
        <Link to={"/recruiter/profile/home"} style={{textDecoration: "none"}}>
          <button>Finish Later</button>
        </Link>
      </div>
      <div className="JobAddLayout">
        <div className="JobAddProgress">
          <div className="ProgressTab">
            <Link
              to={"/recruiter/profile/addjob"}
            >
              <button
                className={ButtonClass(tabs.company)}
              >
                <div className="ProfileProgressTab">
                  {isActive === "company" ? (
                    <ActiveTabIcon className="ProgressTabIcon" />
                  ) : tabs.company === tabStates[0] ? (
                    <BsCheck className="ProgressTabIcon" />
                  ) : (
                    <InactiveTabIcon className="ProgressTabIcon" />
                  )}
                  <div className="ProfileProgressTabDetails">
                    <span>1. About the Company</span>
                    <span>{tabs.company}</span>
                  </div>
                </div>
              </button>
            </Link>
          </div>
          <div className="ProgressTab">
            <Link
              to={"/recruiter/profile/addjob/details"}
            >
              <button
                className={ButtonClass(tabs.details)}
              >
                <div className="ProfileProgressTab">
                  {isActive === "details" ? (
                    <ActiveTabIcon className="ProgressTabIcon" />
                  ) : tabs.details === tabStates[0] ? (
                    <BsCheck className="ProgressTabIcon" />
                  ) : (
                    <InactiveTabIcon className="ProgressTabIcon" />
                  )}
                  <div className="ProfileProgressTabDetails">
                    <span>2. Enter Job details</span>
                    <span>{tabs.details}</span>
                  </div>
                </div>
              </button>
            </Link>
          </div>
          <div className="ProgressTab">
            <Link
              to={"/recruiter/profile/addjob/relevance"}
            >
              <button
                className={ButtonClass(tabs.relevance)}
              >
                <div className="ProfileProgressTab">
                  {isActive === "relevance" ? (
                    <ActiveTabIcon className="ProgressTabIcon" />
                  ) : tabs.relevance === tabStates[0] ? (
                    <BsCheck className="ProgressTabIcon" />
                  ) : (
                    <InactiveTabIcon className="ProgressTabIcon" />
                  )}
                  <div className="ProfileProgressTabDetails">
                    <span>3. Set Relevance target</span>
                    <span>{tabs.relevance}</span>
                  </div>
                </div>
              </button>
            </Link>
          </div>
          <div className="ProgressTab">
            <Link
              to={"/recruiter/profile/addjob/review"}
            >
              <button
                className={ButtonClass(tabs.review)}
              >
                <div className="ProfileProgressTab">
                  {isActive === "review" ? (
                    <BsCheck className="ProgressTabIcon" />
                  ) : (
                    <InactiveTabIcon className="ProgressTabIcon" />
                  )}
                  <div className="ProfileProgressTabDetails">
                    <span>4. Review Job posting</span>
                    <span>{tabs.review}</span>
                  </div>
                </div>
              </button>
            </Link>
          </div>
        </div> 
        <div className="JobAddOverview">{children}</div>
      </div>
    </div>
  );
};
RecruiterJobAdd.propTypes = {
  children: PropTypes.node
};
export default RecruiterJobAdd;

export const CompanyDetails = () => {
  const navigate = useNavigate();
  const initialState = {
    department: "",
    size: "",
    about: "",
  };
  const [state, setState] = useState(initialState);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/recruiter/profile/addjob/details");
  };
  useEffect(() => {
    const isValid = Object.values(state).every(value => value.trim() !== "");
    setIsFormValid(isValid);
  }, [state]);

  const handleCancelClick = () => {
    navigate("/recruiter/profile/home");
  };
  return (
    <div className="CompanyContainer">
      <h4>About Company</h4>
      <div className="JobAddSelectFields">
        <SelectField 
          label={"Job Department"}
          placeholder={"Select job department"}
          id={"JobAddSelectField"}
          className={"JobAddSelectField"}
          name={"department"}
          onChange={handleInputChange}
          selectedValue={state.department}
          values={["Engineering", "Marketing", "Graphics", "Human Resources", "Management", "Administration", "Others"]}
        />
        <SelectField 
          label={"Company Size"}
          placeholder={"Select company size"}
          id={"JobAddSelectField"}
          className={"JobAddSelectField"}
          name={"size"}
          onChange={handleInputChange}
          selectedValue={state.size}
          values={["1-10", "10-25", "25-50", "50-100", "100+" ]}
        />
      </div>
      <hr className="PageDivider"/>
      <TextArea 
        label={"About The Company"}
        id={"JobAddTextArea"}
        className={"JobAddTextArea"}
        name={"about"}
        onChange={handleInputChange}
        value={state.about}  
        placeholder={"Enter company information"}
      />
      <div className="JobAddButtons">
        <button 
          onClick={handleCancelClick}
        >
          Cancel
        </button>
        <button 
          onClick={handleSubmit} 
          disabled={!isFormValid}
        >
          Save and Proceed
        </button>
      </div>
    </div>
  );
};

export const JobDetails = () => {
  const navigate = useNavigate();

  const initialState = {
    title: "",
    location: "",
    type: "",
    fromRange: "",
    toRange:"",
    hours: "",
    fromPeriod: "",
    toPeriod: "",
    level: "",
    skills: "",
    description: "",
  };
  const [state, setState] = useState(initialState);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/recruiter/profile/addjob/relevance");
  };

  useEffect(() => {
    const isValid = Object.values(state).every(value => value.trim() !== "");
    setIsFormValid(isValid);
  }, [state]);

  const handleCancelClick = () => {
    navigate("/recruiter/profile/home");
  };
  return (
    <div className="DetailsContainer">
      <h4>Job Details</h4>
      <div className="DetailsSelectFields">
        <TextField
          id={"DetailsSelectField"}
          className={"DetailsSelectField"}
          label={"Job Title"}
          placeholder={"Enter job title"}
          type={"text"}
          name={"title"}
          onChange={handleInputChange}
          value={state.title}
        />
        <SelectField
          id={"DetailsSelectField"}
          className={"DetailsSelectField"}
          label={"Job Location"}
          values={["Remote", "On-site", "Hybrid"]}
          name={"location"}
          onChange={handleInputChange}
          selectedValue={state.location}
        />
      </div>
      <div className="DetailsSelectFields">
        <SelectField
          id={"DetailsSelectField"}
          className={"DetailsSelectField"}
          label={"Job Type"}
          values={["Internship", "Contract", "Full-time", "Part-Time", "Volunteer"]}
          name={"type"}
          onChange={handleInputChange}
          selectedValue={state.type}
        />
        <span>
          <TextField
            id={"DetailsSelectField"}
            className={"DetailsSelectField"}
            label={"Salary Range"}
            placeholder={"Minimun Range"}
            type={"text"}
            name={"fromRange"}
            onChange={handleInputChange}
            value={state.fromRange}

          />
          <span 
            style={{ 
              display: "flex", 
              flexDirection: "row", 
              width: "2%", 
              height: "48px",
              justifyContent: "center", 
              alignItems:"center"
            }}
          >
            <Hyphen />
          </span>
          <TextField
            id={"DetailsSelectField"}
            className={"DetailsSelectField"}
            placeholder={"Maximum Range"}
            type={"text"}
            name={"toRange"}
            onChange={handleInputChange}
            value={state.toRange}
          />
        </span>
      </div>
      <div className="DetailsSelectFields">
        <SelectField
          id={"DetailsSelectField"}
          className={"DetailsSelectField"}
          label={"Work Hours"}
          name={"hours"}
          values={["4 hours", "6 hours", "7 hours", "8 hours", "9 hours", "10 hours", "11 hours", "12 hours"]}
          selectedValue={state.hours}
          onChange={handleInputChange}
        />
        <span>
          <SelectField
            id={"DetailsSelectField"}
            className={"DetailsSelectField"}
            label={"Posting Period"}
            name={"fromPeriod"}
            values={["11.11.11"]}
            selectedValue={state.fromPeriod}
            onChange={handleInputChange}
          />
          <span 
            style={{ 
              display: "flex", 
              flexDirection: "row", 
              width: "2%", 
              height: "48px",
              justifyContent: "center", 
              alignItems:"center"
            }}
          >
            <Hyphen />
          </span>
          <SelectField
            id={"DetailsSelectField"}
            className={"DetailsSelectField"}
            values={["11.12.11"]}
            selectedValue={state.toPeriod}
            name={"toPeriod"}
            onChange={handleInputChange}
          />
        </span>
      </div>
      <div className="DetailsSelectFields">
        <SelectField
          id={"DetailsSelectField"}
          className={"DetailsSelectField"}
          label={"Experience Level"}
          values={["Entry level", "Junior", "Mid-level", "Intermediate", "Senior", "Advanced", "Professional"]}
          selectedValue={state.level}
          onChange={handleInputChange}
          name={"level"}
        />
        <SelectField
          id={"DetailsSelectField"}
          className={"DetailsSelectField"}
          label={"Skills"}
          values={["JavaScript", "Python"]}
          name={"skills"}
          selectedValue={state.skills}
          onChange={handleInputChange}
        />
      </div>
      <hr className="DetailsDivider"/>
      <TextArea 
        label={"Job Description"}
        placeholder={"Enter Job description..."}
        id={"DetailsTextArea"}
        className={"DetailsTextArea"}
        name={"description"}
        value={state.description}
        onChange={handleInputChange}
      />
      <div className="DetailsButtons">
        <button onClick={handleCancelClick}>Cancel</button>
        <button
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          Save and Proceed
        </button>
      </div>
    </div>
  );
}; 