import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PropTypes from "prop-types";
import { IoIosClose } from "react-icons/io";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckboxesTags = ({ options, placeholder, className }) => {
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleRemoveItem = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
  };

  return (
    <div>
      <Autocomplete
        multiple
        size="normal"
        id="checkboxes-tags-demo"
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              color: "var(--Secondary-200, #9BA6BC)",
              fontFamily: "TT Wellingtons Trl",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
              "&:hover": { backgroundColor: "#ECF1FE" },
            }}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8, width: "14px", height: "14px" }}
              checked={selected}
            />
            {option}
          </li>
        )}
        className={className}
        style={{ height: "48px", border: "none" }}
        onChange={(event, value) => setSelectedOptions(value)}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} sx={{ height: 100 }} />}
      />
      <div style={{ marginTop: "10px" }}>
        {selectedOptions.map((option, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#ECF1FE",
              display: "inline-flex",
              padding: "8px 6px",
              boxSizing: "border-box",
              borderRadius: "4px",
              marginRight: "8px",
              marginBottom: "8px",
            }}
          >
            {option}
            <IoIosClose style={{ width: "24px", height: "24px" }} />
          </div>
        ))}
      </div>
    </div>
  );
};

CheckboxesTags.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default CheckboxesTags;
