import React from "react";
import "./coachsubpages.style.scss";

const CoachProgress = () => {
  return (
    <div className="CoachProgress">
      <p>Nothing to display</p>
    </div>
  ); 
};

export default CoachProgress;