import React from "react";
import { useNavigate } from "react-router-dom";
import "./RecruiterHiringStage.style.scss";
import { TextField } from "../../../Materials/InputFields";
import ToggleSwitch from "../../../Materials/Toggle";

const RecruiterHiringStage = () => {
  const navigate = useNavigate();

  const handleClickSave = () => {
    navigate("/recruiter/profile/jobs/hiring_stage");
  };
  return (
    <div className="HiringStageContainer">
      <div className="HiringStageDetails">
        <h6>Job Details</h6>
        <div className="HiringInputs">
          <TextField
            className={"HiringInput"}
            id={"HiringInput"}
            type={"text"}
            placeholder={"Associate Product Designer"}
            disabled={true}
            label={"Job Title"}
          />
          <TextField
            className={"HiringInput"}
            id={"HiringInput"}
            type={"text"}
            placeholder={"Design"}
            disabled={true}
            label={"Category"}
          />
          <TextField
            className={"HiringInput"}
            id={"HiringInput"}
            type={"text"}
            placeholder={"Full-Time"}
            disabled={true}
            label={"Job Type"}
          />
        </div>
        <div className="HiringInputs">
          <TextField
            className={"HiringInput"}
            id={"HiringInput"}
            type={"text"}
            placeholder={"Remote"}
            disabled={true}
            label={"Location"}
          />
          <TextField
            className={"HiringInput"}
            id={"HiringInput"}
            type={"text"}
            placeholder={"14th April, 2024"}
            disabled={true}
            label={"Date Created"}
          />
          <TextField
            className={"HiringInput"}
            id={"HiringInput"}
            type={"text"}
            placeholder={"30th May, 2024"}
            disabled={true}
            label={"Closing Date"}
          />
        </div>
      </div>
      <div className="HiringStages">
        <div className="HiringStageContent">
          <div className="HiringStageTop">
            <h4>Send Invite</h4>
          </div>
          <div className="HiringStageToggles">
            <span>
              <ToggleSwitch />
              <h6>Send Invite</h6>
            </span>
            <span>
              <ToggleSwitch />
              <h6>Accepted</h6>
            </span>
          </div>
        </div>
        <div className="HiringStageContent">
          <div className="HiringStageTop">
            <h4>Interview Scheduled</h4>
          </div>
          <div className="HiringStageToggles">
            <span>
              <ToggleSwitch />
              <h6>Scheduled</h6>
            </span>
            <span>
              <ToggleSwitch />
              <h6>Completed</h6>
            </span>
          </div>
        </div>
        <div className="HiringStageContent">
          <div className="HiringStageTop">
            <h4>Assessment</h4>
          </div>
          <div className="HiringStageToggles">
            <span>
              <ToggleSwitch />
              <h6>Required</h6>
            </span>
            <span>
              <ToggleSwitch />
              <h6>Pass</h6>
            </span>
          </div>
        </div>
        <div className="HiringStageContent">
          <div className="HiringStageTop">
            <h4>Offer Extended</h4>
          </div>
          <div className="HiringStageToggles">
            <span>
              <ToggleSwitch />
              <h6>Yes</h6>
            </span>
            <span>
              <ToggleSwitch />
              <h6>Accepted</h6>
            </span>
          </div>
        </div>
      </div>
      <div className="HiringStageButtons">
        <button>Cancel</button>
        <button onClick={handleClickSave}>Save</button>
      </div>
    </div>
  );
};

export default RecruiterHiringStage;