const date = new Date(Date.now());
const year = `${
  date.getDay() + "/" + date.getMonth()+ "/" + date.getFullYear()
}`;
export const notification = [
  {
    name: "Kate Henshaw",
    date: year,
    message: "Dear user, your message has been verified successfully",
  },
  {
    name: "Lee seung gi",
    date: year,
    message: "Dear user, your message has been verified successfully",
  },
  {
    name: "John Peter",
    date: year,
    message: "Dear user, your message has been verified successfully",
  },
];