import React, {useState} from "react";
import { CoachesProfile } from "../../../data/CoachData";
import { CustomPagination } from "../../../Materials/Materials";
import "./JsCoaches.style.scss";
import { useNavigate } from "react-router-dom";
import { BsFillStarFill } from "react-icons/bs";

const JsAllCoaches = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalItems = CoachesProfile.length;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const itemsToDisplay = CoachesProfile.slice(startIndex, endIndex);

  const navigate = useNavigate();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleCoachDetails = (name) => {
    navigate(`/job_seeker/profile/coach/${name}`);
    return ;
  };
  return (
    <div className="AllCoachesContainer">
      <div className="AllCoachesInnerContainer">
        {
          itemsToDisplay && itemsToDisplay.map((item, index) => {
            return (
              <div className="AllCoachesClasses" key={index}>
                <img
                  src={item.image}
                  alt="coachImage"
                  height={300}
                  width={300}
                />
                <div className="CoachesName">
                  <h2>{item.name}</h2>
                  <p><span><BsFillStarFill className="rating"/></span> {item.rate}</p>
                </div>
                <div className="coachesExperience">
                  <p>{item.yearsOfExperience} years of experience</p>
                  <span>{item.workStatus}</span>
                </div>
                
                <button onClick={() => handleCoachDetails(item.name)}>Start Connection</button>
              
              </div>
            );})
        }
      </div>
      {/* Pagination */}
      <div className="CoachesPagination">
        <p>
          Showing {currentPage} out of {totalPages}
        </p>
        <div>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default JsAllCoaches;