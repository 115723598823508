import React, { useMemo, useCallback, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../../../Utils/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { setFormData, setFormValid, setError } from "../../../Redux/Slicers/settingsSlice";
import { PasswordField } from "../../../Materials/PasswordField";


import "./CoachSecuritySettings.style.scss";
import { CheckBox } from "../../../Materials/InputFields";
import { Toggle } from "../../../Materials/ToggleMui";

const CoachSecuritySettings = () => {
  const { updateMyData } = useMyContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckBoxChange = () => {
    return;
  };

  const formData = useSelector((state) => state.settings.formData);
  const isFormValid = useSelector(
    (state) => state.settings.isFormValid
  );

  const errors = useSelector ((state) => state.settings.error);

  // const initialFormData = useMemo(
  //   () => JSON.parse(JSON.stringify(formData)), [formData]);
  const initialErrors = useMemo(
    () => JSON.parse(JSON.stringify(errors)), [errors]
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      dispatch(setFormData({ [name]: value }));
    },
    [dispatch]
  );
  
  useEffect(() => {
    const newErrors = { ...initialErrors };
    let hasErrorsChanged = false;
    for (const fieldName in formData) {
      switch (fieldName) {

      case "currentPassword":
        newErrors.currentPassword = "";

        if (formData.currentPassword ===""){
          newErrors.currentPassword = "Password is required";
        } else if (formData.currentPassword.length < 8) {
          newErrors.currentPassword =
            "Password must be at least 8 characters";
        } else if (
          !/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/.test(
            formData.currentPassword
          )
        ) 
        {
          newErrors.currentPassword =
          "Password must contain at least one uppercase letter, one number, and one special character";
        }
        break;

      case "newPassword":
        newErrors.newPassword = "";
    
        if (formData.newPassword ===""){
          newErrors.newPassword = "Password is required";
        } else if (formData.newPassword.length < 8) {
          newErrors.newPassword =
          "Password must be at least 8 characters";
        } else if (
          !/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/.test(
            formData.newPassword
          )
        ) 
        {
          newErrors.newPassword =
          "Password must contain at least one uppercase letter, one number, and one special character";
        }
        break;

      case "confirmPassword":
        newErrors.confirmPassword = "";
    
        if (formData.confirmPassword === "") {
          newErrors.confirmPassword = "Confirm Password is required";
        } else if (formData.confirmPassword !== formData.password) {
          newErrors.confirmPassword = "Passwords do not match";
        }
        break;

      default:
        break;
      }
    }
    for(const fieldName in formData) {
      if(newErrors[fieldName] !==initialErrors[fieldName]) {
        hasErrorsChanged = true;
        break;
      }
    }
    if(hasErrorsChanged) {
      dispatch(setError(newErrors));
      const formIsValid = !Object.values(newErrors).some((error) => error);
      dispatch(setFormValid(formIsValid));
    }
  }, [dispatch, formData, initialErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(isFormValid) {
      updateMyData({ settingsData: formData});
      navigate("/coach/profile/settings");
      console.log("Form submitted:", formData);
    } else {
      console.log("Form submission canceled due to validation errors.");
    }
  };

  return (
    <div className="CoachSecuritySettingsContainer">
      <div className="CoachSecuritySettingsContent">
        <div className="SecuritySettingsHeading">
          <h2>Sign in and Security</h2>
          <IoIosArrowDown style={{height: "24px", width: "24px", color: "#9BA6BC"}} />
        </div>
        <div className="SecurityEmailSettings">
          <h6>Email Address</h6>
          <input 
            type="text"
            placeholder="siddiq@lushon@gmail.com"
            disabled 
          />
        </div>
        <div className="SecurityPasswordSettings">
          <h6>Change Password</h6>
          <p>Please enter a new password below</p>
          <div className="SecurityPasswordSettingsInputs">
            <PasswordField
              type={"password"}
              name={"currentPassword"}
              className={"PasswordSettingInputField"} 
              id={"PasswordSettingInputField"}
              label={"Current Password"}
              onChange={handleInputChange}
              value={formData.currentPassword}
              transform="translateY(-2.5rem)"
            />
            {errors.currentPassword ? (
              <span className="ErrorPasswordField"><span style={{color: "#FF4040"}}>*</span>{errors.currentPassword}</span>
            ) : (
              <div className="ErrorPasswordField"></div>
            )}
            <PasswordField
              type={"password"}
              name={"newPassword"}
              className={"PasswordSettingInputField"} 
              id={"PasswordSettingInputField"}
              label={"New Password"}
              onChange={handleInputChange}
              value={formData.newPassword}
              transform="translateY(-2.5rem)"
              marginLeft={"890px"}
            />
            {errors.newPassword ? (
              <span className="ErrorPasswordField"><span style={{color: "#FF4040"}}>*</span>{errors.newPassword}</span>
            ) : (
              <div className="ErrorPasswordField"></div>
            )}
            <PasswordField
              type={"password"}
              name={"confirmPassword"}
              className={"PasswordSettingInputField"} 
              id={"PasswordSettingInputField"}
              label={"Confirm New Password"}
              transform="translateY(-2.5rem)"
              marginLeft={"890px"}
            />
            {errors.confirmPassword ? (
              <span className="ErrorPasswordField"><span style={{color: "#FF4040"}}>*</span>
                {errors.confirmPassword}
              </span>
            ) : (
              <div className="ErrorPasswordField"></div>
            )}
          </div>
          <CheckBox
            name={""}
            value={""}
            className={"SecuritySettingsCheckBox"}
            onChange={handleCheckBoxChange}
            id={"SecuritySettingsCheckBox"}
            label={"Requires all devices to sign in with new password"} 
          />
          <button onClick={handleSubmit}>Save Password</button>
          <div className="SecuritySettingAuthentication">
            <h6>Two-Step Aunthentication</h6>
            <span>
              <p>Secure your Ether Account with two-step verification</p>
              <Toggle />
            </span>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default CoachSecuritySettings;
