import React, { useState } from "react";
import { GoPaperclip } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";

import "./RecruiterApplicantDetails.style.scss";
import { ApplicantScoreDetails } from "./RecruiterAllApplicants";
import { TextField } from "../../../Materials/InputFields";

const RecruiterApplicantDetails = () => {
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [notes, setNotes] = useState([]);

  const handleAddNote = () => {
    if (newNote.trim() !== "") {
      setNotes([...notes, newNote]);
      setNewNote("");
      setIsAddingNote(false);
    }
  };

  const columns = [
    { id: "criteria", label: "Criteria", minWidth: "auto", align: "center" },
    { id: "weight", label: "Weight", minWidth: "auto", align: "center" },
    { id: "point", label: "Point", minWidth: "auto", align: "center" },
    { id: "score", label: "Score", minWidth: "auto", align: "center" }
  ];

  function createData(criteria, weight, point, score) {
    return { criteria, weight, point, score };
  }

  const rows = [
    createData("Education", 65, 50, 20),
    createData("Skills", 50, 50, 20),
    createData("Experience", 20, 80, 16)
  ];

  const totalWeight = rows.reduce((acc, row) => (acc += row.weight), 0);
  const totalScore = rows.reduce((acc, row) => (acc += row.score), 0);
  return (
    <div className="ApplicantDetailsContainer">
      <div className="ApplicantDetailsBio">
        <h6>Bio</h6>
        <p>
          Kwenier is a senior solutions architect 
          and a consultant, with rich experience 
          of over 5 years in core IT infrastructure 
          services and cloud computing. I am outgoing, 
          dedicated, and open-minded. I get across to 
          people and adjust to changes with ease. 
          I believe that a person should work on 
          developing their professional skills and 
          learning new things all the time.
        </p>
      </div>
      <div className="ApplicantDetailsPort">
        <h6>Portfolio</h6>
        <span>
          http://behance.com/kweenierhamjane
          <button>View Portfolio</button>
        </span>
      </div>
      <div className="ApplicationDetailsTopSection">
        <h3>Cover Letter</h3>
        <p className="ApplicantCoverLetter">
          Our mission: At Included Health, we’re on a mission to raise the
          standard of healthcare for everyone. We’re a new kind of healthcare
          company, focused on breaking down barriers to provide high-quality
          care for every person in every community — no matter where they are in
          their health journey or what type of care they need. We offer
          guidance, advocacy, and access to personalized care for urgent care,
          primary care, behavioral health, and specialty care.
          <br />
          <br />
          Our mission: At Included Health, we’re on a mission to raise the
          standard of healthcare for everyone. We’re a new kind of healthcare
          company, focused on breaking down barriers to provide high-quality
          care for every person in every community — no matter where they are in
          their health journey or what type of care they need. We offer
          guidance, advocacy, and access to personalized care for urgent care,
          primary care, behavioral health, and specialty care.
          <br />
          <br />
          Our mission: At Included Health, we’re on a mission to raise the
          standard of healthcare for everyone. We’re a new kind of healthcare
          company, focused on breaking down barriers to provide high-quality
          care for every person in every community — no matter where they are in
          their health journey or what type of care they need. We offer
          guidance, advocacy, and access to personalized care for urgent care,
          primary care, behavioral health, and specialty care.
          <br />
          <br />
        </p>
      </div>
      <div className="ApplicantDetailsLink">
        <span>
          <GoPaperclip className="LinkIcon" />
          <p className="LinkTitle">Resume:</p>
          <p className="LinkFile">JohnDoe_CV</p>
        </span>
        <span>
          <GoPaperclip className="LinkIcon" />
          <p className="LinkTitle">Coverletter:</p>
          <p className="LinkFile">JohnDoe_CoverLetter</p>
        </span>
      </div>
      <div className="ApplicationDetail">
        <h6>Application Details</h6>
        <div className="ApplicationInputs">
          <TextField
            className={"ApplicationInput"}
            id={"ApplicationInput"}
            type={"text"}
            placeholder={"Associate Product Designer"}
            disabled={true}
            label={"Job Title"}
          />
          <TextField
            className={"ApplicationInput"}
            id={"ApplicationInput"}
            type={"text"}
            placeholder={"Design"}
            disabled={true}
            label={"Category"}
          />
          <TextField
            className={"ApplicationInput"}
            id={"ApplicationInput"}
            type={"text"}
            placeholder={"Full-Time"}
            disabled={true}
            label={"Job Type"}
          />
        </div>
        <div className="ApplicationInputs">
          <TextField
            className={"ApplicationInput"}
            id={"ApplicationInput"}
            type={"text"}
            placeholder={"Remote"}
            disabled={true}
            label={"Location"}
          />
          <TextField
            className={"ApplicationInput"}
            id={"ApplicationInput"}
            type={"text"}
            placeholder={"14th April, 2024"}
            disabled={true}
            label={"Date Created"}
          />
          <TextField
            className={"ApplicationInput"}
            id={"ApplicationInput"}
            type={"text"}
            placeholder={"30th May, 2024"}
            disabled={true}
            label={"Closing Date"}
          />
        </div>
      </div>
      <div className="RelvanceScore">
        <h2>Relevance Score</h2>
        <ApplicantScoreDetails
          rows={rows}
          columns={columns}
          totalWeight={totalWeight}
          totalScore={totalScore}
        />
      </div>
      <div className="AddNote">
        <div className="AddNewNote">
          <h6>Notes</h6>
          <span onClick={() => setIsAddingNote(true)}>
            <p>Add New Note</p>
            <IoMdAddCircleOutline className="NewNoteIcon" />
          </span>
        </div>
        {isAddingNote && (
          <div className="NewNoteInputArea">
            <textarea
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              placeholder="Enter your note here..."
              className="TextArea"
            />
            <button onClick={handleAddNote}>Add Note</button>
          </div>
        )}
        <div className="NewNoteArea">
          {notes.map((note, index) => (
            <div key={index} className="Note">
              {note}
            </div>
          ))}
        </div>
      </div>
      <div className="Button">
        <button>Save</button>
      </div>
    </div>
  );
};

export default RecruiterApplicantDetails;
