import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    passkey1: "",
    passkey2: "",
    passkey3: "",
    passkey4: "",
    passkey5: "",
  },

  isFormValid: false,

  error: {
    passkey1: "",
    passkey2: "",
    passkey3: "",
    passkey4: "",
    passkey5: "",
  }
};

const passkeySlice = createSlice ({
  name: "passkey",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setError: (state, action) => {
      state.error = { ...state.error, ...action.payload };
    },
    setFormValid: (state, action) => {
      state.isFormValid = action.payload;
    }
  }
});

export const { setFormData, setError, setFormValid } = passkeySlice.actions;
export default passkeySlice.reducer;