import React from "react";
import "./coachsubpages.style.scss";
import etherLogo from "../../../static/images/logo/Ether Logo.png";
const CoachGeneralChat = () => {
  return (
    <div className="GeneralChatPickedContainer">
      <img src={etherLogo} alt="ether logo" width={100} height={60} />
      <p>
        You can choose a chat by clicking on your contact lists to view your
        messages...
      </p>
    </div>
  );
};

export default CoachGeneralChat;
