import React from "react";
import { useNavigate } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";
import PropTypes from "prop-types";

import "./CourseSuccessModal.style.scss";

const CourseSuccessModal = ({ onClose }) => {
  const naviagte = useNavigate();

  const handleDoneClick = () => {
    naviagte("/coach/profile/roadmap/");
  };


  return (
    <div className="CourseSuccessModalContainer">
      <div className="CourseSuccessModal">
        <div className="SuccessIconWrapper">
          <FaRegCheckCircle className="SuccessIcon" />
        </div>
        <h3>Successful</h3>
        <p>
          Your submission for review was successful.
          This might take 2 working days for approval
        </p>
        <button onClick={handleDoneClick}>Done</button>
      </div>
    </div>
  );
};
CourseSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default CourseSuccessModal;
