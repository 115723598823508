/* eslint-disable quotes */
import React, {useState, useEffect} from "react";
import { workHistoryData } from "../../../data/AppliedData";
import { CustomPagination } from "../../../Materials/Materials";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import JobApplicationRecent from "../JobApplicationRecent/JobApplicationRecent";
import "./JsApplied.style.scss";

const JsRecentApplied = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  //const [navigation, setNavigation] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const itemsPerPage = 4;
  const totalItems = workHistoryData.length;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const itemsToDisplay = workHistoryData.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleClosePopOvers = (i) => {
    setIsOpen(!isOpen);
    setIndex(i);
    navigate("/job_seeker/profile/applied_courses/recent?application");
  };
  useEffect(() => {
    setIndex(index);
  }, [index]);
  return (
    <div>
      <div className="JsAppliedCategory">
        {isOpen && (
          <div className="JsAppliedPopOvers">
            <div className="JsAppliedSkin" onClick={handleClosePopOvers}></div>
            <div className="JsAppliedPopOver">
              <JobApplicationRecent index={index}/>
            </div>
          </div>
        )}
        <div className="JsAppliedTabs">
          {itemsToDisplay.map((history, index) => {
            return (
              <div
                key={index}
                className="JsAppliedTabsData"
                onClick={() => handleClosePopOvers(index)}
              >
                <div className="ExperienceData">
                  <div className="JsAppliedPic">
                    <img src={history.icon} alt="" width={95} height={95} />
                  </div>
                  <div className="JsAppliedDataInfo">
                    <h5>{history.title}</h5>
                    <p id="JsAppliedDataParagOne">Company: {history.company}</p>
                    <p id="JsAppliedDataParagTwo">
                      Status: <span>{history.status}</span>
                    </p>
                  </div>
                </div>
                <div className="EditApplied">
                  <Link
                    to={`/job_seeker/profile/applied_courses/recent/application`}
                  >
                    {history.applicationDays}{" "}
                    <IoIosArrowForward className="EditAppliedIcon" />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="JsJobsPagination">
          <p>
            Showing {currentPage} out of {totalPages}
          </p>
          <div>
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JsRecentApplied;