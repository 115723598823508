import React, { useState, useCallback } from "react";
import { BsSearch} from "react-icons/bs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import "./RecruiterApplicantsPage.style.scss";
import JobView from "./JobView";

const columns = [
  { id: "names", label: "Names", minWidth: 200, align: "left" },
  { id: "role", label: "Job Role", minWidth: 100, align: "center" },
  { id: "type", label: "Job Type", minWidth: 160, align: "center" },
  { id: "location", label: "Location", minWidth: 120, align: "center" },
  { id: "date", label: "Date Applied", minWidth: 130, align: "center" },
  { id: "status", label: "Status", minWidth: 80, align: "center" },
  { id: "action", label: "Action", minWidth: 80, align: "center" }
];
function createData(names, role, type, location, date, status, action) {
  return { names, role, type, location, date, status, action };
}

const rows = [
  createData("Kweenier Hamza Jane", "Product Design", "Full Time", "Remote", "02/09/23", "Rejected", "View"),
  createData("Stalin Moh", "Fullstack Engineer", "Internship", "Hybrid", "27/09/23", "Pending", "View"),
  createData("Jessica Pearson", "Front End Developer", "Contract", "Onsite", "01/11/23", "Accepted", "View"),
  createData("John Doe", "Back End Developer", "Full Time", "Onsite", "15/10/23", "Pending", "View"),
  createData("Alice Johnson", "UI/UX Designer", "Contract", "Remote", "05/08/23", "Accepted", "View"),
  createData("Bob Smith", "Data Scientist", "Internship", "Hybrid", "19/07/23", "Rejected", "View"),
  createData("Eva Green", "Software Engineer", "Full Time", "Onsite", "08/06/23", "Rejected", "View"),
  createData("Michael Brown", "Mobile App Developer", "Contract", "Remote", "12/04/23", "Pending", "View"),
  createData("Olivia Taylor", "QA Tester", "Full Time", "Hybrid", "03/03/23", "Accepted", "View"),
  createData("William Turner", "System Administrator", "Internship", "Onsite", "22/02/23", "Pending", "View"),
  createData("Grace Thompson", "Marketing Specialist", "Full Time", "Remote", "10/01/23", "Accepted", "View"),
  createData("Samuel White", "Network Engineer", "Contract", "Onsite", "18/12/22", "Pending", "View"),
  createData("Sophie Turner", "Content Writer", "Internship", "Hybrid", "04/11/22", "Pending", "View"),
  createData("Edward Harris", "Financial Analyst", "Full Time", "Onsite", "30/09/22", "Rejected", "View"),
  createData("Isabel Robinson", "HR Coordinator", "Contract", "Remote", "12/08/22", "Pending", "View"),
  createData("Leo Adams", "Graphic Designer", "Full Time", "Hybrid", "26/07/22", "Pending", "View"),
  createData("Mia Foster", "Business Analyst", "Internship", "Onsite", "15/06/22", "Accepted", "View"),
  createData("Daniel Baker", "Customer Support", "Contract", "Remote", "03/05/22", "Accepted", "View"),
  createData("Chloe Powell", "Data Entry Clerk", "Full Time", "Onsite", "20/04/22", "Pending", "View"),
  createData("Liam Walker", "Project Manager", "Internship", "Hybrid", "08/03/22", "Rejected", "View"),
];

const RecruiterApplicantsPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isView, setIsView] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick =  useCallback(() => 
    setIsView(true), []);
  
  const handleClose = useCallback(() => 
    setIsView(false), []);

  return (
    <div className="RecruiterApplicantsContainer">
      <div className="RecruiterApplicantsContent">
        <div className="ApplicantSearchInputField">
          <BsSearch className="ApplicantSearchIcon" />
          <input 
            type="text"
            placeholder={"Search course keyword or title"}
            className={"ApplicantSearchTextField"} 
            onChange={""}
            value={""}
          />
        </div>
        <div className="ApplicantsTopSection">
          <div className="ApplicantSelectButtons">
            <button>
              <select>
                <option value="" key="">
                  Advance Filter
                </option>
              </select>
            </button>
            <button onClick={handleClick}>Set Hiring Stage</button>
          </div>
        </div>
        <Paper className="ApplicantTablePaper">
          <TableContainer className="ApplicantTableContainer">
            <Table className="ApplicantTable">
              <TableHead className="ApplicantTableHead">
                <TableRow className="ApplicantTableHeadRow">
                  {columns.map((column) => (
                    <TableCell
                      className="ApplicantTableHeadCell"
                      key={column.id}
                      align={column.align}
                      style={{minWidth: column.minWidth}}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="ApplicantTableBody">
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code} className="ApplicantTableBodyRow">
                        {columns.map((column, colIndex) => {
                          const value = row[column.id];
                          return (
                            <TableCell 
                              className="ApplicantTableBodyCell"
                              key={column.id}
                              align={column.align}
                              onClick={() => {
                                if(column.id === "action") {
                                  handleClick();
                                }
                              }}
                              style={column.id === "action" ? { cursor: "pointer", color: "var(--Foundation-PRI-pri-500, #2A53A9)" } : {}}
                            >
                              {(column.format && typeof value === "number"
                                ? column.format(value)
                                : column.id === "status" ? (
                                  <span
                                    style={{ 
                                      backgroundColor: 
                                        value === "Accepted" ? "var(--Alert-Succes-100, #66DDB3)" : 
                                          value === "Pending" ? "var(--Alert-Warning-100, #FFD688)" :
                                            value === "Rejected" ? "var(--Alert-Danger-100, #FF8C8C)" : "", 
                                      padding: "2px 10px 2px 10px",
                                      borderRadius: "10px",
                                      color: "var(--CoolGray-90, #21272A)",
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {value}
                                  </span>
                                ) : ( 
                                  value
                                )
                              )} 
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination 
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            shape="rounded"
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <JobView 
        open={isView} 
        onClose={handleClose}
        anchor="right"
      />
    </div>
  );
};

export default RecruiterApplicantsPage;