import React, { useState } from "react";
import { EyeIcon } from "../../../svgs/SvgComponent";
import { FiEdit } from "react-icons/fi";
import { FiMessageCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import MenteeDetailsModal from "./MenteeDetailsModal";
import MenteeGradingModal from "./MenteeGradingModal";
import PropTypes from "prop-types";

import "./SeeMoreModal.style.scss";

const SeeMoreModal = ({ onClose }) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isGradingModalOpen, setIsGradingModalOpen] = useState(false);

  const handleOpenDetailsModal = () => {
    setIsDetailsModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };
  const handleOpenGradingModal = () => {
    setIsGradingModalOpen(true);
  };
  const handleCloseGradingModal = () => {
    setIsGradingModalOpen(false);
  };

  return (
    <div className="ModalContainer">
      <div className="MenteeModal">
        <div className="CloseIconWrapper">
          <IoClose onClick={onClose} />
        </div>
        <div onClick={handleOpenDetailsModal} className="MenteeViewWrapper">
          <EyeIcon className="ModalIcon" />
          <p>View Details</p>
        </div>
        <div onClick={handleOpenGradingModal} className="MenteeGradingWrapper">
          <FiEdit className="ModalIcon" />
          <p>Add Gradings and Recommendations</p>
        </div>
        <div className="MenteeChatWrapper">
          <FiMessageCircle className="ModalIcon" />
          <p>Message</p>
        </div>
      </div>
      {isDetailsModalOpen && <MenteeDetailsModal onClose={handleCloseDetailsModal} />}
      {isGradingModalOpen && <MenteeGradingModal onClose={handleCloseGradingModal} />}
    </div>
  );
};
SeeMoreModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SeeMoreModal;