import React, { useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./RecruiterViewApplicant.style.scss";
import { IoArrowBackOutline } from "react-icons/io5";
import { TextField } from "../../../Materials/InputFields";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { HiDotsHorizontal } from "react-icons/hi";
import { EmptyEye } from "../../../svgs/svg";
import PropTypes from "prop-types";
import { ApplicantBreakdown } from "../RecruiterApplicants/RecruiterAllApplicants";
import JobView from "../RecruiterApplicants/JobView";

const data = [
  { name: "On Pass Score", value: 180 },
  { name: "Above Pass Score", value: 600 },
  { name: "Below Pass Score", value: 420 }
];

const colors = ["#2A53A9", "#00B374", "#CC3333"];

const radian = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * radian);
  const y = cy + radius * Math.sin(-midAngle * radian);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const columns = [
  { id: "score", label: "Score", minWidth: "auto", align: "center" },
  { id: "flag", label: "Flag", minWidth: "auto", align: "center" },
  {
    id: "count",
    label: "Applicant Count",
    minWidth: "auto",
    align: "center"
  },
  { id: "action", label: "Action", minWidth: "auto", align: "center" }
];

function createData(score, flag, count, action) {
  return { score, flag, count, action };
}

const rows = [
  createData("Above 50", "Above Pass Score", 2),
  createData("On 50", "On Pass Score", 1),
  createData("Below 50", "Below Pass Score", 1)
];

export const ApplicationTop = () => {
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };

  const ApplicantsCount = rows.reduce((accumulator, row) => {
    return accumulator + row.count;
  }, 0);
  return (
    <div className="TopContent">
      <div
        className="ApplicationsTop"
        onClick={handleClickBack}
        style={{ cursor: "pointer" }}
      >
        <IoArrowBackOutline className="AppIcon" />
        <p>Back</p>
      </div>
      <h6>Applicants/Posting</h6>
      <div className="ApplicantCounts">
        <div className="ApplicantCount">
          <p>Applicants:</p>
          <span>{ApplicantsCount}</span>
        </div>
        <div className="ApplicantStatus">
          <p>Status:</p>
          <span>Ongoing</span>
        </div>
      </div>
    </div>
  );
};

export const CustomPieChart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
              // style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
const spanStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center"
};
const dotStyle = {
  margin: "0px 8px",
  height: "8px",
  width: "8px",
  borderRadius: "8px"
};

const RecruiterViewApplicant = () => {
  const navigate = useNavigate();

  const handleGreen = () => {
    navigate("/recruiter/profile/job/highly-recommended");
  };

  const handleRed = () => {
    navigate("/recruiter/profile/job/not-recommended");
  };

  const handleBlue = () => {
    navigate("/recruiter/profile/job/recommended");
  };

  const handleClickEye = (score) => {
    switch (score) {
      case "Above 50":
        return handleGreen();
      case "Below 50":
        return handleRed();
      case "On 50":
        return handleBlue();
      default:
        return () => {};
    }
  };

  const totalSum = rows.reduce((accumulator, row) => {
    return accumulator + row.count;
  }, 0);

  const scoreColors = [
    "var(--Alert-Succes-600, #00B374)",
    "#2A53A9",
    "var(--Alert-Danger-700, #C33)"
  ];

  return (
    <div className="ApplicationsContainer">
      <div className="ApplicationsContent">
        <ApplicationTop />
        <RecruiterApplicationDetails />
        <div className="ApplicationBox">
          <div className={"ApplicationDistribution"}>
            <div className="ApplicationTable">
              <div className="AppTableHeading">
                <h6>All Applicant Distrubution:</h6>
                <a href="/recruiter/profile/job/all-applicants">View All</a>
              </div>
              <Paper className="AppPaper">
                <Table className="AppTable">
                  <TableHead className="AppTableHead">
                    <TableRow className="AppTableRow">
                      {columns.map((column) => (
                        <TableCell
                          className="AppTableCell"
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="AppTableBody">
                    {rows
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, rowIndex) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                            className="AppTableBodyRow"
                          >
                            {columns.map((column, colIndex) => {
                              const value = row[column.id];

                              const cellStyle = {};

                              if (column.id === "score" && rowIndex < 3) {
                                cellStyle.color = scoreColors[rowIndex];
                              }
                              return (
                                <TableCell
                                  className="AppTableBodyCell"
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    color:
                                      column.id === "score"
                                        ? scoreColors[rowIndex]
                                        : "inherit",
                                    fontWeight: column.id == "score" ? 600 : ""
                                  }}
                                >
                                  {colIndex === columns.length - 1 ? (
                                    <div
                                      onClick={() => handleClickEye(row.score)}
                                      className="ActionContainer"
                                    >
                                      <EmptyEye />
                                    </div>
                                  ) : column.format &&
                                    typeof value === "number" ? (
                                    column.format(value)
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Paper>
              <span className="AppTableTotal">
                <p>Total</p>
                <p>{totalSum}</p>
              </span>
            </div>
            <div className="ApplicationChart">
              <h6>Pass Score Distrubution</h6>
              <div className="AppPieWrapper">
                <CustomPieChart />
              </div>
              <div className="AppPieChartKeys">
                <div className="AppPieChartKey">
                  <span style={{ ...spanStyle }}>
                    <span
                      style={{
                        ...dotStyle,
                        backgroundColor: "#00B374"
                      }}
                    ></span>
                    <p>Above Pass Score</p>
                  </span>
                  <span style={{ ...spanStyle }}>
                    <span
                      style={{
                        ...dotStyle,
                        backgroundColor: "#CC3333"
                      }}
                    ></span>
                    <p>Below Pass Score</p>
                  </span>
                </div>
                <div className="AppPieChartKey">
                  <span style={{ ...spanStyle }}>
                    <span
                      style={{
                        ...dotStyle,
                        backgroundColor: "#2A53A9"
                      }}
                    ></span>
                    <p>On Pass Score</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"AllApplicantsView"}>
            <RecruiterViewAllApplicant />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterViewApplicant;

export const RecruiterViewAllApplicant = () => {
  const [view, setView] = useState(false);
  const [clickPosition, setClickPosition] = useState({ y: 0 });

  const handleClickView = () => {
    setView(true);
    setClickPosition({ y: event.clientY });
  };

  const handleClickClose = () => {
    setView(false);
  };

  const today = new Date();

  let day = today.getDate();
  let month = today.getMonth() + 1;
  const year = today.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  const formattedDate = day + "/" + month + "/" + year;

  const columns = [
    { id: "name", label: "Name", minWidth: "auto", align: "left" },
    {
      id: "score",
      label: "Score",
      minWidth: "auto",
      align: "center",
      format: (value) => {
        let color;

        if (value === 50) {
          color = "#2A53A9";
        } else if (value > 50) {
          color = "var(--Alert-Succes-600, #00B374)";
        } else {
          color = "var(--Alert-Danger-700, #C33)";
        }

        return (
          <div className="ScoreContainer">
            <span style={{ color }}>{value}</span>
            <span style={{ color }}>/100</span>
          </div>
        );
      }
    },
    { id: "flag", label: "Flag", minWidth: "auto", align: "center" },
    {
      id: "recommend",
      label: "Recommendation",
      minWidth: "auto",
      align: "center"
    },
    { id: "date", label: "Date Applied", minWidth: "auto", align: "center" },
    { id: "action", label: "Action", minWidth: "auto", align: "center" }
  ];

  function createData(name, score, flag, recommend, date, action) {
    return { name, score, flag, recommend, date, action };
  }

  const rows = [
    createData(
      "Amirah Doe",
      60,
      "Above Pass score",
      "Highly Recommended",
      formattedDate
    ),
    createData(
      "John Holt",
      45,
      "Below Pass score",
      "Not Recommended",
      formattedDate
    ),
    createData("Udoh Holt", 50, "On Pass score", "Recommended", formattedDate),
    createData(
      "Usman Dexdee",
      55,
      "Above Pass score",
      "Highly Recommended",
      formattedDate
    )
  ];

  return (
    <div className="AllApplicantContainer">
      <Paper className="AllPaper">
        <Table className="AllTable">
          <TableHead className="AllTableHead">
            <TableRow className="AllTableRow">
              <TableCell className="AllTableCell" key="s/no" align="center">
                S/No
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  className="AllTableCell"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="AllTableBody">
            {rows.map((row, index) => {
              return (
                <TableRow
                  className="AllTableBodyRow"
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  <TableCell
                    className="AllTableBodyCell"
                    key="s/no"
                    align="center"
                  >
                    {index + 1}
                  </TableCell>
                  {columns.map((column, colIndex) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        className="AllTableBodyCell"
                        key={column.id}
                        align={column.align}
                      >
                        {colIndex === columns.length - 1 ? (
                          <div
                            className="ViewIconContainer"
                            style={{ cursor: "pointer" }}
                          >
                            <HiDotsHorizontal
                              onClick={handleClickView}
                              className="ViewIcon"
                            />
                          </div>
                        ) : column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : column.id === "recommend" ? (
                          <span
                            style={{
                              padding: "1px 10px 2px 10px",
                              borderRadius: "10px",
                              fontSize: "16px",
                              color: "white",
                              backgroundColor:
                                value === "Highly Recommended"
                                  ? "var(--Alert-Succes-600, #00B374)"
                                  : value === "Recommended"
                                  ? "var(--Alert-Info-600, #2A53A9)"
                                  : "var(--Alert-Danger-700, #C33)"
                            }}
                          >
                            {value}
                          </span>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <ViewOptions
        onClose={handleClickClose}
        isOpen={view}
        position={clickPosition}
      />
    </div>

  );
};

export const ViewOptions = ({ onClose, isOpen, position }) => {
  const [isBreakdown, setIsBreakdown] = useState(false);
  const [isView, setIsView] = useState(false);

  const handleBreakdown = () => {
    setIsBreakdown(true);
  };

  const handleBreakdownClose = () => {
    setIsBreakdown(false);
  };

  const handleClick =  useCallback(() => 
    setIsView(true), []);
  
  const handleClose = useCallback(() => 
    setIsView(false), []);

  return (
    <>
      {isOpen && (
        <div className="ViewOverlay" onClick={onClose}>
          <div
            className="View"
            onClick={(e) => e.stopPropagation()}
            style={{ top: position.y }}
          >
            <Link 
              onClick={handleBreakdown}
            >
              View Breakdown
            </Link>
            <Link 
              onClick={handleClick}
            >
              Applicant Details
            </Link>
          </div>
          <ApplicantBreakdown
            isOpen={isBreakdown}
            onClose={handleBreakdownClose}
          />
          <JobView 
            open={isView} 
            onClose={handleClose}
            anchor="right"
          />
        </div>
      )}
    </>
  );
};
ViewOptions.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
  position: PropTypes.func.isRequired
};

export const RecruiterApplicationDetails = () => {
  return (
    <div className="ApplicationDetails">
      <div className="ApplicationInputs">
        <TextField
          className={"ApplicationInput"}
          id={"ApplicationInput"}
          type={"text"}
          placeholder={"Associate Product Designer"}
          disabled={true}
          label={"Job Title"}
        />
        <TextField
          className={"ApplicationInput"}
          id={"ApplicationInput"}
          type={"text"}
          placeholder={"Design"}
          disabled={true}
          label={"Category"}
        />
        <TextField
          className={"ApplicationInput"}
          id={"ApplicationInput"}
          type={"text"}
          placeholder={"Full-Time"}
          disabled={true}
          label={"Job Type"}
        />
      </div>
      <div className="ApplicationInputs">
        <TextField
          className={"ApplicationInput"}
          id={"ApplicationInput"}
          type={"text"}
          placeholder={"Remote"}
          disabled={true}
          label={"Location"}
        />
        <TextField
          className={"ApplicationInput"}
          id={"ApplicationInput"}
          type={"text"}
          placeholder={"14th April, 2024"}
          disabled={true}
          label={"Date Created"}
        />
        <TextField
          className={"ApplicationInput"}
          id={"ApplicationInput"}
          type={"text"}
          placeholder={"30th May, 2024"}
          disabled={true}
          label={"Closing Date"}
        />
      </div>
    </div>
  );
};
