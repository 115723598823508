import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { MdInfoOutline } from "react-icons/md";
import { CloseRed } from "../../svgs/svg";

import "./OptionsModal.style.scss";
import EditRoleDrawer from "./EditRoleDrawer";

export const Assign = ( { isOpen, onClose } ) => {
  return (
    <>
      {isOpen && (
        <div className="AssignContainer">
          <div className="AssignContent">
            <div className="AssignTop">
              <h6>Role Assigned to</h6>
              <span className="AssignIconWrapper" onClick={onClose}>
                <CloseRed />
              </span>
            </div>
            <p>These employees have been assigned the <span>Admin</span> role.</p>
            <div className="AssignedRoles">
              <span>
                <p>Mary Doe</p>
                <button>Remove</button>
              </span>
              <span>
                <p>Singh Parkesh</p>
                <button>Remove</button>
              </span>
            </div>
            <div className="AssignedButtons">
              <button onClick={onClose}>Cancel</button>
              <button>Save</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
Assign.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
};

const Delete = ( { onClose, isOpen } ) => {
  return (
    <>
      {isOpen && (
        <div className="DeleteContainer">
          <div className="Delete">
            <div className="DeleteIconWrapper">
              <MdInfoOutline className="DeleteIcon" />
            </div>
            <h6>Delete this Role</h6>
            <p>You are about to delete a role , do you want to proceed?</p>
            <div className="ActivityButtons">
              <button onClick={onClose}>Back</button>
              <button>Delete Role</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
Delete.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
};

const OptionsModal = ( { onClose, isOpen, position } ) => {
  const [isDelete, setIsDelete] = useState(false);
  const [isAssign, setIsAssign] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleEditClick = useCallback(() => setIsEdit(true), []);

  const handleCloseEdit = useCallback(() => setIsEdit(false), []);

  const handleDeleteClick = () => {
    setIsDelete(true);
  };
  const handleCancelDelete = () => {
    setIsDelete(false);
  };
  const handleAssignClick = () => {
    setIsAssign(true);
  };
  const handleCancelAssign = () => {
    setIsAssign(false);
  };
  return (
    <>
      {isOpen && (
        <div 
          className="ModalContainer" 
          onClick={onClose}
        >
          <div 
            className="Modal" 
            onClick={(e) => e.stopPropagation()}
            style={{ top: position.y}}
          >
            <p
              onClick={handleEditClick}
            >Edit</p>
            <p 
              onClick={handleDeleteClick}
            >Delete</p>
            <p
              onClick={handleAssignClick}
            >Assign to</p>
          </div>
          <Delete 
            isOpen={isDelete}
            onClose={handleCancelDelete}
          />
          <Assign 
            isOpen={isAssign}
            onClose={handleCancelAssign}
          />
          <EditRoleDrawer 
            open={isEdit}
            onClose={handleCloseEdit}
            anchor="right"
          />
        </div>
      )}
    </>
  );
};
OptionsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
  position: PropTypes.func.isRequired
};

export default OptionsModal;