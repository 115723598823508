import React, { useState } from "react";
import { quiz } from "../../../data/roadmapData";
import { modules } from "../../../data/roadmapData";
import { RiArrowDownSLine } from "react-icons/ri";
import { ActiveTabIcon, InactiveTabIcon } from "../../../svgs/SvgComponent";
import { Link } from "react-router-dom";
import CourseSuccessModal from "./CourseSuccessModal";

import "./CoachCourseSummary.style.scss";

const CoachCourseSummary = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="CourseSummaryContainer">
      <div className="CourseSummaryContent">
        <div className="CourseSummaryHeading">
          <h3>Course Title</h3>
          <button onClick={handleOpenModal}>Submit for Review</button>
        </div>
        <div className="CourseSummaryTabs">
          <div className="CourseSummaryTab">
            <InactiveTabIcon className="CourseSummaryTabIcon" />
            <p>1. Course Details</p>
          </div>
          <div className="CourseSummaryTab">
            <InactiveTabIcon className="CourseSummaryActiveTabIcon" />
            <p>2. Course Resources</p>
          </div>
          <div className="CourseSummaryTab">
            <InactiveTabIcon className="CourseSummaryTabIcon" />
            <p>3. Course Quiz</p>
          </div>
          <div className="CourseSummaryActiveTab">
            <ActiveTabIcon className="CourseSummaryTabIcon" />
            <p>4. Summary</p>
          </div>
        </div>
        <div className="CourseSummaryDetails">
          <div className="SummaryDetailsWrapper">
            <h4>Course Details</h4>
            <Link
              to={"/coach/profile/roadmap/add_roadmap"}
              style={{textDecoration: "none"}}
            >
              <p>Edit</p>
            </Link>
          </div>
        </div>
        <div className="CourseSummaryResources">
          <div className="SummaryResourcesHeading">
            <h4>Course Resources</h4>
            <Link
              to={"/coach/profile/roadmap/course_resources"}
              style={{textDecoration: "none"}}
            >
              <p>Edit</p>
            </Link>
          </div>
          {modules.map((module, index) => (
            <div key={index} className="CourseResourcesWrapper">
              <p>{module.title}</p>
              <RiArrowDownSLine style={{ height: "24px", width: "24px", color: "#2A53A9"}} />
            </div>
          ))}
        </div>
        <div className="CourseSummaryQuiz">
          <div className="SummaryQuizHeading">
            <h4>Course Quiz</h4>
            <Link
              to={"/coach/profile/roadmap/course_quiz"}
              style={{textDecoration: "none"}}
            >
              <p>Edit</p>
            </Link>
          </div>
          {quiz.map((data, index) => (
            <div key={index} className="SummaryQuizWrapper">
              <p>{data.title}</p>
              <RiArrowDownSLine style={{ height: "24px", width: "24px", color: "#2A53A9"}} />
            </div>
          ))}
        </div>
      </div>
      {modalOpen && <CourseSuccessModal onClose={handleCloseModal} /> }
    </div>
  );
};

export default CoachCourseSummary;
