import React from "react";
import { ExploreIcon } from "../../../svgs/SvgComponent";
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";

import "./RecruiterHomepage.style.scss";


const data = [
  {
    total: "35",
    reviewed: "23",
    rejected: "10",
    hired: "11"
  }
];

const month = [
  { month: "Jan", value: 200, key: 250 },
  { month: "Feb", value: 300, key: 400 },
  { month: "Mar", value: 320, key: 350 },
  { month: "Apr", value: 450, key: 500 },
  { month: "May", value: 470, key: 520 },
  { month: "Jun", value: 500, key: 540 },
  { month: "Jul", value: 490, key: 510 },
  { month: "Aug", value: 530, key: 570 },
  { month: "Sep", value: 590, key: 630 },
];

const jobs = [
  {
    category: "Design",
    title: "Associate Product Designer",
    location: "Remote",
    type: "Full-Time",
    status: "Published",
    applicants: "57"
  },
  {
    category: "Engineering",
    title: "Frontend Engineer",
    location: "Hybrid",
    type: "Internship",
    status: "Published",
    applicants: "23"
  },
  {
    category: "Engineering",
    title: "Full-Stack Engineer",
    location: "Onsite",
    type: "Contract",
    status: "Published",
    applicants: "37"
  },
];

const RecruiterHomepage = () => {
  return (
    <div className="RecruiterHomePageContainer">
      <div className="RecruitmentHomePageContent">
        {data.length === 0 ? (
          <div className="EmptyDataSection">
            <div className="HomeTopSection">
              <div className="TopSectionBox">
                <p>Total Applicants</p>
                <p>0</p>
              </div>
              <div className="TopSectionBox">
                <p>Reviewed Applicants</p>
                <p>0</p>
              </div>
              <div className="TopSectionBox">
                <p>Rejected Applicants</p>
                <p>0</p>
              </div>
              <div className="TopSectionBox">
                <p>Hired Applicants</p>
                <p>0</p>
              </div>
            </div>
            <div className="HomeBottomSection">
              <ExploreIcon />
              <p>No data to Display, search for an item for result to display</p>
              <button>Start Hiring</button>
            </div>  
          </div>
        ) : (
          data.map((item, index) => (
            <div key={index} className="FilledDataSection">
              <div className="HomeTopSection">
                <div className="TopSectionBox">
                  <p>Total Applicants</p>
                  <p>{item.total}</p>
                </div>
                <div className="TopSectionBox">
                  <p>Reviewed Applicants</p>
                  <p>{item.reviewed}</p>
                </div>
                <div className="TopSectionBox">
                  <p>Rejected Applicants</p>
                  <p>{item.rejected}</p>
                </div>
                <div className="TopSectionBox">
                  <p>Interviewed Applicants</p>
                  <p>{item.hired}</p>
                </div>
              </div>
              <div className="LineChartSection">
                <div className="LineChartHeading">
                  <h6>Job Posting Statistics</h6>
                  <div className="LineChartButtons">
                    <div className="LineChartKeys">
                      <span style={{height: "8px", width: "8px", backgroundColor: "var(--Alert-Succes-600, #00B374)", margin: "0 8px", borderRadius: "8px"}}></span>
                      <span>Job Views</span>
                      <span style={{height: "8px", width: "8px", backgroundColor: "var(--Foundation-PRI-pri-500, #2A53A9)", margin: "0 8px", borderRadius: "8px"}}></span>
                      <span>Job Application</span>
                    </div>
                    <button>
                      <select>
                        <option value="" key="">Relevance Type</option>
                      </select>
                    </button>
                    <button>
                      <select>
                        <option value="" key="">Filter Job Category</option>
                      </select>
                    </button>
                  </div>
                </div>
                <div className="HomeLineChart">
                  <AreaChart width={1150} height={360} data={month}>
                    <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 0 3"/>
                    <XAxis dataKey="month"
                      axisLine={{ stroke: "none", strokeWidth: 0 }} 
                      tick={{ fill: "var(--Opacity-Opacity-80, rgba(0, 0, 0, 0.80))", fontSize: 12}} 
                      tickLine={{ stroke: "none" }} 
                      tickMargin={10}
                    />
                    <YAxis 
                      axisLine={{ stroke: "none", strokeWidth: 0 }} 
                      tick={{ fill: "var(--Secondary-200, #9BA6BC)", fontSize: 10 }} 
                      tickLine={{ stroke: "none" }}
                    />
                    <Tooltip />
                    <Area
                      dataKey="value"
                      strokeWidth={2.5}
                      stroke="var(--alert-succes-500-base, #00C781)"
                      fill= "#E7F6EC"
                      dot={false}
                    />
                    <Area
                      dataKey="key"
                      strokeWidth={2.5}
                      stroke="var(--Foundation-PRI-pri-500, #2A53A9)"
                      fill= "var(--Primary-100, #ECF1FE)"
                      dot={false}
                    />
                  </AreaChart>
                </div>
              </div>
              <div className="HomeBottomSection">
                {jobs.map((job, jobIndex) => (
                  <div key={jobIndex} className="HomeJobBox">
                    <p>{job.category}</p>
                    <h2>{job.title}</h2>
                    <p className="Location">Location: <span>{job.location}</span></p>
                    <p className="Type">Job Type: <span>{job.type}</span></p>
                    <p className="Status">{job.status}</p>
                    <div className="TotalApplicants">
                      <p>Total Applicants</p>
                      <span>{job.applicants}</span>
                    </div>
                    <button>View Job</button>
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default RecruiterHomepage;