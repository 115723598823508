/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { AiOutlinePlus } from "react-icons/ai";
import PropTypes from "prop-types";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Button from "@mui/material/Button";
import { Switch, TextField } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Custom Switch
export const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--Primay-500, #3C76F1)",
        opacity: 1,
        border: 0
      }
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "var(--Secondary-Bg,#EDF1F8)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));

export const LinearIndeterminate = () => {
  return (
    <Box sx={{ width: "50%" }}>
      <LinearProgress />
    </Box>
  );
};

export const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleChange = (event, newPage) => {
    onPageChange(newPage);
  };

  return (
    <Stack spacing={2}>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
      />
    </Stack>
  );
};

// Accordion Button
export const AccordionButton = ({ heading, data }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<AiOutlinePlus />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          style={{
            color: "var(--cool-gray-90, #21272A)",
            fontFamily: "TT Wellingtons Trl",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "110%"
          }}
        >
          {heading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          style={{
            color: "var(--cool-gray-90, #21272A)",
            fontFamily: "TT Wellingtons Trl",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "110%"
          }}
        >
          {data}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
AccordionButton.propTypes = {
  heading: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired
};

// Another Accordion
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none"
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ height: "24px", width: "24px" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

export const AccordionComponent = ({ heading, data }) => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{heading}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{data}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
AccordionComponent.propTypes = {
  heading: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired
};

// ToolTip
export const ToolTips = ({
  button,
  display,
  arrow,
  placement,
  width,
  height,
  className,
  boxShadow,
  background,
  color,
  marginLeft,
  marginTop
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: background,
      color: color,
      Width: width,
      fontSize: theme.typography.pxToRem(12),
      border: "none",
      position: "fixed",
      zIndex: 14,
      height: height,
      boxShadow: boxShadow,
      overflowY: "scroll",
      overflowX: "scroll",
      marginTop: marginTop ? marginTop : "0.8rem",
      marginLeft: marginLeft ? marginLeft : "0",
      left: "5%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }
  }));

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={display}
          arrow={arrow}
          placement={placement}
        >
          <Button className={className} onClick={handleTooltipOpen}>
            {button}
          </Button>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  );
};
