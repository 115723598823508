import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import { getApi } from "../../../Api/server";
import { shortenedYear } from "../../../data/ProfileSetupData";
import { BsPencil } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import "react-circular-progressbar/dist/styles.css";
import "./ProfileDetail.style.scss";

export const NoDataStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "auto",
};
export const NoDataParag = {
  fontFamily: "$first-font",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  color: "#21272a",
};

const WorkExperience = () => {
  const getExperienceIndex = useRef(null);
  const [isLoading, setIsLoading] =
		useState(false);
  const [workHistoryData, setWorkHistoryData] =
		useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getUserWorkingExperience = async () => {
      setIsLoading(true);
      const response = await getApi(
        "e1/job_seeker/profile/work_experience"
      );
      if (response?.code === 403) {
        navigate("/login");
      }
      if (response?.code !== 200) {
        console.log(response?.message);
        setIsLoading(false);
        return;
      } else {
        const value = response?.data;
        setWorkHistoryData(value);
        setIsLoading(false);
      }
    };
    getUserWorkingExperience();
  }, []);
  return (
    <div className="ProfileExperiences">
      <div className="ProfileExperienceHeader">
        <h2>Work Experience</h2>
        <Link
          to={
            "/job_seeker/profile/profile_setup/add_experience"
          }>
          <p>Add work Experience</p>
          <AiOutlinePlusCircle className="ProfileAddExperience" />
        </Link>
      </div>
      <div className="ProfileExperienceTagNames">
        {isLoading ? (
          <CircularProgress
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        ) : workHistoryData ? (
          workHistoryData?.map(
            (history, index) => {
              getExperienceIndex.current = index;
              return (
                <div
                  key={index}
                  className="AddExperienceData">
                  <div className="ExperienceData">
                    <span>
                      <img
                        src="/icons/certificate.png"
                        alt=""
                        width={56}
                        height={56}
                      />
                    </span>
                    <span>
                      <h5>{history.jobTitle}</h5>
                      <div>
                        <p>
                          {history.companyName}
                        </p>
                        <p>
                          {shortenedYear[
                            history.startDate.split(
                              "-"
                            )[1] -1
                          ] + " " + history.startDate.split(
                            "-"
                          )[0]}
                        </p>
                        <p>{history.endDate !== "Till Date" ? shortenedYear[
                          history.endDate.split(
                            "-"
                          )[1] -1
                        ] + " " + history.endDate.split(
                          "-"
                        )[0] : "Till Date"}</p>
                      </div>
                    </span>
                  </div>
                  <div>
                    <Link
                      to={`/job_seeker/profile/profile_setup/add_experience/update/index=${index}`}>
                      <BsPencil />
                    </Link>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div
            className="NoDataSection"
            style={NoDataStyle}>
            <p style={NoDataParag}>
							No Data to display
            </p>
          </div>
        )}
      </div>
      <div className="ProfileExperienceButton">
        <button>Save</button>
      </div>
    </div>
  );
};

export default WorkExperience;
