/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./CoachProfileDetail.style.scss";
import { TextField, SelectField, TextArea } from "../../../Materials/InputFields";
import imageLoader from "../../../static/images/icons/user.png";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { workHistoryData, educationHistoryData } from "../../../data/ProfileSetupData";
import { useMyContext } from "../../../Utils/ContextProvider";
import { BsPencil } from "react-icons/bs";
import { AvatarIcon, UploadIcon } from "../../../svgs/SvgComponent";


const CoachProfileDetail = () => {
  const fileInputRef = useRef(null);
  const initialValues = {
    firstName: "",
    lastName: "",
    careerRole: "",
    yearsOfExperience: "",
    location: "",
    biography: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const { updateMyData } = useMyContext();
  const getExperienceIndex = useRef(null);
  const getEducationIndex = useRef(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      setTimeout(() => {
        navigate("/coach/profile/profile_setup");
      }, 300);
      console.log("Form submitted:", formData);
    } else {
      console.log("Form submission canceled due to validation errors.");
    }
  };
  const handleProceed = (e) => {
    e.preventDefault();
    navigate("/coach/profile/preferences");
  };
  return (
    <div className="ProfileDetailContainer">
      <div className="ProfileDetailInnerContainer">
        <div className="ProfilePhotoSection">
          <div className="ProfilePhotoHeader">
            <p>Profile Photo</p>
          </div>
          <div className="ProfilePhotoHandler">
            <div className="ProfileImageLoader">
              <span className="ProfileImageSpace">
                <AvatarIcon />
              </span>
              <span className="ProfileImageType">
                <input type="file" accept=".jpg, .jpeg, .png" onChange={""} />
                <button className="ProfileImageUpload">Upload</button>
                <button className="ProfileImageRemove">remove</button>
              </span>
            </div>
            <div className="ProfileImageRequirement">
              <p>Image Requirement</p>
              <ol>
                <li>Min 400 x 400px</li>
                <li>Max 2MB</li>
                <li>Your face or company Logo</li>
              </ol>
            </div>
          </div>
        </div>
        <hr className="ProfileLineBreaker" />

        <div className="ProfileDetailSection">
          <div className="ProfileDetailHeader">
            <h3>User Details</h3>
          </div>
          <div className="ProfileDetailForm">
            <div className="ProfileTextFields">
              <TextField
                name="firstName"
                type="text"
                value={formData.firstName}
                onChange={handleInputChange}
                label="First Name"
                className="ProfileTextField"
                id="ProfileTextField"
              />
              <TextField
                name="lastName"
                type="text"
                value={formData.lastName}
                onChange={handleInputChange}
                label="Last Name"
                className="ProfileTextField"
                id="ProfileTextField"
              />
            </div>
            <SelectField
              label="Select Career Role"
              value={formData.careerRole}
              onChange={handleSelectChange}
              className="ProfileSelectFieldOne"
              id="ProfileSelectFieldOne"
              values={[""]}
            />
            <div className="ProfileSelectFields">
              <SelectField
                label="Years of Experience"
                value={formData.yearsOfExperience}
                onChange={handleSelectChange}
                className="ProfileSelectField"
                id="ProfileSelectField"
                values={[1, 2, 3, 4]}
              />
              <SelectField
                label="Location"
                value={formData.location}
                onChange={handleSelectChange}
                className="ProfileSelectField"
                id="ProfileSelectField"
                values={["Nigeria"]}
              />
            </div>
            <TextArea
              label="Bio"
              className="ProfileBiography"
              id="ProfileBiography"
              value={formData.biography}
              onChange={handleInputChange}
              placeholder="Write something about yourself..."
            />
            <div className="TopProfileSaveButton">
              <button onClick={handleSubmit} >Save</button>
            </div>
            
            <hr className="ProfileDivider" />
            <div className="ProofOfIdentity">
              <h3>Proof Of Identity</h3>
              <div className="ProofOfIdentityUpload">
                <UploadIcon />
                <p>Please upload your  means of identification</p>
                <button onClick={handleBrowseClick}>Browse File</button>
                <input 
                  type="file" 
                  accept=".jpg .jpeg .pdf .JPG .JPEG .PDF" 
                  hidden
                  ref={fileInputRef}
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    console.log("Selected File:", selectedFile);
                  }}
                />
              </div>
            </div>
            <div className="ProfileExperiences">
              <div className="ProfileExperienceHeader">
                <h2>Work Experience</h2>
                <Link to={"/job_seeker/profile/profile_setup/add_experience"}>
                  <p>Add work Experience</p>
                  <AiOutlinePlusCircle className="ProfileAddExperience" />
                </Link>
              </div>
              <div className="ProfileExperienceTagNames">
                {workHistoryData.map((history, index) => { 
                  getExperienceIndex.current = index;
                  return(
                    <div key={index} className="AddExperienceData">
                      <div className="ExperienceData">
                        <span>
                          <img src={history.icon} alt="" width={56} height={56} />
                        </span>
                        <span>
                          <h5>{history.title}</h5>
                          <div>
                            <p>{history.company}</p>
                            <p>{history.startDate}</p>
                            <p>{history.endDate}</p>
                          </div>
                        </span>
                      </div>
                      <div>
                        <Link
                          to={
                            `/job_seeker/profile/profile_setup/add_experience/update/index=${index}`
                          }
                        >
                          <BsPencil />
                        </Link>
                      </div>
                    </div>
                  );})}
              </div>
              <div className="ProfileExperienceButton">
                <button>Save</button>
              </div>
            </div>
            <hr className="ProfileDivider" />
            <div className="ProfileExperiences">
              <div className="ProfileExperienceHeader">
                <h2>Education History</h2>
                <Link to={"/job_seeker/profile/profile_setup/add_education"}>
                  <p>Add Education History</p>
                  <AiOutlinePlusCircle className="ProfileAddExperience" />
                </Link>
              </div>
              <div className="ProfileExperienceTagNames">
                {educationHistoryData.map((history, index) => {
                  getEducationIndex.current = index;
                  return(
                    <div key={index} className="AddExperienceData">
                      <div className="ExperienceData">
                        <span>
                          <img src={history.icon} alt="" width={56} height={56} />
                        </span>
                        <span>
                          <h5>{history.title}</h5>
                          <div>
                            <p>{history.certificate}</p>
                            <p>{history.startDate}</p>
                            <p>{history.endDate}</p>
                          </div>
                        </span>
                      </div>
                      <div>
                        <Link
                          to={
                            `/job_seeker/profile/profile_setup/add_education/update/index=${index}`
                          }
                        >
                          <BsPencil />
                        </Link>
                      </div>
                    </div>
                  );})}
              </div>
              <div className="ProfileExperienceButton">
                <button>Save</button>
              </div>
            </div>
            <hr className="ProfileDivider" />
            <div className="ImportantSite">
              <div className="ImportantSiteHeader">
                <h2>Important Sites</h2>
                <Link>
                  <p>Add Site Links</p>
                  <AiOutlinePlusCircle className="ProfileAddExperience" />
                </Link>
              </div>
              <div className="LinkTextFields">
                <TextField
                  name="websiteLink"
                  type="text"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  label="Website Link"
                  className="LinkTextField"
                  id="LinkTextField"
                />
                <TextField
                  name="linkedIn"
                  type="text"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  label="LinkedIn"
                  className="LinkTextField"
                  id="LinkTextField"
                />
                <TextField
                  name="twitter"
                  type="text"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  label="Twitter"
                  className="LinkTextField"
                  id="LinkTextField"
                />
              </div>
              <div className="SaveLinkButton">
                <button>
                  Save
                </button>
              </div>
            </div>

            <div className="HandleProceedButton">
              <button onClick={handleProceed}>Proceed</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachProfileDetail;
