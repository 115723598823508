import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UploadIcon } from "../../../svgs/SvgComponent";
import userImage from "../../../static/images/icons/User Thumb.png";
import { SelectField, TextField } from "../../../Materials/InputFields";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

import "./RecruiterProfileVerification.style.scss";

const RecruiterProfileVerification = () => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate("/recruiter/profile/home");
  };
  
  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };
  const handleSubmit = () => {
    setShowSuccessModal(true);
  };
  
  return (
    <div className="ProfileVerificationContainer">
      <div className="ProfileVerificationContent">
        <div className="ProfileVerificationTop">
          <h3>Verification</h3>
          <div className="VerificationProfile">
            <div className="ProfileDetails">
              <img src={userImage} alt="" />
              <div className="ProfileParticulars">
                <p>Jane Doe Annizah</p>
                <p>Product Designer</p>
              </div>
            </div>
            <div className="AboutMe">
              <p><b>Needs a role:{" "}</b> that enables me to make a significant impact, 
              not only on the products and experiences I create but also on the overall strategic direction of the design team and the organization as a whole. 
              A collaborative workplace that encourages cross-functional cooperation and open communication
              </p>
            </div>
          </div>
        </div>
        <hr className="RecruiterVerificationDivider"/>
        <div className="RecruiterBusinessVerification">
          <h3>Business Verification</h3>
          <div className="BusinessVerificationInput">
            <TextField
              label={"Business License Number"}
              className={"BusinessVerificationTextField"}
              name={"licenseNumber"}
              value={""}
              id={"BusinessVerificationTextField"}
              onChange={""}
              type={"text"}
              placeholder={"Enter your Business License"}
            />
            <TextField 
              label={"Address"}
              className={"BusinessVerificationTextField"}
              name={"address"}
              value={""}
              id={"BusinessVerificationTextField"}
              onChange={""}
              type={"text"}
              placeholder={"Enter your Business address"}
            />
          </div>
          <div className="RecruiterAddressVerification">
            <p>Proof Of Address</p>
            <div className="RecruiterAddressUpload">
              <UploadIcon />
              <p>Please Upload your proof of Address</p>  
              <button onClick={handleBrowseClick}>Browse File</button>
              <input 
                type="file"
                accept=".jpg .jpeg .pdf .JPG .JPEG .PDF" 
                hidden
                ref={fileInputRef}
                onChange={(e) => {
                  const selectedFile = e.target.files[0];
                  console.log("Selected File:", selectedFile);
                }} 
              />  
            </div>
          </div>
        </div>
        <hr className="RecruiterVerificationDivider"/>
        <div className="RecruiterBankVerification">
          <h3>BanK Verification</h3>
          <div className="BankVerificationInput">
            <TextField
              label={"Tax Identification Number"}
              className={"BankVerificationTextField"}
              name={"tax"}
              value={""}
              id={"BankVerificationTextField"}
              onChange={""}
              type={"text"}
              placeholder={"Enter your TIN"}
            />
            <TextField 
              label={"Bank Name"}
              className={"BankVerificationTextField"}
              name={"bankName"}
              value={""}
              id={"BankVerificationTextField"}
              onChange={""}
              type={"text"}
              placeholder={"Enter your Bank Name"}
            />
          </div>
          <div className="BankVerificationInput">
            <TextField
              label={"Account Number"}
              className={"BankVerificationTextField"}
              name={"account"}
              value={""}
              id={"BankVerificationTextField"}
              onChange={""}
              type={"text"}
              placeholder={"Enter your acount number"}
            />
            <SelectField 
              label={"Identity Verification"}
              className={"BankVerificationTextField"}
              name={"identity"}
              values={["National Identity Card", "International Passport", "Driver's License"]}
              id={"BankVerificationTextField"}
              onChange={""}
              type={"text"}
              placeholder={"Enter choose your proof of Identification"}
            />
          </div>
          <div className="RecruiterIdentityVerification">
            <p>Proof Of Identity</p>
            <div className="RecruiterIdentityUpload">
              <UploadIcon />
              <p>Please Upload your proof of Identity</p>  
              <button onClick={handleBrowseClick}>Browse File</button>
              <input 
                type="file"
                accept=".jpg .jpeg .pdf .JPG .JPEG .PDF" 
                hidden
                ref={fileInputRef}
                onChange={(e) => {
                  const selectedFile = e.target.files[0];
                  console.log("Selected File:", selectedFile);
                }} 
              />  
            </div>
          </div>
        </div>
        <div className="RecruiterVerifyButton">
          <button onClick={handleSubmit}>Verify</button>
        </div>
        {showSuccessModal && (
          <div className="SuccessModalOverlay">
            <div className="SuccessModal">
              <div className="SuccessIconWrapper">
                <IoIosCheckmarkCircleOutline className="SuccessIcon" />
              </div>
              <h2>Successful</h2>
              <p>Your account with Ether has been updated successfully. 
                Your documents will be verified within 5 business days 
              </p>
              <button onClick={handleSuccessModalClose}>Continue</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecruiterProfileVerification;