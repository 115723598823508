/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./jsprofile.style.scss";
import editIcon from "../../../static/images/icons/edit.png";
import AvatarIcon from "../../../static/images/icons/Avatar1.png";
import { GetProfileContact } from "./GetProfileContact";
import {
  AiOutlineSearch,
  AiFillHeart,
  AiOutlinePlusCircle,
  AiFillFileText,
} from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md";
import {
  BsPersonCircle,
  BsArrowRight,
  BsHandThumbsUpFill,
  BsPencil,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import { HiOutlinePencil } from "react-icons/hi";
import {
  workHistoryData,
  educationHistoryData,
  Activity,
  Licence,
} from "../../../data/ProfileSetupData";
import { BorderLinearProgress } from "./BorderLinearProgress";


const JobSeekerProfile = () => {
  const [value, setValue] = useState(30);
  
  const [seeContact, setSeeContact] = useState(false);
  const number = 5;
  const numOfViews = 50;
  const searchApps = 2;
  const level = "Midlevel";
  const information = "Complete your profile to become visible to recruiters";
  const handleInputChange = () => {
    return;
  };
  const handleContact = () => {
    setSeeContact(!seeContact);
  };
  const handleCancelContact = () => {
    setSeeContact(false);
  };
  const name = "Siddiq Lushon";
  const Profession = "Software Tester | Full Stack Engineer ";
  

  useEffect(() => {
    const handlePageClick = (event) => {
      if (
        seeContact &&
        !event.target.closest(".JsProfileCard") &&
        !event.target.closest(".ContactInfo")
      ) {
        handleCancelContact();
      }
    };

    document.addEventListener("click", handlePageClick);
    return () => document.removeEventListener("click", handlePageClick);
  }, [seeContact]);

  return (
    <div className="JsProfileContainer">
      <div>
        {seeContact && (
          <GetProfileContact
            handleCancelContact={handleCancelContact}
            className="JsProfileCard"
          />
        )}
      </div>
      <div className="JsProfileCover">
        <div className="JsSearchBar">
          <AiOutlineSearch className="JsProfileSearch" />
          <input
            type="text"
            placeholder="Search course keyword or title"
            value=""
            onChange={handleInputChange}
            className="JsProfileInputSearch"
          />
        </div>
        <div className="JsEditCover">
          <input
            type="file"
            value=""
            onChange={handleInputChange}
            className="JsProfileAddCover"
          />
          <img src={editIcon} alt="" width={25} height={25} />
        </div>
      </div>
      <div className="JsProfileImage">
        <img src={AvatarIcon} alt="profile" width={200} height={200} />
      </div>
      <div className="JsProfileInformation">
        <div className="JsProfileDetailsOne">
          <h2>{name}</h2>
          <h3>{Profession}</h3>
          <p>Cloud Practitioner | 5 years Experience</p>
          <p>
            Johannesburg, South Africa.{" "}
            <span onClick={handleContact} className="ContactInfo">
              Contact Info
            </span>
          </p>
          <p>{number} Certifications completed</p>
        </div>
        <div className="JsProfileDetailsTwo">
          <img src={editIcon} alt="" width={25} height={25} id="EditIcon" />
          <div>
            <h2>Bio</h2>
            <p>
              Siddiq is a senior solutions architect and a consultant, with rich
              experience of over 20 years in core IT infrastructure services and
              cloud computing. I am outgoing, dedicated, and open-minded. I get
              across to people and adjust to changes with ease. I believe that a
              person should work on developing their professional skills and
              learning new things all the time.
            </p>
          </div>
        </div>
      </div>

      <div className="ProfileSectionThree">
        <div className="ProfileStrength" id="ProfileSectionThree">
          <div className="ProfileStrengthHeadings">Your Profile Strength</div>
          <div className="ProfileStrengthVisibility">
            <MdOutlineVisibility />
            <p>Visible to you only</p>
          </div>
          <div className="ProfileStrengthLevel">
            <p>{level}</p>
            <div>
              <BorderLinearProgress variant="determinate" value={value} />
              <p>{value} %</p>
            </div>
          </div>
          <div className="ProfileStrengthInfo">
            <p>{information}</p>
          </div>
        </div>
        <div className="ProfileView" id="ProfileSectionThree">
          <div className="ProfileViewHeadings">Your Profile View</div>
          <div className="ProfileViewVisibility">
            <MdOutlineVisibility />
            <p>Visible to you only</p>
          </div>
          <div className="ProfileViewsNumber">
            <BsPersonCircle />
            <p>{numOfViews} Profile views</p>
          </div>
          <div className="ProfileSearchApps">
            <GoSearch />
            <p>{searchApps} Search Appearances</p>
          </div>
          <div className="ProfileViewInfo">
            <p>Discover your profile visibility and search appearances</p>
          </div>
        </div>
        <div className="Portfolio" id="ProfileSectionThree">
          <div className="PortfolioHeadings">Portfolio</div>
          <div className="PortfolioVisibility">
            <p>An highlight of your work experience to showcase your skills</p>
          </div>
          <div className="PortfolioSearch">
            <p>Add Portfolio</p>
            <input
              type="text"
              placeholder="Paste link to portfolio here"
              value=""
              onChange={handleInputChange}
              className="PortfolioSearch"
            />
          </div>
        </div>
      </div>
      <div className="ProfileSectionFour">
        <div className="ProfileSectionAddress">
          <h1 className="ProfileSectionHeader">Address</h1>
          <div>
            <a href="">Add Activity</a>
            <HiOutlinePencil />
          </div>
        </div>
        <div className="ActivitySection">
          {Activity &&
            Activity.map((activity, index) => {
              return (
                <div key={index} id="ActivitySection">
                  <div className="ActivityDisplay">
                    <img
                      src={activity.icon}
                      alt="icon"
                      width={56}
                      height={56}
                    />
                    <div className="ActivityInfomation">
                      <p id="ActivityName">{activity.name}</p>
                      <div>
                        <p id="ActivityNotification">{activity.notification}</p>
                        <p id="ActivityPeriod">{activity.period}</p>
                      </div>
                      <div>
                        <AiFillHeart id="ActivityIcon" />
                        <BsHandThumbsUpFill id="ActivityIcon" />
                        <p id="ActivityReaction">
                          {activity.reactions} reactions
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ActivityShowMore">
                    <a href="/">
                      <button>
                        Show more <BsArrowRight />
                      </button>
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="ProfileSectionFive" id="ProfileGeneralSegment">
        <div className="ProfileExperienceHeader">
          <h2>Work Experience</h2>
          <Link to={"/job_seeker/profile/profile_setup/add_experience"}>
            <p>Add work Experience</p>
            <AiOutlinePlusCircle className="ProfileAddExperience" />
          </Link>
        </div>
        <div className="ProfileExperienceTagNames">
          {workHistoryData.map((history, index) => {
            return (
              <div key={index} className="AddExperienceData">
                <div className="ExperienceData">
                  <span>
                    <img src={history.icon} alt="" width={56} height={56} />
                  </span>
                  <span>
                    <h5>{history.title}</h5>
                    <div>
                      <p>{history.company}</p>
                      <p>{history.startDate}</p>
                      <p>{history.endDate}</p>
                    </div>
                  </span>
                </div>
                <div>
                  <Link
                    to={`/job_seeker/profile/profile_setup/add_experience/update/index=${index}`}
                  >
                    <BsPencil />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="ProfileSectionSix" id="ProfileGeneralSegment">
        <div className="ProfileExperienceHeader">
          <h2>Education History</h2>
          <Link to={"/job_seeker/profile/profile_setup/add_education"}>
            <p>Add Education History</p>
            <AiOutlinePlusCircle className="ProfileAddExperience" />
          </Link>
        </div>
        <div className="ProfileExperienceTagNames">
          {educationHistoryData.map((history, index) => {
            return (
              <div key={index} className="AddExperienceData">
                <div className="ExperienceData">
                  <span>
                    <img src={history.icon} alt="" width={56} height={56} />
                  </span>
                  <span>
                    <h5>{history.title}</h5>
                    <div>
                      <p>{history.certificate}</p>
                      <p>{history.startDate}</p>
                      <p>{history.endDate}</p>
                    </div>
                  </span>
                </div>
                <div>
                  <Link
                    to={`/job_seeker/profile/profile_setup/add_education/update/index=${index}`}
                  >
                    <BsPencil />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="ProfileSectionSeven">
        <div className="ProfileSectionAddress">
          <h1 className="ProfileSectionHeader">Licences and Certifications</h1>
          <div>
            <a href="">Add Certification</a>
            <AiOutlinePlusCircle />
          </div>
        </div>
        <div className="LicenceSection">
          {Licence &&
            Licence.map((licence, index) => {
              return (
                <div key={index} id="LicenceSection">
                  <div className="LicenceDisplay">
                    <img src={licence.icon} alt="icon" width={56} height={56} />
                    <div className="LicenceInfomation">
                      <p id="LicenceName">{licence.title}</p>
                      <div>
                        <p id="LicenceState">{licence.state}</p>
                        <p id="LicencePeriod">{licence.period}</p>
                      </div>
                      <div className="LicenceShowMore">
                        <a href="/">
                          <button>
                            Show Credentials <AiFillFileText />
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="UpdateCertification">
                    <Link
                      to={`/job_seeker/profile/profile_setup/add_education/update/index=${index}`}
                    >
                      <BsPencil />
                    </Link>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default JobSeekerProfile;
