import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CompleteTabIcon } from "../../../svgs/SvgComponent";
import "./CoachConfirmVerification.style.scss";
import userImage from "../../../static/images/icons/User Thumb.png";
import { MdOutlineCheckCircle } from "react-icons/md";
import { FaRegCircleXmark } from "react-icons/fa6";


const CoachConfirmVerification = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/coach/profile/verified");
  };

  return (
    <div className="ConfirmVericationContainer">
      <div className="ContainerWrapper">
        <div className="TopSection">
          <button>Complete Profile</button>
        </div>
        <div className="PageLayout">
          <div className="ProfileProgress">
            <div className="ProgressTabOne">
              <Link>
                <button>
                  <div className="ProfileProgressTab">
                    <CompleteTabIcon className="ProgressTabIcon" />
                    <div className="ProfileProgressTabDetails">
                      <span>1. Profile</span>
                      <span>Completed</span>
                    </div>
                  </div>
                </button>
              </Link>
            </div>
            <div className="ProgressTabOne">
              <Link>
                <button>
                  <div className="ProfileProgressTab">
                    <CompleteTabIcon className="ProgressTabIcon" />
                    <div className="ProfileProgressTabDetails">
                      <span>2. Preferences</span>
                      <span>Completed</span>
                    </div>
                  </div>
                </button>
              </Link>
            </div> 
            <div className="ProgressTabOne">
              <Link>
                <button>
                  <div className="ProfileProgressTab">
                    <CompleteTabIcon className="ProgressTabIcon" />
                    <div className="ProfileProgressTabDetails">
                      <span>3. Culture</span>
                      <span>Completed</span>
                    </div>
                  </div>
                </button>
              </Link>
            </div>
            <div className="ProgressTabOne">
              <Link>
                <button>
                  <div className="ProfileProgressTab">
                    <CompleteTabIcon className="ProgressTabIcon" />
                    <div className="ProfileProgressTabDetails">
                      <span>4. Verification</span>
                      <span>Completed</span>
                    </div>
                  </div>
                </button>
              </Link>
            </div>       
          </div>
          <div className="VerificationTopSection">
            <h3>Verification</h3>
            <div className="VerificationProfile">
              <div className="ProfileDetails">
                <img src={userImage} alt="" />
                <div className="ProfileParticulars">
                  <p>Jennifer Anniston</p>
                  <p>Product Designer Coach</p>
                </div>
              </div>
              <div className="AboutMe">
                <p><b>About Me:{" "}</b>Hello! I am a passionate and innovative product designer with over four years of experience in the industry. 
                  My journey began with a strong fascination for creating beautiful, functional, and user-centric designs. 
                  What sets me apart is my dual role as a coach, where I draw from my design expertise to guide and mentor aspiring designers.
                </p>
              </div>
            </div>
          </div>
          <hr className="VerificationDivider"/>
          <div className="ProfileVerification">
            <h3>Profile Verification</h3>
            <div className="VerificationProcess">
              <div className="Proccessing">
                <div className="Counter">
                  <p>25%</p>
                </div>
                <p>Proccessing</p>
              </div>
              <div className="VerifyCheck">
                <div className="MarkStatus">
                  <div className="IconWrapper">
                    <MdOutlineCheckCircle className="CheckMark" />
                  </div>
                  <p>Educational Certificate Verified</p> 
                </div>
                <div className="MarkStatus">
                  <div className="IconWrapper">
                    <FaRegCircleXmark className="XMark" />
                  </div>
                  <p>Professional Certificate Unverified</p>
                </div>
                <div className="MarkStatus">
                  <div className="IconWrapper">
                    <FaRegCircleXmark className="XMark" />
                  </div>
                  <p>Skills Unverified</p>
                </div>
                <div className="MarkStatus">
                  <div className="IconWrapper">
                    <FaRegCircleXmark className="XMark" />
                  </div>
                  <p>Years of Experience Unverified</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="VerificationDivider"/>
          <button onClick={handleSubmit} className="ProceedButton">Proceed</button>
        </div>
      </div>
    </div>
  );
};

export default CoachConfirmVerification;