import React, {useState} from "react";
import PropTypes from "prop-types";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const PasswordField = ({ name, type, value, label, onChange, transform, className, id, marginLeft }) => {
  const [showPassword, setShowPassword] = useState(false);
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
        
  return (
    <div id={id}>
      <label htmlFor="email">{label}: </label>
      <input
        type={showPassword ? "text" : type}
        placeholder="*************"
        value={value}
        onChange={onChange}
        name={name}
        className={className}
      />
      {showPassword ? (
        <VisibilityIcon
          style={{
            position: "relative",
            marginLeft: marginLeft,
            transform: transform,
            zIndex: "3",
          }}
          onClick={handleClickShowPassword}
        />
      ) : (
        <VisibilityOffIcon
          style={{
            position: "relative",
            marginLeft: marginLeft,
            transform: transform,
            zIndex: "3",
          }}
          onClick={handleClickShowPassword}
        />
      )}
    </div>
  );
};
  
PasswordField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  transform: PropTypes.string,
  marginLeft: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string
};