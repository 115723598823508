import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  },
  isFormValid: false,
  error: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  },
};

const settingsSlice = createSlice ({
  name: "settings",
  initialState,
  reducers: {
    setFormData : (state, action) => {
      state.formData = {...state.formData, ...action.payload};
    },
    setFormValid: (state, action) => {
      state.error.isFormValid = action.payload;
    },
    setError: (state, action) => {
      state.error = {...state.error, ...action.payload};
    },
  }
});
export const { setFormData, setFormValid, setError } = settingsSlice.actions;
export default settingsSlice.reducer;