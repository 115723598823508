import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiArrowDownSLine } from "react-icons/ri";
import { IoAddCircleOutline } from "react-icons/io5";
import { ActiveTabIcon, InactiveTabIcon } from "../../../svgs/SvgComponent";
import { modules } from "../../../data/roadmapData";

import "./CoachCourseResources.style.scss";

const CoachCourseResources = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (index) => {
    if(selected == index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <div className="CoachCourseResourcesContainer">
      <div className="CourseResourcesWrapper">
        <h3>Course Title</h3>
        <div className="CourseResourcesTabs">
          <div className="CourseResourcesTab">
            <InactiveTabIcon className="CourseResourcesTabIcon" />
            <p>1. Course Details</p>
          </div>
          <div className="CourseResourcesActiveTab">
            <ActiveTabIcon className="CourseResourcesActiveTabIcon" />
            <p>2. Course Resources</p>
          </div>
          <div className="CourseResourcesTab">
            <InactiveTabIcon className="CourseResourcesTabIcon" />
            <p>3. Course Quiz</p>
          </div>
          <div className="CourseResourcesTab">
            <InactiveTabIcon className="CourseResourcesTabIcon" />
            <p>4. Summary</p>
          </div>
        </div>
        <div className="CourseModuleResources">
          {modules.map((data, index) => (
            <div className="ModuleResourcesAccordionWrapper" key={index}>
              <div className="ModuleResourcesHeading" onClick={() => toggle(index)}>
                <h4>{data.title}</h4>
                <RiArrowDownSLine className={ selected === index ? "ModuleResourcesAccordionIcon rotate" : "ModuleResourcesAccordionIcon"} />
              </div>
              <div className={selected === index ? "ModuleResourcesAdd show" : "ModuleResourcesAdd"}>
                <p>Add a course resource</p>
                <IoAddCircleOutline className="ModuleResourcesAddIcon" />
              </div>
            </div>
          )
          )}
        </div>
        <div className="ModuleResourcesButton">
          <Link
            to={"/coach/profile/roadmap/course_quiz"}
          >
            <button>Next</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CoachCourseResources;
