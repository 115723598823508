/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import PropTypes from "prop-types";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";

export const TextField = ({
  name,
  type,
  value,
  label,
  onChange,
  onBlur,
  className,
  id,
  placeholder,
  disabled
}) => {
  return (
    <div id={id}>
      <label htmlFor="email">{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        className={className}
        disabled={disabled}
      />
    </div>
  );
};

TextField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export const PasswordField = ({
  name,
  type,
  value,
  label,
  onChange,
  transform,
  className,
  id
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <div id={id}>
      <label htmlFor="email">{label}: </label>
      <input
        type={showPassword ? "text" : type}
        placeholder="*************"
        value={value}
        onChange={onChange}
        name={name}
        className={className}
      />
      {showPassword ? (
        <VisibilityIcon
          style={{
            position: "absolute",
            marginLeft: "0px",
            transform: transform,
            zIndex: "3"
          }}
          onClick={handleClickShowPassword}
        />
      ) : (
        <VisibilityOffIcon
          style={{
            position: "absolute",
            marginLeft: "0px",
            transform: transform,
            zIndex: "3"
          }}
          onClick={handleClickShowPassword}
        />
      )}
    </div>
  );
};

PasswordField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  transform: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string
};

export const CheckBox = ({ name, checked, className, onChange, id, label }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        marginBottom: "15px"
      }}
      id={id}
    >
      <input
        type="checkbox"
        name={name}
        checked={checked}
        className={className}
        onChange={onChange}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};
CheckBox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string
};

export const RadioButton = ({
  label,
  id,
  className,
  onChange,
  value,
  checked
}) => {
  return (
    <div id={id}>
      <label>
        <input
          type="radio"
          value={value}
          onChange={onChange}
          className={className}
          checked={checked}
        />
        {label}
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.func
};

export const InputButton = ({ name, id, className, disabled, isLoading }) => {
  return (
    <div id={id}>
      <button type="submit" className={className} disabled={disabled}>
        {isLoading && (
          <CircularProgress
            sx={{ width: "20px", height: "20px" }}
            style={{
              width: "20px",
              height: "20px"
            }}
          />
        )}
        {name}
      </button>
    </div>
  );
};
InputButton.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.func,
  id: PropTypes.string,
  isLoading: PropTypes.bool
};
export const SecondaryButton = ({ name, className, onClick }) => {
  return (
    <div onClick={onClick}>
      <button type="submit" className={className}>
        {name}
      </button>
    </div>
  );
};
SecondaryButton.propTypes = {
  name: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export const SelectField = ({
  values,
  onChange,
  className,
  label,
  id,
  selectedValue,
  placeholder,
  disabled,
  name
}) => {
  return (
    <div id={id}>
      <label htmlFor={label}>{label}</label>

      <select
        value={selectedValue}
        onChange={onChange}
        className={className}
        name={name}
      >
        
				disabled={disabled}
        <option value="">{placeholder}</option>
        {values &&
          values.map((value) => (
            <option value={value} key={value}>
              {value}
            </option>
          ))}
      </select>
    </div>
  );
};
SelectField.propTypes = {
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  selectedValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string
};
export const TextArea = ({
  value,
  label,
  className,
  onChange,
  placeholder,
  id,
  disabled,
  name,
}) => {
  return (
    <div id={id}>
      <label htmlFor={label}>{label}</label>
      <textarea
        rows="5"
        cols="30"
        id={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={className}
        name={name}
        disabled={disabled}
      ></textarea>
    </div>
  );
};
TextArea.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string
};

export const RangeSlider = (id, className, min, max, value, onChange) => {
  return (
    <div id={id}>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        className={className}
      />
    </div>
  );
};
RangeSlider.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};
