import React, { useState } from "react";
import { ToggleOn, ToggleOff } from "../svgs/svg";

const ToggleSwitch = () => {
    const [isToggled, setIsToggled] = useState(false);
  
    const handleToggle = () => {
      setIsToggled(!isToggled);
    };
  
    return (
      <div onClick={handleToggle} style={{ cursor: "pointer", display: "inline-block" }}>
        {isToggled ? <ToggleOn  /> : <ToggleOff/>}
      </div>
    );
  };
  
  export default ToggleSwitch;