import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { SelectField, TextField } from "../../../Materials/InputFields";

import "./RecruiterJobEdit.style.scss";

const RecruiterJobEdit = () => {
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleClickSave = () => {
    navigate("/recruiter/profile/jobs/job/details");
  };
  return (
    <div className="JobEditContainer">
      <div className="JobEditContent">
        <Link
          to={"/recruiter/profile/jobs"}
          style={{textDecoration: "none"}}
          className="JobEditNavigation"
        >
          <IoArrowBack className="JobBackIcon" />
          <p>Associate Product Designer</p>
        </Link>
        <div className="JobEditTitle">
          <TextField 
            type={"text"}
            name={"jobTitle"}
            placeholder={"Enter Job Title"}
            label={"Job Title"}
            className={"JobEditTextField"}
            id={"JobEditTextField"}
            value={inputValue}
            onChange={handleInputChange}
          />
          <SelectField 
            name="jobLocation"
            label={"Job Location"}
            className={"JobEditSelectField"}
            id={"JobEditSelectField"}
            value={selectedValue}
            placeholder={"Enter job location"}
            onChange={handleSelectChange}
            values={["Remote", "Onsite", "Hybrid", "Local Onsite"]}
          />
        </div>
        <div className="JobEditSelectFields">
          <SelectField 
            name="jobType"
            label={"Job Type"}
            className={"JobEditSelectField"}
            id={"JobEditSelectField"}
            value={selectedValue}
            onChange={handleSelectChange}
            values={["Full Time", "Contract", "Internship", "Part -Time", "Temporary", "Volunteer"]}
          />
          <SelectField 
            name="size"
            label={"Company Size"}
            className={"JobEditSelectField"}
            id={"JobEditSelectField"}
            value={selectedValue}
            onChange={handleSelectChange}
            values={["1 - 50", "50 - 100", "100 - 200", "200 - 500", "500 - 1000", "1000+"]}
          />
        </div>
        <h2>Job Description</h2>
        <div className="JobDetailsJobInfo">
          <h4>What you&apos;ll do</h4>
          <p>
          You will work with product and engineering teams to successfully communicate, 
          evolve and execute a strong product design standard that showcases Brex as a leader in financial innovation. 
          You are a crucial component of our company’s growth and product launches through your contributions on strategic direction, 
          high visual standards and focus on user needs. You take on an owner’s mentality, 
          taking pride in your work as well as your team’s work and view what you do as a part-owner and builder of Brex.
          </p>
          <h4>Responsibilities</h4>
          <ul>
            <li>Partner with the leadership team to successfully communicate our product strategy, process and lead cross-functional meetings to align teams</li>
            <li>Creative thinking and strategic execution. You will be asked to think outside of the box, and partner with our product and eng teams to push innovation forward</li>
            <li>Will work across the entire business stack and make an impact at different stages of the product: building vision & strategy, ensuring all the loose ends are tied up when executing, and making sure the operational components to support onboarding are in place, not just the product</li>
            <li>Grow and scale the design team across the design org</li>
            <li>Push forward and maintain a high design bar and level of execution</li>
          </ul>
          <h4>Requirements</h4>
          <ul>
            <li>Strong written and verbal communication skills, with a talent for detailed articulations of strategy, process, and leading cross-functional meetings to align teams</li>
            <li>Experience working cross-functionally with engineering, marketing, design and product management teams</li>
            <li>Strong ability to craft a clear narrative and paint a vision that communicates and aligns teams</li>
            <li>Passion for team building and development</li>
            <li>6+ years of design experience</li>
          </ul>
          <h4>Bonus Points</h4>
          <ul>
            <li>Experience working with tools including but not limited to Figma, Jira, Looker, Slack, Google Apps, Salesforce, Chrome Console</li>
            <li>Experience working on products in both B2B and B2C companies</li>
          </ul>
        </div>
        <div className="JobEditSaveButton">
          <button onClick={handleClickSave}>Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default RecruiterJobEdit;