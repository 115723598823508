import React from "react";
import { Link } from "react-router-dom";
import { BsSearch} from "react-icons/bs";
import EtherLogo from "../../../static/images/logo/Ether Logo.png";

import "./SettingsNavBar.style.scss";

const SettingsNavBar = () => {
  return (
    <div className="SettingsNavBarContainer">
      <div className="NavBarLogo">
        <Link
          to={"/"}
        >
          <img src={EtherLogo} alt="Ether Logo" />
        </Link>
      </div>
      <div className="NavBarSearchArea">
        <BsSearch className="NavBarSearchIcon" />
        <input
          type="text"
          placeholder={"Search in Settings"}
          className={"NavBarInputField"}
          value=""
        /> 
      </div>
    </div>
  );
};

export default SettingsNavBar;
