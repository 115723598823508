export const slidesData = [
  {
    id: 1,
    rating: 4,
    comment: "Coaching approach is worth my time, she is a perfect match.",
    rater: "Mercy Smith"
  },
  {
    id: 2,
    rating: 5,
    comment: "Great experience! Highly recommend.",
    rater: "John Doe"
  },
  {
    id: 3,
    rating: 3,
    comment: "Good service. Room for improvement.",
    rater: "Alice Johnson"
  },
  {
    id: 4,
    rating: 5,
    comment: "Absolutely fantastic! Couldn't be happier.",
    rater: "Bob Anderson"
  },
  {
    id: 5,
    rating: 2,
    comment: "Needs improvement. Not satisfied.",
    rater: "Eva Williams"
  },
];