import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { BsSearch} from "react-icons/bs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { HiDotsHorizontal } from "react-icons/hi";
import PropTypes from "prop-types";

import "./RecruiterJobList.style.scss";

const columns = [
  { id: "title", label: "Job Title", minWidth: 200, align: "left" },
  { id: "category", label: "Category", minWidth: 100, align: "center" },
  { id: "type", label: "Job Type", minWidth: 100, align: "center" },
  { id: "location", label: "Location", minWidth: 120, align: "center" },
  { id: "date", label: "Date Created", minWidth: 130, align: "center" },
  { id: "status", label: "Status", minWidth: 80, align: "center" },
  { id: "applicants", label: "Applicant Count", minWidth: 100, align: "center" },
  { 
    id: "seeMore", 
    label: "See\u00a0More", 
    minWidth: 70, 
    align: "center", 
  },
];
function createData(title, category, type, location, date, status, applicants, seeMore) {
  return { title, category, type, location, date, status, applicants, seeMore };
}

const rows = [
  createData("Associate Product Designer", "Design", "Full Time", "Remote", "02/09/23", "Published", 6, ),
  createData("Software Engineer", "Engineering", "Part Time", "Onsite", "15/10/23", "Published", 10, ),
  createData("Marketing Specialist", "Marketing", "Contract", "Remote", "05/08/23", "Hired", 2, ),
  createData("Data Scientist", "Data Science", "Full Time", "Hybrid", "19/07/23", "Pending", 0, ),
  createData("Customer Support", "Support", "Internship", "Onsite", "08/06/23", "Published", 3, ),
  createData("Project Manager", "Management", "Full Time", "Onsite", "12/04/23", "Hired", 2, ),
  createData("QA Tester", "Quality Assurance", "Contract", "Remote", "03/03/23", "Pending", 4, ),
  createData("HR Coordinator", "Human Resources", "Internship", "Hybrid", "22/02/23", "Published", 1, ),
  createData("UI/UX Designer", "Design", "Full Time", "Remote", "10/01/23", "Hired", 3, ),
  createData("Network Engineer", "Engineering", "Contract", "Onsite", "18/12/22", "Pending", 0, ),
  createData("Content Writer", "Content", "Internship", "Hybrid", "04/11/22", "Published", 2, ),
  createData("Financial Analyst", "Finance", "Full Time", "Onsite", "30/09/22", "Hired", 1, ),
  createData("HR Manager", "Human Resources", "Contract", "Remote", "12/08/22", "Pending", 2, ),
  createData("Graphic Designer", "Design", "Full Time", "Hybrid", "26/07/22", "Published", 4, ),
  createData("Business Analyst", "Business", "Internship", "Onsite", "15/06/22", "Hired", 1, ),
  createData("Customer Success", "Customer Support", "Contract", "Remote", "03/05/22", "Pending", 0, ),
  createData("Data Entry Clerk", "Data Entry", "Full Time", "Onsite", "20/04/22", "Published", 0, ),
  createData("Product Manager", "Management", "Internship", "Hybrid", "08/03/22", "Hired", 1, ),
];

const Options = ({ isOpen, onClose, position }) => {
  const navigate = useNavigate();

  const handleApplicants = () => {
    navigate("/recruiter/profile/applicantions");
  };
  const handleViewJob = () => {
    navigate("/recruiter/profile/addjob/review");
  };
  return (
    <>
      {isOpen && (
        <div 
          className="OptionsContainer" 
          onClick={onClose}
        >
          <div 
            className="Options" 
            onClick={(e) => e.stopPropagation()}
            style={{ top: position.y}}
          >
            <p onClick={handleViewJob}>View Details</p>
            <p onClick={handleApplicants}>View Applicants</p>
            <p>Delete</p>
          </div>
        </div>
      )}
    </>
  );
};
Options.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
  position: PropTypes.func.isRequired
};

const RecruiterJobsPage = () => {
  const [isOptions, setIsOptions] = useState(false);
  const [clickPosition, setClickPosition] = useState({ y: 0 });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event) => {
    setClickPosition({ y: event.clientY });
    setIsOptions(true);
  };
  const handleCloseOptions = () => {
    setIsOptions(false);
  };

  return (
    <div className="RecruiterJobContainer">
      <div className="RecruiterJobContent">
        <div className="JobSearchInputField">
          <BsSearch className="JobSearchIcon" />
          <input 
            type="text"
            placeholder={"Search course keyword or title"}
            className={"JobSearchTextField"} 
            onChange={""}
            value={""}
          />
        </div>
        <div className="JobTopSection">
          <h3>Job Listing</h3>
          <div className="JobSelectButtons">
            <button>
              <select>
                <option value="" key="">
                  Advance Filter
                </option>
              </select>
            </button>
            <Link
              to={"/recruiter/profile/addjob"}
              className="RecruiterJobCreate"
            >
              Create Job
            </Link>
          </div>
        </div>
        <Paper className="JobTablePaper">
          <TableContainer className="JobTableContainer">
            <Table className="JobTable">
              <TableHead className="JobTableHead">
                <TableRow className="JobTableHeadRow">
                  {columns.map((column) => (
                    <TableCell
                      className="JobTableHeadCell"
                      key={column.id}
                      align={column.align}
                      style={{minWidth: column.minWidth}}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="JobTableBody">
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code} className="JobTableBodyRow">
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell 
                              className="JobTableBodyCell"
                              key={column.id}
                              align={column.align}
                            >
                              {column.id === "seeMore" ? <HiDotsHorizontal className="SeeMore" onClick={handleClick} /> : (
                                column.format && typeof value === "number"
                                  ? column.format(value)
                                  : column.id === "status" ? (
                                    <span
                                      style={{ 
                                        backgroundColor: 
                                          value === "Hired" ? "var(--Alert-Succes-100, #66DDB3)" : 
                                            value === "Pending" ? "var(--Alert-Warning-100, #FFD688)" :
                                              value === "Published" ? "var(--CoolGray-10, #F2F4F8)" : "", 
                                        padding: "2px 10px 2px 10px",
                                        borderRadius: "10px",
                                        color: "var(--Foundation-Neutral-Black, #2E2C2C))",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {value}
                                    </span>
                                  ) : ( 
                                    value
                                  )
                              )} 
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination 
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <Options 
          isOpen={isOptions}
          position={clickPosition}
          onClose={handleCloseOptions}
        />
      </div>
    </div>
  );
};

export default RecruiterJobsPage;