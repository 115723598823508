/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
  useRef,
  useState, 
  useEffect
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import { NoDataParag, NoDataStyle } from "./WorkExperience";
import { getApi } from "../../../Api/server";
import { shortenedYear } from "../../../data/ProfileSetupData";
import "react-circular-progressbar/dist/styles.css";

import "./ProfileDetail.style.scss";


const EducationHistory = () => {
  const getEducationIndex = useRef(null);
  const [isLoading, setIsLoading] =
    useState(false);
  const [educationHistoryData, setEducationHistoryData] = useState([]);
  
  const navigate = useNavigate();

  useEffect(() => {
    const getUserWorkingExperience = async () => {
      setIsLoading(true);
      const response = await getApi(
        "e1/job_seeker/profile/education_history"
      );
      if (response?.code === 403) {
        navigate("/login");
      }
      if (response?.code !== 200) {
        console.log(response?.message);
        setIsLoading(false);
        return;
      } else {
        const value = response?.data;
        setEducationHistoryData(value);
        setIsLoading(false);
      }
    };
    getUserWorkingExperience();
  }, []);
  return (
    <div className="ProfileExperiences">
      <div className="ProfileExperienceHeader">
        <h2>Education History</h2>
        <Link
          to={
            "/job_seeker/profile/profile_setup/add_education"
          }>
          <p>Add Education History</p>
          <AiOutlinePlusCircle className="ProfileAddExperience" />
        </Link>
      </div>
      <div className="ProfileExperienceTagNames">
        {isLoading ? (
          <CircularProgress
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        ) : 
          educationHistoryData ? educationHistoryData.map(
            (history, index) => {
              getEducationIndex.current = index;
              return (
                <div
                  key={index}
                  className="AddExperienceData">
                  <div className="ExperienceData">
                    <span>
                      <img
                        src="/icons/certificate.png"
                        alt=""
                        width={56}
                        height={56}
                      />
                    </span>
                    <span>
                      <h5>
                        {history.educationName}
                      </h5>
                      <div>
                        <p>
                          {history.degreeType}
                        </p>
                        <p>
                          {shortenedYear[
                            history.startDate.split(
                              "-"
                            )[1] - 1
                          ] +
														" " +
														history.startDate.split(
														  "-"
														)[0]}
                        </p>
                        <p>
                          {history.endDate !==
													"Till Date"
                            ? shortenedYear[
                              history.endDate.split(
                                "-"
                              )[1] - 1
														  ] +
														  " " +
														  history.endDate.split(
														    "-"
														  )[0]
                            : "Till Date"}
                        </p>
                      </div>
                    </span>
                  </div>
                  <div>
                    <Link
                      to={`/job_seeker/profile/profile_setup/add_education/update/index=${index}`}>
                      <BsPencil />
                    </Link>
                  </div>
                </div>
              );
            }
          )
            : (
              <div className="NoDataSection" style={NoDataStyle}>
                <p style={NoDataParag}>No Data to display</p>
              </div>
            )
        }
      </div>
      <div className="ProfileExperienceButton">
        <button>Save</button>
      </div>
    </div>
  );
};

export default EducationHistory;
