import React from "react";
import JsNavBar from "../JsNavBar/JsNavBar";
import JsSideBar from "../JsSideBar/JsSideBar";
import "./JsBarLayout.style.scss";
import Proptypes from "prop-types";

const JsBarLayout = ({children}) => {
  return (
    <div className="jsbLayoutContainer">
      <div className="jsbLayoutSegmentOne">
        <JsNavBar />
      </div>
      <div className="jsbLayoutSegmentTwo">
        <JsSideBar />
        <div className="jsbLayoutSegmentTwoContainer">{children}</div>
      </div>
    </div>
  );
};
JsBarLayout.propTypes = {
  children: Proptypes.node
};

export default JsBarLayout;