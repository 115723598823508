import React, { useState } from "react";
import { GoLink } from "react-icons/go";
import { FiFileText } from "react-icons/fi";
import { FiVideo } from "react-icons/fi";
import { RiArrowDownSLine } from "react-icons/ri";
import { TbDotsVertical } from "react-icons/tb";
import { modules } from "../../../data/roadmapData";

import "./CoachFilledResources.style.scss";

const CoachFilledResources = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (index) => {
    if(selected == index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <div className="FilledResourcesSection">
      <div className="FilledResourcesAccordionWrapper">
        {modules.map((module, index) => (
          <div key={index} className="FilledResourcesAccordion">
            <div className="FilledModuleHeading" onClick={() => toggle(index)}>
              <h3>{module.title}</h3>
              <RiArrowDownSLine className={ selected === index ? "FilledModuleAccordionIcon rotate" : "FilledModuleAccordionIcon"} />
            </div>
            <div className={ selected === index ? "FilledModuleDetails show" : "FilledModuleDetails"}>
              <div className="FilledModuleResource">
                <div className="IconArea">
                  <div className="IconWrapper">
                    <FiVideo className="FilledIcon" />
                  </div>
                  <div className="ModuleDetails">
                    <h6>Getting to know the coures.mp4</h6>
                    <p>9mins 48Sec</p>
                  </div>
                </div>
                <TbDotsVertical />
              </div>
              <div className="FilledModuleResource">
                <div className="IconArea">
                  <div className="IconWrapper">
                    <FiVideo className="FilledIcon" />
                  </div>
                  <div className="ModuleDetails">
                    <h6>Getting to know the coures.mp4</h6>
                    <p>9mins 48Sec</p>
                  </div>
                </div>
                <TbDotsVertical />
              </div>
              <hr className="FilledResourcesDivider" />
              <div className="FilledModuleResource">
                <div className="IconArea">
                  <div className="IconWrapper">
                    <FiFileText className="FilledIcon" />
                  </div>
                  <div className="ModuleDetails">
                    <h6>Getting to know the coures.mp4</h6>
                    <p>9mins 48Sec</p>
                  </div>
                </div>
                <TbDotsVertical />
              </div>
              <div className="FilledModuleResource">
                <div className="IconArea">
                  <div className="IconWrapper">
                    <FiFileText className="FilledIcon" />
                  </div>
                  <div className="ModuleDetails">
                    <h6>Getting to know the coures.mp4</h6>
                    <p>9mins 48Sec</p>
                  </div>
                </div>
                <TbDotsVertical />
              </div>
              <hr className="FilledResourcesDivider" />
              <div className="FilledModuleResource">
                <div className="IconArea">
                  <div className="IconWrapper">
                    <GoLink className="FilledIcon" />
                  </div>
                  <div className="ModuleDetails">
                    <h6>Getting to know the coures.mp4</h6>
                    <p>9mins 48Sec</p>
                  </div>
                </div>
                <TbDotsVertical />
              </div>
              <div className="FilledModuleResource">
                <div className="IconArea">
                  <div className="IconWrapper">
                    <GoLink className="FilledIcon" />
                  </div>
                  <div className="ModuleDetails">
                    <h6>Getting to know the coures.mp4</h6>
                    <p>9mins 48Sec</p>
                  </div>
                </div>
                <TbDotsVertical />
              </div>
            </div>
          </div>
        ))} 
      </div>
    </div>
  );
};

export default CoachFilledResources;
