import React from "react";
import PropTypes from "prop-types";

export const ApplyIcon = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1393_21832)">
        <path
          d="M19 7H5C3.89543 7 3 7.89543 3 9V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V9C21 7.89543 20.1046 7 19 7Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 7V5C8 4.46957 8.21071 3.96086 8.58579 3.58579C8.96086 3.21071 9.46957 3 10 3H14C14.5304 3 15.0391 3.21071 15.4142 3.58579C15.7893 3.96086 16 4.46957 16 5V7"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12V12.01"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 13C5.79158 14.4067 8.87403 15.1394 12 15.1394C15.126 15.1394 18.2084 14.4067 21 13"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1393_21832">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
ApplyIcon.propTypes = {
  stroke: PropTypes.string,
};
export const JobIcon = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1393_21835)">
        <path
          d="M12 8V12L15 15"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.56014 3.68994C7.46822 4.14193 6.47601 4.80454 5.64014 5.63994"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.69 8.56006C3.23656 9.65036 3.0021 10.8192 3 12.0001"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.68994 15.4399C4.14193 16.5319 4.80454 17.5241 5.63994 18.3599"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.56006 20.3101C9.65036 20.7635 10.8192 20.998 12.0001 21.0001"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4399 20.3101C16.5319 19.8581 17.5241 19.1955 18.3599 18.3601"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3101 15.44C20.7635 14.3497 20.998 13.1808 21.0001 12"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3099 8.55989C19.8579 7.46797 19.1953 6.47576 18.3599 5.63989"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.44 3.69C14.3497 3.23656 13.1808 3.0021 12 3"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1393_21835">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
JobIcon.propTypes = {
  stroke: PropTypes.string,
};
export const MessageIcon = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1393_21838)">
        <path
          d="M3 20.0001L4.3 16.1001C3.17644 14.4384 2.76999 12.4705 3.15622 10.5624C3.54244 8.65427 4.69506 6.93575 6.39977 5.72635C8.10447 4.51696 10.2453 3.89898 12.4241 3.98732C14.6029 4.07566 16.6715 4.86431 18.2453 6.20664C19.819 7.54896 20.7909 9.35362 20.9801 11.2851C21.1693 13.2165 20.563 15.1433 19.2739 16.7072C17.9848 18.2711 16.1007 19.3657 13.9718 19.7874C11.8429 20.2092 9.6142 19.9294 7.7 19.0001L3 20.0001"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 13L11 11L13 13L16 11"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1393_21838">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
MessageIcon.propTypes = {
  stroke: PropTypes.string,
};
export const UsersIcon = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M28.3335 33.3327H36.6668V29.9993C36.6668 27.2379 34.4283 24.9993 31.6668 24.9993C30.0742 24.9993 28.6554 25.744 27.7398 26.9042M28.3335 33.3327H11.6668M28.3335 33.3327V29.9993C28.3335 28.9056 28.1228 27.8611 27.7398 26.9042M11.6668 33.3327H3.3335V29.9993C3.3335 27.2379 5.57207 24.9993 8.3335 24.9993C9.92617 24.9993 11.3449 25.744 12.2606 26.9042M11.6668 33.3327V29.9993C11.6668 28.9056 11.8775 27.8611 12.2606 26.9042M12.2606 26.9042C13.4893 23.8344 16.4915 21.666 20.0002 21.666C23.5088 21.666 26.511 23.8344 27.7398 26.9042M25.0002 11.666C25.0002 14.4274 22.7616 16.666 20.0002 16.666C17.2387 16.666 15.0002 14.4274 15.0002 11.666C15.0002 8.90459 17.2387 6.66602 20.0002 6.66602C22.7616 6.66602 25.0002 8.90459 25.0002 11.666ZM35.0002 16.666C35.0002 18.507 33.5078 19.9993 31.6668 19.9993C29.8259 19.9993 28.3335 18.507 28.3335 16.666C28.3335 14.8251 29.8259 13.3327 31.6668 13.3327C33.5078 13.3327 35.0002 14.8251 35.0002 16.666ZM11.6668 16.666C11.6668 18.507 10.1744 19.9993 8.3335 19.9993C6.49255 19.9993 5.00016 18.507 5.00016 16.666C5.00016 14.8251 6.49255 13.3327 8.3335 13.3327C10.1744 13.3327 11.6668 14.8251 11.6668 16.666Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
UsersIcon.propTypes = {
  stroke: PropTypes.string,
};

export const CloseRed = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12.0009 12.0005L17.2435 17.2431M6.7583 17.2431L12.0009 12.0005L6.7583 17.2431ZM17.2435 6.75781L12.0009 12.0005L17.2435 6.75781ZM12.0009 12.0005L6.7583 6.75781L12.0009 12.0005Z" stroke="#FF4040" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export const Hyphen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5H11.5C11.6326 7.5 11.7598 7.55268 11.8536 7.64645C11.9473 7.74021 12 7.86739 12 8C12 8.13261 11.9473 8.25979 11.8536 8.35355C11.7598 8.44732 11.6326 8.5 11.5 8.5H4.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8Z" fill="#21272A"/>
    </svg>
  );
};

export const EmptyEye = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M7.99935 9.33268C8.73575 9.33268 9.33268 8.73575 9.33268 7.99935C9.33268 7.26295 8.73575 6.66602 7.99935 6.66602C7.26295 6.66602 6.66602 7.26295 6.66602 7.99935C6.66602 8.73575 7.26295 9.33268 7.99935 9.33268Z" fill="#697077" stroke="#697077" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 8C12.7409 9.994 10.4789 12 8 12C5.52113 12 3.25904 9.994 2 8C3.53237 6.1055 5.32775 4 8 4C10.6723 4 12.4677 6.10547 14 8Z" stroke="#697077" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export const ToggleOn = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="28" viewBox="0 0 48 28" fill="none">
      <rect x="0.5" y="0.786133" width="47" height="26.4286" rx="13.2143" fill="#3C76F1"/>
      <rect x="0.5" y="0.786133" width="47" height="26.4286" rx="13.2143" stroke="#ECF1FE"/>
      <ellipse cx="34.2857" cy="14.0006" rx="10.2857" ry="10.2857" fill="white"/>
    </svg>
  );
};

export const ToggleOff = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="28" viewBox="0 0 48 28" fill="none">
      <rect y="0.286133" width="48" height="27.4286" rx="13.7143" fill="#C8C8C8"/>
      <circle cx="13.7143" cy="14.0006" r="10.2857" fill="white"/>
    </svg>
  );
};
