import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    email: "",
    password: "",
    
  },

  isFormValid: false,

  error: {
    email: "",
    password: ""
  },
  
  isRemember: false
};
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = {...state.formData, ...action.payload};
    },
    setErrorData: (state, action) => {
      state.error = {...state.error, ...action.payload};
    },
    setFormValid: (state, action) => {
      state.isFormValid = action.payload;
    },
    setRememberMe: ((state, action) => {
      state.isRemember = action.payload;
    })
  }
});

export const {setFormData, setErrorData, setFormValid, setRememberMe} = loginSlice.actions;
export default loginSlice.reducer;