import React, { useRef } from "react";
import { HiArrowLeft } from "react-icons/hi2";
import { VisibleIcon, PersonIcon, SearchIcon } from "../../../svgs/SvgComponent";
import { TextField } from "../../../Materials/InputFields";
import { workHistoryData, educationHistoryData } from "../../../data/ProfileSetupData";
import { licenses } from "../../../data/CoachData";
import { FiFileText } from "react-icons/fi";
import Avatar from "../../../images/Avatar1.png";


import "./AdminUserJobseeker.style.scss";


const AdminUserJobseeker = () => {
  const getExperienceIndex = useRef(null);
  const getEducationIndex = useRef(null);
  const getLicenseIndex = useRef(null);

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="UserJobSeekerContainer">
      <div className="UserJobSeekerContent">
        <div className="UserJobSeekerTopSection">
          <div className="UserJobSeekerTopHeading">
            <span>
              <HiArrowLeft className="UserJobSeekerBackIcon" onClick={handleGoBack} />
              <h4>Siddiq Lushon</h4>
            </span>
            <button>
              Admin Action
            </button>
          </div> 
          <img src={Avatar} alt="" />
        </div>
        <div className="UserJobSeekerDetails">
          <div className="UserJobSeekerInfo">
            <h6>Software Tester</h6>
            <p className="FirstChild">Cloud Practitioner| 5years Experience</p>
            <p className="SecondChild">Johannesburg, South Africa. <span>Contact Info</span></p>
            <p className="ThirdChild">5 Certifications Completed</p>
          </div>
          <div className="UserJobSeekerBio">
            <h4>Bio</h4>
            <p>
              Siddiq is a senior solutions architect and a consultant, 
              with rich experience of over 20 years in core IT infrastructure services and cloud computing. 
              I am outgoing, dedicated, and open-minded. 
              I get across to people and adjust to changes with ease. 
              I believe that a person should work on developing their professional skills and learning new things all the time.
            </p>
          </div>
        </div>
        <div className="UserJobSeekerProfile">
          <div className="UserJobSeekerProfileStrength">
            <h4>Profile Strength</h4>
            <p>Mid - Level</p>
            <span>
              <div className="UserJobSeekerProgressWrapper">
                <div 
                  className="UserJobSeekerProgress"
                  style={{width: "57%"}}
                >
                </div>
              </div>
              <p>57%</p>
            </span>
            <p className="Score">Relevance Score = 80%</p>
            <p className="Score">Token Score = 5000</p>
          </div>
          <div className="UserJobSeekerProfileView">
            <h4>Your Profile View</h4>
            <span>
              <VisibleIcon />
              <p>Visible to you only</p>  
            </span> 
            <span>
              <PersonIcon />
              <p>80 Profile views</p>  
            </span>
            <span>
              <SearchIcon />
              <p>19 Search appearances</p>
            </span> 
            <p>
            Discover  your profile visibility and search appearances
            </p>
          </div>
          <div className="UserJobSeekerPortfolio">
            <h4>Portfolio</h4>
            <p>
              An highlight of your work experience to showcase your skills
            </p>
            <TextField 
              type={"text"}
              className={"UserJobSeekerText"}
              id={"UserJobSeekerText"}
              placeholder={"Paste link to portfolio here"}
              label={"Add Portfolio"}
            />
          </div>  
        </div> 
        <div className="UserJobSeekerExperience">
          <h2>Work Experience</h2>
          {workHistoryData.map((data, index) => {
            getExperienceIndex.current = index;
            return (
              <div key={index} className="UserJobSeekerExperienceData">
                <img src={data.icon} alt="company image" />
                <div className="UserJobSeekerWorkDetails">
                  <h6>{data.title}</h6>
                  <div className="UserJobSeekerWorkInfo">
                    <span>{data.company}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.startDate}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.endDate}</span>
                  </div>
                </div>
              </div>
            );
          })}  
        </div>
        <div className="UserJobSeekerEducation">
          <h2>Education</h2>
          {educationHistoryData.map((data, index) => {
            getEducationIndex.current = index;
            return (
              <div key={index} className="UserJobSeekerEducationData">
                <img src={data.icon} alt="company image" />
                <div className="UserJobSeekerEducationDetails">
                  <h6>{data.title}</h6>
                  <div className="UserJobSeekerEducationInfo">
                    <span>{data.certificate}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.startDate}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.endDate}</span>
                  </div>
                </div>
              </div>
            );
          })}  
        </div>
        <div className="UserJobSeekerLicense">
          <h2>Licenses and Certifications</h2>
          {licenses.map((data, index) => {
            getLicenseIndex.current = index;
            return (
              <div key={index} className="UserJobSeekerCertificationData">
                <div className="UserJobSeekerLicenseParticulars">
                  <img src={data.image} alt="" />
                  <div className="UserJobSeekerCertInfo">
                    <h6>{data.title}</h6>
                    <div className="UserJobSeekerCertDetails">
                      <span>{data.state}</span>
                      <span>{data.date}</span>
                    </div>
                  </div>
                </div>
                <div className="UserJobSeekerShowButton">
                  <p>Show Credentials</p>
                  <FiFileText className="ButtonIcon" />
                </div>
              </div>
            );
          })}
        </div> 
      </div>      
    </div>
  );
};

export default AdminUserJobseeker;
