import React from "react";
import { FaRegUser} from "react-icons/fa";
import { AiOutlineSetting} from "react-icons/ai";
import {IoLogOutOutline} from "react-icons/io5";
const style = {
  width: "24px",
  height: "24px"
};
export const settings = [
  {
    type: "Profile",
    image: <FaRegUser style={style} />,
    link: "/job_seeker/profile",
  },
  {
    type: "Settings",
    image: <AiOutlineSetting style={style} />,
    link: "/job_seeker/profile/profile_setup",
  },
  {
    type: "Logout",
    image: <IoLogOutOutline style={style} />,
    link:"/",
  },
];