import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import PropTypes from "prop-types";
import { changeAnchor } from "../../AdminSettings/DrawerHelper";
import Avatar from "../../../images/Avatar1.png";

import "./JobView.style.scss";
import RecruiterApplicantDetails from "./RecruiterApplicantDetails";
import RecruiterHiringStage from "./RecruiterHiringStage";

const JobView = ({ onClose, anchor, open }) => {
  const [isActive, setIsActive] = useState(null);
  const [isTab, setIsTab] = useState(0);

  const activeTab = (index) => {
    if (isTab == index) {
      return setIsTab(null);
    }
    setIsTab(index);
  };

  const activeStyle = {
    borderBottom: "2px solid var(--Foundation-PRI-pri-500, #2A53A9)",
    color: "var(--Foundation-PRI-pri-500, #2A53A9)",
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [open]);

  const handleTabIconClick = (direction) => {
    const tabs = document.querySelectorAll(".JobViewTab").length;
    let currentIndex = isTab;

    if (currentIndex !== -1) {
      let newIndex = direction === "back" ? currentIndex - 1 : currentIndex + 1;
      newIndex = (newIndex + tabs) % tabs; 
      setIsTab(newIndex);
    }
  };

  return (
    <div
      className={`overlay ${!open && "overlayHidden"} ${open && "overlayOpen"}`}
      aria-hidden="true"
    >
      <div
        tabIndex="-1"
        className={`drawer ${open ? "animate" : ""} ${
          !open ? "hidden" : ""
        } ${changeAnchor(anchor, "")}`}
        onClick={(e) => e.stopPropagation()} 
      >
        <div className="ApplicantJobViewContent">
          <span
            onClick={onClose}
            className="ApplicantJobViewNavigation"
          >
            <IoArrowBack className="ApplicantBackIcon" />
            <p>Back</p>
          </span>
          <div className="JobViewTopsection">
            <div className="ApplicantProfile">
              <img src={Avatar} alt="Avatar" />
              <span>
                <h6>Kweenier Hamza Jane</h6>
                <p>Senior Designer</p>
              </span>
            </div>
            <button>Send Message</button>
          </div>
          <div className="ApplicantJobTabsSection">
            <div className="JobViewTabs">
              <Link className="JobViewTab">
                <button
                  style={isTab === 0 ? activeStyle : {}}
                >
                  Applicant Details
                </button>
              </Link>
              <Link className="JobViewTab">
                <button 
                style={isTab === 1 ? activeStyle : {}}
                >
                  Hiring Stage
                </button>
              </Link>
            </div>
            <div className="JobViewTabsNavigation">
              <div
                className="IconWrapper"
                onClick={() => handleTabIconClick("back")}
              >
                <MdArrowBackIosNew className="TabIcon" />
              </div>
              <div
                className="IconWrapper"
                onClick={() => handleTabIconClick("forward")}
              >
                <MdArrowForwardIos className="TabIcon" />
              </div>
            </div>
          </div>
          <div 
            className={isTab === 0 ? "ApplicationDetails display" : "ApplicationDetails"}
          >
            <RecruiterApplicantDetails />
          </div>
          <div 
            className={isTab === 1 ? "HiringStage display" : "HiringStage"}
          >
            <RecruiterHiringStage />
          </div>
        </div>
      </div>
    </div>
  );
};
JobView.propTypes = {
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default JobView;
