import React from "react";
import AdminNavBar from "../AdminNavBar/AdminNavBar";
import AdminSideBar from "../AdminSideBar/AdminSideBar";
import "./AdminBarLayout.style.scss";
import Proptypes from "prop-types";

const AdminBarLayout = ({children}) => {
  return (
    <div className="AdminbLayoutContainer">
      <div className="AdminbLayoutSegmentOne">
        <AdminNavBar />
      </div>
      <div className="AdminbLayoutSegmentTwo">
        <AdminSideBar />
        <div className="AdminbLayoutSegmentTwoContainer">{children}</div>
      </div>
    </div>
  );
};
AdminBarLayout.propTypes = {
  children: Proptypes.node
};

export default AdminBarLayout;