/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./RecruiterProfileDetail.style.scss";
import { TextField, TextArea } from "../../../Materials/InputFields";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AvatarIcon } from "../../../svgs/SvgComponent";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from "react-phone-number-input";
import { getApi } from "../../../Api/server";
import WarningCard from "../../Cards/WarningCard";


const RecruiterProfileDetail = () => {

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    companyName: "",
    website: "",
    address: "",
    years: "",
    bio: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [isFormValid, setIsFormValid] = useState(false);
  const [bio, setBio] = useState("");
  const [contact, setContact] = useState();
  const [image, setImage] = useState("");
  const [instantImage, setInstantImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const maxWord = 100;
  const wordCount = bio
    ?.trim()
    .split(/\s+/).length;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleTextChange = (e) => {
    const { value } = e.target;
    setBio(value);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 1 * 1024 * 1024;
    const minSize = 50 * 1024;
    const maxWidth = 1024;
    const maxHeight = 1024;
    if (
      file.size > maxSize ||
			file.size < minSize
    ) {
      setMessage(
        "File is too large or too small. File must be between 50kb and 1mb"
      );
      setIsOpen(true);
      event.target.value = "";
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          console.log(width, height);
          if (
            width > maxWidth ||
						height > maxHeight
          )
          {
            setMessage(
              `Image dimensions ${
                width + "X" + height
              } are high. File must be or below 512 X 512`
            );
            setIsOpen(true);
            event.target.value = "";
            setInstantImage("");
            setSelectedFile("");
            return;
          }
        };
        const base64 =
					reader.result.split(",")[1];
        setImage(base64);
        setInstantImage(reader.result);
      };
      setSelectedFile(file);
      reader.readAsDataURL(file);
    }
  };
  const handleRemoveFile = () => {
    setSelectedFile("");
    setInstantImage("");
    setImage("");
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();
    const formFile = new FormData();
    const websiteRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[a-zA-Z0-9_-]*)*\/?(\?[a-zA-Z0-9_=&]*)?(#[a-zA-Z0-9_-]*)?$/;
    if (formData.address === "" || formData?.website === "" || formData?.years === "" || bio === "")
    {
      setMessage("All fields are required");
      setIsOpen(true);
    }if (selectedFile !== "") {
      setMessage("Profile Picture is required");
      setIsOpen(true);
    }
    if (contact === "" || !isPossiblePhoneNumber(contact) || !isValidPhoneNumber(contact))
    {
      setMessage("Phone number format is invalid");
      setIsOpen(true);
    }
    // setTimeout(() => {
    //   navigate(
    //     "/recruiter/profile/profile_setup"
    //   );
    // }, 300);
    if (websiteRegex.test(formData?.website))
    {
      setMessage("Invalid website URL");
      setIsOpen(true);
    }
    if (wordCount > maxWord || wordCount < 75)
    {
      setMessage("Biography must be more than 75 words and less than 100 words");
      setIsOpen(true);
    } else
    {
      const data = {
        biography: bio,
        phoneNumber: contact,
        companyName: formData.companyName,
        address: formData.address,
        companyWebSite: formData.website,
        yearsOfRecruitment: formData.years
      };
      formFile.append("data", JSON.stringify(data));
      formFile.append("file", selectedFile);
    }
  };
  const handleProceed = (e) => {
    e.preventDefault();
    navigate("/recruiter/profile/preferences");
  };

  useEffect(() => {
    const getUser = async () => {
      const response = await getApi("e1/recruiter/profile");
      if (response?.code === 403)
      {
        navigate("/login/recruiter");
      } else if (response?.code !== 200)
      {
        console.log(response?.message);
      } else
      {
        setFormData({
          firstName: response.data.firstName,
          lastName: response?.data.lastName,
          email: response?.data.email
        });
      }
    };
    getUser();
  }, []);

  return (
    <div className="RecruiterProfileDetailContainer">
      {isOpen && (
        <WarningCard
          isOpen={isOpen}
          message={message}
          onClose={setIsOpen}
        />
      )}
      <div className="ProfileDetailInnerContainer">
        <div className="ProfilePhotoSection">
          <div className="ProfilePhotoHeader">
            <p>Profile Photo</p>
          </div>
          <div className="ProfilePhotoHandler">
            <div className="ProfileImageLoader">
              {instantImage !== "" ? (
                <div className="InstantImage">
                  <img
                    src={instantImage}
                    alt="Profile Pics"
          
                  />
                </div>
              ) : (
                <span className="ProfileImageSpace">
                  <AvatarIcon />
                </span>
              )}

              <span className="ProfileImageType">
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleImageChange}
                />
                <button className="ProfileImageUpload">
									Upload
                </button>
                <button
                  className="ProfileImageRemove"
                  onClick={handleRemoveFile}>
									remove
                </button>
              </span>
            </div>
            <div className="ProfileImageRequirement">
              <p>Image Requirement</p>
              <ol>
                <li>Min 400 x 400px</li>
                <li>Max 2MB</li>
                <li>Your face or company Logo</li>
              </ol>
            </div>
          </div>
        </div>
        <hr className="ProfileLineBreaker" />
        <div className="ProfileDetailSection">
          <div className="ProfileDetailHeader">
            <h3>User Details</h3>
          </div>
          <div className="ProfileDetailForm">
            <TextField
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleInputChange}
              label="First Name"
              className="ProfileTextField"
              id="ProfileTextField"
              disabled={
                formData.firstName !== ""
                  ? true
                  : false
              }
              placeholder={
                "Enter your First Name"
              }
            />
            <TextField
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleInputChange}
              disabled={
                formData.lastName !== ""
                  ? true
                  : false
              }
              label="Last Name"
              className="ProfileTextField"
              id="ProfileTextField"
              placeholder={"Enter your Last Name"}
            />
            <TextField
              name="email"
              type="text"
              value={formData.email}
              onChange={handleInputChange}
              disabled={
                formData.email !== ""
                  ? true
                  : false
              }
              label="Email Address"
              className="ProfileTextField"
              id="ProfileTextField"
              placeholder={
                "Enter your Email Address"
              }
            />
            <div id="ProfileContact">
              <p>Contact </p>
              <PhoneInput
                placeholder="Enter phone number"
                value={contact}
                onChange={setContact}
                className="ProfileContact"
              />
            </div>
            <TextField
              name="companyName"
              type="text"
              value={formData.companyName}
              onChange={handleInputChange}
              label="Company/Agency Name"
              className="ProfileTextField"
              id="ProfileTextField"
              placeholder={
                "Enter Company/Agency Name"
              }
            />
            <TextField
              name="website"
              type="text"
              value={formData.website}
              onChange={handleInputChange}
              label="Company/Agency Name Website"
              className="ProfileTextField"
              id="ProfileTextField"
              placeholder={
                "Enter Company/Agency website"
              }
            />
            <TextField
              name="address"
              type="text"
              value={formData.address}
              onChange={handleInputChange}
              label="Office Address"
              className="ProfileTextField"
              id="ProfileTextField"
              placeholder={"Enter Office Address"}
            />
            <TextField
              name="years"
              type="number"
              value={formData.years}
              onChange={handleInputChange}
              label="Years in Recruitment"
              className="ProfileTextField"
              id="ProfileTextField"
              placeholder={
                "Enter your Years In Recruitment"
              }
            />
            <TextArea
              label="Bio"
              className="ProfileTextArea"
              id="ProfileTextArea"
              value={bio}
              onChange={handleTextChange}
              placeholder="Write something about yourself..."
            />
            <div
              className="wordCount"
              style={{ textAlign: "right" }}>
              <span>
                {wordCount}/{maxWord}
              </span>
            </div>
            <button
              onClick={handleSubmit}
              className="ProfileSaveButton">
							Save
            </button>
            <hr className="ProfileDivider" />
            <div className="ImportantSite">
              <div className="ImportantSiteHeader">
                <h2>Important Sites</h2>
                <div className="AddMoreLinks">
                  <p>Add Site Links</p>
                  <AiOutlinePlusCircle className="AddMoreLinksIcon" />
                </div>
              </div>
              <TextField
                name="websiteLink"
                type="text"
                onChange={handleInputChange}
                label="Website Link"
                className="AddLinksTextField"
                id="AddLinksTextField"
              />
              <TextField
                name="linkedIn"
                type="text"
                onChange={handleInputChange}
                label="LinkedIn"
                className="AddLinksTextField"
                id="AddLinksTextField"
              />
              <TextField
                name="twitter"
                type="text"
                onChange={handleInputChange}
                label="Twitter"
                className="AddLinksTextField"
                id="AddLinksTextField"
              />
              <button className="SaveLinksButton">
								Save
              </button>
            </div>

            <div className="ProceedButton">
              <button onClick={handleProceed}>
								Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterProfileDetail;
