import axios from "axios";

const url = process.env.REACT_APP_URL;

export const getApi = async (path) => {
  try {
    const api = `${url}/${path}`;
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.get(
      api,
      {
        headers,
        withCredentials: true,
      },
      { timeout: 5000 }
    );
    if (!response) {
      return "Server internal error";
    }
    return response?.data;
  } catch (error) {
    return error?.response.data;
  }
};
export const postApi = async (path, data) => {
  try {
    const api = `${url}/${path}`;
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      api,
      data,
      {
        headers,
        withCredentials: true,
      },
      { timeout: 5000 }
    );

    if (!response) {
      return "Server internal error";
    }
    return response?.data;
  } catch (error) {
    return error?.response.data;
  }
};
export const putApi = async (path, data) => {
  const api = `${url}/${path}`;
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.put(api, data, {
      headers,
      withCredentials: true,
    });

    if (!response) {
      return "Server internal error";
    }
    return response?.data;
  } catch (error) {
    return error?.response.data;
  }
};

export const getWithFileApi = async (path) => {
  try {
    const api = `${url}/${path}`;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const response = await axios.get(
      api,
      {
        headers,
        withCredentials: true,
      },
      { timeout: 5000 }
    );
    if (!response) {
      return "Server internal error";
    }
    return response?.data;
  } catch (error) {
    return error?.response.data;
  }
};
export const postWithFileApi = async (path, data) => {
  try {
    const api = `${url}/${path}`;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const response = await axios.post(
      api,
      data,
      {
        headers,
        withCredentials: true,
      },
      { timeout: 5000 }
    );

    if (!response) {
      return "Server internal error";
    }
    return response?.data;
  } catch (error) {
    return error?.response.data;
  }
};
export const putWithFileApi = async (path, data) => {
  const api = `${url}/${path}`;
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axios.put(api, data, {
      headers,
      withCredentials: true,
    });

    if (!response) {
      return "Server internal error";
    }
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
};
