/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from "react";
import "./confirm.style.scss";
import userImage from "../../../static/images/icons/User Thumb.png";
import { BsPlusCircle} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import {
  getApi,
  postWithFileApi,
} from "../../../Api/server";
import { IoCheckmarkCircleOutline} from "react-icons/io5";
import { RxCrossCircled} from "react-icons/rx";
import {HiOutlineInformationCircle} from "react-icons/hi2";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircularProgress from "@mui/material/CircularProgress";

const ConfirmVerification = () => {
  const [verified, setVerified] = useState("Not Verified");
  const [processed, setProcessed] = useState("Verified");
  const [percentage, setPercentage] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] =
    useState(false);
  const [userData, setUserData] = useState(null);
  const [
    userJobPreferences,
    setUserJobPreference,
  ] = useState(null);
  
  const iconStyle = {
    width: "16px",
    height: "16px",
    backgroundColor: "#ECF1FE",
    borderRadius: "100%",
    color: "#00B374",
  };

  const navigate = useNavigate();

  const getUser = async (url, setData) => {
    const response = await getApi(url);
    if (!response) {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
      return;
    } else {
      if (response?.code === 403) {
        navigate("/login");
      } else if (response?.code !== 200) {
        setTimeout(() => {
          setIsLoading(false);
        }, 4000);
        return;
      } else
      {
        const data = response?.data;
        setData(data);
        setTimeout(() => { setIsLoading(false); }, 6000);
        
      }
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getUser("e1/job_seeker/profile", setUserData);
    
  }, []);
  useEffect(() => { 
    if (userData)
    {
      setPercentage(
        Number(
          userData?.profileVerification.split(
            "%"
          )[0]
        )
      );
    }
  }, [userData]);
  useEffect(() => {
    setIsLoading(true);
    getUser(
      "e1/job_seeker/profile/job_preferences",
      setUserJobPreference
    );
  }, []);
  const handleHome = () => {
    navigate(
      "/job_seeker/home"
    );
  };
  const handleSeeCoach = () => {
    navigate(
      "/job_seeker/profile/coaches/all_coaches"
    );
  };

  return isLoading ? (
    <CircularProgress
      sx={{ width: "20px", height: "20px" }}
      style={{
        width: "20px",
        height: "20px",
      }}
    />): (
    <div className="ConfirmVerificationContainer">
      <div className="ConfirmVerificationInnerContainer">
        <div className="ConfirmVerificationHeader">
          <h3>Verification</h3>
        </div>
        {isLoading ? (
          <CircularProgress
            sx={{ width: "20px", height: "20px" }}
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        ) : (
          <div className="ConfirmVerificationProfile">
            <div className="ConfirmVerificationProfileChecker">
              <span className="ConfirmVerificationImageSpace">
                <img
                  src={
                    userData
                      ? userData?.profilePicture
                      : userImage
                  }
                  alt="Verification"
                  width={70}
                  height={70}
                />
              </span>
              <span className="ConfirmVerificationDetails">
                <p className="ConfirmVerificationName">
                  {`${userData?.firstName} ${userData?.lastName}`
                    ?? "No data"}
                </p>
                <p className="ConfirmVerificationJob">
                  {userData?.careerRole ||
										"No data to display"}
                </p>
              </span>
            </div>
            <div className="ConfirmVerificationPersonRole">
              <p>
                {userJobPreferences
                  ? userJobPreferences?.culture
                    .split(" ")
                    .splice(0, 50)
                    .join(" ") + "..."
                  : "No data to display"}
              </p>
            </div>
          </div>
        )}
        <hr className="PreferenceDivider" />
        <div className="ConfirmVerificationSecondHeader">
          <p>Profile Verification</p>
          <Link
            to={"/job_seeker/add_verification"}>
						Add Work Experience{" "}
            <span>
              <BsPlusCircle />
            </span>
          </Link>
        </div>
        <hr className="PreferenceDivider" />
        <div className="ConfirmVerificationSecondSection">
          <div className="ConfirmVerificationProcessor">
            <div
              style={{
                width: "300px",
                height: "300px",
              }}>
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                className="CircularProgressiveBar"
                styles={buildStyles({
                  textColor: "#808080",
                })}
              />
            </div>
            <p>{processed}</p>
          </div>
          <div className="ConfirmVerificationCategories">
            <Link to={"/"}>
              <div>
                <span>
                  {verified === "verified" ? (
                    <IoCheckmarkCircleOutline
                      style={iconStyle}
                    />
                  ) : (
                    <RxCrossCircled
                      style={iconStyle}
                    />
                  )}
                </span>{" "}
								Education Certification {verified}
              </div>
            </Link>
            <Link to={"/"}>
              <div>
                <span>
                  {verified === "verified" ? (
                    <IoCheckmarkCircleOutline
                      style={iconStyle}
                    />
                  ) : (
                    <RxCrossCircled
                      style={iconStyle}
                    />
                  )}
                </span>{" "}
								Professional Certification{" "}
                {verified}
              </div>
            </Link>
            <Link to={"/"}>
              <div>
                <span>
                  {verified === "verified" ? (
                    <IoCheckmarkCircleOutline
                      style={iconStyle}
                    />
                  ) : (
                    <RxCrossCircled
                      style={iconStyle}
                    />
                  )}
                </span>{" "}
								Skills {verified}
              </div>
            </Link>
            <Link to={"/"}>
              <div>
                <span>
                  {verified === "verified" ? (
                    <IoCheckmarkCircleOutline
                      style={iconStyle}
                    />
                  ) : (
                    <RxCrossCircled
                      style={iconStyle}
                    />
                  )}
                </span>{" "}
								Years of Experience {verified}
              </div>
            </Link>
          </div>
        </div>
        <div className="ConfirmVerificationInfo">
          <span>
            {<HiOutlineInformationCircle />}
          </span>{" "}
          <p>
						After processing your profile we
						recommend seeing a career coach or
						checking the certification roadmap to
						up-skill for added advantage in your
						career path.
          </p>
        </div>

        <div className="ConfirmVerificationButton">
          <button onClick={handleHome}>
						Go To Dashboard
          </button>
          <button onClick={handleSeeCoach}>
						See a Career Coach
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmVerification;
