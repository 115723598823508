import React, { useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { IoAddCircleOutline } from "react-icons/io5";
import { CiCircleRemove } from "react-icons/ci";
import { Toggle } from "../../../Materials/ToggleMui";
import { Link } from "react-router-dom";
import { quiz } from "../../../data/roadmapData";
import "./AddQuiz.style.scss";

const AddQuiz = () => {
  const [selected, setSelected] = useState(null);
  const [answerOptions, setAnswerOptions] =
		useState([]);

  const toggle = (index) => {
    if (selected == index) {
      return setSelected(null);
    }
    setSelected(index);
  };
  const handleAddOptionClick = () => {
    setAnswerOptions([
      ...answerOptions,
      { id: Date.now(), content: "" },
    ]);
  };

  const handleOptionChange = (index, content) => {
    const updatedOptions = [...answerOptions];
    updatedOptions[index].content = content;
    setAnswerOptions(updatedOptions);
  };
  const handleRemoveOptionClick = (index) => {
    const updatedOptions = [...answerOptions];
    updatedOptions.splice(index, 1);
    setAnswerOptions(updatedOptions);
  };
  const handleSaveClick = () => {
    console.log("Saved Options:", answerOptions);
  };
  return (
    <div className="AddQuizContainer">
      <div className="CourseQuizArea">
        <div className="CourseQuizAccordionWrapper">
          {quiz.map((data, index) => (
            <div
              key={index}
              className="CourseQuizAccordion">
              <div
                className="CourseQuizHeading"
                onClick={() => toggle(index)}>
                <h3>{data.title}</h3>
                <RiArrowDownSLine
                  className={
                    selected === index
                      ? "CourseQuizAccordionIcon rotate"
                      : "CourseQuizAccordionIcon"
                  }
                />
              </div>
              <div
                className={
                  selected === index
                    ? "CourseQuizDetails show"
                    : "CourseQuizDetails"
                }>
                <div className="CourseQuizQuestion">
                  <p>Enter Your Question</p>
                  <input
                    type="text"
                    placeholder="Type your question..."
                    className="CourseQuizQuestionInput"
                  />
                </div>
                <div className="CourseQuizMultipleChoices">
                  <div className="MultipleChoicesHeading">
                    <p>Multiple Choices</p>
                    <IoAddCircleOutline
                      className="AddOptionsIcon"
                      onClick={
                        handleAddOptionClick
                      }
                    />
                  </div>
                  <div className="CourseQuizAnswerOptions">
                    {answerOptions.map(
                      (option, index) => (
                        <div
                          key={option.id}
                          className="CourseQuizAnswerOption">
                          <input
                            type="text"
                            className="CourseQuizAnswerInput"
                            placeholder={`Option ${
                              index + 1
                            }`}
                            value={option.content}
                            onChange={(e) =>
                              handleOptionChange(
                                index,
                                e.target.value
                              )
                            }
                          />
                          <CiCircleRemove
                            className="RemoveOptionsIcon"
                            onClick={
                              handleRemoveOptionClick
                            }
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="CourseQuizSubmit">
                  <div className="CourseQuizPoint">
                    <span>Point</span>
                    <input
                      type="text"
                      className="PointInput"
                    />
                  </div>
                  <div className="CourseQuizSaveButton">
                    <Toggle className="CourseQuizToggle" />
                    <button
                      onClick={handleSaveClick}>
											Save Question
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="CourseQuizButton">
          <Link
            to={
              "/coach/profile/roadmap/course_summary"
            }>
            <button>Next</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AddQuiz;