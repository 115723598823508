import React, { useRef } from "react";
import { HiArrowLeft } from "react-icons/hi2";
import { VisibleIcon, PersonIcon, SearchIcon, StarIcon } from "../../../svgs/SvgComponent";
import { TextField } from "../../../Materials/InputFields";
import { licenses } from "../../../data/CoachData";
import { FiFileText } from "react-icons/fi";
import Avatar from "../../../images/Avatar1.png";
import Logo from "../../../images/course-icon1.png";

import "./AdminUserRecruiter.style.scss";

const AdminUserRecruiter = () => {
  const getLicenseIndex = useRef(null);

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="UserRecruiterContainer">
      <div className="UserRecruiterContent">
        <div className="UserRecruiterTopSection">
          <div className="UserRecruiterTopHeading">
            <span>
              <HiArrowLeft className="UserRecruiterBackIcon" onClick={handleGoBack} />
              <h4>Siddiq Lushon</h4>
            </span>
            <button>
              Admin Action
            </button>
          </div> 
          <img src={Avatar} alt="" />
        </div>
        <div className="UserRecruiterDetails">
          <div className="UserRecruiterInfo">
            <h6>Software Tester</h6>
            <p className="FirstChild">Cloud Practitioner| 5years Experience</p>
            <p className="SecondChild">Johannesburg, South Africa. <span>Contact Info</span></p>
            <p className="ThirdChild">5 Certifications Completed</p>
          </div>
          <div className="UserRecruiterBio">
            <h4>Bio</h4>
            <p>
              Siddiq is a senior solutions architect and a consultant, 
              with rich experience of over 20 years in core IT infrastructure services and cloud computing. 
              I am outgoing, dedicated, and open-minded. 
              I get across to people and adjust to changes with ease. 
              I believe that a person should work on developing their professional skills and learning new things all the time.
            </p>
          </div>
        </div>
        <div className="UserRecruiterProfile">
          <div className="UserRecruiterProfileStrength">
            <h4>Profile Strength</h4>
            <p>Industry Type</p>
            <div className="UserRecruiterType">
              <span>Marketing and Sales</span>
              <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
              <span>B2C</span>
            </div>
            <p className="Score">Total Jobs Posted = 17</p>
            <p className="Score">Total Hired = 8</p>
            <div className="UserRecruiterRating">
              <StarIcon />
              <span>4.5 (2.1k views)</span>
            </div>
          </div>
          <div className="UserRecruiterProfileView">
            <h4>Your Profile View</h4>
            <span>
              <VisibleIcon />
              <p>Visible to you only</p>  
            </span> 
            <span>
              <PersonIcon />
              <p>80 Profile views</p>  
            </span>
            <span>
              <SearchIcon />
              <p>19 Search appearances</p>
            </span> 
            <p>
            Discover  your profile visibility and search appearances
            </p>
          </div>
          <div className="UserRecruiterPortfolio">
            <h4>Portfolio</h4>
            <p>
              An highlight of your work experience to showcase your skills
            </p>
            <TextField 
              type={"text"}
              className={"UserRecruiterText"}
              id={"UserRecruiterText"}
              placeholder={"Paste link to portfolio here"}
              label={"Add Portfolio"}
            />
          </div>  
        </div> 
        <div className="UserRecruiterBusiness">
          <h2>Business Verification</h2>
          <div className="UserRecruiterBusinessData">
            <img src={Logo} alt="company image" />
            <div className="UserRecruiterBusinessDetails">
              <h6>Business License Number: 093-8392-89394</h6>
              <div className="UserRecruiterBusinessInfo">
                <span>4, Libreville crescent Berlin, Germany </span>
                <span>Verified</span>
              </div>
            </div>
          </div>
        </div>
        <div className="UserRecruiterBank">
          <h2>Bank Verification</h2>
          <span>
            <div className="LabelWrapper">
              <p className="Label">Tax Identification Number</p>
              <p className="Labelbox">093-8392-89394</p>
            </div>
            <div className="LabelWrapper">
              <p className="Label">Bank Name</p>
              <p className="Labelbox">First Monumental Bank</p>
            </div>
          </span>
          <span>
            <div className="LabelWrapper">
              <p className="Label">Account Number</p>
              <p className="Labelbox">093-8392-89394</p>
            </div>
            <div className="LabelWrapper">
              <p className="Label">Identity Verification</p>
              <p className="Labelbox">Company Id</p>
            </div>
          </span>
        </div>
        <div className="UserRecruiterLicense">
          <h2>Licenses and Certifications</h2>
          {licenses.map((data, index) => {
            getLicenseIndex.current = index;
            return (
              <div key={index} className="UserRecruiterCertificationData">
                <div className="UserRecruiterLicenseParticulars">
                  <img src={data.image} alt="" />
                  <div className="UserRecruiterCertInfo">
                    <h6>{data.title}</h6>
                    <div className="UserRecruiterCertDetails">
                      <span>{data.state}</span>
                      <span>{data.date}</span>
                    </div>
                  </div>
                </div>
                <div className="UserRecruiterShowButton">
                  <p>Show Credentials</p>
                  <FiFileText className="ButtonIcon" />
                </div>
              </div>
            );
          })}
        </div> 
      </div>      
    </div>
  );
};

export default AdminUserRecruiter;
