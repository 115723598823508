import React from "react";
import { roadmapData } from "../../../data/roadmapData";
import "./coachsubpages.style.scss";

const CoachRoadmapList = () => {
  return (
    <div className="roadMapList">
      {roadmapData &&
        roadmapData.map((data, index) => (
          <div key={index} className="roadMapItem">
            <p id="weekNum">{data.weekNo}</p>
            <div className="roadmapOthers">
              <p>{data.meetingNo}</p>
              <p>{data.lessonNo}</p>
              <p>{data.taskNo}</p>
            </div>
            <hr className="roadmapBreaker" />
          </div>
        ))}
    </div>
  );
};

export default CoachRoadmapList;