import React from "react";
import RecruiterNavBar from "../RecruiterNavBar/RecruiterNavBar";
import RecruiterSideBar from "../RecruiterSideBar/RecruiterSideBar";
import "./RecruiterBarLayout.style.scss";
import Proptypes from "prop-types";

const RecruiterBarLayout = ({children}) => {
  return (
    <div className="RecruiterbLayoutContainer">
      <div className="RecruiterbLayoutSegmentOne">
        <RecruiterNavBar />
      </div>
      <div className="RecruiterbLayoutSegmentTwo">
        <RecruiterSideBar />
        <div className="RecruiterbLayoutSegmentTwoContainer">{children}</div>
      </div>
    </div>
  );
};
RecruiterBarLayout.propTypes = {
  children: Proptypes.node
};

export default RecruiterBarLayout;