import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import CoachingPreferences from "./CoachingPreferences";
import CalendarNotifications from "./CalendarNotifications";
import MessagingNotifications from "./MessagingNotifications";
import MenteeNotification from "./MenteeNotifications";
import ProfileUpdateNotification from "./ProfileUpdateNotifications";

import "./CoachNotificationSettings.style.scss";

const CoachNotificationSettings = () => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    selectedSectionIndex: null,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSectionClick = (sectionIndex) => {
    setState({ ...state, selectedSectionIndex: sectionIndex, right: true });
  };

  const sections = [
    { title: "Coaching Preferences", drawerContent: <CoachingPreferences /> },
    { title: "Messaging", drawerContent: <MessagingNotifications /> },
    { title: "Calendar and Availability", drawerContent: <CalendarNotifications /> },
    { title: "Mentee Information", drawerContent: <MenteeNotification /> },
    { title: "Performance Analytics", },
    { title: "Profile Update", drawerContent: <ProfileUpdateNotification /> }
  ];

  const content = (anchor, drawerContent) => (
    <Box
      sx={{ width: 1000 }}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor)}
    >
      {drawerContent}
    </Box>
  );

  return (
    <div className="CoachNotificationSettingsContainer">
      <div className="NotificationContainerContent">
        <div className="CoachNotificationTopSection">
          <h2>Notifications Preferences</h2>
          <p>
          With customizable notifications in Ether, you can streamline your both business and personal experience and receive tailored alerts when you need them.
          </p>
        </div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            {sections.map((section, sectionIndex) => (
              <Button className="CoachNotificationMidSection" key={sectionIndex} onClick={() => handleSectionClick(sectionIndex)}>
                <div className="MidSectionContentDrawer">
                  <p>{section.title}</p>
                  <IoIosArrowForward />
                </div>
              </Button>
            ))}
            
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {state.selectedSectionIndex !== null && (
                <div>
                  {content(anchor, sections[state.selectedSectionIndex].drawerContent)}
                </div>
              )}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CoachNotificationSettings;
