import React, { useState } from "react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import PropTypes from "prop-types";

import "./CoachingPreferences.style.scss";
import { Toggle } from "../../../Materials/ToggleMui";
import { CheckBox, RadioButton } from "../../../Materials/InputFields";

const Modal = ( { onClose, isOpen, position } ) => {
  return (
    <>
      {isOpen && (
        <div 
          className="SpecializationModalContainer" 
          onClick={onClose}
        >
          <div 
            className="SpecializationModal" 
            onClick={(e) => e.stopPropagation()}
            style={{ top: position.y}}
          >
            <button>Move Down</button>
            <button>Remove</button>
          </div>
        </div>
      )}
    </>
  );
};
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
  position: PropTypes.func.isRequired
};

const CoachingPreferences = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickPosition, setClickPosition] = useState({ y: 0 });
  const [selectedRadioButton, setSelectedRadioButton] = useState(" ");

  const handleModalOpen = (e) => {
    setClickPosition({ y: e.clientY });
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleCheckBoxChange = () => {
    return;
  };
  const handleRadioButtonChange = (event) => {
    setSelectedRadioButton(event.target.value);
  };

  return (
    <div className="CoachingPreferencesContainer">
      <div className="CoachingPreferencesContent">
        <div className="PreferencesTopSection">
          <h6>Specializations</h6>
          <div className="PreferencesAddSpecialization">
            <p>As a coach you can  select specific subjects or specializations you are best equipped to handle.</p>
            <button>Add Specializations</button>
          </div>
          <div className="PreferencesSpecializations">
            <div className="PreferencesSpecialization">
              <p>Data Science</p>
              <PiDotsThreeVerticalBold 
                style={{cursor: "pointer"}}  
                onClick={(e) => handleModalOpen(e)} 
              />
            </div>
            <div className="PreferencesSpecialization">
              <p>Project Design</p>
              <PiDotsThreeVerticalBold 
                style={{cursor: "pointer"}}  
                onClick={(e) => handleModalOpen(e)} 
              />
            </div>
            <div className="PreferencesSpecialization">
              <p>Project Management</p>
              <PiDotsThreeVerticalBold 
                style={{cursor: "pointer"}}  
                onClick={(e) => handleModalOpen(e)} 
              />
            </div>
            <div className="PreferencesSpecializationNotification">
              <div className="PreferencesNotificationAllow">
                <h6>Allow Specialization Notifications</h6>
                <p>Enable notifications related to updates, messages, or events associated with these chosen specializations.</p>
              </div>
              <Toggle />
            </div>
          </div>
        </div>
        <hr style={{width: "100%", height: "0.5px", background: "#9BA6BC"}}/>
        <div className="PreferencesMidSection">
          <h6>Coaching Style</h6>
          <div className="PreferencesCoachingStyle">
            <div className="PreferencesCoachingStyleHeading">
              <h3>Group Session</h3>
              <p>Customize your preferred coaching style for this device</p>
            </div>
            <div className="PreferencesGroupSessions">
              <div className="PreferencesGroupSession">
                <p>Group Session</p>
                <Toggle />
              </div>
              <div className="PreferencesGroupSession">
                <p>One-on-One </p>
                <Toggle />
              </div>
              <div className="PreferencesGroupSession">
                <p>Asynchronous Style </p>
                <Toggle />
              </div>
            </div>
            <div className="PreferencesAppointment">
              <h6>Appointment Request</h6>
              <div className="PreferencesAppointmentSwitch">
                <p>Turn on appointment request </p>
                <Toggle />
              </div>
            </div>
          </div>
        </div>
        <hr style={{width: "100%", height: "0.5px", background: "#9BA6BC"}}/>
        <div className="PreferencesBottomSection">
          <div className="PreferencesCommunicationNotifications">
            <h6>Allow Communication Notifications</h6>
            <Toggle />
          </div>
          <p>These are notifications letting you know you&#39;ve an appointment on your communication tools.</p>
          <div className="PreferencesNotificationsChoice">
            <h6>Choose Notification Preferences</h6>
            <div className="PreferencesCheckBoxes">
              <CheckBox 
                name={""}
                value={""}
                className={"PreferencesCheckBox"}
                onChange={handleCheckBoxChange}
                id={"PreferencesCheckBox"}
                label={"Email"}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"PreferencesCheckBox"}
                onChange={handleCheckBoxChange}
                id={"PreferencesCheckBox"}
                label={"In-app"}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"PreferencesCheckBox"}
                onChange={handleCheckBoxChange}
                id={"PreferencesCheckBox"}
                label={"Pushed Notifications"}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"PreferencesCheckBox"}
                onChange={handleCheckBoxChange}
                id={"PreferencesCheckBox"}
                label={"Desktop Notifications"}
              />
            </div>
          </div>
          <div className="PreferencesNotificationsFrequency">
            <h6>Choose Notification Frequency</h6>
            <div className="PreferencesRadioButtons">
              <RadioButton 
                name={"notificationFrequency"}
                value={"daily"}
                label={"Daily"}
                className={"PreferencesRadioButton"}
                id={"PreferencesRadioButton"}
                onChange={handleRadioButtonChange}
                checked={selectedRadioButton === "daily"}

              />
              <RadioButton 
                name={"notificationFrequency"}
                value={"weekly"}
                label={"Weekly"}
                className={"PreferencesRadioButton"}
                id={"PreferencesRadioButton"}
                onChange={handleRadioButtonChange}
                checked={selectedRadioButton === "weekly"}
              />
              <RadioButton 
                name={"notificationFrequency"}
                value={"monthly"}
                label={"Monthly"}
                className={"PreferencesRadioButton"}
                id={"PreferencesRadioButton"}
                onChange={handleRadioButtonChange}
                checked = {selectedRadioButton === "monthly"}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal 
        isOpen={isModalOpen} 
        onClose={handleModalClose}
        position={clickPosition} 
      />
    </div>
  );
};

export default CoachingPreferences;
