import React from "react";
import PropTypes from "prop-types";
import { workHistoryData } from "../../../data/AppliedData";

const JobDescription = ({index}) => {
  const data = workHistoryData[index];
  return (
    <div className="JobDescriptionContainer">
      <div className="JobDescriptionAbout">
        <h2>About The Job</h2>
        <p>{data.jobDetails.aboutJob}</p>
      </div>
      <div className="JobDescriptionSections">
        <div className="JobDescriptionSectionOne">
          <h2>What you will do</h2>
          <p>{data.jobDetails.jobInstructions}</p>
        </div>
        <div className="JobDescriptionSectionTwo">
          <h2>Responsibilities</h2>
          <div className="ResponsibilityList">
            {data.jobDetails.jobResponsibilities.map((responsibity, index) => (
              <ul key={index}>
                <li>{responsibity}</li>
              </ul>
            ))}
          </div>
        </div>
        <div className="JobDescriptionSectionThree">
          <h2>Requirements</h2>
          <div className="ResponsibilityList">
            {data.jobDetails.jobRequirements.map((responsibity, index) => (
              <ul key={index}>
                <li>{responsibity}</li>
              </ul>
            ))}
          </div>
        </div>
        <div className="JobDescriptionSectionFour">
          <h2>Bonus Points</h2>
          <div className="ResponsibilityList">
            {data.jobDetails.bonusPoints.map((responsibity, index) => (
              <ul key={index}>
                <li>{responsibity}</li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

JobDescription.propTypes = {
  index: PropTypes.number,
};
export default JobDescription;