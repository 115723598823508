import React from "react";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import "./Cards.scss";
import PropTypes from "prop-types";

const SuccessCard = ({ message, onClose, isOpen}) => {
  const handleCancelSuccess = () => {
    onClose(!isOpen);
  };
  return (
    <div className="SuccessCardContainer">
      <div className="SuccessCard">
        <div className="SuccessIcon">
          <span>
            <IoCheckmarkDoneSharp id="SuccessIcon" />
          </span>
        </div>
        <div className="SuccessMessage">
          <p>{message}</p>
        </div>
        <div className="SuccessButton">
          <button onClick={handleCancelSuccess}>
						Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

SuccessCard.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  isClose: PropTypes.bool,
};
export default SuccessCard;