import React, { useEffect, useState } from "react";
import { NavSettingsIcon, ProfileIcon, CompassIcon, Badge, NotificationIcon } from "../../../svgs/SvgComponent";
import { Link, useNavigate } from "react-router-dom";


import "./SettingsSideBar.style.scss";

const SettingsSideBar = () => {
  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate();

  const activeStyle = {
    borderBottom: "2px solid var(--Foundation-PRI-pri-500, #2A53A9)",
    color: "var(--Foundation-PRI-pri-500, #2A53A9)"
  };

  const handleButtonClick = (button) => {
    setIsActive(button);
    if(setIsActive === "account") {
      navigate("/coach/profile/settings");
    }
    if(setIsActive === "notifications") {
      navigate("/coach/profile/settings/notifications");
    }
    if(setIsActive === "security") {
      navigate("/coach/profile/settings/security");
    }
    if(setIsActive === "payment") {
      navigate("/coach/profile/settings/payment");
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log(currentPath);
    switch (currentPath) {

    case "/coach/profile/settings":
      setIsActive("account");
      break;
    
    case "/coach/profile/settings/notifications":
      setIsActive("notifications");
      break;

    case "/coach/profile/settings/security":
      setIsActive("security");
      break;

    case "/coach/profile/settings/payment":
      setIsActive("payment");
      break;

    default:
      setIsActive(null);
      break;
    }
  }, [history]);

  return (
    <div className="SettingsSideBarContainer">
      <div className="ContainerContent">
        <div className="SideBarHeader">
          <NavSettingsIcon className="SideBarHeaderIcon" />
          <h2>Settings</h2>
        </div>
        <div className="SideTabs">
          <Link
            className="SideTab"
            to={"/coach/profile/settings"}
            onClick={() => handleButtonClick("account")}
            style={isActive === "account" ? activeStyle : {}}
          >
            <ProfileIcon className="SideTabIcon" style={ isActive === "account" ? { color: "var(--Foundation-PRI-pri-500, #2A53A9)"} : {}} />
            <p
              style={isActive === "account" ? {color: "var(--Foundation-PRI-pri-500, #2A53A9)"} : {}}
            >Account</p>
          </Link>
          <Link
            className="SideTab"
            to={"/coach/profile/settings/notifications"}
            onClick={() => handleButtonClick("notifications")}
            style={isActive === "notifications" ? activeStyle : {}}
          >
            <NotificationIcon className="SideTabIcon" />
            <p
              style={isActive === "notifications" ? {color: "var(--Foundation-PRI-pri-500, #2A53A9)"} : {}}
            >Notification Preferences</p>
          </Link>
          <Link
            className="SideTab"
            to={"/coach/profile/settings/security"}
            onClick={() => handleButtonClick("security")}
            style={isActive === "security" ? activeStyle : {}}
          >
            <CompassIcon className="SideTabIcon" />
            <p
              style={isActive === "security" ? {color: "var(--Foundation-PRI-pri-500, #2A53A9)"} : {}}
            >Security</p>
          </Link>
          <Link
            className="SideTab"
            to={"/coach/profile/settings/payment"}
            onClick={() => handleButtonClick("payment")}
            style={isActive === "payment" ? activeStyle : {}}
          >
            <Badge className="SideTabIcon" />
            <p
              style={isActive === "payment" ? {color: "var(--Foundation-PRI-pri-500, #2A53A9)"} : {}}
            >Payment Management</p>
          </Link>
        </div>

      </div>
    </div>
  );
};

export default SettingsSideBar;
