import React, {useState, useEffect} from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import SuccessCard from "../../Cards/SuccessCard";
import WarningCard from "../../Cards/WarningCard";
import {
  TextField,
} from "../../../Materials/InputFields";
import {
  Link,
  useNavigate
} from "react-router-dom";
import "./ProfileDetail.style.scss";
import { putApi } from "../../../Api/server";
import PropTypes from "prop-types";

const initialValues = {
  linkedIn: "",
  twitter: "",
  website: "",
};

const ImportantSites = ({socialHandles}) => {
  const [userForm, setUserForm] = useState(initialValues);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();


  useEffect(() => { 
    if (socialHandles)
    { 
      const website = socialHandles[0];
      const linkedIn = socialHandles.find(handle => handle.includes("linkedin"));
      const twitter = socialHandles.find(
        (handle) => handle.includes("twitter")
      );
      setUserForm((prevState) => ({
        ...prevState,
        linkedIn: linkedIn,
        twitter: twitter,
        website: website
      }));
    }
  }, [socialHandles]);

  const handleInputChange = (e) => { 
    const { name, value } = e.target;
    setUserForm({
      ...userForm,
      [name]: value
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const regex = /https:\/\/www./;
    if (
      regex.test(userForm.website) ||
			regex.test(userForm.twitter) ||
			regex.test(userForm.linkedIn)
    ) {
      setMessage(
        "website, linkedIn or X must not start with https://www"
      );
      setIsOpen(true);
      return;
    }
    const data = {
      website: userForm.website,
      twitter: userForm.twitter,
      linkedIn: userForm.linkedIn
    };
    const response = putApi(
      "e1/update/job_seeker/social_handles",
      data
    );
    if (!response)
    {
      setMessage("Internal server error");
      setIsOpen(true);
      return;
    }
    if (response?.code === 403)
    {
      navigate("/login");
      return;
    }
    if (response?.code !== 200)
    {
      setMessage(response?.message);
      setIsOpen(true);
      return;
    }
    setMessage("Your social handles have been updated.");
    setIsSuccess(true);
    
  };
  return (
    <div className="ImportantSite">
      {isOpen && (
        <WarningCard
          isOpen={isOpen}
          message={message}
          onClose={setIsOpen}
        />
      )}
      {isSuccess && (
        <SuccessCard
          isOpen={isSuccess}
          message={message}
          onClose={setIsSuccess}
        />
      )}
      <div className="ImportantSiteHeader">
        <h2>Important Sites</h2>
        <Link>
          <p>Add Site Links</p>
          <AiOutlinePlusCircle className="ProfileAddExperience" />
        </Link>
      </div>
      <TextField
        name="websiteLink"
        type="text"
        value={userForm.website}
        onChange={handleInputChange}
        label="Website Link"
        className="AddLinksTextField"
        disabled={
          userForm.website !== "" ? true : false
        }
        id="AddLinksTextField"
        placeholder="Add a portfolio website"
      />
      <TextField
        name="linkedIn"
        type="text"
        value={userForm.linkedIn}
        onChange={handleInputChange}
        label="LinkedIn"
        disabled={
          userForm.linkedIn !== "" ? true : false
        }
        className="AddLinksTextField"
        id="AddLinksTextField"
        placeholder="Add your linkedIn url without https//:"
      />
      <TextField
        name="twitter"
        type="text"
        value={userForm.twitter}
        onChange={handleInputChange}
        label="Twitter"
        disabled={
          userForm.twitter !== "" ? true : false
        }
        className="AddLinksTextField"
        id="AddLinksTextField"
        placeholder="Add your X url without https//:"
      />
      <button
        className="HandleSaveLinksButton"
        onClick={handleSubmit}>
				Save
      </button>
    </div>
  );
};

ImportantSites.propTypes = {
  socialHandles: PropTypes.array,
};

export default ImportantSites;