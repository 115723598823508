import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { HomeIcon, CalendarIcon, SettingsIcon, BooksIcon } from "../../../svgs/SvgComponent";
import { IoPeopleOutline } from "react-icons/io5";
import "./CoachSideBar.style.scss";

const CoachSideBar = () => {
  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate();

  const hoverStyle = {
    borderBottom: "2px solid var(--Foundation-PRI-pri-500, #2A53A9)",
    fontStyle: "bolder",
    color: "var(--Foundation-PRI-pri-500, #2A53A9)",
    padding: "0px 10px",
  };

  const handleButtonClick = (button) => {
    setIsActive(button);
    if (isActive === "home") {
      navigate("/coach/home");
    }
    if (isActive === "mentees") {
      navigate("/coach/profile/mentees");
    }
    if (isActive === "roadmap") {
      navigate("/coach/profile/roadmap");
    }
    if (isActive === "calendar") {
      navigate("/coach/profile/calendar");
    }
    if (isActive === "settings") {
      navigate("/coach/profile/settings");
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log(currentPath);
    switch (currentPath) {
    case "/coach/home":
      setIsActive("home");
      break;
    case "/coach/profile/mentees":
      setIsActive("mentees");
      break;
    case "/coach/profile/roadmap":
      setIsActive("roadmap");
      break;
    case "/coach/profile/calendar":
      setIsActive("calendar");
      break;
    case "/coach/profile/settings":
      setIsActive("settings");
      break;
      // Add cases for other pages and button names as needed
    default:
      setIsActive(null);
      break;
    }
  }, [history]);
  return (
    <div className='SideBarContainer'>
      <div className="InnerContainer">
        <div className="CoachSideLinks">
          <Link
            to={"/coach/home"}
            className="CoachSideLink"
            onClick={() => handleButtonClick("home")}
            style={isActive === "home" ? hoverStyle : { fontSize: "14px" }}
          >
            <HomeIcon stroke={isActive === "home" ? "#2A53A9" : "#9BA6BC"} alt="home" width={24} height={24} />
            <span>Home</span>
          </Link>
        </div>
        <div className="CoachSideLinks">
          <Link
            to={"/coach/profile/mentees"}
            className="CoachSideLink"
            onClick={() => handleButtonClick("mentees")}
            style={isActive === "mentees" ? hoverStyle : { fontSize: "14px" }}
          >
            <IoPeopleOutline color={isActive === "mentees" ? "#2A53A9" : "#9BA6BC"}  alt="mentees" style={{height: "24px", width: "24px"}} />
            <span>Mentees</span>
          </Link>
        </div>
        <div className="CoachSideLinks">
          <Link
            to={"/coach/profile/roadmap"}
            className="CoachSideLink"
            onClick={() => handleButtonClick("roadmap")}
            style={isActive === "roadmap" ? hoverStyle : { fontSize: "14px" }}
          >
            <BooksIcon stroke={isActive === "roadmap" ? "#2A53A9" : "#9BA6BC"} alt="roadmap" width={24} height={24} />
            <span>Roadmap</span>
          </Link>
        </div>
        <div className="CoachSideLinks">
          <Link
            to={"/coach/profile/calendar"}
            className="CoachSideLink"
            onClick={() => handleButtonClick("calendar")}
            style={isActive === "calendar" ? hoverStyle : { fontSize: "14px" }}
          >
            <CalendarIcon stroke={isActive === "calendar" ? "#2A53A9" : "#9BA6BC"} alt="carlendar" width={24} height={24} />
            <span>Calendar</span>
          </Link>
        </div>
        <div className="CoachSideLinks">
          <Link
            to={"/coach/profile/settings"}
            className="CoachSideLink"
            onClick={() => handleButtonClick("settings")}
            style={isActive === "settings" ? hoverStyle : { fontSize: "14px" }}
          >
            <SettingsIcon stroke={isActive === "settings" ? "#2A53A9" : "#9BA6BC"} alt="settings" width={24} height={24} />
            <span>Settings</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CoachSideBar;
