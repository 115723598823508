export const progressData = [
  {
    videos: [
      {
        videoName: "Introductory Video",
        videoPath: "/",
        videoTime: "1 hour",
      },
      {
        videoName: "Understanding Microscopy",
        videoPath: "/",
        videoTime: "2 hour",
      },
    ],
    readings: [
      {
        readingName: "Practice Session",
        readingPath: "/",
        readingHour: "30 mins",
      },
    ],
  },
  {
    videos: [
      {
        videoName: "Introductory Video",
        videoPath: "/",
        videoTime: "1 hour",
      },
      {
        videoName: "Understanding Microscopy",
        videoPath: "/",
        videoTime: "2 hour",
      },
    ],
    readings: [
      {
        readingName: "Practice Session",
        readingPath: "/",
        readingHour: "30 mins",
      },
    ],
  },
];