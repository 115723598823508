import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    title: "",
    courseDes: "",
    courseExpec: "",
  },
  isFormValid: false,
  error: {
    title: "",
    courseDes: "",
    courseExpec: "",
  }
};

const roadMapSlice = createSlice ({
  name: "roadMap",
  initialState,
  reducers: {
    setFormData : (state, action) => {
      state.formData = {...state.formData, ...action.payload};
    },
    setFormValid: ( state, action) => {
      state.isFormValid = action;
    },
    setError: (state, action) => {
      state.error = {...state.error, ...action.payload};
    },
  }
});

export const { setFormData, setFormValid, setError} = roadMapSlice.actions;
export default roadMapSlice.reducer; 