import React, { useState, useRef } from "react";
import "./FilledCoachProfile.style.scss";
import userImage from "../../../static/images/icons/thumbImage.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import { FiFileText, FiDownload } from "react-icons/fi";
import { PiVideoCameraThin } from "react-icons/pi";
import { TfiLoop } from "react-icons/tfi";
import { MdOutlineVerified } from "react-icons/md";
import { EditIcon, StarIcon, RateIcon, ThreeDots, VisibleIcon, PersonIcon, SearchIcon, CheckIcon, VerifiedIcon, InfiniteIcon, NextIcon, PrevIcon } from "../../../svgs/SvgComponent";
import { workHistoryData, educationHistoryData } from "../../../data/ProfileSetupData";
import { slidesData } from "../../../data/Slides";
import { licenses } from "../../../data/CoachData";
import { Link } from "react-router-dom";

const FilledCoachProfile = () => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = Math.min(5, slidesData.length);
  const getExperienceIndex = useRef(null);
  const getEducationIndex = useRef(null);
  const getLicenseIndex = useRef(null);

  const handleGoBack = () => {
    window.history.back();
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 5 - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 5 - 1 ? 0 : prevIndex + 1));
  };

  const currentSlide = slidesData[currentIndex];

  return (
    <div className="FilledProfileContainer">
      <div className="ContainerWrapper">
        <div className="CoverPhotoSection">
          <div className="TopNavigation">
            <div onClick={handleGoBack} className="Navigation">
              <IoIosArrowRoundBack className="NavigationIcon" />
              <p>Back</p>
            </div>
            <div className="EditIcon">
              <Link to={"/coach/profile/profile_setup"}>
                <EditIcon />
              </Link>
              
            </div>
            
          </div>
        </div>
        <div className="ProfileDetails">
          <img src={userImage} alt="" />
          <div className="AboutInfo">
            <h3>About Jennifer Aniston</h3>
            <div className="ProfileRating">
              <span>2 years experience</span>
              <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
              <span>Verified</span>
              <span>
                <StarIcon />
                <span>4.5</span>
              </span>
            </div>
            <div className="BioDetails">
              <p>
                Hello! I am a passionate and innovative product designer with over four years of experience in the industry. 
                My journey began with a strong fascination for creating beautiful, functional, 
                and user-centric designs. What sets me apart is my dual role as a coach, 
                where I draw from my design expertise to guide and mentor aspiring designers.
              </p>
              <p>
                <b>Coaching Approach:</b> As a coach, I am dedicated to sharing my knowledge and helping aspiring designers and design enthusiasts reach their full potential. 
                I understand the challenges of breaking into the design industry, 
                and I am committed to providing guidance, feedback, 
                and mentorship to foster growth and development.
              </p>
            </div>

          </div>
        </div>
        <div className="MiddleSection">
          <div className="CoachPerks">
            <div className="ProfilePreview">
              <div className="ProfileStrength">
                <div className="Heading">
                  <h6>Your Profile Strength</h6>
                  <ThreeDots className="ThreeDots" />
                </div>
                <div className="Visibility">
                  <VisibleIcon />
                  <p>Visible to you only</p>
                </div>
                <div className="ExperienceLevel">
                  <h6>Mid - Level</h6>
                  <div className="ProgressBar">
                    <div className="PercentBar"><div className="InnerPercentBar"></div></div>
                    <span>90%</span>
                  </div>
                  <p>Complete your profile so you can become visible to Mentee.</p>
                </div>
              </div>
              <div className="ProfileView">
                <div className="ViewHeading">
                  <h6>Your Profile View</h6>
                  <ThreeDots className="ThreeDots" />
                </div>
                <div className="ViewVisibility">
                  <VisibleIcon />
                  <p>Visible to you only</p>
                </div>
                <div className="ProfileViews">
                  <PersonIcon />
                  <p>80 profile views</p>
                </div>
                <div className="SearchAppearances">
                  <SearchIcon />
                  <p>19 Search appearances</p>
                </div>
                <p>Discover  your profile visibility and search appearances</p>
              </div>
            </div>
            <div className="AchievementSection">
              <h3>Achievements</h3>
              <hr className="PageDivider"/>
              <div className="Verifications">
                <div className="IconWrapper">
                  <VerifiedIcon />
                </div>
                <p>Ether verified coach</p>
              </div>
              <div className="Verifications">
                <div className="IconWrapper">
                  <InfiniteIcon />
                </div>
                <p>5 Successful coaching sessions</p>
              </div>
              <div className="Verifications">
                <div className="IconWrapper">
                  <CheckIcon />
                </div>
                <p>5 Successful coaching sessions</p>
              </div>
              <div className="Verifications">
                <div className="IconWrapper">
                  <CheckIcon />
                </div>
                <p>Verified Field Experience</p>
              </div>
            </div>
          </div>
          <div className="CoachPlan">
            <div className="MentorServices">
              <div className="AreaOfExpertise">
                <h6>Area of Expertise</h6>
                <ul>
                  <li>Data Science</li>
                  <li>Product Design</li>
                  <li>Product Manager</li>
                </ul>  
              </div> 
              <div className="MentorshipRoadmap">
                <h6>Mentorship Roadmap</h6> 
                <div className="RoadMaps">
                  <div className="RoadmapsIcons">
                    <PiVideoCameraThin className="RoadmapIcon" />
                    <p>54 hours on-demand video</p>
                  </div>
                  <div className="RoadmapsIcons">
                    <FiFileText className="RoadmapIcon" />
                    <p>Assignments &  articles</p>
                  </div>
                  <div className="RoadmapsIcons">
                    <FiDownload className="RoadmapIcon" />
                    <p>Downloadable resources</p>
                  </div>
                  <div className="RoadmapsIcons">
                    <TfiLoop className="RoadmapIcon" />
                    <p>Full lifetime access</p>
                  </div>
                  <div className="RoadmapsIcons">
                    <MdOutlineVerified className="RoadmapIcon" />
                    <p>Certificate of completion</p>
                  </div>
                </div>
              </div> 
            </div>
            <div className="ReviewSlide">
              <div className="SlideButtons">
                <div onClick={handlePrev} className="PrevSlideIcon"><PrevIcon /></div>
                <p className="Review">Reviews</p>
                <p className="Count">{`${currentIndex + 1}/${totalSlides}`}</p>
                <div onClick={handleNext} className="NextSlideIcon"><NextIcon /></div> 
              </div>
              <hr className="ReviewDivider"/>
              <div className="Slide">
                <div className="StarRating">
                  {Array(currentSlide.rating).fill(null).map((_, index) => (
                    <RateIcon key={index} />
                  ))}
                </div>
                <p>{currentSlide.comment}</p>
                <div className="Rater">
                  <PersonIcon />
                  <p>{currentSlide.rater}</p>
                </div>
              </div>
            </div>  
          </div>
        </div>
        <div className="WorkExperience">
          <h3>Work Experience</h3>
          {workHistoryData.map((data, index) => {
            getExperienceIndex.current = index;
            return (
              <div key={index} className="ExperienceData">
                <img src={data.icon} alt="company image" />
                <div className="WorkDetails">
                  <h6>{data.title}</h6>
                  <div className="WorkInfo">
                    <span>{data.company}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.startDate}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.endDate}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="Education">
          <h3>Education</h3>
          {educationHistoryData.map((data, index) => {
            getEducationIndex.current = index;
            return (
              <div key={index} className="EducationData">
                <img src={data.icon} alt="company image" />
                <div className="EducationDetails">
                  <h6>{data.title}</h6>
                  <div className="EducationInfo">
                    <span>{data.certificate}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.startDate}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.endDate}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="CoachCertification">
          <h3>Licenses and Certifications</h3>
          {licenses.map((data, index) => {
            getLicenseIndex.current = index;
            return (
              <div key={index} className="CertificationData">
                <div className="LicenseParticulars">
                  <img src={data.image} alt="" />
                  <div className="CertInfo">
                    <h6>{data.title}</h6>
                    <div className="CertDetails">
                      <span>{data.state}</span>
                      <span>{data.date}</span>
                    </div>
                  </div>
                </div>
                <div className="ShowButton">
                  <p>Show Credentials</p>
                  <FiFileText className="ButtonIcon" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FilledCoachProfile;
