import React, { useState } from "react";
import { IoPersonSharp } from "react-icons/io5";
import { LuPenLine } from "react-icons/lu";
import { Toggle } from "../../Materials/ToggleMui";
import "./AdminAccountSettings.style.scss";

const AdminAccountSettings = () => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    country: "",
  };
  const [state, setState] =
    useState(initialState);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  
  return (
    <div className="AdminSettingsContainer">
      <div className="ProfileSettings">
        <div className="ProfileHeader">
          <h1>Profile Settings</h1>
          <LuPenLine className="ProfileIcon" />
        </div>
        <div className="CourseInstructor">
          <div className="CourseInstructorProfileWrapper">
            <div className="CourseInstructorProfile">
              <IoPersonSharp className="CourseImage" />
              <input
                type="file"
                className="CourseImageInput"
              />
              <button id="UploadPhoto">
								Upload Photo
              </button>
              <button className="RemovePhoto">
								Remove
              </button>
            </div>
            <div className="CourseInstructorCondition">
              <p>Image requirements</p>
              <ol>
                <li>Min. 400 x 400px</li>
                <li>Max. 2MB</li>
                <li>Your face or company logo</li>
              </ol>
            </div>
          </div>
          <div className="InstructorName">
            <div className="InputField">
              <label htmlFor="name">
								First Name:
              </label>
              <input
                type="text"
                value={state.firstName}
                onChange={handleInputChange}
                id="InputField"
                name="firstName"
                placeholder="Placeholder"
              />
            </div>
            <div className="InputField">
              <label htmlFor="lastName">
								Last Name:
              </label>
              <input
                type="text"
                value={state.lastName}
                onChange={handleInputChange}
                id="InputField"
                name="lastName"
                placeholder="Placeholder"
              />
            </div>
            <div className="InputField">
              <label htmlFor="email">
								Email Address:
              </label>
              <input
                type="email"
                value={state.email}
                onChange={handleInputChange}
                id="InputField"
                name="email"
                placeholder="Placeholder"
              />
            </div>
            <div className="InputField">
              <label htmlFor="phone">
								Phone Number:
              </label>
              <input
                type="text"
                value={state.phone}
                onChange={handleInputChange}
                id="InputField"
                name="phone"
                placeholder="Placeholder"
              />
            </div>
            <div className="InputField">
              <label htmlFor="password">
								Password:
              </label>
              <input
                type="password"
                value={state.password}
                onChange={handleInputChange}
                id="InputField"
                name="password"
                placeholder="Placeholder"
              />
            </div>
            <div className="InputField">
              <label htmlFor="country">
								Country:
              </label>
              <input
                type="text"
                value={state.country}
                onChange={handleInputChange}
                id="InputField"
                name="country"
                placeholder="Placeholder"
              />
            </div>
          </div>
          <div className="SaveButton">
            <button>Save</button>
          </div>
        </div>
      </div>
      <div className="DisplaySetting">
        <h1>Dispaly</h1>
        <div className="LightMode">
          <h5>Light Mode</h5>
          <p>Customize your ETHER&apos;s appearance for this device</p>
        </div>
        <div className="ToggleModes">
          <span className="ToggleMode">
            <p>Light Mode</p>
            <Toggle />
          </span>
          <span className="ToggleMode">
            <p>Dark Mode</p>
            <Toggle />
          </span>
          <span className="ToggleMode">
            <p>System&apos;s Default</p>
            <Toggle />
          </span>
        </div>
        <div className="FontSize">
          <h5>Font Size</h5>
          <button>
            <select>
              <option value="Medium" key="medium">Medium(Recommended)</option>
              <option value="Small" key="small">Small</option>
              <option value="Large" key="large">Large</option>
            </select>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminAccountSettings;
