import React from "react";
import { CheckBox } from "../../../Materials/InputFields";

import "./CalendarNotifications.style.scss";
import { Toggle } from "../../../Materials/ToggleMui";

const CalendarNotifications = () => {

  const handleCheckBoxChange = () => {
    return;
  };

  return (
    <div className="CalendarNotificationsContainer">
      <div className="CalendarNotificationsContent">
        <div className="CalendarNotificationsHeading">
          <h6>Allow Calendar Notifications</h6>
          <Toggle />
        </div>
        <div className="CalendarCheckboxHeadings">
          <div className="CalendarCheckboxHeading"></div>
          <div className="CalendarCheckboxHeading">
            <h6>Email</h6>
            <p>Sent to your registered email</p>
          </div>
          <div className="CalendarCheckboxHeading">
            <h6>In-app notifications</h6>
            <p>Delivered through the app</p>
          </div>
          <div className="CalendarCheckboxHeading">
            <h6>Pushed notifications</h6>
            <p>Get notified through your device</p>
          </div>
          <div className="CalendarCheckboxHeading">
            <h6>Desktop </h6>
            <p>Receive notification on windows</p>
          </div>
        </div>
        <div className="CalendarCheckBoxes">
          <div className="CalendarCheckBox">
            <div className="CalendarCheckBoxText">
              <h6>Invitations</h6>
              <p>These are notifications about appointment invites set on Calendar.</p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
          </div>
          <div className="CalendarCheckBox">
            <div className="CalendarCheckBoxText">
              <h6>Reminders</h6>
              <p>These are notifications about upcoming events you&apos;ve set on calendar </p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
          </div>
          <div className="CalendarCheckBox">
            <div className="CalendarCheckBoxText">
              <h6>Time Zone</h6>
              <p>These are notifications about having an appointment different from your time zone and synchronize automatically to your time zone when added to calendar .</p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"CalendarCheckBox"}
              onChange={handleCheckBoxChange}
              id={"CalendarCheckBox"}
              label={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarNotifications;
