const apiUrl =
	"https://restcountries.com/v3.1/all";

export const countryNames = fetch(apiUrl)
  .then((response) => response.json())
  .then((data) => {
    // Extracting country names from the response
    const countryNames = data.map(
      (country) => country.name.common
    );

    return countryNames;
  })
  .catch((error) =>
    console.error(
      "Error fetching country data:",
      error
    )
  );
