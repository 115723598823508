import React from "react";
import CoachNavBar from "../CoachNavBar/CoachNavBar";
import CoachSideBar from "../CoachSideBar/CoachSideBar";
import "./CoachBarLayout.style.scss";
import Proptypes from "prop-types";

const CoachBarLayout = ({children}) => {
  return (
    <div className="CoachbLayoutContainer">
      <div className="CoachbLayoutSegmentOne">
        <CoachNavBar />
      </div>
      <div className="CoachbLayoutSegmentTwo">
        <CoachSideBar />
        <div className="CoachbLayoutSegmentTwoContainer">{children}</div>
      </div>
    </div>
  );
};
CoachBarLayout.propTypes = {
  children: Proptypes.node
};

export default CoachBarLayout;