import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import "./Cards.scss";

const AlertCard = ({
  message,
  onClose,
  isOpen,
  address
}) => {

  const navigate = useNavigate();
  const handleCancelAlert = () => {
    onClose(!isOpen);
  };
  const handleLeave = () => {
    navigate(address);
  };

  return (
    <div className="AlertCardContainer">
      <div className="AlertCard">
        <div className="SuccessIcon">
          <span>
            <FiAlertTriangle id="SuccessIcon" />
          </span>
        </div>
        <div className="SuccessMessage">
          <p>{message}</p>
        </div>
        <div className="SuccessButton">
          <button onClick={handleLeave}>
						Proceed
          </button>
          <button onClick={handleCancelAlert}>
						Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

AlertCard.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  address: PropTypes.string.isRequired

};

export default AlertCard;