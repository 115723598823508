import React, { useState, useMemo, useCallback, useEffect } from "react";
import { ActiveTabIcon, InactiveTabIcon } from "../../../svgs/SvgComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMyContext } from "../../../Utils/ContextProvider";
import { setFormData, setFormValid, setError } from "../../../Redux/Slicers/roadMapSlice";
import { IoAddCircleOutline } from "react-icons/io5";


import "./CoachNewRoadMap.style.scss";

const CoachNewRoadMap = () => {
  const { updateMyData } = useMyContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formData = useSelector((state) => state.roadMap.formData);
  const isFormValid = useSelector(
    (state) => state.roadMap.isFormValid
  );

  const errors = useSelector ((state) => state.roadMap.error);

  const initialFormData = useMemo(
    () => JSON.parse(JSON.stringify(formData)), [formData]);
  const initialErrors = useMemo(
    () => JSON.parse(JSON.stringify(errors)), [errors]);

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      dispatch(setFormData({ [name]: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    const newErrors = { ...initialErrors };
    let hasErrorsChanged = false;
    for (const fieldName in formData) {
      switch (fieldName) {
      case "title":
        newErrors.title = "";

        if (formData.title ===""){
          newErrors.title = "Course Title is required";
        } else if (formData.title.split(" ").length < 2) {
          newErrors.title =
            "Course Title must contain more characters";
        }
        break;

      case "courseDes":
        newErrors.courseDes = "";

        if (formData.courseDes === "") {
          newErrors.courseDes = "Course Description is required";
        }
        break;

      case "courseExpec":
        newErrors.courseExpec = "";

        if(formData.courseExpec === "") {
          newErrors.courseExpec = "Course Expectation is required";
        }
        break;

      default:
        break;
      }
    }
    for(const fieldName in formData) {
      if(newErrors[fieldName] !==initialErrors[fieldName]) {
        hasErrorsChanged = true;
        break;
      }
    }
    if(hasErrorsChanged) {
      dispatch(setError(newErrors));
      const formIsValid = !Object.values(newErrors).some((error) => error);
      dispatch(setFormValid(formIsValid));
    }
  }, [dispatch, formData, initialErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(isFormValid) {
      updateMyData({ courseData: formData});
      navigate("/coach/profile/roadmap/course_resources");
      console.log("Form submitted:", formData);
    } else {
      console.log("Form submission canceled due to validation errors.");
    }
  };



  return (
    <div className="NewRoadMapContainer">
      <div className="RoadMapContainerContent">
        <input 
          type="text" 
          placeholder="Add Course Title" 
          className="NewRoadMapInput" 
          name="title"
          onChange={handleInputChange}
          value={formData.title}
        />
        {errors.title ? (
          <span className="ErrorField"><span style={{color: "#FF4040"}}>*</span>{errors.title}</span>
        ) : (
          <div className="ErrorField"></div>
        )}
        <div className="NewRoadMapTabs">
          <div className="NewRoadMapActiveTab">
            <ActiveTabIcon className="NewRoadMapTabIcon" />
            <p>1. Course Details</p>
          </div>
          <div className="NewRoadMapTab">
            <InactiveTabIcon className="NewRoadMapTabIcon" />
            <p>2. Course Resources</p>
          </div>
          <div className="NewRoadMapTab">
            <InactiveTabIcon className="NewRoadMapTabIcon" />
            <p>3. Course Quiz</p>
          </div>
          <div className="NewRoadMapTab">
            <InactiveTabIcon className="NewRoadMapTabIcon" />
            <p>4. Summary</p>
          </div>
        </div>
        <div className="NewRoadMapInputSection">
          <div className="NewCourseDescription">
            <h6>Course Description</h6>
            <textarea 
              type="text" 
              className="CourseDescriptionInput" 
              placeholder="Write your Course Description..."
              name="courseDes"
              onChange={handleInputChange}
              value={formData.courseDes}
            />
            {errors.courseDes ? (
              <span className="ErrorField"><span style={{color: "#FF4040"}}>*</span>{errors.courseDes}</span>
            ) : (
              <div className="ErrorField"></div>
            )}
          </div>
          <hr className="NewRoadMapDivider"/>
          <div className="NewCourseExpectations">
            <div className="ExpectationsHeadings">
              <h6>Course Expectations</h6>
              <p>What will be the outcome of users taking this course?</p>
              <textarea 
                type="text"
                className="CourseExpectationsInput"
                placeholder="Write your Course Expectations..."
                name="courseExpec"
                onChange={handleInputChange}
                value={formData.courseExpec}
              />
              {errors.courseExpec ? (
                <span className="ErrorField"><span style={{color: "#FF4040"}}>*</span>{errors.courseExpec}</span>
              ) : (
                <div className="ErrorField"></div>
              )}
            </div>
            <hr className="NewRoadMapDivider" />
            <div className="CourseModules">
              <h6>Course Modules</h6>
              <div className="CourseModuleWrapper">
                <p>Add a course module</p>
                <IoAddCircleOutline className="CourseModuleIcon" />
              </div>
            </div>
            <div className="ButtonWrapper">
              <button onClick={handleSubmit}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachNewRoadMap;
