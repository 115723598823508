import React from "react";
import { CheckBox, RadioButton } from "../../../Materials/InputFields";

import "./ProfileUpdateNotifications.style.scss";
import { Toggle } from "../../../Materials/ToggleMui";

const ProfileUpdateNotifications = () => {

  const handleCheckBoxChange = () => {
    return;
  };

  return (
    <div className="ProfileUpdateNotificationsContainer">
      <div className="ProfileUpdateNotificationsContent">
        <div className="ProfileUpdateNotificationsHeading">
          <h6>Allow Profile Notifications</h6>
          <Toggle />
        </div>
        <div className="ProfileUpdateCheckboxHeadings">
          <div className="ProfileUpdateCheckboxHeading"></div>
          <div className="ProfileUpdateCheckboxHeading">
            <h6>Email</h6>
            <p>Sent to your registered email</p>
          </div>
          <div className="ProfileUpdateCheckboxHeading">
            <h6>In-app notifications</h6>
            <p>Delivered through the app</p>
          </div>
          <div className="ProfileUpdateCheckboxHeading">
            <h6>Pushed notifications</h6>
            <p>Get notified through your device</p>
          </div>
          <div className="ProfileUpdateCheckboxHeading">
            <h6>Desktop </h6>
            <p>Receive notification on windows</p>
          </div>
        </div>
        <div className="ProfileUpdateCheckBoxSection">
          <div className="ProfileUpdateCheckBoxContent">
            <div className="ProfileUpdateCheckBoxText">
              <h6>Profile Recommendation</h6>
              <p>These are notifications about ways to complete your profile, such as adding education, industry, or photo.</p>
            </div>
            <div className="ProfileUpdateCheckBoxes">
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"MessagingCheckBox"}
                label={""}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
            </div>
          </div>
          <div className="ProfileUpdateCheckBoxContent">
            <div className="ProfileUpdateCheckBoxText">
              <h6>New Roadmap</h6>
              <p>These are notifications that you&apos;ve added new roadmap</p>
            </div>
            <div className="ProfileUpdateCheckBoxes">
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
            </div>
          </div>
          <div className="ProfileUpdateCheckBoxContent">
            <div className="ProfileUpdateCheckBoxText">
              <h6>Tips and Offers for using Ether</h6>
              <p>These are notifications with tips and offers for using Ether effectively.</p>
            </div>
            <div className="ProfileUpdateCheckBoxes">
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
              <CheckBox 
                name={""}
                value={""}
                className={"ProfileUpdateCheckBox"}
                onChange={handleCheckBoxChange}
                id={"ProfileUpdateCheckBox"}
                label={""}
              />
            </div>
          </div>
        </div>
        <div className="ProfileUpdateNotificationsFrequency">
          <h6>Choose Email Frequency</h6>
          <div className="ProfileUpdateRadioButtons">
            <RadioButton 
              name={"notificationFrequency"}
              value={"daily"}
              label={"Daily"}
              className={"ProfileUpdateRadioButton"}
              id={"ProfileUpdateRadioButton"}
            />
            <RadioButton 
              name={"notificationFrequency"}
              value={"weekly"}
              label={"Weekly"}
              className={"ProfileUpdateRadioButton"}
              id={"ProfileUpdateRadioButton"}
            />
            <RadioButton 
              name={"notificationFrequency"}
              value={"monthly"}
              label={"Monthly"}
              className={"ProfileUpdateRadioButton"}
              id={"ProfileUpdateRadioButton"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUpdateNotifications;
