import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { CalendarDates } from "../../data/CoachCalendar";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import "./CoachCalendar.style.scss";
import {
  IoIosArrowForward,
  IoIosArrowDown,
} from "react-icons/io";
import TopCalendar from "./TopCalendar";

const newArr = [];
for (let i = 0; i < CalendarDates.length; i++) {
  newArr.push(false);
}
console.log(newArr);

const renderEventContent = (startDate, endDate) => {
  const start = new Date(
    startDate
  );
  const end = new Date(endDate);

  // Format start and end date
  const formattedStartDate = `${start.toLocaleString(
    "default",
    { month: "short" }
  )} ${start.getDate()}`;
  const formattedEndDate = `${end.toLocaleString(
    "default",
    { month: "short" }
  )} ${end.getDate()}`;
  
  return formattedStartDate !== formattedEndDate
    ? `${formattedStartDate} - ${formattedEndDate}`
    : formattedStartDate;

};


const CoachCalendar = () => {
  const [searchDate, setSearchDate] =
		useState("");
  const [selectedInput, setSelectedInput] =
		useState("");
  const [accordionDown, setAccordionDown] =
		useState(newArr);
  const navigate = useNavigate();

  const handleAppointment = () => { 
    navigate(
      "/coach/profile/calendar/create_appointment"
    );
  };
  
  const handleAccordion = (index) => {
    setAccordionDown((prevAccordionDown) => {
      const updatedAccordion = [
        ...prevAccordionDown,
      ];
      updatedAccordion[index] =
				!updatedAccordion[index]; // Toggle the accordion state
      return updatedAccordion;
    });
  };
  const cancelAccordion = (index) => {
    setAccordionDown((prevAccordionDown) => {
      const updatedAccordionList = [
        ...prevAccordionDown
      ];
      updatedAccordionList[index] = false;
      return updatedAccordionList;
    });
  };

  const handleInputChange = (event) => {
    setSearchDate(event.target.value);
  };
  const handleSelect = (event) => {
    setSelectedInput(event.target.value);
  };
  return (
    <div className="CoachCalendarContainer">
      <div className="SearchField">
        <CiSearch className="SearchIcon" />
        <input
          type="text"
          placeholder="Search course keyword or title"
          id="SearchField"
          value={searchDate}
          onChange={handleInputChange}
        />
      </div>
      <div className="AppointmentSection">
        <h1>Your Calendar</h1>
        <button onClick={handleAppointment}>
					Add Appointment
        </button>
      </div>
      <div className="FilterCalendar">
        <div className="FilterSection">
          <div className="FilterField">
            <p>Filter</p>
            <select
              value={selectedInput}
              onChange={handleSelect}
              className="SelectField">
              <option value="">---</option>
              <option value="day">day</option>
              <option value="month">
								month{" "}
              </option>
              <option value="year">year</option>
            </select>
          </div>
          <div className="CalendarDates">
            {CalendarDates.map(
              (element, index) => (
                <div
                  key={index}
                  className="FilterCalendar">
                  <div className="FilterHeader">
                    {accordionDown[index] ? (
                      <IoIosArrowDown
                        className="Accordion"
                        onClick={() =>
                          cancelAccordion(index)
                        }
                      />
                    ) : (
                      <IoIosArrowForward
                        className="Accordion"
                        onClick={() =>
                          handleAccordion(index)
                        }
                      />
                    )}
                    <h1>{element.title} </h1>
                    <HiDotsVertical className="Accordion" />
                  </div>
                  <div className="FilterDate">
                    <p>
                      {renderEventContent(
                        element.start,
                        element.end
                      )}
                    </p>
                  </div>
                  <div className="EventList">
                    {accordionDown[index] ? (
                      <ul>
                        {element?.eventList?.map(
                          (element, index) => (
                            <li key={index}>
                              {" "}
                              {element}{" "}
                            </li>
                          )
                        )}
                      </ul>
                    ) : (
                      <p>
                        {
                          element?.eventList
                            ?.length
                        }{" "}
												events
                      </p>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div className="CalendarSection">
          <TopCalendar />
        </div>
      </div>
    </div>
  );
};

export default CoachCalendar;
