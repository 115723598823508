import React, {useState, useEffect} from "react";
import "./verification.style.scss";
import userImage from "../../../static/images/icons/User Thumb.png";
import uploader from "../../../static/images/icons/uploader.png";
import { getApi, postWithFileApi } from "../../../Api/server";
import { useNavigate } from "react-router-dom";
import WarningCard from "../../Cards/WarningCard";
import SuccessCard from "../../Cards/SuccessCard";
import CircularProgress from "@mui/material/CircularProgress";
import "react-circular-progressbar/dist/styles.css";

const Verification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userJobPreferences, setUserJobPreference] = useState(null);
  const [selectProfessionalFile, setSelectProfessionalFile] = useState(null);
  const [selectEducationalFile, setSelectEducationalFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();

  const getUser = async (url, setData) => {
    const response = await getApi(url);
    if (!response) {
      setIsLoading(false);
      return;
    } else {
      if (response?.code === 403) {
        navigate("/login");
      } else if (response?.code !== 200) {
        setIsLoading(false);
        return;
      } else {
        setData(response?.data);
        console.log(response?.data);
        setIsLoading(false);
      }
    }
  };

  const handleProfessionalCert = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setSelectProfessionalFile(file);
  };
  const handleEducationalCert = (e) => { 
    const file = e.target.files[0];
    setSelectEducationalFile(file);
  };

  useEffect(() => { 
    setIsLoading(true);  
    getUser("e1/job_seeker/profile", setUserData);
  }, []);
  useEffect(() => {
    setIsLoading(true);
    getUser(
      "e1/job_seeker/profile/job_preferences",
      setUserJobPreference
    );
  }, []);
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    const formFile = new FormData();
    const files = [
      selectProfessionalFile,
      selectEducationalFile,
    ];
    for (let i = 0; i < files.length; i++) {
      formFile.append("files", files[i]);
    }
    
    const response = await postWithFileApi(
      "e1/job_seeker/profile/upload_certificates",
      formFile
    );
    if (response?.code !== 200)
    {
      setMessage(response?.message);
      setIsOpen(true);
    } else
    {
      setMessage(response?.message);
      setIsSuccess(true);
      navigate(
        "/job_seeker/profile/confirm_verification"
      );
    }
  };

  return (
    <div className="VerificationContainer">
      {
        isOpen && <WarningCard isOpen={isOpen} onClose={setIsOpen} message={message} />
      }
      {
        isSuccess && <SuccessCard isOpen={isSuccess} onClose={setIsSuccess} message={message} />
      }
      <div className="VerificationInnerContainer">
        <div className="VerificationHeader">
          <h3>Verification</h3>
        </div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div className="VerificationProfile">
            <div className="VerificationProfileChecker">
              <span className="VerificationImageSpace">
                <img
                  src={
                    userData?.profilePicture ||
										userImage
                  }
                  alt="Verification"
                  width={70}
                  height={70}
                />
              </span>
              <span className="VerificationDetails">
                <p className="VerificationName">
                  {`${userData?.firstName} ${userData?.lastName}` ||
										"No data"}
                </p>
                <p className="VerificationJob">
                  {userData?.careerRole ||
										"No data to display"}
                </p>
              </span>
            </div>
            <div className="VerificationPersonRole">
              <p>
                {userJobPreferences?.culture
                  .split(" ")
                  .splice(0, 50)
                  .join(" ") + "..." ||
									"No data to display"}
              </p>
            </div>
          </div>
        )}
        <hr className="PreferenceDivider" />
        <div className="VerificationProfCert">
          <div className="VerificationProfHeader">
            <h2>Professional Certificate</h2>
          </div>
          <div className="VerificationUploader">
            <div>
              <input
                type="file"
                onChange={handleProfessionalCert}
                accept=".pdf,.epub,.doc,.docx"
              />
            </div>
            <div className="UploaderDetails">
              {selectProfessionalFile ? selectProfessionalFile?.name : <img
                src={uploader}
                alt="uploader"
                width={81}
                height={73}
              />}
              <p id="UploaderInfo">
								Please upload your professional
								certificate
              </p>
              <p>Browse File</p>
            </div>
          </div>
        </div>
        <hr className="PreferenceDivider" />
        <div className="VerificationEduCert">
          <div className="VerificationEduHeader">
            <h2>Education Certificate</h2>
          </div>
          <div className="VerificationUploader">
            <div>
              <input
                type="file"
                onChange={handleEducationalCert}
                accept=".pdf,.epub,.doc,.docx"
              />
            </div>
            <div className="UploaderDetails">
              {selectEducationalFile ? selectEducationalFile?.name : <img
                src={uploader}
                alt="uploader"
                width={81}
                height={73}
              />}
              <p id="UploaderInfo">
								Please upload your professional
								certificate
              </p>
              <p>Browse File</p>
            </div>
          </div>
        </div>
        <div className="VerificationButton">
          <button onClick={handleSubmit}>
						Verify
          </button>
        </div>
        <div className="VerificationInfo">
          <p>
						Don’t have any certificate to upload?
						Proceed to{" "}
            <span>
              <a href="/">profile processing</a>
            </span>{" "}
						Not uploading your certificate will
						affect your job search
          </p>
        </div>
      </div>
    </div>
  );
};

export default Verification;