/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useState, useEffect} from "react";
import "./Preferences.style.scss";
import { useNavigate } from "react-router-dom";
import { SelectField, TextField } from "../../../Materials/InputFields";
import Slider from "@mui/material/Slider";
import { getApi, postWithFileApi } from "../../../Api/server";
import { countryNames } from "../../../data/CountryLists";
import WarningCard from "../../Cards/WarningCard";
import SuccessCard from "../../Cards/SuccessCard";
import { RxCross2 } from "react-icons/rx";

const Preferences = () => {
  const navigate = useNavigate();
  const [rangeValues, setRangeValues] = useState([0, 1000000]);
  const [jobInterest, setJobInterest] = useState("");
  const [jobInterestPreferences, setJobInterestPreferences] = useState([]);
  const [countries, setCountries] = useState("");
  const [country, setCountry] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [currency, setCurrency] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [companySizePreferences, setCompanySizePreferences] = useState([]);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf" || fileType === "application/epub+zip" || fileType === "application/msword") {
        setSelectedFile(file);
        console.log(file);
      } else {
        setMessage("Please select a PDF, EPUB, or DOC file.");
        setIsOpen(true);
      }
    }
  };
	
  const handleSliderChange = (event, newValue) => {
    setRangeValues(newValue);
  };
  const handleJobInterest = (e) => {
    const value = e.target.value;
    setJobInterest(value);
    if (
      jobInterestPreferences &&  jobInterestPreferences.length < 3 &&
			!jobInterestPreferences.includes(value)
    ) {
      setJobInterestPreferences((prevState) => [
        ...prevState,
        value,
      ]);
    }
  };
  const deleteJobPreference = (index) => { 
    if (
      jobInterestPreferences &&
				jobInterestPreferences.length > 0
    ) {
      const jobPreference =
					jobInterestPreferences.filter(
					  (preference, i) => i !== index
					);
      setJobInterestPreferences(jobPreference);
    }
  };
  const handleCountries = (e) => {
    const value = e.target.value;
    setCountry(value);
    if (
      selectedCountries &&
			selectedCountries.length < 4 &&
			!selectedCountries.includes(value)
    ) {
      setSelectedCountries((prevState) => [
        ...prevState,
        value,
      ]);
    }
  };
  const deleteCountry = (index) => {
    if (
      selectedCountries &&
      selectedCountries.length > 0
    )
    {
      const jobPreference =
        selectedCountries.filter(
          (preference, i) => i !== index
        );
      setSelectedCountries(jobPreference);
    }
  };
  const handleCurrency = (e) => {
    const value = e.target.value;
    setCurrency(value);
    
  };
  const handleCompanySize = (e) => {
    const value = e.target.value;
    setCompanySize(value);
    if (
      companySizePreferences &&
			companySizePreferences.length < 2 &&
			!companySizePreferences.includes(value)
    ) {
      setCompanySizePreferences((prevState) => [
        ...prevState,
        value,
      ]);
    }
  };
  const deleteCompanySize = (index) => {
    if (
      companySizePreferences &&
			companySizePreferences.length > 0
    ) {
      const jobPreference =
				companySizePreferences.filter(
				  (preference, i) => i !== index
				);
      setCompanySizePreferences(jobPreference);
    }
  };
  const handleContinue = (e, value) => {
    e.preventDefault();
    if(value === "" || value?.length < 1 || value === null){
      const message = "You have not entered any input. Nothing is saved";
      setMessage(message);
      setIsOpen(true);
    } else
    {
      setMessage("Your input has been saved");
      setIsSuccess(true);
    }
    
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const formFile = new FormData();
    if (selectedCountries.length < 1 || jobInterestPreferences.length < 1 || companySizePreferences.length < 1 )
    {
      setMessage("Required fields are not filled");
      setIsOpen(true);
      return;
    }
    if (currency === "" || priceRange === "0-1000000")
    {
      setMessage("Salary size preference is required");
      setIsOpen(true);
      return;
    }
    const data = {
      jobTypePreferences: jobInterestPreferences,
      salarySizePreference: priceRange,
      companySizePreferences: companySizePreferences,
      jobLocationPreferences: selectedCountries,
      currencyPreference: currency
    };
    formFile.append("file", selectedFile);
    formFile.append("data", JSON.stringify(data));
    const response = await postWithFileApi(
      "e1/job_seeker/profile/job_preference",
      formFile
    );
    if(response?.code === 403){
      navigate("/login");
    } if (response.code !== 200)
    {
      setMessage(response?.message);
      setIsOpen(true);
      return;
    } if (response?.code === 200)
    {
      setMessage("User CV saved successfully");
      setIsSuccess(true);
      navigate("/job_seeker/profile/culture");
    }
    
  };
  const handleTextChange = (e) => {
    const value = e.target.value;
    setPriceRange(value);
  };

  useEffect(() => {
    countryNames
      .then((result) => {
        setCountries(result?.sort());
      })
      .catch((error) => console.log(error));
  }, [countryNames]);

  useEffect(() => { 
    if(rangeValues){
      setPriceRange(rangeValues.join(",").replace(",", "-"));
    }
  }, [rangeValues]);

  useEffect(() => {
    const userData = async () => {
      let response = await getApi(
        "e1/job_seeker/profile/job_preferences"
      );
      if (!response)
      {
        return;
      } else if (response?.code === 403)
      {
        navigate("/login");
      } else if (response.code !== 200)
      {
        return;
      }else{
        console.log(response?.data);
        const data = response?.data;
        setCompanySizePreferences(data?.companySizePreferences);
        setJobInterestPreferences(data.jobTypePreferences);
        setCurrency(data.currencyPreference);
        setSelectedCountries(data?.jobLocationPreferences);
        setPriceRange(data?.salarySizePreference);
      }
    };
    userData();
  }, []);

  return (
    <div className="PreferencesContainer">
      {isOpen && (
        <WarningCard
          isOpen={isOpen}
          onClose={setIsOpen}
          message={message}
        />
      )}
      {isSuccess && (
        <SuccessCard
          isOpen={isSuccess}
          onClose={setIsSuccess}
          message={message}
        />
      )}
      <div className="PreferencesInnerContainer">
        <div className="PrefenceCvUpload">
          <h2 className="PreferenceTitle">
						Upload CV
          </h2>
          <div className="PreferenceCvField">
            <form
              onSubmit={(e) =>
                handleContinue(e, selectedFile)
              }>
              <div>
                <label htmlFor="cv">
									Upload CV
                </label>
                <input
                  type="file"
                  name="cv"
                  accept=".pdf,.epub,.doc,.docx"
                  onChange={handleFileChange}
                />
              </div>
              <button type="submit">
								Submit
              </button>
            </form>
          </div>
        </div>
        <hr className="PreferenceDivider" />
        <div className="PreferenceJobInterest">
          <h2 className="PreferenceTitle">
						Job Interest
          </h2>
          <div className="PreferenceJobField">
            <form
              onSubmit={(e) =>
                handleContinue(
                  e, jobInterestPreferences
                )
              }>
              <SelectField
                values={[
                  "Contract",
                  "Full Time",
                  "Internship",
                ]}
                onChange={handleJobInterest}
                selectedValue={jobInterest}
                label="Select Job Type"
                className="PreferenceSelectField"
                id="PreferenceSelectField"
                
              />
              <div className="PreferenceSelectChoice">
                {jobInterestPreferences &&
									jobInterestPreferences.length >
										0 && (
                  <ul className="JobPreferencesSection">
                    {jobInterestPreferences.map(
                      (preference, index) => (
                        <li key={index}>
                          {preference}
                          <RxCross2
                            className="CancelIcon"
                            onClick={() =>
                              deleteJobPreference(
                                index
                              )
                            }
                          />
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
              <button type="submit">Save</button>
            </form>
          </div>
        </div>
        <hr className="PreferenceDivider" />
        <div className="PreferenceJobLocation">
          <h2 className="PreferenceTitle">
						Job Location Preference
          </h2>
          <div className="PreferenceLocationField">
            <form
              onSubmit={(e) =>
                handleContinue(e, selectedCountries)
              }>
              <SelectField
                values={[...countries]}
                onChange={handleCountries}
                selectedValue={country}
                label="Select Country"
                className="PreferenceSelectField"
                id="PreferenceSelectField"
              />
              <div className="PreferenceSelectChoice">
                {selectedCountries &&
									selectedCountries.length >
										0 && (
                  <ul className="JobPreferencesSection">
                    {selectedCountries.map(
                      (preference, index) => (
                        <li key={index}>
                          {preference}
                          <RxCross2
                            className="CancelIcon"
                            onClick={() =>
                              deleteCountry(
                                index
                              )
                            }
                          />
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
              <button type="submit">Save</button>
            </form>
          </div>
        </div>
        <hr className="PreferenceDivider" />
        <div className="PreferenceSalary">
          <h2 className="PreferenceTitle">
						Salary Size Preference
          </h2>
          <div className="PreferenceSalaryField">
            <form
              onSubmit={(e) =>
                handleContinue(e, priceRange)
              }>
              <div>
                <SelectField
                  values={[
                    "USD($)",
                    "Naira(#)",
                    "Yen",
                  ]}
                  onChange={handleCurrency}
                  selectedValue={currency}
                  label="Select Currency"
                  className="PreferenceSelectFieldOne"
                  id="PreferenceSelectFieldOne"
                />
                <TextField
                  name="amount"
                  type="text"
                  value={priceRange}
                  label="Enter Amount"
                  onChange={handleTextChange}
                  className="PreferenceTextField"
                  id="PreferenceTextField"
                />
              </div>
              <div className="PreferenceSalarySlider">
                <p>Salary Range</p>
                <Slider
                  value={rangeValues}
                  onChange={handleSliderChange}
                  valueLabelDisplay="auto"
                  size="small"
                  aria-labelledby="range-slider"
                  min={0}
                  max={1000000}
                  marks={[
                    { value: 0, label: "0" },
                    {
                      value: 1000000,
                      label: "1,000,000",
                    },
                  ]}
                />
              </div>
              <button type="submit">Save</button>
            </form>
          </div>
        </div>
        <hr className="PreferenceDivider" />
        <div className="PreferenceCompanySize">
          <h2 className="PreferenceTitle">
						Company Size Preference
          </h2>
          <div className="PreferenceCompanySize">
            <form
              onSubmit={(e) =>
                handleContinue(
                  e, companySizePreferences
                )
              }>
              <SelectField
                values={[
                  "Seed (1-10 employees)",
                  "Early (11-50 employees)",
                  "Early (11-50 employees)",
                ]}
                onChange={handleCompanySize}
                label="Select your preferred company Size"
                selectedValue={companySize}
                className="PreferenceSelectField"
                id="PreferenceSelectField"
              />
              <div className="PreferenceSelectChoice">
                {companySizePreferences &&
									companySizePreferences.length >
										0 && (
                  <ul className="JobPreferencesSection">
                    {companySizePreferences.map(
                      (preference, index) => (
                        <li key={index}>
                          {preference}
                          <RxCross2
                            className="CancelIcon"
                            onClick={() =>
                              deleteCompanySize(
                                index
                              )
                            }
                          />
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
              <button type="submit">Save</button>
            </form>
          </div>
        </div>
        <hr className="PreferenceDivider" />
        <div className="PreferenceProceed">
          <button onClick={handleSubmit}>
						Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preferences;