import React from "react";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { PiPencilSimple } from "react-icons/pi";
import { IoPeopleOutline } from "react-icons/io5";
import { IoLocationOutline } from "react-icons/io5";
import { FiDollarSign } from "react-icons/fi";

import "./RecruiterJobDetails.style.scss";

const RecruiterJobInfo = () => {
  return (
    <div className="JobDetailsContainer">
      <div className="JobDetailsContent">
        <div className="JobDetailsHeading">
          <Link
            to={"/recruiter/profile/jobs"}
            style={{textDecoration: "none"}}
            className="JobDetailsNavigation"
          >
            <IoArrowBack className="JobBackIcon" />
            <p>Associate Product Designer</p>
          </Link>
          <Link
            to={"/recruiter/profile/jobs/job/edit"}
            className="JobDetailsEdit"
          >
            <PiPencilSimple className="JobDetailsEditIcon" />
            <p>Edit</p>
          </Link>
        </div>
        <div className="JobDetailsCompanyInfo">
          <h2>About Company</h2>
          <div className="JobDetailsIconInfo">
            <span>
              <IoPeopleOutline className="JobDetailsIcon" />
              <p>10 - 50 Employess</p>
            </span>
            <span>
              <IoLocationOutline className="JobDetailsIcon" />
              <p>Remote Only</p>
            </span>
            <span>
              <FiDollarSign className="JobDetailsIcon" />
              <p>2200k - 5000k</p>
            </span>
          </div>
          <p>
            Our mission:At Included Health, we’re on a mission to raise the standard of healthcare for everyone. 
            We’re a new kind of healthcare company, focused on breaking down barriers to provide high-quality care for every person in every community — no matter where they are in their health journey or what type of care they need. 
            We offer guidance, advocacy, and access to personalized care for urgent care, primary care, behavioral health, and specialty care. 
            We’re especially focused on increasing access to high-quality and culturally competent providers that can raise care equity for the  Black Americans and other communities who have been historically disenfranchised and underserved in the US healthcare system.
          </p>
        </div>
        <div className="JobDetailsJobInfo">
          <h4>What you&apos;ll do</h4>
          <p>
          You will work with product and engineering teams to successfully communicate, 
          evolve and execute a strong product design standard that showcases Brex as a leader in financial innovation. 
          You are a crucial component of our company’s growth and product launches through your contributions on strategic direction, 
          high visual standards and focus on user needs. You take on an owner’s mentality, 
          taking pride in your work as well as your team’s work and view what you do as a part-owner and builder of Brex.
          </p>
          <h4>Responsibilities</h4>
          <ul>
            <li>Partner with the leadership team to successfully communicate our product strategy, process and lead cross-functional meetings to align teams</li>
            <li>Creative thinking and strategic execution. You will be asked to think outside of the box, and partner with our product and eng teams to push innovation forward</li>
            <li>Will work across the entire business stack and make an impact at different stages of the product: building vision & strategy, ensuring all the loose ends are tied up when executing, and making sure the operational components to support onboarding are in place, not just the product</li>
            <li>Grow and scale the design team across the design org</li>
            <li>Push forward and maintain a high design bar and level of execution</li>
          </ul>
          <h4>Requirements</h4>
          <ul>
            <li>Strong written and verbal communication skills, with a talent for detailed articulations of strategy, process, and leading cross-functional meetings to align teams</li>
            <li>Experience working cross-functionally with engineering, marketing, design and product management teams</li>
            <li>Strong ability to craft a clear narrative and paint a vision that communicates and aligns teams</li>
            <li>Passion for team building and development</li>
            <li>6+ years of design experience</li>
          </ul>
          <h4>Bonus Points</h4>
          <ul>
            <li>Experience working with tools including but not limited to Figma, Jira, Looker, Slack, Google Apps, Salesforce, Chrome Console</li>
            <li>Experience working on products in both B2B and B2C companies</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RecruiterJobInfo;