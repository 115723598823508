export const CoachesProfile = [
  {
    name: "John Smith",
    rate: "4.3",
    yearsOfExperience: "3",
    workStatus: "pending",
    image: "/icons/Jobseeker/coach1.png",
    About:
      "As a seasoned software coach, I empower individuals to master coding and software development through personalized guidance and hands-on training. With a wealth of industry experience, I specialize in simplifying complex concepts, fostering a deep understanding of programming languages, and cultivating problem-solving skills. My coaching approach combines theoretical knowledge with practical application, ensuring students not only grasp fundamental principles but also gain the confidence to tackle real-world coding challenges. Through a supportive and collaborative teaching style, I inspire continuous learning and skill refinement, guiding aspiring developers towards success in the dynamic world of software engineering.",
    AreasOfExpertise: ["Python", "Django", "Bash Scripting"],
    NumOfCoachingSection: 4,
  },
  {
    name: "Abraham Lincs",
    rate: "4.5",
    yearsOfExperience: "6",
    workStatus: "Verified",
    image: "/icons/Jobseeker/coach2.png",
    About:
      "As a UI/UX coach, I specialize in shaping the next generation of user-centric designers. Leveraging my extensive expertise, I guide students through the intricacies of user interface and experience design, emphasizing a harmonious blend of aesthetics and functionality. My coaching method focuses on practical application, honing skills in wireframing, prototyping, and user testing. I foster a creative and analytical mindset, empowering designers to craft visually compelling and intuitively navigable digital experiences. With a commitment to staying abreast of industry trends, I inspire a forward-thinking approach to UI/UX that prepares my students for success in the ever-evolving landscape of digital design.",
    AreasOfExpertise: ["UI/UX", "Prototyping", "App Testing"],
    NumOfCoachingSection: 3,
  },
  {
    name: "Muhammad Abubakar",
    rate: "4.8",
    yearsOfExperience: "10",
    workStatus: "verified",
    image: "/icons/Jobseeker/coach3.png",
    About:
      "As an administration coach, I specialize in equipping individuals with essential skills for effective organizational management. Leveraging my experience, I guide students through key administrative principles, emphasizing efficiency, communication, and strategic planning. My coaching method centers on practical application, honing skills in time management, decision-making, and team coordination. I foster a proactive and adaptable mindset, empowering administrators to navigate complex challenges with confidence. Committed to staying current with industry best practices, I instill a forward-thinking approach to administration that prepares my students for success in dynamic professional environments.",
    AreasOfExpertise: ["Marketing", "Administration"],
    NumOfCoachingSection: 10,
  },
  {
    name: "Zainab Ndaliman",
    rate: "5.0",
    yearsOfExperience: "12",
    workStatus: "Verified",
    image: "/icons/Jobseeker/coach4.png",
    About:
      "As an instructional coach, I specialize in cultivating effective teaching practices. Drawing on my expertise, I guide educators in honing pedagogical skills, fostering engaging learning environments, and promoting student-centered instruction. My coaching approach prioritizes practical application, focusing on lesson planning, effective communication, and adaptability in the classroom. I instill a passion for lifelong learning and a commitment to student success. With a keen understanding of evolving educational landscapes, I inspire educators to be dynamic, empathetic, and impactful, shaping them into effective and influential teachers.",
    AreasOfExpertise: ["Teaching", "Traveling & Tour Guide", "Counseling"],
    NumOfCoachingSection: 15,
  },
  {
    name: "Ester Babatunde",
    rate: "4.8",
    yearsOfExperience: "10",
    workStatus: "pending",
    image: "/icons/Jobseeker/coach5.png",
    About:
      "As an instructional coach, I specialize in cultivating effective teaching practices. Drawing on my expertise, I guide educators in honing pedagogical skills, fostering engaging learning environments, and promoting student-centered instruction. My coaching approach prioritizes practical application, focusing on lesson planning, effective communication, and adaptability in the classroom. I instill a passion for lifelong learning and a commitment to student success. With a keen understanding of evolving educational landscapes, I inspire educators to be dynamic, empathetic, and impactful, shaping them into effective and influential teachers.",
    AreasOfExpertise: ["UI/UX", "Designs & Prototypes", "Simple Animation"],
    NumOfCoachingSection: 5,
  },
  {
    name: "Mudathir Salahudeen",
    rate: "4.8",
    yearsOfExperience: "8",
    workStatus: "verified",
    image: "/icons/Jobseeker/coach6.png",
    About:
      "As an instructional coach, I specialize in cultivating effective teaching practices. Drawing on my expertise, I guide educators in honing pedagogical skills, fostering engaging learning environments, and promoting student-centered instruction. My coaching approach prioritizes practical application, focusing on lesson planning, effective communication, and adaptability in the classroom. I instill a passion for lifelong learning and a commitment to student success. With a keen understanding of evolving educational landscapes, I inspire educators to be dynamic, empathetic, and impactful, shaping them into effective and influential teachers.",
    AreasOfExpertise: ["Scrum Mastery", "Data Science", "Python"],
    NumOfCoachingSection: 4,
  },
];

export const licenses = [
  {
    image: "/icons/course-icon1.png",
    title: "Fundamentals of Project Management",
    state: "Pending",
    date: "Apr 2023"
  },
  {
    image: "/icons/course-icon2.png",
    title: "Scrum Master Certification",
    state: "Issued",
    date: "Aug 2022"
  },
  {
    image: "/icons/course-icon3.png",
    title: "Principles of Software Design",
    state: "Issued",
    date: "Jan 2018"
  }
];