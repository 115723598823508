import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import PropTypes from "prop-types";
import Avatar from "../../../images/Avatar1.png";

import "./MenteeDetailsModal.style.scss";

const MenteeDetailsModal = ({ onClose }) => {
  return (
    <div className="DetailsModalContainer">
      <div className="DetailModal">
        <div className="TopModalSection">
          <p>Mentees Detail</p>
          <IoCloseCircleOutline className="ModalCloseIcon" onClick={onClose} />
        </div>
        <div className="BottomModalSection">
          <div className="ModalAvatarWrapper">
            <img src={Avatar} alt="Avatar" />
          </div>
          <div className="MenteeModalDetails">
            <h3>John Melody Doe</h3>
            <div className="MenteeParticulars">
              <div className="ParticularsKeys">
                <p>Phone Number</p>
                <p>Email Address</p>
                <p>Course Enrolled</p>
                <p>Sessions</p>
              </div>
              <div className="ParticularsValues">
                <p>+234 (0)813 782 003 8</p>
                <p>Johnmelodydoe@gmail.com</p>
                <p>Product Designer</p>
                <p>hours:{" "} <span>300</span>/500</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
MenteeDetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MenteeDetailsModal;
