const jobDetails = 
  {
    jobTitle: "Project Manager",
    company: "Amazon",
    aboutJob:
      "We are a growth stage company that creates software solutions combining lean principles, predictive and prescriptive analytics, and machine learning to transform hospital and infusion center operations. More than 180 health systems and over 685 hospitals across 49 states rely on our award-winning products to increase patient access, decrease wait times, and reduce healthcare delivery costs. We have raised more than $300 million from top-tier investors such as Bain Capital, Insight Partners, and Goldman Sachs. We have been named among the top 100 AI companies in the world.",
    jobInstructions:
      "You will work with product and engineering teams to successfully communicate, evolve and execute a strong product design standard that showcases Brex as a leader in financial innovation. You are a crucial component of our company’s growth and product launches through your contributions on strategic direction, high visual standards and focus on user needs. You take on an owner’s mentality, taking pride in your work as well as your team’s work and view what you do as a part-owner and builder of Brex.",
    jobResponsibilities: [
      "Partner with the leadership team to successfully communicate our product strategy, process and lead cross-functional meetings to align teams",
      "Creative thinking and strategic execution. You will be asked to think outside of the box, and partner with our product and eng teams to push innovation forward",
      "Creative thinking and strategic execution. You will be asked to think outside of the box, and partner with our product and eng teams to push innovation forward",
    ],
    jobRequirements: [
      "Strong written and verbal communication skills, with a talent for detailed articulations of strategy, process, and leading cross-functional meetings to align teams",
      "Experience working cross-functionally with engineering, marketing, design and product management teams",
      "Passion for team building and development",
      "6+ years of design experience",
    ],
    bonusPoints: [
      "Experience working with tools including but not limited to Figma, Jira, Looker, Slack, Google Apps, Salesforce, Chrome Console",
      "Experience working on products in both B2B and B2C companies",
    ],
  };
const jobInformation = {
  noOfEmployees: "10-50",
  location: "Lagos, Nigeria",
  website: "ether.elevayt.com",
  companyDetails:
    "We are a growth stage company that creates software solutions combining lean principles, predictive and prescriptive analytics, and machine learning to transform hospital and infusion center operations. More than 180 health systems and over 685 hospitals across 49 states rely on our award-winning products to increase patient access, decrease wait times, and reduce healthcare delivery costs",
  founders: ["John Doe", "Peter Parker"],
  coverImages: ["/icons/cover.png", "/icons/coverImage.png"],
  founderPostions: ["CEO", "Assistant Manager"],
  handles: ["linkedin.com", "twitter.com", "instagram.com", "facebook.com"],
  companyCulture: ["100% Remote"],
};
export const workHistoryData = [
  {
    icon: "/icons/certificate.png",
    title: "Project Manager",
    company: "Amazon",
    status: "pending",
    applicationDays: "Applied 2 days ago",
    applicationImage: "/icons/images/Avatar1.png",
    applicationCV: "Caxton_Cynthia_Cv",
    applicationCover: "Caxton_Cynthia_Cover",
    jobDetails: jobDetails,
    jobInformation: jobInformation,
    applicationInfo:
      "Amazon does not transmit status updates to us for this application. You will get updates on your application status from them.",
    applicationLetter: ` Hello Masha, \n Cynthiacaxton.super.site \n I am thrilled to express my interest in the Product design role within LeanTaaSAs a seasoned professional, I have effectively collaborated with cross-functional teams, including product managers, developers, and stakeholders, to deliver cutting-edge designs that align with business objectives and user needs. \n
    My expertise extends to conducting in-depth user research, creating user personas, journey maps, and wireframes, and conducting usability tests to iterate and improve designs continuously.One of my key strengths is my proficiency in designing for various platforms, including web, mobile, and desktop applications. \n 
    I take pride in being an advocate for the end-users, consistently striving to exceed their expectations and deliver products that leave a lasting positive impact..Thank you for considering my application. I am looking forward to the opportunity to discuss my qualifications further in an interview.`,
  },
  {
    icon: "/icons/certificate.png",
    title: "UI/UX Designer",
    company: "Apple",
    status: "pending",
    applicationDays: "Applied 2 days ago",
    applicationImage: "/icons/images/Avatar1.png",
    jobDetails: jobDetails,
    jobInformation: jobInformation,
    applicationCV: "Caxton_Cynthia_Cv",
    applicationCover: "Caxton_Cynthia_Cover",
    applicationInfo:
      "Amazon does not transmit status updates to us for this application. You will get updates on your application status from them.",
    applicationLetter: ` Hello Masha, Cynthiacaxton.super.site I am thrilled to express my interest in the Product design role within LeanTaaSAs a seasoned professional, I have effectively collaborated with cross-functional teams, including product managers, developers, and stakeholders, to deliver cutting-edge designs that align with business objectives and user needs. 
    My expertise extends to conducting in-depth user research, creating user personas, journey maps, and wireframes, and conducting usability tests to iterate and improve designs continuously.One of my key strengths is my proficiency in designing for various platforms, including web, mobile, and desktop applications. 
    I take pride in being an advocate for the end-users, consistently striving to exceed their expectations and deliver products that leave a lasting positive impact..Thank you for considering my application. I am looking forward to the opportunity to discuss my qualifications further in an interview.`,
  },
];


