export const company = {
  about:
    "A leading tech company specializing in software development and innovative tech solutions. We strive to create groundbreaking software products that transform industries and improve people's lives. With a focus on cutting-edge technologies and a talented team of engineers, we are committed to delivering excellence in every project.",
  department: "Engineering",
  size: "100-500 employees",
};

export const job = {
  title: "Senior Software Engineer",
  location: "Lagos, Nigeria",
  type: "Full-time",
  salary: "$80,000 - $120,000 per year",
  hours: "40 hours per week",
  postDuration: "April 30, 2024 - May 30, 2024", // Date when job post was made to when it will be removed/expires
  experience: "Senior",
  skills: ["JavaScript", "React", "Node.js", "MongoDB"],
  description:
    "We are seeking a talented Senior Software Engineer to join our team and lead the development of cutting-edge software solutions. The ideal candidate should have strong expertise in JavaScript, React, Node.js, and MongoDB, with a proven track record of delivering high-quality software products.",
};

export const relevance = {
  Weight: {
    Criteria: ["Education", "Experience", "Skills"],
    Weights: [40, 30, 30], // Values in percent
    totalWeights: 100,
  },
  TargetScore: {
    maxScore: 100,
    targetScore: 80,
  },
  Quantify: {
    education: {
      "Doctorate": 50,
      "Master's Degree": 40,
      "Bachelor's Degree": 30,
      "Associate Degree": 20,
      "High School Diploma": 10,
    },
    experience: {
      "0 or Entry level": 20,
      "1 to 2 or Intermediate": 30,
      "3 to 5 or Experienced" : 50,
    },
    skills: {
      "PHP": "Beginner",
      "Java" : "Advanced",
      "React" : "Intermediate"
    },
  },
};
