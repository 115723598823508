import React from "react";
import "./JsCoaches.style.scss";
import { BsFillStarFill } from "react-icons/bs";
import placeholderImage from "../../../static/images/icons/placeholder.png";
const MyCoaches = [];

const JsMyCoach = () => {
  return (
    <div className="MyCoachContainer">
      <div className="MyCoachInnerContainer">
        {MyCoaches.length === 0 ? (
          <div className="MyCoachEmptyContainer">
            <img
              src={placeholderImage}
              alt="placeholder"
              width={170}
              height={170}
            />
            <p>You have not started a mentorship session with a coach</p>
            <button>Explore Coaches</button>
          </div>
        ) : (
          MyCoaches &&
          MyCoaches.map((item, index) => {
            return (
              <div className="AllCoachesClasses" key={index}>
                <img
                  src={item.image}
                  alt="coachImage"
                  height={300}
                  width={300}
                />
                <div className="CoachesName">
                  <h2>{item.name}</h2>
                  <p>
                    <span>
                      <BsFillStarFill className="rating" />
                    </span>{" "}
                    {item.rate}
                  </p>
                </div>
                <div className="coachesExperience">
                  <p>{item.yearsOfExperience} years of experience</p>
                  <span>{item.workStatus}</span>
                </div>
                <a href="/">
                  <button>Start Connection</button>
                </a>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default JsMyCoach;
