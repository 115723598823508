import React from "react";
import { SlSocialLinkedin } from "react-icons/sl";
import etherLogo from "../../../static/images/logo/Ether Logo.png";
import { MdOutlineCancel } from "react-icons/md";
import {HiOutlineMail } from "react-icons/hi";
import editIcon from "../../../static/images/icons/edit.png";
import PropTypes from "prop-types";
import "./jsprofile.style.scss";

const Card = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};
export const GetProfileContact = ({ handleCancelContact, className }) => {
  return (
    <Card className={className}>
      <div className="CardSectionOne">
        <p>Siddiq Lushon</p>
        <MdOutlineCancel onClick={handleCancelContact} />
      </div>
      <div className="CardSectionTwo">
        <p>Contact Info</p>
        <img src={editIcon} alt="" width={25} height={25} />
      </div>
      <div className="CardSectionThree">
        <div className="ProfileLogo">
          <img src={etherLogo} alt="" width={40} height={40} />
        </div>
        <div className="ProfileAddresses">
          <h3>Your Address</h3>
          <a href="/job_seeker/profile">ether.com/ether/siddiq-lushon-jff</a>
        </div>
      </div>
      <div className="CardSectionThree">
        <div className="ProfileLogo">
          <HiOutlineMail />
        </div>
        <div className="ProfileAddresses">
          <h3>Your Email</h3>
          <p>siddiqLush@gmail.com</p>
        </div>
      </div>
      <div className="CardSectionThree">
        <div className="ProfileLogo">
          <SlSocialLinkedin />
        </div>
        <div className="ProfileAddresses">
          <h3>LinkedIn</h3>
          <a href="/job_seeker/profile">linkedIn.com</a>
        </div>
      </div>
    </Card>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

GetProfileContact.propTypes = {
  handleCancelContact: PropTypes.func,
  className: PropTypes.string,
};
