import React, { useState } from "react";
import "./AddResource.style.scss";
import { LuPlusCircle } from "react-icons/lu";
import { IoPersonSharp } from "react-icons/io5";

const AddResources = () => {
  const initialState = {
    courseDescription: "",
    courseExpectation: "",
    name: "",
    designation: "",

  };
  const [state, setState] =
		useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  return (
    <div className="AddResourceContainer">
      <div className="CourseDetails">
        <h1>Course Description</h1>
        <textarea
          className="TextField"
          placeholder="Add Course Description"
          value={state.courseDescription}
          name="courseDescription"
          onChange={handleInputChange}>
					Add Course Description
        </textarea>
      </div>
      <hr className="LineBreaker" />
      <div className="CourseExpectation">
        <h1>Course Expextation</h1>
        <h3>
					What will be the outcome of users taking
					this course?
        </h3>
        <textarea
          className="TextField"
          placeholder="Add Course Expectation"
          value={state.courseExpectation}
          name="courseExpectation"
          onChange={handleInputChange}></textarea>
      </div>
      <hr className="LineBreaker" />
      <div className="CourseModule">
        <h1>Course Modules</h1>
        <div className="CourseModuleWrapper">
          <p>Add a course module</p>
          <LuPlusCircle className="CourseModuleIcon" />
        </div>
      </div>
      <div className="CourseInstructor">
        <h1>Course Instructor</h1>
        <div className="CourseInstructorProfileWrapper">
          <div className="CourseInstructorProfile">
            <IoPersonSharp className="CourseImage" />
            <input
              type="file"
              className="CourseImageInput"
            />
            <button id="UploadPhoto">
							Upload Photo
            </button>
            <button className="RemovePhoto">
							Remove
            </button>
          </div>
          <div className="CourseInstructorCondition">
            <p>Image requirements</p>
            <ol>
              <li>Min. 400 x 400px</li>
              <li>Max. 2MB</li>
              <li>Your face or company logo</li>
            </ol>
          </div>
        </div>
        <div className="InstructorName">
          <div className="InputField">
            <label htmlFor="name">
							Full Name:
            </label>
            <input
              type="text"
              value={state.name}
              onChange={handleInputChange}
              id="InputField"
              name="name"
              placeholder="Placeholder"
            />
          </div>
          <div className="InputField">
            <label htmlFor="designation">
							Designation:
            </label>
            <input
              type="text"
              value={state.designation}
              onChange={handleInputChange}
              id="InputField"
              name="designation"
              placeholder="Placeholder"
            />
          </div>
        </div>
      </div>
      <div className="NextButton">
        <button>Next</button>
      </div>
    </div>
  );
};

export default AddResources;
