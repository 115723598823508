import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { DashboardIcon, SettingsIcon, BooksIcon } from "../../../svgs/SvgComponent";
import { IoPeopleOutline } from "react-icons/io5";
import "./AdminSideBar.style.scss";

const AdminSideBar = () => {
  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate();

  const hoverStyle = {
    borderBottom: "2px solid var(--Foundation-PRI-pri-500, #2A53A9)",
    fontStyle: "bolder",
    color: "var(--Foundation-PRI-pri-500, #2A53A9)",
    padding: "0px 10px",
  };

  const handleButtonClick = (button) => {
    setIsActive(button);
    if (isActive === "dashboard") {
      navigate("/admin/profile/dashboard");
    }
    if (isActive === "users") {
      navigate("/admin/profile/users");
    }
    if (isActive === "resources") {
      navigate("/admin/profile/resources");
    }
    if (isActive === "settings") {
      navigate("/admin/profile/settings");
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log(currentPath);
    switch (currentPath) {
    case "/admin/profile/dashboard":
      setIsActive("dashboard");
      break;
    case "/admin/profile/users":
      setIsActive("users");
      break;
    case "/admin/profile/resources":
      setIsActive("resources");
      break;
    case "/admin/profile/settings":
      setIsActive("settings");
      break;
      // Add cases for other pages and button names as needed
    default:
      setIsActive(null);
      break;
    }
  }, [history]);
  return (
    <div className='SideBarContainer'>
      <div className="InnerContainer">
        <div className="AdminSideLinks">
          <Link
            to={"/admin/profile/dashboard"}
            className="AdminSideLink"
            onClick={() => handleButtonClick("dashboard")}
            style={isActive === "dashboard" ? hoverStyle : { fontSize: "14px" }}
          >
            <DashboardIcon stroke={isActive === "dashboard" ? "#2A53A9" : "#9BA6BC"} alt="dashboard" width={24} height={24} />
            <span>Dashboard</span>
          </Link>
        </div>
        <div className="AdminSideLinks">
          <Link
            to={"/admin/profile/users"}
            className="AdminSideLink"
            onClick={() => handleButtonClick("users")}
            style={isActive === "users" ? hoverStyle : { fontSize: "14px" }}
          >
            <IoPeopleOutline  stroke={isActive === "users" ? "#2A53A9" : "#9BA6BC"}  alt="users" style={{height: "24px", width: "24px"}} />
            <span>Users</span>
          </Link>
        </div>
        <div className="AdminSideLinks">
          <Link
            to={"/admin/profile/resources"}
            className="AdminSideLink"
            onClick={() => handleButtonClick("resources")}
            style={isActive === "resources" ? hoverStyle : { fontSize: "14px" }}
          >
            <BooksIcon stroke={isActive === "resources" ? "#2A53A9" : "#9BA6BC"} alt="resources" width={24} height={24} />
            <span>Resources</span>
          </Link>
        </div>
        <div className="AdminSideLinks">
          <Link
            to={"/admin/profile/settings"}
            className="AdminSideLink"
            onClick={() => handleButtonClick("settings")}
            style={isActive === "settings" ? hoverStyle : { fontSize: "14px" }}
          >
            <SettingsIcon stroke={isActive === "settings" ? "#2A53A9" : "#9BA6BC"} alt="settings" width={24} height={24} />
            <span>Settings</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
