import React from "react";
import SettingsSideBar from "../SettingsSideBar/SettingsSideBar";
import SettingsNavBar from "../SettingsNavBar/SettingsNavBar";
import Proptypes from "prop-types";

import "./SettingsLayout.style.scss";

const SettingsLayout = ({children}) => {
  return (
    <div className="SettingsLayoutContainer">
      <div className="LayoutSegmentOne">
        <SettingsNavBar />
      </div>
      <div className="LayoutSegmentTwo">
        <SettingsSideBar />
        <div className="LayoutContent">{children}</div>
      </div>
    </div>
  );
};
SettingsLayout.propTypes = {
  children: Proptypes.node
};

export default SettingsLayout;
