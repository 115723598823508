import React from "react";
import "./ProfileDetail.style.scss";
import { GoInfo } from "react-icons/go";
import PropTypes from "prop-types";


const WelcomeCard = ({ setIsNewUser }) => {
  
  const cancelModal = () =>
  {
    setIsNewUser(false);
  };

  return (
    <div className="JsWelcomeCard">
      <div className="WelcomeCardContainer">
        <GoInfo className="WelcomeIcon"/>
        <h1>Profile Update</h1>
        <p>
					Welcome to Ether, please update your
					profile to proceed to your dashboard
        </p>
        <button onClick={cancelModal}>Proceed</button>
      </div>
    </div>
  );
};

WelcomeCard.propTypes = {
  setIsNewUser: PropTypes.func,
};

export default WelcomeCard;