import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import "./RecruiterSideBar.style.scss";
import { ApplyIcon, MessageIcon, JobIcon } from "../../../svgs/svg";
import { CalendarIcon, HomeIcon } from "../../../svgs/SvgComponent";

const RecruiterSideBar = () => {
  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate();

  const hoverStyle = {
    borderBottom: "2px solid var(--Foundation-PRI-pri-500, #2A53A9)",
    fontStyle: "bolder",
    color: "var(--Foundation-PRI-pri-500, #2A53A9)",
    padding: "0px 10px",
  };

  const handleButtonClick = (button) => {
    setIsActive(button);
    if (isActive === "home") {
      navigate("/recruiter/profile/home");
    }
    if (isActive === "applicants") {
      navigate("/recruiter/profile/applicants");
    }
    if (isActive === "jobs") {
      navigate("/recruiter/profile/jobs");
    }
    if (isActive === "messages") {
      navigate("/recruiter/profile/messages");
    }
    if (isActive === "calendar") {
      navigate("/recruiter/profile/calendar");
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log(currentPath);
    switch (currentPath) {
    case "/recruiter/profile/home":
      setIsActive("home");
      break;
    case "/recruiter/profile/applicants":
      setIsActive("applicants");
      break;
    case "/recruiter/profile/jobs":
      setIsActive("jobs");
      break;
    case "/recruiter/profile/messages":
      setIsActive("messages");
      break;
    case "/recruiter/profile/calendar":
      setIsActive("calendar");
      break;
      // Add cases for other pages and button names as needed
    default:
      setIsActive(null);
      break;
    }
  }, [history]);
  return (
    <div className='SideBarContainer'>
      <div className="InnerContainer">
        <div className="RecruiterSideLinks">
          <Link
            to={"/recruiter/profile/home"}
            className="RecruiterSideLink"
            onClick={() => handleButtonClick("home")}
            style={isActive === "home" ? hoverStyle : { fontSize: "14px" }}
          >
            <HomeIcon stroke={isActive === "home" ? "#2A53A9" : "#9BA6BC"} alt="home" width={24} height={24} />
            <span>Home</span>
          </Link>
        </div>
        <div className="RecruiterSideLinks">
          <Link
            to={"/recruiter/profile/applicants"}
            className="RecruiterSideLink"
            onClick={() => handleButtonClick("applicants")}
            style={isActive === "applicants" ? hoverStyle : { fontSize: "14px" }}
          >
            <ApplyIcon  stroke={isActive === "applicants" ? "#2A53A9" : "#9BA6BC"}  alt="applicants" style={{height: "24px", width: "24px"}} />
            <span>Applicants</span>
          </Link>
        </div>
        <div className="RecruiterSideLinks">
          <Link
            to={"/recruiter/profile/jobs"}
            className="RecruiterSideLink"
            onClick={() => handleButtonClick("jobs")}
            style={isActive === "jobs" ? hoverStyle : { fontSize: "14px" }}
          >
            <JobIcon stroke={isActive === "jobs" ? "#2A53A9" : "#9BA6BC"} alt="jobs" width={24} height={24} />
            <span>Jobs</span>
          </Link>
        </div>
        <div className="RecruiterSideLinks">
          <Link
            to={"/recruiter/profile/messages"}
            className="RecruiterSideLink"
            onClick={() => handleButtonClick("messages")}
            style={isActive === "messages" ? hoverStyle : { fontSize: "14px" }}
          >
            <MessageIcon stroke={isActive === "messages" ? "#2A53A9" : "#9BA6BC"} alt="messages" width={24} height={24} />
            <span>Messages</span>
          </Link>
        </div>
        <div className="RecruiterSideLinks">
          <Link
            to={"/recruiter/profile/calendar"}
            className="RecruiterSideLink"
            onClick={() => handleButtonClick("calendar")}
            style={isActive === "calendar" ? hoverStyle : { fontSize: "14px" }}
          >
            <CalendarIcon stroke={isActive === "calendar" ? "#2A53A9" : "#9BA6BC"} alt="messages" width={24} height={24} />
            <span>Calendar</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecruiterSideBar;
