/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./coachsubpages.style.scss";
import CoachChattList from "./CoachChatList";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useMyContext } from "../../../Utils/ContextProvider";
import CoachRoadmapList from "./CoachRoadmapList";
import CoachProgressList from "./CoachProgressList";

const CoachMessages = ({children}) => {
  const [chatPicked, setChatPicked] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const {updateMyData} = useMyContext();
  const location = window.location.href;
  const pathway = location.split("/");
  const path = pathway[pathway.length - 1];
  const CoachName = pathway[pathway.length - 2].replace("%20", " ");
  
  const linkStyle = {
    borderBottom: "2px solid #2A53A9",
    color: "#2A53A9",
    fontSize: "16px",
    fontWeight: "500"
  };

  const handleInputChange = () => {
    return;
  };
  const message =
    "Welcome to ether chat, we are here to ensure you have maximum experience in any skill and get a handsome paying job any day, you can chat us here. Our representative will be online to give you response";
  useEffect(() => {
  
    if (chatPicked) {
      updateMyData({ chatIsPicked: chatPicked, chatMessage: chatMessage });
    }
  }, [chatPicked, chatMessage]);

  const handleEtherChat = () => {
    setChatPicked(true);
    setChatMessage(message); 

  };

  return (
    <div className="CoachSubPagesContainer" id="CoachMessagesContainer">
      <div className="JsMessageContacts">
        <div className="JsSearchBar">
          <AiOutlineSearch className="JsProfileSearch" />
          <input
            type="text"
            placeholder="Search course keyword or title"
            value=""
            onChange={handleInputChange}
            className="JsProfileInputSearch"
          />
        </div>
        <div className="CoachLinks">
          <Link
            to={`/job_seeker/profile/coach/${CoachName}/messages`}
            style={path === "messages" ? linkStyle : { fontStyle: "normal" }}
          >
            Message
          </Link>
          <Link
            to={`/job_seeker/profile/coach/${CoachName}/roadmap`}
            style={path === "roadmap" ? linkStyle : { fontStyle: "normal" }}
          >
            Roadmap
          </Link>
          <Link
            to={`/job_seeker/profile/coach/${CoachName}/progress`}
            style={path === "progress" ? linkStyle : { fontStyle: "normal" }}
          >
            Progress
          </Link>
        </div>
        {path === "messages" && (
          <CoachChattList message={message} onClick={handleEtherChat} />
        )}
        {path === "roadmap" && (
          <CoachRoadmapList message={message} onClick={handleEtherChat} />
        )}
        {path === "progress" && (
          <CoachProgressList message={message} onClick={handleEtherChat} />
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};

CoachMessages.propTypes = {
  children: PropTypes.node,
};
export default CoachMessages;
