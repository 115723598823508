import React, { useState } from "react";
import { chat } from "../../../data/messageData";
import { BsSearch} from "react-icons/bs";
import { IoSendOutline } from "react-icons/io5";
import { CiFaceSmile } from "react-icons/ci";
import { PiDotsThree } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

import "./RecruiterMessage.style.scss";



const RecruiterMessage = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const navigate = useNavigate();

  const handleChatClick = (message) => {
    setSelectedChat(message);
  };
  const handleViewClick = () => {
    navigate("/recruiter/profile/applicant/job/applicantDetails");
  };

  return (
    <div className="RecruiterMessageContainer">
      <div className="RecruiterMessageContent">
        <div className="RecruiterMessageTop">
          <div className="RecruiterMessageSearchArea">
            <BsSearch className="MessageSearchIcon" />
            <input 
              type="text"
              placeholder={"Search course keyword or title"}
              className={"MessageSearchTextField"} 
              onChange={""}
              value={""}
            />
          </div>
          <button onClick={handleViewClick}>View Application</button>
        </div>
        <div className="RecruiterMessageChatSection">
          <div className="RecruiterChats">
            {chat.map((message) => (
              <div key={message.id} className="RecruiterChat" onClick={() => handleChatClick(message)}>
                <div className="RecruiterChatTop">
                  <span className="RecruiterChatProfile">
                    <img src={message.avatar} alt="" />
                    <p>{message.userName}</p>
                  </span>
                  <p>{message.timeStamp}</p>
                </div>
                <p>{message.sent[message.sent.length - 1]}</p>
              </div>
            ))}
          </div>
          <div className="RecruiterMessageChat">
            {selectedChat && (
              <div className="SelectedChatDetails">
                {selectedChat.received.map((receivedMessage, index) => (
                  <div key={`received-${index}`} className="ReceivedMessage">
                    <img src={selectedChat.avatar} alt="" />
                    <span className="RecruiterChatProfile">
                      <p>{selectedChat.userName}</p>
                      <p>{receivedMessage}</p>
                    </span>
                    {selectedChat.sent[index] && (
                      <div className="SentMessage">
                        <span>
                          <p>You</p>
                          <p>{selectedChat.sent[index]}</p>
                        </span>
                      </div>
                    )}
                  </div>
                ))}
                
              </div>
            )}
            <div className="RecruiterChatArea">
              <input 
                type="text"
                className="RecruiterChatInput"
                placeholder="Send a Message" 
              />
              <div className="RecruiterChatIcons">
                <CiFaceSmile className="RecruiterChatIcon"  />
                <PiDotsThree className="RecruiterChatIcon" />
                <button>
                  <IoSendOutline className="ButtonIcon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterMessage;