import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { CalendarDates } from "../../data/CoachCalendar";
import "./TopCalendar.style.scss";

export default function TopCalendar() {
  // Define your events with colors
  const events = [...CalendarDates];
  const renderEventContent = (eventInfo) => {
    const startDate = new Date(
      eventInfo.event.start
    );
    const endDate = new Date(eventInfo.event.end);

    // Format start and end date
    const formattedStartDate = `${startDate.toLocaleString(
      "default",
      { month: "short" }
    )} ${startDate.getDate()}`;
    const formattedEndDate = `${endDate.toLocaleString(
      "default",
      { month: "short" }
    )} ${endDate.getDate()}`;

    const paragStyle = {
      color:
				"var(--Dark-Base-Base-Black, #2C2C2C)",
      fontFamily: "TT Wellingtons Trl",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      width: "90%",
      wordWrap: "wrap",
      marginTop: "-2px",
    };
    const dateStyle = {
      fontFamily: "TT Wellingtons Trl",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      width: "90%",
      wordWrap: "wrap",
      marginTop: "-10px",
    };

    return (
      <div
        className="EventCard"
        style={{
          backgroundColor:
						eventInfo.event.backgroundColor,
          color: eventInfo.event.textColor,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          overflow: "scroll",
          padding: "5px",
          borderRadius: "5px",
        }}>
        <div className="EventHeader">
          <p style={paragStyle}>
            {eventInfo.event.title}
          </p>
        </div>
        <b style={dateStyle}>
          {formattedStartDate !== formattedEndDate
            ? `${formattedStartDate} - ${formattedEndDate}`
            : `${formattedStartDate}`}
        </b>
      </div>
    );
  };

  return (
    <div>
      <FullCalendar
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right:
						"dayGridMonth,timeGridWeek,timeGridDay",
        }}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        initialView="dayGridMonth"
        weekends={true}
        events={events}
        eventContent={renderEventContent}
        className="myCustomCalendar"
      />
    </div>
  );
}
