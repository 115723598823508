import React from "react";
import "./coachpage.style.scss";
import { CoachesProfile } from "../../../data/CoachData";
import {
  AiOutlineArrowLeft,
  AiOutlineFileText,
  AiOutlineDownload,
} from "react-icons/ai";
import { MdOutlinePublishedWithChanges} from "react-icons/md";
import { BsFillStarFill, BsCameraVideo } from "react-icons/bs";
import { GoVerified } from "react-icons/go";
import {ImInfinite} from "react-icons/im";
import { useNavigate } from "react-router-dom";
const CoachPage = () => {
  const navigate = useNavigate();
  const location = window.location.pathname;
  const splitter= location.split("/");
  const coach = (splitter[splitter.length - 1]).replace("%20", " ");
  const coachProfile = CoachesProfile.find((coachName) => coachName.name === `${coach}`);
  const handleGoBack = () => {
    navigate("/job_seeker/profile/coaches/all_coaches");
  };
  const handleClickMentorship = () => {
    navigate(`/job_seeker/profile/coach/${coach}/messages`);
  };
  return (
    <div className="CoachPageContainer">
      <div className="BackToPage">
        <button onClick={handleGoBack}>
          <AiOutlineArrowLeft /> Back
        </button>
      </div>
      <div className="AboutCoach">
        <img src={coachProfile?.image} alt="coach" width={350} height={350} />
        <div className="CoachDetails">
          <h2>About {coachProfile?.name}</h2>
          <div className="coachesExperience">
            <p id="yearsOfExperience">
              {coachProfile?.yearsOfExperience} years of experience
            </p>
            <span>{coachProfile?.workStatus}</span>
            <p id="rating">
              <BsFillStarFill className="rating" /> {coachProfile?.rate}
            </p>
          </div>
          <p id="CoachAbout">{coachProfile?.About}</p>
        </div>
      </div>
      <div className="CoachAchievement">
        <div className="SectionOne">
          <h4 className="SectionOneLabel">MentorShip Details</h4>
          <hr className="CoachLineBreaker" />
          <div className="AreaofExpertise">
            <h3>Areas of Expertise</h3>
            <ul>
              {coachProfile?.AreasOfExpertise?.map((area, index) => (
                <li key={index}>{area}</li>
              ))}
            </ul>
          </div>
          <hr id="CoachLineBreaker" />

          <div className="MentorshipRoadmap">
            <h2>MentorShip Roadmap</h2>
            <p>
              <BsCameraVideo id="MentorshipIcons" /> 54 hours on-demand video
            </p>
            <p>
              <AiOutlineFileText id="MentorshipIcons" /> Assignments & articles
            </p>
            <p>
              <AiOutlineDownload id="MentorshipIcons" /> Downloadable resources
            </p>
            <p>
              <MdOutlinePublishedWithChanges id="MentorshipIcons" /> Full
              lifetime access
            </p>
            <p>
              <GoVerified id="MentorshipIcons" /> Certificate of completion
            </p>
          </div>
          <hr id="CoachLineBreaker" />
        </div>
        <div className="SectionTwo">
          <h3 className="SectionTwoLabel">Achievements</h3>
          <hr id="CoachLineBreakerTwo" />
          <div className="CoachVerification">
            <span>
              <GoVerified id="MentorshipIcons" />
            </span>
            <p>Ether Verified Coach</p>
          </div>
          <div className="CoachVerification">
            <span>
              <ImInfinite id="MentorshipIcons" />
            </span>
            <p>
              {coachProfile?.NumOfCoachingSection} Successful Coaching Sections
            </p>
          </div>
          <div className="CoachVerification">
            <span>
              <GoVerified id="MentorshipIcons" />
            </span>
            <p>Professional Certificate</p>
          </div>
          <div className="CoachVerification">
            <span>
              <GoVerified id="MentorshipIcons" />
            </span>
            <p>Verified Field Experience</p>
          </div>
          <button onClick={handleClickMentorship}>Start MentorShip</button>
        </div>
      </div>
    </div>
  );
};

export default CoachPage;