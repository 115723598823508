/* eslint-disable react/jsx-key */
import React from "react";
import { BsGlobe } from "react-icons/bs";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import { workHistoryData } from "../../../data/AppliedData";
import { BiLogoLinkedin} from "react-icons/bi";
import { BsTwitter} from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { ImFacebook } from "react-icons/im";
const CompanyInfo = ({index}) => {
  const data = workHistoryData[index].jobInformation;
  const icons = [
    <BiLogoLinkedin />,
    <BsTwitter />,
    <AiOutlineInstagram />,
    <ImFacebook />,
  ];
  return (
    <div className="CompanyInformationContainer">
      <div className="CompanyInfoAbout">
        <h2>Company Information</h2>
        <div className="CompanyInfo">
          <span>
            <img src="/icons/Group 18.png" alt="group" width={24} height={24} />
            {data.noOfEmployees}
          </span>
          <span>
            {" "}
            <img
              src="/icons/location-outline.png"
              alt="group"
              width={24}
              height={24}
            />
            {data.location}
          </span>
          <span>
            <BsGlobe />
            <Link to={data.website ? data.website : ""}>{data.website}</Link>
          </span>
        </div>
        <div className="CompanyInfoParag">
          <p>{data.companyDetails}</p>
        </div>
      </div>
      <div className="CompanyFoundersSection">
        <h2>Founders</h2>
        <div>
          {data.founders.map((founder, id) => (
            <div key={id}>
              <img src={data.coverImages[id]} alt="cover" height={220} />
              <p id="founder">{founder}</p>
              <p id="founderPosition">{data.founderPostions[id]}</p>
              <span>
                {data.handles.map((handle, key) => (
                  <a
                    href={`https://${handle}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    key={key}
                  >
                    {icons[key]}
                  </a>
                ))}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="CompanyCultureSection">
        <div>
          <h2>Company Culture</h2>
          <span>
            {data.handles.map((handle, key) => (
              <a
                href={`https://${handle}`}
                target="_blank"
                rel="noreferrer noopener"
                key={key}
              >
                {icons[key]}
              </a>
            ))}
          </span>
        </div>
        <div className="CompanyCulture">
          {data.companyCulture.map((element, key) => (
            <p key={key}>
              {element}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

CompanyInfo.propTypes = {
  index: PropTypes.number,
};
export default CompanyInfo;