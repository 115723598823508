import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsSearch} from "react-icons/bs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import "./AdminUserMgt.style.scss";

const columns = [
  { id: "name", label: "Name", minWidth: 200, align: "left" },
  { id: "phoneNumber", label: "Phone Number", minWidth: "200", align: "center" },
  { id: "email", label: "Email Address", minWidth: "200", align: "center"},
  { id: "userType", label: "User Type", minWidth: "200", align: "center"},
  { id: "date", label: "Date Joined", minWidth: "200", align: "center" },
  { id: "action", label: "Action", minWidth: "200", align: "center" }
];

function createData(name, phoneNumber, email, userType, date, action) {
  return { name, phoneNumber, email, userType, date, action};
}

const rows = [
  createData("John Melody Doe", "+234(0)8137820038", "Johnmelodydoe@gmail.com", "Coach", "02/09/2023", "View"),
  createData("Alice Smith", "+234(0)9876543210", "alice.smith@example.com",  "JobSeeker", "12/15/2022", "View"),
  createData("Bob Johnson", "+234(0)8765432109", "bob.johnson@example.com", "Recruiter", "10/20/2023", "View"),
  createData("Emily Davis", "+234(0)7654321098", "emily.davis@example.com", "Recruiter", "05/03/2024", "View"),
  createData("Michael Lee", "+234(0)6543210987", "michael.lee@example.com", "Coach", "07/08/2023", "View"),
  createData("Sophia Brown", "+234(0)5432109876", "sophia.brown@example.com", "Coach", "09/12/2022", "View"),
  createData("Daniel Wilson", "+234(0)4321098765", "daniel.wilson@example.com", "JobSeeker", "11/30/2023", "View"),
  createData("Emma Thompson", "+234(0)3210987654", "emma.thompson@example.com", "JobSeeker", "03/25/2023", "View"),
  createData("Matthew Harris", "+234(0)2109876543", "matthew.harris@example.com", "Recruiter", "08/18/2022", "View"),
  createData("Olivia Martinez", "+234(0)1098765432", "olivia.martinez@example.com", "Recruiter", "06/11/2024", "View"),
  createData("William Clark", "+234(0)0987654321", "william.clark@example.com", "Coach", "04/04/2023", "View"),
  createData("Ava Rodriguez", "+234(0)9876543210", "ava.rodriguez@example.com", "JobSeeker", "01/01/2024", "View"),
  createData("James Anderson", "+234(0)8765432109", "james.anderson@example.com", "JobSeeker", "10/30/2022", "View"),
  createData("Isabella Wilson", "+234(0)7654321098", "isabella.wilson@example.com", "JobSeeker", "07/20/2023", "View"),
  createData("Benjamin Garcia", "+234(0)6543210987", "benjamin.garcia@example.com", "JobSeeker", "09/05/2024", "View"),
  createData("Mia Lopez", "+234(0)5432109876", "mia.lopez@example.com", "11/28/2022", "JobSeeker", "View"),
  createData("Ethan Martinez", "+234(0)4321098765", "ethan.martinez@example.com", "JobSeeker", "02/14/2023", "View"),
  createData("Charlotte Taylor", "+234(0)3210987654", "charlotte.taylor@example.com", "Recruiter", "12/12/2023", "View"),
  createData("Alexander Hernandez", "+234(0)2109876543", "alexander.hernandez@example.com", "JobSeeker", "06/06/2022", "View"),
  createData("Amelia King", "+234(0)1098765432", "amelia.king@example.com", "Coach", "08/09/2024", "View")
];

const AdminUserMgt = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate();
  };

  const handleInputChange = () => {
    setSearchQuery();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="AdminUserContainer">
      <div className="AdminUserContent">
        <div className="AdminUserPreview">
          <div className="AdminUserPreviewCard">
            <p>Total Registered Users</p>
            <p>0</p>
          </div>
          <div className="AdminUserPreviewCard">
            <p>Total Active Users</p>
            <p>0</p>
          </div>
          <div className="AdminUserPreviewCard">
            <p>Total Inactive Users</p>
            <p>0</p>
          </div>
          <div className="AdminUserPreviewCard">
            <p>Total Revenue Generated</p>
            <p>0</p>
          </div>
        </div>
        <div className="AdminUserTableHeading">
          <h4>User Management</h4>
          <div className="AdminUserSearchInput">
            <BsSearch className="AdminUserSearchIcon" />
            <input 
              type="text"
              placeholder={"Search course keyword or title"}
              className={"AdminUserSearchTextField"}
              value={searchQuery}
              onChange={handleInputChange}
            />
          </div>
          <button>
            <select>
              <option value="" key="">Sort By</option>
              <option value="" key="">Jobseeker</option>
              <option value="" key="">Recruiter</option>
              <option value="" key="">Coach</option>
              <option value="" key="">Active Users</option>
              <option value="" key="">Inactive Users</option>
            </select>
          </button>
        </div>
        <Paper className="AdminUserTablePaper">
          <TableContainer className="AdminUserTableContainer">
            <Table className="AdminUserTable">
              <TableHead className="AdminUserTableHead">
                <TableRow className="AdminUserTableRow">
                  {columns.map((column) => (
                    <TableCell 
                      className="AdminUserTableCell"
                      key={column.id}
                      align={column.align}
                      style={{minWidth: column.minWidth}}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="AdminUserTableBody">
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code} className="AdminUserTableBodyRow">
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              className="AdminUserTableBodyCell"
                              key={column.id}
                              align={column.align}
                              onClick={() => {
                                if(column.id === "action") {
                                  handleClick();
                                }
                              }}
                              style={column.id === "action" ? { cursor: "pointer", color: "var(--Foundation-PRI-pri-500, #2A53A9)" } : {}}
                            >
                              {(column.format && typeof value === "number"
                                ? column.format(value)
                                : column.id === "userType" ? (
                                  <span
                                    style={{
                                      backgroundColor: 
                                        value === "JobSeeker" ? "var(--Alert-Succes-100, #66DDB3)" : 
                                          value === "Recruiter" ? "var(--Alert-Warning-100, #FFD688)" :
                                            value === "Coach" ? "var(--Primary-200, #D8E4FC)" : "", 
                                      padding: "2px 10px 2px 10px",
                                      borderRadius: "10px",
                                      color: "var(--Foundation-Neutral-Black, #2E2C2C)",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {value}
                                  </span>
                                ) : (
                                  value
                                )
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination 
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
};

export default AdminUserMgt;