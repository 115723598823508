import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";

import "./RecruiterHiringStatus.style.scss";
import { IoArrowBack } from "react-icons/io5";
import { TextField } from "../../../Materials/InputFields";
import { Box, Grid, Typography, styled } from "@mui/material";
import { CustomPagination } from "../../../Materials/Materials";

const statsScheduled = [
  { className: "blueStat", label: "In Progress", value: 2 },
  { className: "greenStat", label: "Completed", value: 3 },
  { className: "yellowStat", label: "Pending", value: 2 },
  { className: "redStat", label: "Declined", value: 1 },
];

const statsInterviewed = [
  { className: "blueStat", label: "Sent", value: 2 },
  { className: "greenStat", label: "Accepted", value: 3 },
  { className: "yellowStat", label: "Pending", value: 2 },
  { className: "greyStat", label: "N/A", value: 1 },
];

const statsAssessment = [
  { className: "greenStat", label: "Pass", value: 3 },
  { className: "redStat", label: "Fail", value: 2 },
  { className: "greyStat", label: "N/A", value: 1 },
];

const statsOffer = [
  { className: "greenStat", label: "Accepted", value: 2 },
  { className: "redStat", label: "Declined", value: 3 },
  { className: "yellowStat", label: "Pending", value: 2 },
  { className: "greyStat", label: "N/A", value: 1 },
];
const statsHired = [
  { className: "greenStat", label: "Yes", value: 1 },
  { className: "redStat", label: "No", value: 1 },
  { className: "greyStat", label: "N/A", value: 1 },
];

const HiringStatusCard = ({ title, stats }) => {
  return (
    <div className="HiringStatusStats">
      <h3>{title}</h3>
      {stats.length > 0 && (
        <Box>
          <Grid container rowSpacing={"8px"} columnSpacing={"36px"}>
            {stats.map((stat, index) => (
              <Grid
                item
                xs={6}
                key={index}
                className={stat.className}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>{stat.label}</div>
                <div>{stat.value}</div>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </div>
  );
};

HiringStatusCard.propTypes = {
  title: PropTypes.string.isRequired,
  stats: PropTypes.array.isRequired,
};

const columns = [
  { id: "names", label: "Applicant Name", align: "left" },
  {
    id: "score",
    label: "Score",
    minWidth: "auto",
    align: "center",
    format: (value) => {
      const scoreColors = [
        "var(--Alert-Success-600, #00B374)", // Above 60
        "var(--foundation-PRI-pri-500, #2a53a9)", // 50-59
        "var(--Alert-Danger-700, #cc3333)", // Below 50
      ];
      let color;

      value >= 60
        ? (color = scoreColors[0])
        : value >= 50
        ? (color = scoreColors[1])
        : (color = scoreColors[2]);

      return (
        <div style={{ color }}>
          <span>{value}</span>
          <span>/100</span>
        </div>
      );
    },
  },
  { id: "flag", label: "Flag", align: "center" },
  { id: "date", label: "Date Applied", align: "center" },
  { id: "scheduled", label: "Interview Scheduled", align: "center" },
  { id: "interviewed", label: "Interviewed", align: "center" },
  { id: "assessment", label: "Assessment", align: "center" },
  { id: "offer", label: "Offer", align: "center" },
  { id: "hired", label: "Hired", align: "center" },
];
function createData(
  names,
  score,
  flag,
  date,
  scheduled,
  interviewed,
  assessment,
  offer,
  hired
) {
  return {
    names,
    score,
    flag,
    date,
    scheduled,
    interviewed,
    assessment,
    offer,
    hired,
  };
}

const rows = [
  createData(
    "Jakolo Hamza",
    60,
    "Above Pass Score",
    "02/09/23",
    "Sent",
    "Pending",
    "N/A",
    "N/A",
    "N/A"
  ),
  createData(
    "Bob Smith",
    50,
    "On Pass Score",
    "02/09/23",
    "Accepted",
    "Completed",
    "Pass",
    "Accepted",
    "Yes"
  ),
  createData(
    "Leo Adams",
    85,
    "Above Pass Score",
    "02/09/23",
    "Declined",
    "In Progress",
    "Fail",
    "N/A",
    "N/A"
  ),
  createData(
    "Mia Johnson",
    55,
    "On Pass Score",
    "02/09/23",
    "Accepted",
    "Completed",
    "N/A",
    "N/A",
    "N/A"
  ),
  createData(
    "John Yee",
    37,
    "Below Pass Score",
    "02/09/23",
    "N/A",
    "N/A",
    "N/A",
    "N/A",
    "N/A"
  ),
  createData(
    "Bob Smith",
    65,
    "Above Pass Score",
    "02/09/23",
    "Accepted",
    "Completed",
    "Pass",
    "Accepted",
    "Yes"
  ),
];

const RecruiterHiringStatus = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = () => {
    navigate("/recruiter/profile/HiringStatus/job/");
  };

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "var(--Primary-100, #ecf1fe)",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div className="RecruiterHiringStatusContainer">
      <div className="RecruiterHiringStatusContent">
        <div className="HiringStatusHeading">
          <Link
            to={"/recruiter/profile/jobs"}
            style={{ textDecoration: "none" }}
            className="HiringStatusNavigation"
          >
            <IoArrowBack className="HiringStatusBackIcon" />
            <p>Back</p>
          </Link>
        </div>
        <div className="HiringStatusSearchInputField">
          <BsSearch className="HiringStatusSearchIcon" />
          <input
            type="text"
            placeholder={"Search course keyword or title"}
            className={"HiringStatusSearchTextField"}
            onChange={""}
            value={""}
          />
        </div>
        <div className="HiringStatusTopSection">
          <div className="HiringStatusButtonTabs">
            <button>All</button>
            <button>Interview Scheduled</button>
            <button>Interviewed</button>
            <button>Offer</button>
            <button>Hired</button>
          </div>
          <div className="HiringStatusSelectButtons">
            <button>
              <select>
                <option value="" key="">
                  Advance Filter
                </option>
              </select>
            </button>
            <button>Set Interview</button>
          </div>
        </div>
        {/*------------------- STATISTICS SECTION------------------- */}
        <Grid
          sx={{ margin: "48px 33px" }}
          container
          rowSpacing={"15px"}
          columnSpacing={{ xs: "24px" }}
        >
          <Grid item xs={3}>
            <div className="HiringStatusStats">
              <h3>Total Applicants</h3>
              <Typography as="p" color={"#2a53a9"} paddingBottom={"28px"}>
                8 out of 100
              </Typography>
            </div>
          </Grid>
          <Grid item xs={3}>
            <HiringStatusCard
              title="Interview Scheduled"
              stats={statsScheduled}
            />
          </Grid>
          <Grid item xs={3}>
            <HiringStatusCard title="Interviewed" stats={statsInterviewed} />
          </Grid>
          <Grid item xs={3}>
            <HiringStatusCard title="Assessment" stats={statsAssessment} />
          </Grid>
          <Grid item xs={3}>
            <HiringStatusCard title="Offer" stats={statsOffer} />
          </Grid>
          <Grid item xs={3}>
            <HiringStatusCard title="Hired" stats={statsHired} />
          </Grid>
        </Grid>
        {/*-------------------- FILTERS SECTION --------------------*/}
        <div className="HiringStatusDetails">
          <div className="HiringStatusInputs">
            <TextField
              className={"HiringStatusInput"}
              id={"HiringStatusInput"}
              type={"text"}
              placeholder={"Associate Product Designer"}
              disabled={true}
              label={"Job Title"}
            />
            <TextField
              className={"HiringStatusInput"}
              id={"HiringStatusInput"}
              type={"text"}
              placeholder={"Design"}
              disabled={true}
              label={"Category"}
            />
            <TextField
              className={"HiringStatusInput"}
              id={"HiringStatusInput"}
              type={"text"}
              placeholder={"Full-Time"}
              disabled={true}
              label={"Job Type"}
            />
          </div>
          <div className="HiringStatusInputs">
            <TextField
              className={"HiringStatusInput"}
              id={"HiringStatusInput"}
              type={"text"}
              placeholder={"Remote"}
              disabled={true}
              label={"Location"}
            />
            <TextField
              className={"HiringStatusInput"}
              id={"HiringStatusInput"}
              type={"text"}
              placeholder={"14th April, 2024"}
              disabled={true}
              label={"Date Created"}
            />
            <TextField
              className={"HiringStatusInput"}
              id={"HiringStatusInput"}
              type={"text"}
              placeholder={"30th May, 2024"}
              disabled={true}
              label={"Closing Date"}
            />
          </div>
        </div>
        {/*------------------------- TABLE ------------------------- */}
        <div className="AppPaper">
          <Table className="AppTable">
            <TableHead className="AppTableHead">
              <StyledTableRow className="AppTableRow">
                <TableCell className="AllTableCell" key="s/no" align="center">
                  S/No
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    className="AppTableCell"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody className="AppTableBody">
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={row.sn}
                    className="AppTableBodyRow"
                  >
                    <TableCell
                      className="AllTableBodyCell"
                      key="s/no"
                      align="center"
                    >
                      {index + 1}
                    </TableCell>
                    {columns.map((column) => {
                      const value = row[column.id];

                      const switchColor = (columnId, value) => {
                        switch (columnId) {
                          case "scheduled":
                            return value === "Accepted"
                              ? "var(--Alert-Success-600, #00B374)"
                              : value === "Sent"
                              ? "var(--Alert-Info-600, #2A53A9)"
                              : value === "Declined"
                              ? "var(--Alert-Danger-700, #C33)"
                              : "var(--Secondary-100, #CED3DD)";
                          case "interviewed":
                            return value === "Completed"
                              ? "var(--Alert-Success-600, #00B374)"
                              : value == "In Progress"
                              ? "var(--Alert-Info-600, #2A53A9)"
                              : value == "Pending"
                              ? "var(--Alert-Warning-600, #E6A832)"
                              : "var(--Secondary-100, #CED3DD)";
                          case "assessment":
                            return value === "Pass"
                              ? "var(--Alert-Success-600, #00B374)"
                              : value == "Fail"
                              ? "var(--Alert-Danger-700, #C33)"
                              : "var(--Secondary-100, #CED3DD)";
                          case "offer":
                            return value === "Accepted"
                              ? "var(--Alert-Success-600, #00B374)"
                              : value == "Pending"
                              ? "var(--Alert-Warning-600, #E6A832)"
                              : value == "Declined"
                              ? "var(--Alert-Danger-600, #C33)"
                              : "var(--Secondary-100, #CED3DD)";
                          case "hired":
                            return value === "Yes"
                              ? "var(--Alert-Success-600, #00B374)"
                              : value == "No"
                              ? "var(--Alert-Danger-600, #C33)"
                              : "var(--Secondary-100, #CED3DD)";
                          case "flag":
                            return value === "Above Pass Score"
                              ? "var(--Alert-Success-600, #00B374)"
                              : value === "On Pass Score"
                              ? "var(--Alert-Info-600, #2A53A9)"
                              : value === "Below Pass Score"
                              ? "var(--Alert-Danger-700, #C33)"
                              : "inherit";

                          default:
                            return "inherit";
                        }
                      };

                      return (
                        <TableCell
                          className="AppTableBodyCell"
                          key={column.id}
                          align={column.align}
                          style={{
                            color:
                              column.id === "flag"
                                ? switchColor(column.id, value)
                                : "inherit",
                            fontWeight: column.id === "score" ? 600 : "",
                          }}
                        >
                          {[
                            "scheduled",
                            "interviewed",
                            "assessment",
                            "offer",
                            "hired",
                          ].includes(column.id) ? (
                            <span
                              style={{
                                padding: "1px 10px 2px 10px",
                                borderRadius: "10px",
                                fontSize: "16px",
                                color: "white",
                                backgroundColor: switchColor(column.id, value),
                              }}
                            >
                              {value}
                            </span>
                          ) : column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {/* --------------- CUSTOM PAGINATION  --------------- */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "70px auto 0 auto",
          }}
        >
          <CustomPagination currentPage={1} totalPages={11} onPageChange={2} />
        </Box>
      </div>
    </div>
  );
};

export default RecruiterHiringStatus;
