import { quiz } from "./QuizData";
export const AdminResource = [
  {
    course: "Introduction to Data Analysis",
    category: "Data Science",
    enrolled: 40,
    completed: 30,
    status: "Published",
    action: "view",
    quiz: quiz,
    description:
			"This course provides an introduction to the fundamentals of data science. Students will learn key concepts and techniques for collecting, analyzing, and interpreting data. The course covers various aspects of the data science workflow, from data exploration to visualization and communication of findings.",
    expectations: [
      "Understand the basic principles of data science and its applications.",
      "Gain proficiency in using popular data analysis tools, such as Python and Jupyter Notebooks.",
      "Develop the ability to clean and preprocess raw data for analysis.",
      "Apply statistical methods and machine learning algorithms to draw insights from data.",
    ],
    modules: [
      {
        module: "Module 1:",
        title: "Introduction to Data Science",
        topics: [
          "Overview of Data Science",
          "Importance of Data in Decision Making",
          "Ethical Considerations in Data Science",
        ],
      },
      {
        module: "Module 2:",
        title: "Data Collection and Cleaning",
        topics: [
          "Data Collection Methods",
          "Data Cleaning Techniques",
          "Handling Missing Data",
        ],
      },
      {
        module: "Module 3:",
        title: "Exploratory Data Analysis (EDA)",
        topics: [
          "Descriptive Statistics",
          "Data Visualization with Matplotlib and Seaborn",
          "Exploratory Data Analysis Case Studies",
        ],
      },
      {
        module: "Module 4:",
        title:
					"Statistical Analysis and Machine Learning",
        topics: [
          "Statistical Inference",
          "Introduction to Machine Learning",
          "Hands-on Machine Learning Projects",
        ],
      },
      {
        module: "Module 5:",
        title:
					"Data Visualization and Communication",
        topics: [
          "Effective Data Visualization Techniques",
          "Storytelling with Data",
          "Communicating Results to Non-Technical Stakeholders",
        ],
      },
    ],
    moduleResources: [
      {
        title: "Module 1 Resources",
      },
      {
        title: "Module 2 Resources",
      },
      {
        title: "Module 3 Resources",
      },
      {
        title: "Module 4 Resources",
      },
      {
        title: "Module 5 Resources",
      },
    ],
  },
  {
    course: "Introduction to Data Analysis",
    category: "Data Science",
    enrolled: 40,
    completed: 30,
    status: "Published",
    action: "view",
  },
  {
    course: "Professional Certification on Scrum",
    category: "Scrum Framework",
    enrolled: 20,
    completed: 10,
    status: "Published",
    action: "view",
  },
  {
    course: "Introduction to Marketing",
    category: "Marketing",
    enrolled: 40,
    completed: 30,
    status: "Published",
    action: "view",
  },
  {
    course: "Data Structure & Algorithm",
    category: "Programming",
    enrolled: 40,
    completed: 30,
    status: "Published",
    action: "view",
  },

  {
    course:
			"Introduction to UI/UX and Prototyping",
    category: "Product Management",
    enrolled: 55,
    completed: 10,
    status: "Published",
    action: "view",
  },
  {
    course: "Introduction to C++",
    category: "Data Science",
    enrolled: 30,
    completed: 0,
    status: "Pending",
    action: "view",
  },
  {
    course: "Introduction to Html, Css and Js",
    category: "Web Develoment",
    enrolled: 90,
    completed: 70,
    status: "Published",
    action: "view",
  },
  {
    course: "Python Basics",
    category: "Software Development",
    enrolled: 40,
    completed: 30,
    status: "Published",
    action: "view",
  },
  {
    course: "Introduction to Corel Draw",
    category: "Design",
    enrolled: 70,
    completed: 40,
    status: "Published",
    action: "view",
  },
  {
    course: "Machine Learning In Camera",
    category: "Data Science",
    enrolled: 20,
    completed: 5,
    status: "Draft",
    action: "view",
  },
];
