import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
import Avatar from "../../images/Avatar1.png";
import { AdminResource } from "../../data/AdminResourceData"; 
import { useParams } from "react-router-dom";
import "../../components/CoachProfile/CoachRoadMap/CoachCourseDetails.style.scss";

const ResourcesCourseIndex = () => {
  const params = useParams();
  const index = Number(params?.index) - 1;
  

  return (
    <div className="CourseDetailsSection">
      <div className="CourseDescription">
        <p>Course Description</p>
        <p>{AdminResource[index]?.description}</p>
      </div>
      <hr className="CourseDetailsDivider" />
      <div className="CourseExpectations">
        <p>Course Expectations</p>
        <ul>
          {AdminResource[index].expectations?.map(
            (expectation, index) => (
              <li key={index}>{expectation}</li>
            )
          )}
        </ul>
      </div>
      <hr className="CourseDetailsDivider" />
      <div className="CourseModules">
        <p>Course Modules</p>
        {AdminResource[index].modules?.map(
          (module, index) => (
            <div
              key={index}
              className="ModuleContainer">
              <div className="ModuleWrapper">
                <span>{module.module}</span>{" "}
                <span>{module.title}</span>
              </div>
              <MdOutlineKeyboardArrowDown className="CourseModuleIcon" />
            </div>
          )
        )}
      </div>
      <div className="CourseInstructor">
        <div className="CourseInstructorHeading">
          <p>Course Instructor</p>
          <BsInfoCircle className="InstructorIcon" />
        </div>
        <div className="CourseInstructorProfile">
          <img src={Avatar} />
          <div className="InstructorProfileDetails">
            <h5>Dianna Johanness</h5>
            <h6>Developer and Lead Instructor</h6>
            <div className="InstructorRating">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <path
                  d="M5.49619 5.27112L7.15861 1.9225C7.37199 1.49263 7.98856 1.49263 8.20194 1.9225L9.86434 5.27112L13.582 5.81141C14.059 5.88072 14.2491 6.46371 13.9038 6.79811L11.2142 9.40285L11.8489 13.0826C11.9305 13.5552 11.4316 13.9155 11.0048 13.6923L7.68027 11.954L4.35578 13.6923C3.92898 13.9155 3.43009 13.5552 3.5116 13.0826L4.14635 9.40285L1.45675 6.79811C1.11142 6.46371 1.30154 5.88072 1.77853 5.81141L5.49619 5.27112Z"
                  fill="#2A53A9"
                  stroke="#2A53A9"
                />
              </svg>
              <span>4.5 (2.1k views)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesCourseIndex;
