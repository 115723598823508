import React from "react";
import "./jobapp.style.scss";
import PropTypes from "prop-types";
import { workHistoryData } from "../../../data/AppliedData";

console.log(workHistoryData);
const ApplicationPage = ({index}) => {
  console.log(index);
  const data = workHistoryData[index];
  return (
    <div className="ApplicationPageContainer">
      <div className="ApplicationStatus">
        <h5>Application Status</h5>
        <p id="applicationInfo">{data.applicationInfo}</p>
        <p id="applicationStatus">
          Status: <span>{data.status}</span>
        </p>
      </div>
      <div className="ApplicationLetter">
        <h5>Application Letter</h5>
        <div className="ApplicationLetterBody">
          <img
            src={data.applicationImage}
            alt="app-image"
            width={82}
            height={82}
          />
          <p>
            <p>
              {data.applicationLetter.split("\n").map((paragraph, index) => (
                <span key={index}>
                  {paragraph}
                  <br />
                </span>
              ))}
            </p>
          </p>
        </div>
      </div>
      <div className="ApplicationPageAttachment">
        <div>
          <img src="/icons/images/attachment.png" alt="attachment" width={24} height={24} />
          <h6>Resume: </h6>
          <p>{data.applicationCV}</p>
        </div>
        <div>
          <img src="/icons/images/attachment.png" alt="attachment" width={24} height={24} />
          <h6>Cover Letter: </h6>
          <p>{data.applicationCover}</p>
        </div>
      </div>
    </div>
  );
};

ApplicationPage.propTypes = {
  index: PropTypes.number,
};
export default ApplicationPage;