import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IoArrowBack } from "react-icons/io5";
import PropTypes from "prop-types";

import "./MenteesModal.style.scss";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "number", label: "Phone\u00a0Number", minWidth: 100 },
  {
    id: "emailAddress",
    label: "Email Address",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "course", label: "Course Enrolled", align: "center"},
  { 
    id: "sessions", 
    label: "Sessions", 
    align: "center",
    format: (value) => (
      <div className="SessionContainer">
        <span style={{color: "var(--Foundation-Neutral-Black, #2E2C2C)"}}>hours:</span>
        <span style={{color: "var(--alert-succes-500-base, #00C781)"}}>{value}</span>
        <span style={{color: "var(--Foundation-Neutral-Black, #2E2C2C)"}}>/120</span>
      </div>
    )
  },
  { 
    id: "gradings", 
    label: "Gradings", 
    align: "center",
    format: (value) => (
      <div className="GradingContainer">
        <span style={{color: "var(--alert-succes-500-base, #00C781)"}}>{value}</span>
        <span style={{color: "var(--Foundation-PRI-pri-500, #2A53A9)"}}>/120</span>
      </div>
    )
  },
  { id: "recommendations", label: "Recommendations", align: "center"},
  { id: "messsage", label: "Message", align: "center"}
];

function createData(name, number, emailAddress, course, sessions, gradings, recommendations, message) {
  return {name, number, emailAddress, course, sessions, gradings, recommendations, message};
}

const rows = [
  createData("John Melody Doe", "+234(0)813782038", "Johnmelodydoe@gmail.com", "Product Design", 300, 100, "A good Performance" )
];

const MenteesModal = ({ onClose }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  return (
    <div className="MenteesModalContainer">
      <div className="MenteesModal">
        <div onClick={onClose} className="MenteeModalIconWrapper">
          <IoArrowBack className="ModalBackIcon" />
        </div>
        <Paper className="MenteeModalPaper">
          <TableContainer className="MenteeModalTableContainer">
            <Table className="MenteeModalTable">
              <TableHead className="MenteeModalTableHead">
                <TableRow className="MenteeModalTableRow">
                  {columns.map((column) => (
                    <TableCell
                      className="MenteeModalTableCell"
                      key={column.id}
                      align={column.align}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="MenteeModalTableBody">
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => {
                    return (
                      <TableRow className="MenteeModalTableBodyrow" hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column, colIndex) => {
                          const value = row[column.id];
                          return (
                            <TableCell className="MenteeModalTableBodyCell" key={column.id} align={column.align}>
                              {colIndex === columns.length - 1 ? (
                                <div className="MessageButton">
                                  <button>Send a Message</button>
                                </div>
                              ) : (
                                column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
MenteesModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default MenteesModal;