import React, { useRef } from "react";
import { HiArrowLeft } from "react-icons/hi2";
import { VisibleIcon, PersonIcon, SearchIcon, StarIcon } from "../../../svgs/SvgComponent";
import { TextField } from "../../../Materials/InputFields";
import { workHistoryData, educationHistoryData } from "../../../data/ProfileSetupData";
import { licenses } from "../../../data/CoachData";
import { FiFileText } from "react-icons/fi";
import Avatar from "../../../images/Avatar1.png";

import "./AdminUserCoach.style.scss";

const AdminUserCoach = () => {
  const getExperienceIndex = useRef(null);
  const getEducationIndex = useRef(null);
  const getLicenseIndex = useRef(null);

  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <div className="UserCoachContainer">
      <div className="UserCoachContent">
        <div className="UserCoachTopSection">
          <div className="UserCoachTopHeading">
            <span>
              <HiArrowLeft className="UserCoachBackIcon" onClick={handleGoBack} />
              <h4>Siddiq Lushon</h4>
            </span>
            <button>
              Admin Action
            </button>
          </div> 
          <img src={Avatar} alt="" />
        </div>
        <div className="UserCoachDetails">
          <div className="UserCoachInfo">
            <h6>Software Tester</h6>
            <p className="FirstChild">Cloud Practitioner| 5years Experience</p>
            <p className="SecondChild">Johannesburg, South Africa. <span>Contact Info</span></p>
            <p className="ThirdChild">5 Certifications Completed</p>
          </div>
          <div className="UserCoachBio">
            <h4>Bio</h4>
            <p>
              Siddiq is a senior solutions architect and a consultant, 
              with rich experience of over 20 years in core IT infrastructure services and cloud computing. 
              I am outgoing, dedicated, and open-minded. 
              I get across to people and adjust to changes with ease. 
              I believe that a person should work on developing their professional skills and learning new things all the time.
            </p>
          </div>
        </div>
        <div className="UserCoachProfile">
          <div className="UserCoachMetrics">
            <h4>Coaching Metrics</h4>
            <p>Categories</p>
            <div className="UserCoachCategories">
              <span>Product Design</span>
              <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
              <span>Product Management</span>
            </div>
            <p className="Score">Total Course Published = 6</p>
            <p className="Score">Course Engagement = 80%</p>
            <div className="UserCoachRating">
              <StarIcon />
              <span>4.5 (2.1k views)</span>
            </div>
          </div>
          <div className="UserCoachProfileView">
            <h4>Your Profile View</h4>
            <span>
              <VisibleIcon />
              <p>Visible to you only</p>  
            </span> 
            <span>
              <PersonIcon />
              <p>80 Profile views</p>  
            </span>
            <span>
              <SearchIcon />
              <p>19 Search appearances</p>
            </span> 
            <p>
            Discover  your profile visibility and search appearances
            </p>
          </div>
          <div className="UserCoachPortfolio">
            <h4>Portfolio</h4>
            <p>
              An highlight of your work experience to showcase your skills
            </p>
            <TextField 
              type={"text"}
              className={"UserCoachText"}
              id={"UserCoachText"}
              placeholder={"Paste link to portfolio here"}
              label={"Add Portfolio"}
            />
          </div>  
        </div> 
        <div className="UserCoachExperience">
          <h2>Work Experience</h2>
          {workHistoryData.map((data, index) => {
            getExperienceIndex.current = index;
            return (
              <div key={index} className="UserCoachExperienceData">
                <img src={data.icon} alt="company image" />
                <div className="UserCoachWorkDetails">
                  <h6>{data.title}</h6>
                  <div className="UserCoachWorkInfo">
                    <span>{data.company}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.startDate}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.endDate}</span>
                  </div>
                </div>
              </div>
            );
          })}  
        </div>
        <div className="UserCoachEducation">
          <h2>Education</h2>
          {educationHistoryData.map((data, index) => {
            getEducationIndex.current = index;
            return (
              <div key={index} className="UserCoachEducationData">
                <img src={data.icon} alt="company image" />
                <div className="UserCoachEducationDetails">
                  <h6>{data.title}</h6>
                  <div className="UserCoachEducationInfo">
                    <span>{data.certificate}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.startDate}</span>
                    <span style={{ height:"4px", width: "4px", margin: "0 9px", borderRadius: "2px", backgroundColor: "var(--Secondary-200, #9BA6BC)" }}></span>
                    <span>{data.endDate}</span>
                  </div>
                </div>
              </div>
            );
          })}  
        </div>
        <div className="UserCoachLicense">
          <h2>Licenses and Certifications</h2>
          {licenses.map((data, index) => {
            getLicenseIndex.current = index;
            return (
              <div key={index} className="UserCoachCertificationData">
                <div className="UserCoachLicenseParticulars">
                  <img src={data.image} alt="" />
                  <div className="UserCoachCertInfo">
                    <h6>{data.title}</h6>
                    <div className="UserCoachCertDetails">
                      <span>{data.state}</span>
                      <span>{data.date}</span>
                    </div>
                  </div>
                </div>
                <div className="UserCoachShowButton">
                  <p>Show Credentials</p>
                  <FiFileText className="ButtonIcon" />
                </div>
              </div>
            );
          })}
        </div> 
      </div>      
    </div>
  );
};

export default AdminUserCoach;
