import React from "react";
import { CheckBox, RadioButton } from "../../../Materials/InputFields";

import "./MenteeNotifications.style.scss";
import { Toggle } from "../../../Materials/ToggleMui";

const MenteeNotifications = () => {

  const handleCheckBoxChange = () => {
    return;
  };
  const handleRadioButtonChange = () => {
    return; 
  };

  return (
    <div className="MenteeNotificationsContainer">
      <div className="MenteeNotificationsContent">
        <div className="MenteeNotificationsHeading">
          <h6>Allow Mentee Notifications</h6>
          <Toggle />
        </div>
        <div className="MenteeCheckboxHeadings">
          <div className="MenteeCheckboxHeading"></div>
          <div className="MenteeCheckboxHeading">
            <h6>Email</h6>
            <p>Sent to your registered email</p>
          </div>
          <div className="MenteeCheckboxHeading">
            <h6>In-app notifications</h6>
            <p>Delivered through the app</p>
          </div>
          <div className="MenteeCheckboxHeading">
            <h6>Pushed notifications</h6>
            <p>Get notified through your device</p>
          </div>
          <div className="MenteeCheckboxHeading">
            <h6>Desktop </h6>
            <p>Receive notification on windows</p>
          </div>
        </div>
        <div className="MenteeCheckBoxes">
          <div className="MenteeCheckBox">
            <div className="MenteeCheckBoxText">
              <h6>Mentee Progress</h6>
              <p>These are notifications letting you know about your mentee learning progress.</p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
          </div>
          <div className="MenteeCheckBox">
            <div className="MenteeCheckBoxText">
              <h6>Live Session Reminders</h6>
              <p>These are notifications letting you know about live sessions that are upcoming.</p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
          </div>
          <div className="MenteeCheckBox">
            <div className="MenteeCheckBoxText">
              <h6>Assignment Reminders</h6>
              <p>These are notifications letting you know you&apos;ve assignment you haven&apos;t seen or Graded</p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
          </div>
          <div className="MenteeCheckBox">
            <div className="MenteeCheckBoxText">
              <h6>Assignments Overdue</h6>
              <p>These are notifications letting you know you&apos;ve assignment that are due for Grading</p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MenteeCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MenteeCheckBox"}
              label={""}
            />
          </div>
        </div>
        <div className="MenteeNotificationsFrequency">
          <h6>Choose Email Frequency</h6>
          <div className="MenteeRadioButtons">
            <RadioButton 
              name={"notificationFrequency"}
              value={"daily"}
              label={"Daily"}
              className={"MenteeRadioButton"}
              id={"MenteeRadioButton"}
              onChange={handleRadioButtonChange}
            />
            <RadioButton 
              name={"notificationFrequency"}
              value={"weekly"}
              label={"Weekly"}
              className={"MenteeRadioButton"}
              id={"MenteeRadioButton"}
              onChange={handleRadioButtonChange}
            />
            <RadioButton 
              name={"notificationFrequency"}
              value={"monthly"}
              label={"Monthly"}
              className={"MenteeRadioButton"}
              id={"MenteeRadioButton"}
              onChange={handleRadioButtonChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenteeNotifications;
