
export const CalendarDates = [
  {
    title: "Mentorship Session",
    start: "2024-02-16T10:00:00",
    end: "2024-02-16T12:00:00",
    eventList: [
      "C.V Clinic with Jude ",
      "Port-Folio Review with Joshua",
      "Office hours with Mentees",
    ],
    color: "blue",
    background: "transparent",
  },
  {
    title: "A meeting",
    start: "2024-02-19",
    end: "2024-02-22T13:00:00",
    eventList: [
      "Meeting on Certification",
      "Advice to a mentee",
    ],
    color: "green",
  },
  {
    title: "Grading Session",
    start: "2024-02-17T12:00:00",
    end: "2024-02-17T13:00:00",
    eventList: [
      "Overall Grading of a mentee ",
      "Sending review to the admin",
      "A call with the mentee",
    ],
    color: "red",
  },
  {
    title: "Tutor Session",
    start: "2024-03-18T06:00:00",
    end: "2024-03-24T13:00:00",
    date: "Jan 31 - Feb 21",
    eventList: [
      "Explaining the guide of a certification ",
    ],
    color: "yellow",
  },
  {
    title: "Certification Review",
    start: "2024-02-24T12:00:00",
    end: "2024-02-25T13:00:00",
    date: "Jan 31 - Feb 21",
    eventList: [
      "C.V Review with Muhammad",
      "Port-Folio Review with Joshua",
    ],
    color: "brown",
  },
];
