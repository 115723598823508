import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
  isFormValid: false,
  error: {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
  

};
const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setFormData : (state, action) => {
      state.formData = {...state.formData, ...action.payload};
    },
    setFormValid: (state, action) => {
      state.isFormValid = action.payload;
    },
    setError: (state, action) => {
      state.error = {...state.error, ...action.payload};
    },
  }

});

export const { setFormData, setFormValid, setError } = registerSlice.actions;
export default registerSlice.reducer;