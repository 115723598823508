export const chat = [
  {  
    id: "001",
    avatar: "/icons/chat/chat01.png",
    userName: "Zulfawu Abubakar",
    timeStamp: "13:16",
    received: [
      "I'm glad you found my listing appealing! I'd be happy to answer any questions you have. Please go ahead and ask away.",
      "I completely understand. While I can't provide the exact address until you make a booking due to privacy reasons, I can tell you that my property is situated in the heart of the city, just a short walk from popular attractions, restaurants, and public transportation. It offers great convenience for exploring the area.",
      "I will do as you have advised and get back to you soonest"
    ],
    sent: [
      "Great! Firstly, could you provide me with the exact address of your property? I want to make sure it's conveniently located for the places I plan to visit.",
      "That sounds promising. Could you please provide some details about the amenities available in the accommodation? I want to ensure a comfortable stay.",
      "Awesome"
    ]
  },
  {
    id: "002",
    avatar: "/icons/chat/chat02.png",
    userName: "John Doe",
    timeStamp: "15:30",
    received: [
      "Hi there! Thank you for your interest in my property. Feel free to ask me anything you'd like to know.",
      "Certainly! My property is located near the beach, providing breathtaking ocean views. It's also close to various restaurants and entertainment venues.",
      "Sure thing. The amenities available include a swimming pool, gym, spa, and complimentary breakfast for guests."
    ],
    sent: [
      "Hello! Could you tell me about the location of your property? I'm interested in exploring the area.",
      "Sounds lovely! What amenities does your accommodation offer? I want to make sure it meets my needs.",
      "Thank you!"
    ]
  },
  {
    id: "003",
    avatar: "/icons/chat/chat3.png",
    userName: "Alice Smith",
    timeStamp: "11:45",
    received: [
      "Hello! I'm glad you're interested in my rental. What can I help you with?",
      "Absolutely! My property is nestled in the countryside, offering tranquility and scenic views. It's perfect for nature lovers and those seeking relaxation.",
      "Of course! Some of the amenities include a cozy fireplace, hiking trails, and a garden where you can enjoy outdoor activities."
    ],
    sent: [
      "Hi! Can you tell me more about the location of your property? I'm looking for a peaceful getaway.",
      "Sounds idyllic! What amenities are available to guests during their stay?",
      "Thank you for the information!"
    ]
  },
  {
    id: "004",
    avatar: "/icons/chat/chat4.png",
    userName: "Emily Johnson",
    timeStamp: "09:00",
    received: [
      "Hello! Welcome to my property listing. How may I assist you today?",
      "Certainly! My property is situated in the heart of the historic district, surrounded by charming architecture and cultural landmarks.",
      "Absolutely! Guests can enjoy amenities such as a rooftop terrace with panoramic views, a fully-equipped kitchen, and complimentary Wi-Fi."
    ],
    sent: [
      "Hi! I'm interested in your property. Can you provide some details about its location?",
      "Sounds wonderful! Could you please tell me more about the amenities available for guests?",
      "Thank you for the information!"
    ]
  },
  {
    id: "005",
    avatar: "/icons/chat/chat5.png",
    userName: "Michael Brown",
    timeStamp: "14:20",
    received: [
      "Hi there! Feel free to ask any questions you have about my property.",
      "Certainly! My property is located in a bustling neighborhood with easy access to shops, restaurants, and public transportation.",
      "Sure thing! Some of the amenities include a private balcony, fitness center, and 24-hour concierge service."
    ],
    sent: [
      "Hello! I'm interested in learning more about your property. Can you provide details about its location?",
      "Sounds great! Could you tell me about the amenities available for guests?",
      "Thank you!"
    ]
  },
  {
    id: "006",
    avatar: "/icons/chat/chat6.png",
    userName: "Sophia Martinez",
    timeStamp: "12:45",
    received: [
      "Hi! I'm glad you're interested in my property. Let me know how I can help you.",
      "Absolutely! My property is located in a peaceful suburban area, perfect for those seeking a relaxing retreat.",
      "Of course! Amenities include a spacious backyard, modern kitchen appliances, and a cozy fireplace."
    ],
    sent: [
      "Hello! Can you provide more information about the location of your property?",
      "Sounds lovely! What amenities does your accommodation offer?",
      "Thank you!"
    ]
  },
  {
    id: "007",
    avatar: "/icons/chat/chat7.png",
    userName: "Daniel Wilson",
    timeStamp: "10:10",
    received: [
      "Hello! Welcome to my property listing. I'm here to answer any questions you have.",
      "Certainly! My property is located in the heart of downtown, within walking distance of shops, restaurants, and cultural attractions.",
      "Absolutely! Some of the amenities include a rooftop pool, onsite gym, and complimentary breakfast."
    ],
    sent: [
      "Hi! I'm interested in your property. Can you provide details about its location?",
      "Sounds fantastic! Could you tell me more about the amenities available for guests?",
      "Thank you!"
    ]
  },
  {
    id: "008",
    avatar: "/icons/chat/chat8.png",
    userName: "Olivia Taylor",
    timeStamp: "08:30",
    received: [
      "Hi there! Feel free to ask any questions about my property.",
      "Certainly! My property is located in a serene mountainous area, offering stunning views and close proximity to hiking trails.",
      "Sure thing! Amenities include a hot tub, outdoor BBQ area, and cozy fireplace."
    ],
    sent: [
      "Hello! I'm interested in your property. Can you provide details about its location?",
      "Sounds amazing! What amenities does your accommodation offer?",
      "Thank you!"
    ]
  },
  {
    id: "009",
    avatar: "/icons/chat/chat9.png",
    userName: "William Anderson",
    timeStamp: "16:00",
    received: [
      "Hello! Welcome to my property listing. How can I assist you today?",
      "Certainly! My property is located in a vibrant urban neighborhood, known for its cultural diversity and trendy cafes.",
      "Absolutely! Guests can enjoy amenities such as a rooftop lounge, fitness center, and complimentary bicycle rentals."
    ],
    sent: [
      "Hi! I'm interested in your property. Can you provide details about its location?",
      "Sounds exciting! What amenities does your accommodation offer?",
      "Thank you!"
    ]
  },
  {
    id: "010",
    avatar: "/icons/chat/chat10.png",
    userName: "Emma Thompson",
    timeStamp: "17:30",
    received: [
      "Hi there! Feel free to ask any questions about my property.",
      "Certainly! My property is located in a charming coastal town, offering scenic views of the ocean and easy access to beaches.",
      "Sure thing! Amenities include a private beach access, outdoor pool, and on-site restaurant."
    ],
    sent: [
      "Hello! I'm interested in your property. Can you provide details about its location?",
      "Sounds delightful! What amenities does your accommodation offer?",
      "Thank you!"
    ]
  }
];
