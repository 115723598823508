export const careerRoles = [
  // Technology
  "Software Engineer",
  "Data Scientist",
  "Web Developer",
  "Network Administrator",
  "Cloud Architect",
  "UX/UI Designer",
  "Cybersecurity Analyst",
  "Machine Learning Engineer",
  "DevOps Engineer",
  "Product Manager",
  "Systems Analyst",
  "Database Administrator",
  "IT Project Manager",
  "Software Quality Assurance Engineer",
  "Technical Support Specialist",
  "Game Developer",
  "Artificial Intelligence (AI) Engineer",
  "Frontend Developer",
  "Backend Developer",
  "Full Stack Developer",
  // Business
  "Accountant",
  "Financial Analyst",
  "Marketing Manager",
  "Human Resources Manager",
  "Sales Representative",
  "Operations Manager",
  "Business Consultant",
  "Project Manager",
  "Supply Chain Manager",
  "Business Analyst",
  "Management Consultant",
  "Digital Marketing Specialist",
  "Investment Banker",
  "Real Estate Agent",
  "Risk Analyst",
  // Healthcare
  "Physician",
  "Registered Nurse",
  "Pharmacist",
  "Dentist",
  "Medical Technologist",
  "Physical Therapist",
  "Psychologist",
  "Occupational Therapist",
  "Speech-Language Pathologist",
  "Healthcare Administrator",
  "Clinical Research Coordinator",
  "Radiologic Technologist",
  "Pharmacy Technician",
  "Medical Assistant",
  // Arts and Media
  "Actor/Actress",
  "Graphic Designer",
  "Film Director",
  "Photographer",
  "Musician",
  "Art Director",
  "Journalist",
  "Editor",
  "Animator",
  "Content Creator",
  "Public Relations Specialist",
  "Event Planner",
  "Social Media Manager",
  "Copywriter",
  // Education
  "Teacher",
  "Professor",
  "School Counselor",
  "Librarian",
  "Principal",
  "Curriculum Developer",
  "Education Consultant",
  "Tutor",
  "Instructional Designer",
  "Special Education Teacher",
  "Educational Psychologist",
  "Academic Advisor",
  // Engineering
  "Mechanical Engineer",
  "Civil Engineer",
  "Electrical Engineer",
  "Chemical Engineer",
  "Biomedical Engineer",
  "Environmental Engineer",
  "Aerospace Engineer",
  "Petroleum Engineer",
  "Industrial Engineer",
  "Materials Engineer",
  "Nuclear Engineer",
  "Automotive Engineer",
  // Legal
  "Lawyer/Attorney",
  "Paralegal",
  "Legal Secretary",
  "Judge",
  "Legal Consultant",
  "Corporate Lawyer",
  "Criminal Lawyer",
  "Family Lawyer",
  "Intellectual Property Lawyer",
  "Immigration Lawyer",
  "Environmental Lawyer",
  "Civil Rights Lawyer",
  // Hospitality and Tourism
  "Hotel Manager",
  "Event Coordinator",
  "Travel Agent",
  "Tour Guide",
  "Restaurant Manager",
  "Cruise Director",
  "Resort Manager",
  "Hospitality Coordinator",
  "Flight Attendant",
  "Concierge",
  "Sommelier",
  "Chef",
  // Science
  "Biologist",
  "Chemist",
  "Physicist",
  "Geologist",
  "Ecologist",
  "Astrophysicist",
  "Meteorologist",
  "Microbiologist",
  "Zoologist",
  "Botanist",
  "Neuroscientist",
  "Oceanographer",
  // Social Services
  "Social Worker",
  "Counselor",
  "Community Health Worker",
  "Child Welfare Worker",
  "Substance Abuse Counselor",
  "Youth Worker",
  "Probation Officer",
  "Family Therapist",
  "Mental Health Counselor",
  "Victim Advocate",
  "Community Organizer",
  "Humanitarian Aid Worker",
  // Government and Nonprofit
  "Government Administrator",
  "Policy Analyst",
  "Community Organizer",
  "Fundraiser",
  "Grant Writer",
  "Volunteer Coordinator",
  "Public Administrator",
  "Nonprofit Manager",
  "Campaign Manager",
  "Legislative Aide",
  "International Development Worker",
  "Public Affairs Specialist",
  // Trades and Skilled Labor
  "Electrician",
  "Plumber",
  "Welder",
  "Carpenter",
  "HVAC Technician",
  "Machinist",
  "Auto Mechanic",
  "Construction Manager",
  "Boilermaker",
  "Bricklayer",
  "Painter",
  "Roofing Contractor",
];


