import React, { useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { AdminResource } from "../../data/AdminResourceData";
import { useParams } from "react-router-dom";
import "../../components/CoachProfile/CoachRoadMap/CoachFilledQuiz.style.scss";

const ResourceQuiz = () => {
  const [selected, setSelected] = useState(null);
  const [selectedAnswers, setSelectedAnswers] =
		useState({});
  const params = useParams();
  const index = Number(params?.index) - 1;
  const quiz =
		AdminResource[index]?.quiz;
  const handleOptionChange = (
    moduleIndex,
    questionIndex,
    selectedOption
  ) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [`${moduleIndex}-${questionIndex}`]:
				selectedOption,
    });
  };

  const toggle = (index) => {
    if (selected == index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <div className="FilledQuizSection">
      <div className="FilledQuizAccordionWrapper">
        {quiz.map((module, moduleIndex) => (
          <div
            className="FilledQuizAccordion"
            key={moduleIndex}>
            <div
              className="FilledQuizModuleName"
              onClick={() => toggle(moduleIndex)}>
              <h3>{module.module}</h3>
              <RiArrowDownSLine
                className={
                  selected === moduleIndex
                    ? "FilledQuizAccordionIcon rotate"
                    : "FilledQuizAccordionIcon"
                }
              />
            </div>
            <div
              className={
                selected === moduleIndex
                  ? "FilledQuizDetails show"
                  : "FilledQuizDetails"
              }>
              {module.questions.map(
                (question, questionIndex) => (
                  <div
                    key={questionIndex}
                    className="FilledQuizArea">
                    <h3>{question.question}</h3>
                    <ul className="FilledQuizOptions">
                      {question.options.map(
                        (option, optionIndex) => (
                          <li key={optionIndex}>
                            <label>
                              <input
                                type="radio"
                                name={`question-${moduleIndex}-${questionIndex}`}
                                value={option}
                                checked={
                                  selectedAnswers[
                                    `${moduleIndex}-${questionIndex}`
                                  ] === option
                                }
                                onChange={() =>
                                  handleOptionChange(
                                    moduleIndex,
                                    questionIndex,
                                    option
                                  )
                                }
                              />
                              {option}
                            </label>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourceQuiz;
