import { combineReducers } from "@reduxjs/toolkit";

import loginReducer from "../Slicers/loginSlice";
import registerReducer from "../Slicers/registerSlice";
import chatbotReducer from "../Slicers/chatbotSlice";
import roadMapReducer from "../Slicers/roadMapSlice";
import settingsReducer from "../Slicers/settingsSlice";
import loginAdminReducer from "../Slicers/loginAdminSlice";
import passkeyReducer from "../Slicers/passkeySlice";

export const rootReducer = combineReducers({
  register: registerReducer,
  login: loginReducer,
  loginAdmin: loginAdminReducer,
  chatbot: chatbotReducer,
  roadMap: roadMapReducer,
  settings: settingsReducer,
  passkey: passkeyReducer,
});
