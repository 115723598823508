export const changeAnchor = (anchor) => {
  switch (anchor) {

  case "left":
    return "left";
  case "right":
    return "right";
  default:
    return "right";
  }
};
