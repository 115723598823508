import React from "react";
import { RecruiterApplicationDetails } from "./RecruiterViewApplicant";
import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./RecruiterJobHiringStage.style.scss";
import { CustomSwitch } from "../../../Materials/Materials";

const SwitchSection = ({ title, options, optionalSwitch }) => {
  return (
    <Box className="SwitchSection">
      <Box className="Header">
        <Typography variant="h2">{title}</Typography>
        {optionalSwitch && <CustomSwitch />}
      </Box>
      <Box className="SendInvite">
        <Typography variant="p">
          <CustomSwitch />
          <span style={{ marginLeft: "16px" }}>{options[0].label}</span>
        </Typography>
        <Box as="div">
          <p>{options[1].label}</p>
          <CustomSwitch />
          <p>{options[2].label}</p>
        </Box>
      </Box>
    </Box>
  );
};

SwitchSection.propTypes = {
  title: PropTypes.string,
  optionalSwitch: PropTypes.bool,
  options: PropTypes.array,
};

const RecruiterJobHiringStage = () => {
  return (
    <Box component="section" className="JobHiringStageContainer">
      <RecruiterApplicationDetails />
      <SwitchSection
        title="Send Invite"
        optionalSwitch={true}
        options={[
          { label: "Sent" },
          { label: "Declined" },
          { label: "Accepted" },
        ]}
      />
      <SwitchSection
        title="Assessment"
        options={[{ label: "Required" }, { label: "Fail" }, { label: "Pass" }]}
      />
      <SwitchSection
        title="Offer Extended"
        options={[
          { label: "Yes" },
          { label: "Declined" },
          { label: "Accepted" },
        ]}
      />
      <Box className="ButtonsSection">
        <Button>Cancel</Button>
        <Button className="save">Save</Button>
      </Box>
    </Box>
  );
};

export default RecruiterJobHiringStage;
