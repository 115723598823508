import React, { useState, useEffect } from "react";
import { SelectField } from "../../../Materials/InputFields";
import "./RecruiterJobPreferences.style.scss";

const RecruiterJobPreferences = () => {

  const initialValues = {
    specialization: "",
    roles: "",
    preference: "",
    level: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const formIsValid = Object.values(formData).every((value) => value.trim() !== "");
    setIsFormValid(formIsValid);
  }, [formData]);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (isFormValid) {
      console.log("Form submitted successfully!");
    } else {
      console.log("Form validation failed. Please fill out all fields.");
    }
  };

  return (
    <div className="RecruiterPreferencesContainer">
      <div className="RecruiterPreferencesContent">
        <h2>Job Preferences</h2>
        <div className="RecruiterPreferencesWrapper">
          <p>Industrial Specialisation</p>
          <button>
            <SelectField 
              values={ ["Technology", "Healthcare", "Retail", "Manufacturing", "Education"] }
              className={"RecruiterSelectField"}
              onChange={handleSelectChange}
              selectedValue={formData.specialization}
            />
          </button>
        </div>
        <div className="RecruiterPreferencesWrapper">
          <p>Types of Roles</p>
          <button>
            <SelectField 
              values={["Full-Time", "Part-Time", "Temporary", "Contract", "Internship"]}
              className={"RecruiterSelectField"}
              onChange={handleSelectChange}
              selectedValue={formData.roles}
            />
          </button>
        </div>
        <div className="RecruiterPreferencesWrapper">
          <p>Geographic Preference</p>
          <button>
            <SelectField
              className={"RecruiterSelectField"}
              values={ ["Remote", "Onsite", "Hybrid", "Willing to relocate Candidiate", "Local Onsite"] }
              onChange={handleSelectChange}
              selectedValue={formData.preference}
            />
          </button>
        </div>
        <div className="RecruiterPreferencesWrapper">
          <p>Your Experience Level</p>
          <button>
            <SelectField
              className={"RecruiterSelectField"}
              values={["Entry Level", "Mid-Level", "Senior Level", "Executive" ]}
              onChange={handleSelectChange} 
              selectedValue={formData.level}
            />
          </button>
        </div>

        <button 
          name={"Proceed"}
          className={"RecruiterPreferencesButton"}
          onClick={handleSubmit}
          disabled={isFormValid}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default RecruiterJobPreferences;