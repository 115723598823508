import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiArrowDownSLine } from "react-icons/ri";
import { IoAddCircleOutline } from "react-icons/io5";
import { modules } from "../../../data/roadmapData";
import "./AddCourseResource.style.scss";

const AddCourseResource = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (index) => {
    if (selected == index) {
      return setSelected(null);
    }
    setSelected(index);
  };
  return (
    <div className="AddCourseResourceContainer">
      <div className="CourseModuleResources">
        {modules.map((data, index) => (
          <div
            className="ModuleResourcesAccordionWrapper"
            key={index}>
            <div
              className="ModuleResourcesHeading"
              onClick={() => toggle(index)}>
              <h4>{data.title}</h4>
              <RiArrowDownSLine
                className={
                  selected === index
                    ? "ModuleResourcesAccordionIcon rotate"
                    : "ModuleResourcesAccordionIcon"
                }
              />
            </div>
            <div
              className={
                selected === index
                  ? "ModuleResourcesAdd show"
                  : "ModuleResourcesAdd"
              }>
              <p>Add a course resource</p>
              <IoAddCircleOutline className="ModuleResourcesAddIcon" />
            </div>
          </div>
        ))}
      </div>
      <div className="ModuleResourcesButton">
        <Link
          to={
            "/coach/profile/roadmap/course_quiz"
          }>
          <button>Next</button>
        </Link>
      </div>
    </div>
  );
};

export default AddCourseResource;
