import React from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import placeholder from "../../../images/placeholder.png";
import "./addcert.style.scss";

const AddCert = () => {
  return (
    <div className="AddCertificateContainer">
      <div className="CertificateHeader">
        <h1>Course Certificate</h1>
        <HiOutlineDotsVertical className="CertIcon" />
      </div>
      <div className="CertificateUpload">
        <input type="file" id="UploadFile1" />
        <img src={placeholder} alt="placeholder" />
        <p>Please upload your professional certificate</p>
        <input type="file" id="UploadFile2"  />
        <h6>Browse File</h6>
      </div>
      <div className="AddCertificateButtons">
        <button id="SaveCertificate">Save Certificate</button>
        <button id="Publish">Publish</button>
      </div>
    </div>
  );
};

export default AddCert;