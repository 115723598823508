import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import PropTypes from "prop-types";
import "./TopSection.style.scss";

const TopSection = ({ children }) => {
  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate();

  const activeStyle = {
    borderBottom: "2px solid var(--Foundation-PRI-pri-500, #2A53A9)",
    color: "var(--Foundation-PRI-pri-500, #2A53A9)"
  };

  const handleButtonClick = (button) => {
    setIsActive(button);
    if (isActive === "applicant_details") {
      navigate("/recruiter/profile/jobs/applicant_details");
    }
    if (isActive === "hiring_stage") {
      navigate("/recruiter/profile/jobs/hiring_stage");
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log(currentPath);
    switch (currentPath) {
    case "/recruiter/profile/jobs/applicant_details":
      setIsActive("applicant_details");
      break;

    case "/recruiter/profile/jobs/hiring_stage":
      setIsActive("hiring_stage");
      break;

    default:
      setIsActive(null);
      break;
    }
  }, [history]);

  const handleTabIconClick = (direction) => {
    const tabs = ["applicant_details", "hiring_stage"];
    let currentIndex = tabs.indexOf(isActive);

    if (currentIndex !== -1) {
      let newIndex = direction === "back" ? currentIndex - 1 : currentIndex + 1;
      newIndex = (newIndex + tabs.length) % tabs.length;
      const newActiveTab = tabs[newIndex];

      setIsActive(newActiveTab);
      navigate(`/recruiter/profile/jobs/${newActiveTab}`);
    }
  };

  return (
    <div className="ApplicantJobViewContainer">
      <div className="ApplicantJobViewContent">
        <Link
          to={"/recruiter/profile/jobs"}
          style={{ textDecoration: "none" }}
          className="ApplicantJobViewNavigation"
        >
          <IoArrowBack className="ApplicantBackIcon" />
          <p>Back</p>
        </Link>
        <div className="JobViewTopsection">
          <div className="ApplicantProfile">
            <img src="" alt="" />
            <span>
              <h6>Kweenier Hamza Jane</h6>
              <p>Senior Designer</p>
            </span>
          </div>
          <button>Send Message</button>
        </div>
        <div className="ApplicantJobTabsSection">
          <div className="JobViewTabs">
            <Link
              className="JobViewTab"
              to={"/recruiter/profile/jobs/applicant_details"}
              onClick={() => handleButtonClick("applicant_details")}
            >
              <button
                style={isActive === "applicant_details" ? activeStyle : {}}
              >
                Applicant Details
              </button>
            </Link>
            <Link
              className="JobViewTab"
              to={"/recruiter/profile/jobs/hiring_stage"}
              onClick={() => handleButtonClick("hiring_stage")}
            >
              <button style={isActive === "hiring_stage" ? activeStyle : {}}>
                Hiring Stage
              </button>
            </Link>
          </div>
          <div className="JobViewTabsNavigation">
            <div
              className="IconWrapper"
              onClick={() => handleTabIconClick("back")}
            >
              <MdArrowBackIosNew className="TabIcon" />
            </div>
            <div
              className="IconWrapper"
              onClick={() => handleTabIconClick("forward")}
            >
              <MdArrowForwardIos className="TabIcon" />
            </div>
          </div>
        </div>
        <div className="ApplicantJobTabDetails">{children}</div>
      </div>
    </div>
  );
};
TopSection.propTypes = {
  children: PropTypes.node
};

export default TopSection;
