import React from "react";
import "./ResourceCertificate.style.scss";
import { HiOutlineDotsVertical } from "react-icons/hi";
import certificate from "../../images/certificate.png";

const ResourceCertificate = () => {
  return (
    <div className="ResourceCertificateContainer">
      <div className="CertificateHeader">
        <h1>Course Certificate</h1>
        <HiOutlineDotsVertical className="CertIcon"/>
      </div>
      <div className="CertificateImage">
        <img
          src={certificate}
          alt="certificate"
        />
      </div>
    </div>
  );
};

export default ResourceCertificate;
