/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import "./coachsubpages.style.scss";
import CoachChatPage from "./CoachChatPage";
import CoachGeneralChat from "./CoachGeneralChat";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import { LiaSmileBeam } from "react-icons/lia";
import { AiOutlineSend } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useMyContext } from "../../../Utils/ContextProvider";

const CoachMessage = () => {
  const [openEmojis, setOpenEmojis] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const socket = io("");
  const [selectedEmoji, setSelectedEmoji] = useState("1f60a");
  const [inputValue, setInputValue] = useState("");
  const { data } = useMyContext();
  function onClick(emojiData, event) {
    setInputValue(
      (inputValue) =>
        inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji)
    );
    setSelectedEmoji(emojiData.unified);
  }
  // useEffect(() => {
  //   // Establish a connection to the Socket.IO server and handle incoming messages.
  //   socket.on("message", (message) => {
  //     setMessages((prevMessages) => [...prevMessages, message]);
  //   });

  //   // Clean up the socket connection when the component unmounts.
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  const handleFocus = () => {
    setOpenEmojis(false);
  };
  const sendMessage = () => {
    if (newMessage) {
      socket.emit("sendMessage", newMessage);
      setNewMessage("");
    }
  };
  const handleEmojis = () => {
    setOpenEmojis(!openEmojis);
  };
  const chatPicked = data.map(element => element.chatIsPicked)[0];
  const chatMessage = data.map((element) => element.chatMessage)[0];
  console.log(chatPicked);
  return (
    <>
      <div className="JsChatArea">
        <div className="custom-emoji-picker">
          {openEmojis && (
            <EmojiPicker
              height="400px"
              width="250px"
              onEmojiClick={onClick}
              autoFocusSearch={false}
              emojiStyle={EmojiStyle.NATIVE}
            />
          )}
        </div>
        <div className="JsChatRoom">
          {chatPicked ? (
            <CoachChatPage
              userMessage={userMessage}
              chatMessage={chatMessage}
            />
          ) : (
            <CoachGeneralChat />
          )}
        </div>
        <div className="JsTextArea">
          <textarea
            cols="97"
            rows="3.5"
            placeholder="send a message"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={handleFocus}
          ></textarea>
          <div>
            <button id="smileySet" onClick={handleEmojis}>
              <LiaSmileBeam id="smileyIcon" />
            </button>
            <button id="groupButton">
              <BiDotsHorizontalRounded id="dotIcon" />
            </button>
            <button id="sendButton" onClick={sendMessage}>
              <AiOutlineSend id="sendIcon" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoachMessage;
