import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { BsSearch} from "react-icons/bs";
import { SelectField } from "../../../Materials/InputFields";
import { HiDotsHorizontal } from "react-icons/hi";
import SeeMoreModal from "./SeeMoreModal";
import MenteesModal from "./MenteesModal";

import "./CoachMentee.style.scss";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "number", label: "Phone\u00a0Number", minWidth: 100 },
  {
    id: "emailAddress",
    label: "Email Address",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "point",
    label: "Point",
    minWidth: 170,
    align: "center",
    format: (value) => (
      <div className="PointContainer">
        <span style={{color: "var(--alert-succes-500-base, #00C781)"}}>{value}</span>
        <span style={{color: "var(--Foundation-PRI-pri-500, #2A53A9)"}}>/120</span>
      </div>
    )
  },
  {
    id: "seeMore",
    label: "See\u00a0More",
    minWidth: 170,
    align: "center",
  },
];

function createData(name, number, emailAddress, point, seeMore) {
  return { name, number, emailAddress, point, seeMore };
}

const rows = [
  createData("John Melody", "+234(0)813782038", "Johnmelodydoe@gmail.com", 100),
  createData("Emma Johnson", "+234(0)123456789", "emma.j@example.com", 85),
  createData("Michael Smith", "+234(0)987654321", "michael.smith@example.com", 72),
  createData("Sophia Davis", "+234(0)456789012", "sophia.d@example.com", 93),
  createData("William Anderson", "+234(0)789012345", "william.a@example.com", 64),
  createData("Olivia Wilson", "+234(0)234567890", "olivia.w@example.com", 78),
  createData("James Brown", "+234(0)567890123", "james.b@example.com", 91),
  createData("Ava Miller", "+234(0)890123456", "ava.m@example.com", 87),
  createData("Alexander Martinez", "+234(0)345678901", "alexander.m@example.com", 69),
  createData("Sophie Thompson", "+234(0)678901234", "sophie.t@example.com", 80),
  createData("Daniel White", "+234(0)109876543", "daniel.w@example.com", 94),
  createData("Mia Harris", "+234(0)543210987", "mia.h@example.com", 75),
  createData("Ethan Clark", "+234(0)876543210", "ethan.c@example.com", 88),
  createData("Isabella Turner", "+234(0)321098765", "isabella.t@example.com", 97),
  createData("Liam Moore", "+234(0)654321098", "liam.m@example.com", 82),
  createData("Amelia Lee", "+234(0)210987654", "amelia.l@example.com", 68),
  createData("Logan Cooper", "+234(0)876543210", "logan.c@example.com", 73),
  createData("Oliver Taylor", "+234(0)543210987", "oliver.t@example.com", 89),
  createData("Harper Turner", "+234(0)987654321", "harper.t@example.com", 77),
  createData("Elijah Walker", "+234(0)123456789", "elijah.w@example.com", 96),
  createData("Aria Wright", "+234(0)987654321", "aria.w@example.com", 84)
];

const CoachMentee = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState(false);
  const [isMenteeModalOpen, setIsMenteeModalOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSelectChange = () => {
    return;
  };
  const handleSeeMoreClick = () => {
    setIsSeeMoreModalOpen(true);
  };
  const handleCloseSeeMoreModal = () => {
    setIsSeeMoreModalOpen(false);
  };
  const handleOpenMenteeModal = () => {
    setIsMenteeModalOpen(true);
  };
  const handleCloseMenteeModal = () => {
    setIsMenteeModalOpen(false);
  };

  return (
    <div className="CoachMenteeContainer">
      <div className="ContainerContentWrapper">
        <div className="MenteeTopSection">
          <div className="MenteeTopSectionBox">
            <p>Total Registered Mentees</p>
            <p>0</p>
          </div>
          <div className="MenteeTopSectionBox">
            <p>Total Active Mentees</p>
            <p>0</p>
          </div>
          <div className="MenteeTopSectionBox">
            <p>Total Inactive Mentees</p>
            <p>0</p>
          </div>
          <div className="MenteeTopSectionBox">
            <p>Total Revenue Generated</p>
            <p>₦{" "}0</p>
          </div>
        </div>
        <div className="MenteeSearchArea">
          <button>See all mentees</button>
          <div className="MenteeSearchInputField">
            <BsSearch className="MenteeSearchIcon" />
            <input 
              type="text"
              placeholder={"Search course keyword or title"}
              className={"SearchInputField"} 
              onChange={""}
              value={""}
            />
          </div>
          <SelectField
            values={["Filter By"]}
            onChange={handleSelectChange}
            className={"MenteeSelectField"}
            id={"MenteeSelectField"} 
          />
        </div>
        <Paper className="MenteePaper">
          <TableContainer className="MenteeTableContainer" sx={{ maxHeight: 440 }}>
            <Table className="MenteeTable">
              <TableHead className="MenteeTableHead">
                <TableRow className="MenteeTableRow">
                  {columns.map((column) => (
                    <TableCell
                      className="MenteeTableCell"
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="MenteeTableBody">
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow className="MenteeTableBodyRow" hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column, colIndex) => {
                          const value = row[column.id];
                          return (
                            <TableCell 
                              className="MenteeTableBodyCell" 
                              key={column.id} 
                              align={column.align}
                              onClick={() => {
                                if (column.id === "name") {
                                  handleOpenMenteeModal();
                                }
                              }}
                            >
                              {colIndex === columns.length - 1 ? (
                              // Render icon in the last column
                                <div className="SeeMoreIconContainer">
                                  <HiDotsHorizontal onClick={handleSeeMoreClick} className="SeeMoreIcon" />
                                </div>
                              ) : (
                              // Render regular cell content
                                column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {isSeeMoreModalOpen && <SeeMoreModal onClose={handleCloseSeeMoreModal} /> }
        {isMenteeModalOpen && <MenteesModal onClose={handleCloseMenteeModal}/>}
      </div>
    </div>
  );
};

export default CoachMentee;
