/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
} from "react";
import {
  useNavigate,
} from "react-router-dom";
import "./ProfileDetail.style.scss";
import {
  TextField,
  SelectField,
  TextArea,
} from "../../../Materials/InputFields";
import imageLoader from "../../../static/images/icons/user.png";
import WelcomeCard from "./WelcomeCard";
import { toast } from "react-toastify";
import { careerRoles } from "../../../data/CareerRoles";
import { countryNames } from "../../../data/CountryLists";
import { putWithFileApi, getApi } from "../../../Api/server";
import CircularProgress from "@mui/material/CircularProgress";
import "react-circular-progressbar/dist/styles.css";

import AlertCard from "../../Cards/AlertCard";
import WorkExperience from "./WorkExperience";
import EducationHistory from "./EducationHistory";
import ImportantSites from "./ImportantSites";

const initialValues = {
  firstName: "",
  lastName: "",
};
const fields = {
  location: "",
  yearsOfExperience: "",
};

const ProfileDetail = () => {
  const [formData, setFormData] = useState(
    initialValues
  );

  const [isNewUser, setIsNewUser] =
		useState(true);
  const [file, setSelectFile] = useState("");
  const [image, setImage] = useState("");
  const [instantImage, setInstantImage] =
		useState("");
  const [careerRole, setCareerRole] =
		useState("");
  const [suggestions, setSuggestions] = useState(
    []
  );
  const [countries, setCountries] = useState([]);
  const [biography, setBiography] = useState("");
  const [selectedFields, setSelectedField] = useState(fields);
  const [disableFirstButton, setDisableFirstButton] = useState(false);
  const [profileStrength, setProfileStrength] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [socialHandles, setSocialHandles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  

  const maxWord = 50;
  const wordCount = biography
    .trim()
    .split(/\s+/).length;


  useEffect(() => {
    setIsLoading(true);
    const jobSeekerProfile = async() => {
      const response = await getApi(
        "e1/job_seeker/profile",  
      );
      if (!response)
      {
        setTimeout(() => {
          setIsLoading(false);
        }, 4000);
        return;
      }
      else if (response?.code !== 200)
      {
        navigate("/login");
      }else{
        const data = response?.data;
        setSocialHandles(data?.importantSites);
        setFormData({
          firstName: data.firstName,
          lastName: data.lastName,
        });
        setCareerRole(data.careerRole);
        setSelectedField({ yearsOfExperience: data.yearsOfExperience, location: data.location });
        setInstantImage(data.profilePicture);
        setBiography(data.biography);
        const profileStrength = Number(
          data?.profileStrength.split("%")[0]
        );
        setProfileStrength(profileStrength);
        if(profileStrength === 30){
          setDisableFirstButton(true);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      }
      
    };
    jobSeekerProfile();
  
    
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSelectChange = (
    fieldName,
    selectedValue
  ) => {
    setSelectedField({
      ...selectedFields,
      [fieldName]: selectedValue,
    });
  };
  const handleBiography = (e) => {
    const name = e.target.value;
    setBiography(name);
  };
  const handleChooseCareer = (e) => {
    const value = e.target.value;
    setCareerRole(value);
    if (value === "") {
      setSuggestions([]);
      return;
    }
    const filteredSuggestion = careerRoles.filter(
      (role) =>
        role
          .toLowerCase()
          .includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestion);
  };
  const handleSuggestionClick = (suggestion) => {
    setCareerRole(suggestion);
    setSuggestions([]);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 1 * 1024 * 1024;
    const minSize = 50 * 1024;
    const maxWidth = 1024;
    const maxHeight = 1024;
    if (
      file.size > maxSize ||
			file.size < minSize
    ) {
      toast.error(
        "File is too large or too small. File must be between 50kb and 1mb"
      );
      event.target.value = "";
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          console.log(width, height);
          if (
            width > maxWidth ||
						height > maxHeight
          ) {
            toast.error(
              `Image dimensions ${
                width + "X" + height
              } are high. File must be or below 512 X 512`
            );
            event.target.value = "";
            setInstantImage("");
            setSelectFile("");
            return;
          }
        };
        const base64 =
					reader.result.split(",")[1];
        setImage(base64);
        setInstantImage(reader.result);
      };
      setSelectFile(file);
      reader.readAsDataURL(file);
    }
  };
  const handleRemoveFile = () => {
    setSelectFile("");
    setInstantImage("");
    setImage("");
  };

  useEffect(() => {
    countryNames
      .then((result) => {
        setCountries(result?.sort());
      })
      .catch((error) => console.log(error));
  }, [countryNames]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      selectedFields.yearsOfExperience,
      selectedFields.yearsOfExperience,
      selectedFields.location,
      biography,
      careerRole,
      file
    );
    if (
      biography.split(" ").length > 250
    ) {
      toast.error(
        "Biography must not be more tha 250 words"
      );
      return;
    }
    const form = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      yearsOfExperience:
				selectedFields.yearsOfExperience,
      location: selectedFields.location,
      biography: biography,
      careerRole: careerRole,
    };
    const formFile = new FormData();
    formFile.append("file", file);
    formFile.append("data", JSON.stringify(form));
    const response = await putWithFileApi(
      "e1/update/job_seeker",
      formFile
    );
    if(response.code === 403){
      navigate("/login");
    }
  };
  const handleProceed = (e) => {
    e.preventDefault();
    setAddress(
      "/job_seeker/profile/job_preferences"
    );
    if (profileStrength < 55)
    {
      if (profileStrength < 31)
      {
        setMessage("You haven't submitted any user details, work experience, education and social handles");
        setIsAlert(true);
        return;
      }
      if (profileStrength > 30 && profileStrength < 41)
      {
        setMessage(
          "You haven't submitted any work experience, education and social handles"
        );
        setIsAlert(true);
        return;
      }
      if (
        profileStrength > 40 &&
				profileStrength < 51
      ) {
        setMessage(
          "You haven't submitted any education and social handles"
        );
        setIsAlert(true);
        return;
      }
      if (
        profileStrength > 50 &&
				profileStrength < 55
      ) {
        setMessage(
          "You haven't submitted any social handles"
        );
        setIsAlert(true);
        return;
      }
    }else {
      navigate("/job_seeker/profile/job_preferences");
    }
    
  };

  return isLoading ? (
    <CircularProgress
      sx={{ width: "20px", height: "20px" }}
      style={{
        width: "20px",
        height: "20px",
      }}
    />
  ) : (
    <div className="ProfileDetailContainer">
      {isNewUser && (
        <WelcomeCard
          setIsNewUser={setIsNewUser}
        />
      )}
      {isAlert && (
        <AlertCard
          isOpen={isAlert}
          onClose={setIsAlert}
          message={message}
          address={address}
        />
      )}
      <div className="ProfileDetailInnerContainer">
        <div className="ProfilePhotoSection">
          <div className="ProfilePhotoHeader">
            <p>Profile Photo</p>
          </div>
          <div className="ProfilePhotoHandler">
            <div className="ProfileImageLoader">
              <span className="ProfileImageSpace">
                <img
                  src={
                    instantImage !== ""
                      ? instantImage
                      : imageLoader
                  }
                  alt="Profile"
                />
              </span>
              <span className="ProfileImageType">
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleImageChange}
                />
                <button className="ProfileImageUpload">
									Upload
                </button>
                <button
                  className="ProfileImageRemove"
                  onClick={handleRemoveFile}>
									remove
                </button>
              </span>
            </div>
            <div className="ProfileImageRequirement">
              <p>Image Requirement</p>
              <ol>
                <li>Min 300 x 300</li>
                <li>Max 1MB</li>
                <li>Your face or company Logo</li>
              </ol>
            </div>
          </div>
        </div>
        <hr className="ProfileLineBreaker" />

        <div className="ProfileDetailSection">
          <div className="ProfileDetailHeader">
            <h3>User Details</h3>
          </div>
          <div className="ProfileDetailForm">
            <TextField
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleInputChange}
              label="First Name"
              placeholder="Enter your first name"
              className="ProfileTextField"
              id="ProfileTextField"
              disabled={
                formData.firstName !== ""
                  ? true
                  : false
              }
            />
            <TextField
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleInputChange}
              label="Last Name"
              placeholder="Enter your last name"
              className="ProfileTextField"
              id="ProfileTextField"
              disabled={
                formData.lastName !== ""
                  ? true
                  : false
              }
            />
            <TextField
              label="Select Career Role"
              value={careerRole}
              onChange={handleChooseCareer}
              className="ProfileInputFieldOne"
              id="ProfileInputFieldOne"
              placeholder="Select your career role"
              onBlur={() => setSuggestions([])}
              disabled={
                careerRole !== "" ? true : false
              }
            />
            {suggestions.length > 0 && (
              <ul className="suggestionsList">
                {suggestions.map(
                  (suggestion, index) => (
                    <li
                      key={index}
                      onClick={() =>
                        handleSuggestionClick(
                          suggestion
                        )
                      }>
                      {suggestion}
                    </li>
                  )
                )}
              </ul>
            )}
            <div className="ProfileSelectField">
              <SelectField
                label="Years of Experience"
                selectedValue={
                  selectedFields.yearsOfExperience
                }
                onChange={(e) =>
                  handleSelectChange(
                    "yearsOfExperience",
                    e.target.value
                  )
                }
                className="ProfileSelectFieldTwo"
                id="ProfileSelectFieldTwo"
                values={Array.from(
                  { length: 40 },
                  (_, index) => index + 1
                )}
                disabled={
                  selectedFields.yearsOfExperience !==
									""
                    ? true
                    : false
                }
              />
              <SelectField
                label="Location"
                selectedValue={
                  selectedFields.location
                }
                onChange={(e) =>
                  handleSelectChange(
                    "location",
                    e.target.value
                  )
                }
                className="ProfileSelectFieldTwo"
                id="ProfileSelectFieldTwo"
                values={[...countries]}
                disabled={
                  selectedFields.location !== ""
                    ? true
                    : false
                }
              />
            </div>
            <div className="TextArea">
              <TextArea
                label="Biography"
                className="ProfileTextArea"
                id="ProfileTextArea"
                value={biography}
                onChange={handleBiography}
                placeholder="Let viewers know about you"
                disabled={
                  biography !== "" ? true : false
                }
              />
              <div
                className="wordCount"
                style={{ textAlign: "right" }}>
                <span>
                  {wordCount}/{maxWord}
                </span>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              disabled={disableFirstButton}
              className="ProfileSaveButton">
							Save
            </button>
            <hr className="ProfileDivider" />

            {/* Work Experience area */}
            <WorkExperience />
            <hr className="ProfileDivider" />

            {/* Education Experience Area */}
            <EducationHistory />
            <hr className="ProfileDivider" />

            {/* Import Social Links */}
            <ImportantSites
              socialHandles={socialHandles}
            />

            <div className="HandleProceedButton">
              <button onClick={handleProceed}>
								Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetail;
