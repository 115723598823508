import React from "react";
import "./coachsubpages.style.scss";

const CoachRoadmap = () => {
  return (
    <div className="CoachRoadmap">
      <div className="SubjectRoadmap">
        <p>Data Science Roadmap</p>
        <a href="#">Download</a>
      </div>
      <div className="coachRoadmapImage">
        <img
          src="/icons/images/coachRoadmap.png"
          alt=""
          width={700}
          height={500}
        />
      </div>
    
    </div>
  );
};

export default CoachRoadmap;