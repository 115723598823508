import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TiArrowLeft } from "react-icons/ti";
import { changeAnchor } from "./DrawerHelper";
import PropTypes from "prop-types";
import "./CreateRoleDrawer.scss";
import { Toggle } from "../../Materials/ToggleMui";

const CreateRoleDrawer = ({ onClose, anchor, open }) => {
  const initialState = {
    roleName: "",
    description: "",
  };
  const [state, setState] = useState(initialState);
  const [selected, setSelected] = useState(null);
  const [allTogglesEnabled, setAllTogglesEnabled] = useState(false);

  const toggle = (index) => {
    if (selected == index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  const handleEnableAll = () => {
    setAllTogglesEnabled(!allTogglesEnabled);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <div
      className={`overlay ${!open && "overlayHidden"} ${open && "overlayOpen"}`}
      
      aria-hidden="true"
    >
      <div
        tabIndex="-1"
        className={`drawer ${open ? "animate" : ""} ${
          !open ? "hidden" : ""
        } ${changeAnchor(anchor, "")}`}
      >
        <div className="CreateRoleContent">
          <div className="CreateRoleHeader">
            <TiArrowLeft className="ArrowHeader" onClick={onClose} />
            <h2>Roles and Permission</h2>
          </div>
          <div className="CreateRoleFields">
            <div className="CreateRoleTexts">
              <h5>Basic Details</h5>
              <p>Add basic information about this role you are creating</p>
            </div>
            <div className="InputFields">
              <div className="InputField">
                <label htmlFor="roleName">Role Name</label>
                <input
                  type="text"
                  value={state.roleName}
                  onChange={handleInputChange}
                  id="InputField"
                  name="roleName"
                  placeholder="Super Admin"
                />
              </div>
              <div className="InputField">
                <label htmlFor="permission">Role Description</label>
                <input
                  type="text"
                  value={state.permission}
                  onChange={handleInputChange}
                  id="InputField"
                  name="permission"
                  placeholder="Has absolute power to create, delete, assign and view data"
                />
              </div>
            </div>
            <div className="Permissions">
              <span>
                <h5>Set Permissions</h5>
                <p>Modify what individual on this role can do</p>
              </span>
              <div className="PermissionRole">
                <div className="Accordion">
                  <div className="AccordionHeading" >
                    <h4 onClick={() => toggle(0)}>Users</h4>
                    <span>
                      <Toggle 
                        text={"Enable all"}
                        className={"ToggleText"} 
                        onClick={handleEnableAll}
                        enabled={allTogglesEnabled}
                      />
                      <MdKeyboardArrowDown
                        className={
                          selected === 0
                            ? "AccordionArrow rotate"
                            : "AccordionArrow"
                        }
                      />
                    </span>
                  </div>
                  <div className={ selected === 0 ? "AccordionContent show" : "AccordionContent"}>
                    <div className="ContentToggles">
                      <Toggle text={"Access users dashboard"} disabled={!allTogglesEnabled}/>
                      <hr className="ToggleDivider" />
                      <Toggle text={"Edit users"} disabled={!allTogglesEnabled}/>
                      <hr className="ToggleDivider" />
                      <Toggle text={"Delete users"} disabled={!allTogglesEnabled}/>
                      <hr className="ToggleDivider" />
                    </div>
                    <div className="ContentToggles">
                      <Toggle text={"Create new users"} disabled={!allTogglesEnabled}/>
                      <hr className="ToggleDivider" />
                      <Toggle text={"Message users"} disabled={!allTogglesEnabled}/>
                      <hr className="ToggleDivider" />
                      <Toggle text={"View users"} disabled={!allTogglesEnabled}/>
                      <hr className="ToggleDivider" />
                    </div>
                  </div>
                </div>
                <div className="Accordion">
                  <div className="AccordionHeading">
                    <h4  onClick={() => toggle(1)}>Resources</h4>
                    <span>
                      <Toggle 
                        text={"Enable all"}
                        className={"ToggleText"} 
                      />
                      <MdKeyboardArrowDown
                        className={
                          selected === 1
                            ? "AccordionArrow rotate"
                            : "AccordionArrow"
                        }
                      />
                    </span>
                  </div>
                  <div className={ selected === 1 ? "AccordionContent show" : "AccordionContent"}>
                    <div className="ContentToggles">
                      <Toggle text={"View resources"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Edit resources"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Delete resources"} />
                      <hr className="ToggleDivider" />
                    </div>
                    <div className="ContentToggles">
                      <Toggle text={"Approve resources"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Download resources"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Download resources"} />
                      <hr className="ToggleDivider" />
                    </div>
                  </div>
                </div>
                <div className="Accordion">
                  <div className="AccordionHeading" >
                    <h4 onClick={() => toggle(2)}>Settings</h4>
                    <span>
                      <Toggle 
                        text={"Enable all"}
                        className={"ToggleText"} 
                      />
                      <MdKeyboardArrowDown
                        className={
                          selected === 2
                            ? "AccordionArrow rotate"
                            : "AccordionArrow"
                        }
                      />
                    </span>
                  </div>
                  <div className={ selected === 2 ? "AccordionContent show" : "AccordionContent"}>
                    <div className="ContentToggles">
                      <Toggle text={"Access roles and permission settings"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Edit roles and permission settings"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Delete roles and permissiom"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Access user admin settings"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Edit user admin"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"View user admin"} />
                      <hr className="ToggleDivider" />
                    </div>
                    <div className="ContentToggles">
                      <Toggle text={"Create roles and permission"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"View roles and permission"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Assign roles and permissions"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Create user admin"} />
                      <hr className="ToggleDivider" />
                      <Toggle text={"Delete user admin"} />
                      <hr className="ToggleDivider" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ActivityButtons">
              <button onClick={onClose}>Cancel</button>
              <button onClick={handleSubmit}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateRoleDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateRoleDrawer;
