import React, { useState, useRef } from "react";
import "./CoachPreferences.style.scss";
import { useNavigate } from "react-router-dom";
import { SelectField } from "../../../Materials/InputFields";
import Slider from "@mui/material/Slider";
import { UploadIcon } from "../../../svgs/SvgComponent";


function valuetext(value) {
  return "$" + value;
}

const CoachPreferences = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState([2500, 7500]);
  const fileInputRef = useRef(null);

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSelectChange = () => {
    return;
  };
  const handleSubmit = () => {
    navigate("/coach/profile/culture");
  };

  return (
    <div className="PreferencesContainer">
      <div className="PreferencesWrapper">
        <div className="PreferencesHeader">
          <h3>Course Offerings</h3>
        </div>
        <div className="PreferencesFormArea">
          <SelectField
            label="Select Course Type"
            value="Course Type"
            onChange={handleSelectChange}
            className="PreferencesField"
            id="PreferencesField"
            values={["Course Type"]}
          />
        </div>
        <hr className="PreferencesDivider"/>
        <div className="SessionHeader">
          <h3>Session Interest</h3>
          <div className="SessionSelectAreas">
            <SelectField
              label="Select Mentees size per Session"
              value="-"
              onChange={handleSelectChange}
              className="SessionSelectFieldOne"
              id="SessionSelectFieldOne"
              values={["-"]}
            />
            <SelectField
              label="Select Sessions per Day"
              value="-"
              onChange={handleSelectChange}
              className="SessionSelectFieldTwo"
              id="SessionSelectFieldTwo"
              values={["-"]}
            />
          </div>
          <button type="submit">
            <p>Save</p>
          </button>
        </div>
        <hr className="PreferencesDivider"/>
        <div className="PreferencesIncomeRange">
          <h3>Income Range</h3>
          <div className="IncomeFieldAreas">
            <SelectField
              label="Select Currency per session"
              value="-"
              onChange={handleSelectChange}
              className="IncomeSelectFieldOne"
              id="IncomeSelectFieldOne"
              values={["United State Dollars ($)"]}
            />
            <SelectField
              label="Enter amount"
              value="-"
              onChange={handleSelectChange}
              className="IncomeSelectFieldTwo"
              id="IncomeSelectFieldTwo"
              values={["-"]}
            />
          </div> 
          <div className="IncomeRangeSlider">
            <h6>Salary Range</h6>
            <div className="SliderContainer">
              <span className="SliderSpanOne">0</span>
              <div className="RangeSlider">
                <Slider
                  getAriaLabel={() => "Currency range"}
                  size="small"
                  max={10000}
                  min={0} 
                  value={value}
                  valueLabelDisplay="auto"
                  onChange={handleSliderChange}
                  color="primary"
                  getAriaValueText={valuetext}
                />
              </div>
              <span className="SliderSpanTwo">10,000</span>
            </div>
            
          </div>
          <button type="submit">
            <p>Save</p>
          </button>
        </div>
        <div className="PreferencesRoadMap">
          <h3>Course Roadmap</h3>
          <div className="RoadMapUpload">
            <UploadIcon />
            <p>Please upload your course roadmap</p>
            <button onClick={handleBrowseClick}>Browse File</button>
            <input 
              type="file" 
              accept=".jpg .jpeg .pdf .JPG .JPEG .PDF" 
              hidden
              ref={fileInputRef}
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                console.log("Selected File:", selectedFile);
              }}
            />
          </div>
        </div>
        <button onClick={handleSubmit}>Proceed</button>
        

        

      </div>
    </div>
  );
};

export default CoachPreferences;
