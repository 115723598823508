import React, { useState } from "react";
import "./CoachCulturePage.style.scss";
import { useNavigate } from "react-router-dom";
import { TextArea } from "../../../Materials/InputFields";

const CoachCulturePage = () => {
  const [formData, setFormData] = useState();
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/coach/profile/verification");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="CultureContainer">
      <div className="CultureWrapper">
        <h3>Culture</h3>
        <div className="StudyStrategy">
          <TextArea 
            label="Describe the study strategies and techniques you teach, and how they can help students succeed?"
            className="StudyTextArea"
            id="StudyTextArea"
            value=""
            onChange={handleInputChange}
            placeholder="Describe your study strategy..."
          />
          <button type="submit">
            <p>Save</p>
          </button>
        </div>
        <div className="SuccessMeasure">
          <TextArea 
            label="Explain how you measure success and what benchmarks or goals you set for students environment?"
            className="MeasureTextArea"
            id="MeasureTextArea"
            value=""
            onChange={handleInputChange}
            placeholder="Describe how you measure success..."
          />
          <button type="submit">
            <p>Save</p>
          </button>
        </div>
        <button onClick={handleSubmit}>Proceed</button>
      </div>
    </div>
  );
};

export default CoachCulturePage;