// MyContext.js
import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const MyContext = createContext();

export function MyContextProvider({ children }) {
  const [data, setData] = useState([]);

  const updateMyData = (newData) => {
    setData((prevData) => [...prevData, newData]);
  };

  return (
    <MyContext.Provider value={{ data, updateMyData }}>
      {children}
    </MyContext.Provider>
  );
}

MyContextProvider.propTypes = {
  children: PropTypes.node,
};

export function useMyContext() {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
}
