import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectField } from "../../../Materials/InputFields";
import CheckboxesTags from "../../../Materials/LimitTagsMUI";
import "./RecruiterRecruitmentProcess.style.scss";
import Slider from "@mui/material/Slider";

const RecruiterRecruitmentProcess = () => {
  const [value, setValue] = useState([7, 20]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    methodOfContact: "",
    response: "",
    interviewProcess: [],
    onboardingProcess: [],
    assessmentTools: "",
    backgroundCheck: "",
    contractLength: value,
  });

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    setFormData({
      ...formData,
      contractLength: newValue,
    });
  };
  const handleSelectChange = (name, selectedValue) => {
    setFormData({
      ...formData,
      [name]: selectedValue,
    });
  };

  const handleSave = () => {
    console.log("Form data saved:", formData);
  };
  const handleSubmit = () => {
    navigate("/recruiter/profile/verification");
  };

  return (
    <div className="RecruitmentProcessContainer">
      <div className="RecruitmentProcessContent">
        <h2>Recruitment Process</h2>
        <div className="RecruitmentProcessForm">
          <SelectField
            label={"Preferred Method of Contact"} 
            className={"RecruitmentSelectField"}
            id={"RecruitmentSelectField"}
            values={["Email", "Phone Call", "Text Message", "Linkedin"]}
            value={formData.methodOfContact}
            name={"methodOfContact"}
            placeholder={"Select"}
            onChange={handleSelectChange}
          />
          <SelectField 
            label={"Time to Response"}
            className={"RecruitmentSelectField"}
            id={"RecruitmentSelectField"}
            values={["< 24 Hours", "1-3 Days", "3-5 Days", "> 5 Days"]}
            value={formData.response}
            name={"response"}
            placeholder={"Select"}
            onChange={handleSelectChange}
          />
          <div className="RecruitmentInterviewProcess">
            <p>Interview Process</p>
            <CheckboxesTags 
              options={["Screening", "HR Interview", "Whiteboard Interview", "Technical Assessment", "Culture Fit Interview"]}
              placeholder={"Select"}
              className={"RecruitmentProcessBox"}
              name={"interviewProcess"}
              value={formData.interviewProcess}
            />
          </div>
          <div className="RecruitmentOnBoardingProcess">
            <p>Onboarding Process</p>
            <CheckboxesTags 
              options={["Orientation", "Training Program", "Mentorship", "Self-guided"]}
              placeholder={"Select"}
              className={"RecruitmentProcessBox"}
              name={"onboardingProcess"}
              value={formData.onboardingProcess}
            />
          </div>
          <SelectField 
            label={"Use of Assessment Tool"}
            className={"RecruitmentSelectField"}
            id={"RecruitmentSelectField"}
            values={["Skill Test", "Personality Test"]}
            name={"assessmentTools"}
            value={formData.assessmentTools}
            placeholder={"Select"}
            onChange={handleSelectChange}
          />
          <SelectField 
            label={"Background Check Requirements"}
            className={"RecruitmentSelectField"}
            id={"RecruitmentSelectField"}
            values={["Yes", "No"]}
            value={formData.backgroundCheck}
            name={"backgroundCheck"}
            placeholder={"Select"}
            onChange={handleSelectChange}
          />
          <div className="RecruitmentContractLength">
            <p>Contract Length for Temporary Roles</p>
            <div className="RecruitmentProcessSlider">
              <span>0</span>
              <div className="SliderWrapper">
                <Slider
                  getAriaLabel={() => "Month range"}
                  size="small"
                  max={24}
                  min={0} 
                  value={value}
                  valueLabelDisplay="auto"
                  onChange={handleSliderChange}
                  color="primary"
                />
              </div>
              <span>24 Months</span>
            </div>
          </div>
          <div className="RecruitmentSaveButton">
            <button onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
        <button onClick={handleSubmit} className="RecruitmentProceedButton">
          Proceed
        </button>
      </div>
    </div>
  );
};

export default RecruiterRecruitmentProcess;