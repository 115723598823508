import React from "react";
import { CheckBox, RadioButton } from "../../../Materials/InputFields";

import "./MessagingNotifications.style.scss";
import { Toggle } from "../../../Materials/ToggleMui";

const MessagingNotifications = () => {

  const handleCheckBoxChange = () => {
    return;
  };

  return (
    <div className="MessagingNotificationsContainer">
      <div className="MessagingNotificationsContent">
        <div className="MessagingNotificationsHeading">
          <h6>Allow Messaging Notifications</h6>
          <Toggle />
        </div>
        <div className="MessagingCheckboxHeadings">
          <div className="MessagingCheckboxHeading"></div>
          <div className="MessagingCheckboxHeading">
            <h6>Email</h6>
            <p>Sent to your registered email</p>
          </div>
          <div className="MessagingCheckboxHeading">
            <h6>In-app notifications</h6>
            <p>Delivered through the app</p>
          </div>
          <div className="MessagingCheckboxHeading">
            <h6>Pushed notifications</h6>
            <p>Get notified through your device</p>
          </div>
          <div className="MessagingCheckboxHeading">
            <h6>Desktop </h6>
            <p>Receive notification on windows</p>
          </div>
        </div>
        <div className="MessagingCheckBoxes">
          <div className="MessagingCheckBox">
            <div className="MessagingCheckBoxText">
              <h6>Messaging</h6>
              <p>These are notifications letting you know you&apos;ve received messages.</p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
          </div>
          <div className="MessagingCheckBox">
            <div className="MessagingCheckBoxText">
              <h6>Message Reminders</h6>
              <p>These are notifications letting you know you&apos;ve messages you haven&apos;t read.</p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
          </div>
          <div className="MessagingCheckBox">
            <div className="MessagingCheckBoxText">
              <h6>In-Mail Reminders</h6>
              <p>These are notifications letting you know you&apos;ve messages you haven&apos;t read.</p>
            </div>
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
            <CheckBox 
              name={""}
              value={""}
              className={"MessagingCheckBox"}
              onChange={handleCheckBoxChange}
              id={"MessagingCheckBox"}
              label={""}
            />
          </div>
        </div>
        <div className="MessagingNotificationsFrequency">
          <h6>Choose Email Frequency</h6>
          <div className="MessagingRadioButtons">
            <RadioButton 
              name={"notificationFrequency"}
              value={"daily"}
              label={"Daily"}
              className={"MessagingRadioButton"}
              id={"MessagingRadioButton"}
            />
            <RadioButton 
              name={"notificationFrequency"}
              value={"weekly"}
              label={"Weekly"}
              className={"MessagingRadioButton"}
              id={"MessagingRadioButton"}
            />
            <RadioButton 
              name={"notificationFrequency"}
              value={"monthly"}
              label={"Monthly"}
              className={"MessagingRadioButton"}
              id={"MessagingRadioButton"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagingNotifications;
