export const quiz = [
  {
    module: "Module 1 Resources",
    questions: [
      {
        question: "What is Data Science?",
        options: [
          "The study of computer programming",
          "The analysis of structured data",
          "The process of extracting insights from data",
          "The design of data structures",
        ],
        correctAnswer: "C",
      },
      {
        question: "Which programming language is commonly used in Data Science?",
        options: [
          "Java",
          "Python",
          "C++",
          "Ruby",
        ],
        correctAnswer: "B",
      },
      {
        question: "What is the purpose of exploratory data analysis?",
        options: [
          "To clean and preprocess data",
          "To visualize and summarize data",
          "To create machine learning models",
          "To deploy data applications",
        ],
        correctAnswer: "B",
      },
    ],
  },
  {
    module: "Module 2 Resources",
    questions: [
      {
        question: "What is supervised learning?",
        options: [
          "Learning from labeled data",
          "Learning without any guidance",
          "Learning from images only",
          "Learning from unstructured text",
        ],
        correctAnswer: "A",
      },
      {
        question: "What is the role of a decision tree in machine learning?",
        options: [
          "Making coffee decisions",
          "Classifying data based on rules",
          "Predicting stock prices",
          "Creating network connections",
        ],
        correctAnswer: "B",
      },
      {
        question: "What is cross-validation?",
        options: [
          "Validating data across multiple sources",
          "Validating machine learning models",
          "Validating user inputs",
          "Validating software licenses",
        ],
        correctAnswer: "B",
      },
    ],
  },
  {
    module: "Module 3 Resources",
    questions: [
      {
        question: "What is unsupervised learning?",
        options: [
          "Learning from labeled data",
          "Learning without any guidance",
          "Learning from images only",
          "Learning from unstructured text",
        ],
        correctAnswer: "B",
      },
      {
        question: "What is clustering in data science?",
        options: [
          "Sorting data alphabetically",
          "Grouping similar data points",
          "Combining different datasets",
          "Filtering out irrelevant data",
        ],
        correctAnswer: "B",
      },
      {
        question: "What is the purpose of dimensionality reduction?",
        options: [
          "Increasing the complexity of data",
          "Reducing the size of the dataset",
          "Enhancing visualization of data",
          "Adding more features to the model",
        ],
        correctAnswer: "C",
      },
    ],
  },
  {
    module: "Module 4 Resources",
    questions: [
      {
        question: "What is regression in machine learning?",
        options: [
          "Predicting categorical outcomes",
          "Predicting numerical outcomes",
          "Classifying data points",
          "Identifying outliers in data",
        ],
        correctAnswer: "B",
      },
      {
        question: "What is overfitting?",
        options: [
          "Fitting the model too closely to the training data",
          "Underutilizing the training data",
          "Ignoring outliers in the data",
          "Not training the model at all",
        ],
        correctAnswer: "A",
      },
      {
        question: "How does regularization prevent overfitting?",
        options: [
          "By adding noise to the data",
          "By penalizing complex models",
          "By increasing the learning rate",
          "By reducing the number of features",
        ],
        correctAnswer: "B",
      },
    ],
  },
  {
    module: "Module 5 Resources",
    questions: [
      {
        question: "What is natural language processing (NLP)?",
        options: [
          "Processing natural foods",
          "Processing language in its raw form",
          "Understanding and processing human language by computers",
          "A type of language spoken by plants",
        ],
        correctAnswer: "C",
      },
      {
        question: "Which algorithm is commonly used for text classification in NLP?",
        options: [
          "Decision Tree",
          "K-Nearest Neighbors",
          "Naive Bayes",
          "Support Vector Machine",
        ],
        correctAnswer: "C",
      },
      {
        question: "What is sentiment analysis?",
        options: [
          "Analyzing facial expressions",
          "Analyzing the sentiment of written or spoken language",
          "Analyzing musical tones",
          "Analyzing weather patterns",
        ],
        correctAnswer: "B",
      },
    ],
  },
];