import React from "react";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { TbCircles } from "react-icons/tb";
import { UsersIcon } from "../../svgs/svg";

import "./AdminSettings.style.scss";

const AdminSettings = () => {
  return (
    <div className="AdminSettingContainer">
      <div className="AdminSettingContainerWrapper">
        <Link
          to={"/admin/profile/settings/account"}
          style={{textDecoration: "none"}}
          className="AdminSettingContent"
        >
          <CgProfile className="AdminSettingIcon" />
          <h6>Account</h6>
        </Link>
        <Link
          to={"/admin/profile/settings/roles"}
          style={{textDecoration: "none"}}
          className="AdminSettingContent"
        >
          <TbCircles className="AdminSettingIcon" />
          <h6>Roles and Permission</h6>
        </Link>
        <Link
          to={"/admin/profile/settings/user"}
          style={{textDecoration: "none"}}
          className="AdminSettingContent"
        >
          <UsersIcon stroke={"#2A53A9"} className="AdminSettingIcon" />
          <h6>User Admin</h6>
        </Link>
      </div>
    </div>
  );
};
export default AdminSettings;
