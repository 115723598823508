import React, { useState, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { HiDotsHorizontal } from "react-icons/hi";
import { IoIosArrowRoundBack } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import Image from "../../images/cloud.png";

import "./AdminRoleTable.style.scss";
import OptionsModal from "./OptionsModal";
import AdminPermission from "./AdminPermission";
import CreateRoleDrawer from "./CreateRoleDrawer";

const today = new Date();

// Get the day, month, and year
let day = today.getDate();
let month = today.getMonth() + 1; // Month is zero-based, so we add 1
const year = today.getFullYear();

if (day < 10) {
  day = "0" + day;
}
if (month < 10) {
  month = "0" + month;
}
const formattedDate = day + "/" + month + "/" + year;

const columns = [
  { id: "index", label: "S/N", minWidth: 20, align: "left" },
  { id: "role", label: "Role Name", minWidth: 150, align: "left" },
  { id: "desc", label: "Description", minWidth: 250, align: "center" },
  { id: "perm", label: "Permissions", minWidth: 100, align: "center" },
  { id: "assign", label: "Assigned To", minWidth: 100, align: "center" },
  { id: "created", label: "Date Created", minWidth: 100, align: "center" },
  { id: "updated", label: "Last Updated", minWidth: 100, align: "center" },
  { id: "dots", label: "", minWidth: 50, align: "center" },
];

function createData(index, role, desc, perm, assign, created, updated, dots) {
  return { index, role, desc, perm, assign, created, updated, dots };
}

const rows = [
  createData(
    "1.",
    "Super Admin",
    "Has absolute power to create, delete, assign and view data",
    "View",
    "2 Admins",
    formattedDate,
    formattedDate
  ),
  createData(
    "2.",
    "Admin 1",
    "Can only view data/information connected to his/her account",
    "View",
    "0 Admin",
    formattedDate,
    formattedDate
  ),
  createData(
    "3.",
    "Admin 2",
    "Can only view data/information connected to his/her account",
    "View",
    "1 Admin",
    formattedDate,
    formattedDate
  ),
  createData(
    "4.",
    "Manager",
    "Can only view data/information connected to his/her account",
    "View",
    "2 Admin",
    formattedDate,
    formattedDate
  ),
];

const AdminRoleTable = () => {
  const [optionsModal, setOptionsModal] = useState(false);
  const [clickPosition, setClickPosition] = useState({ y: 0 });
  const [openPermission, setIsOpenPermission] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleClick = () => {
    setIsOpenPermission(true);
  };

  const handleClosePermission = () => {
    setIsOpenPermission(false);
  };
  const handleClickDots = (event) => {
    setClickPosition({ y: event.clientY });
    setOptionsModal(true);
  };
  const handleCloseModal = () => {
    setOptionsModal(false);
  };

  return (
    <div className="RoleTableContain">
      {rows.length === 0 ? (
        <div className="EmptyTableWrapper">
          <div className="EmptyTopSection">
            <IoIosArrowRoundBack className="BackIcon" />
            <h2>Roles & Permission</h2>
          </div>
          <div className="EmptyBox">
            <div className="EmptyContent">
              <img src={Image} alt="" />
              <p>
                Oops! You have not created any roles and permission, click on
                the button below to start creating roles.
              </p>
              <button onClick={handleOpen}>Create New Role</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="RoleTableContent">
          <div className="RoleTableTop">
            <IoIosArrowRoundBack className="BackIcon" />
            <h2>Roles & Permission</h2>
          </div>
          <div className="RoleTableSearchArea">
            <div className="RoleTableInputField">
              <BsSearch className="RoleSearchIcon" />
              <input
                type="text"
                placeholder={"Search course keyword or title"}
                className={"SearchInputField"}
                onChange={""}
                value={""}
              />
            </div>
            <button className="RoleTableSelect">
              <select>
                <option value="" key="">
                  Sort by
                </option>
                <option value="" key="">
                  Date Created
                </option>
                <option value="" key="">
                  Last Updated
                </option>
              </select>
            </button>
            <button className="RoleTableCreate" onClick={handleOpen}>Create New Role</button>
          </div>
          <Paper className="RoleTablePaper">
            <TableContainer className="RoleTableContainer">
              <Table className="RoleTable">
                <TableHead className="RoleTableHead">
                  <TableRow className="RoleTableHeadRow">
                    {columns.map((column) => (
                      <TableCell
                        className="RoleTableHeadCell"
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="RoleTableBody">
                  {rows.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        className="RoleTableBodyRow"
                      >
                        {columns.map((column, colIndex) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              className="RoleTableBodyCell"
                              key={column.id}
                              align={column.align}
                              onClick={() => {
                                if (column.id === "perm") {
                                  handleClick();
                                }
                              }}
                              style={
                                column.id === "perm"
                                  ? {
                                    cursor: "pointer",
                                    color:
                                      "var(--Foundation-PRI-pri-500, #2A53A9)",
                                  }
                                  : {}
                              }
                            >
                              {colIndex === columns.length - 1 ? (
                                <div className="OptionsContainer">
                                  <HiDotsHorizontal onClick={handleClickDots}  className="OptionsIcon" />
                                </div>
                              ) : (
                                column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <CreateRoleDrawer 
            open={open}
            onClose={handleClose}
            anchor="right"
          />
          <OptionsModal 
            isOpen={optionsModal}
            onClose={handleCloseModal}
            position={clickPosition}
          />
          {openPermission && <AdminPermission onClose={handleClosePermission}  />}
        </div>
      )}
    </div>
  );
};
export default AdminRoleTable;
