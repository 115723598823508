import React, { useState, useEffect } from "react";
import "./coachsubpages.style.scss";
import etherLogo from "../../../static/images/logo/Ether Logo.png";
import PropTypes from "prop-types";
const CoachChatList = ({ message, onClick }) => {
  const frontMessage = message.split("").splice(0, 45).join("");

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // 60000 milliseconds = 1 minute

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  //const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format and format the hours and minutes with leading zeros
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // const formattedHours = hours < 10 ? `0${hours}` : hours;
  // const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return (
    <div className="CoachChatList" onClick={onClick}>
      <div className="EtherWelcomeChat">
        <div>
          <h4>
            <span>
              <img src={etherLogo} alt="ether" width={24} height={24} />
            </span>{" "}
            Ether
          </h4>
          <p>{`${formattedHours}:${formattedMinutes} `}</p>
        </div>
        <p>{frontMessage}...</p>
      </div>
    </div>
  );
};

CoachChatList.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default CoachChatList;
