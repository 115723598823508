import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import "./RecruiterRelevanceTarget.style.scss";
import { SelectField, TextField } from "../../../Materials/InputFields";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CiCircleInfo } from "react-icons/ci";

const RelevanceTargetLayout = ({ tab }) => {
  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate();

  const activeStyle = {
    borderBottom: "2px solid var(--Foundation-PRI-pri-500, #2A53A9)",
    color: "var(--Foundation-PRI-pri-500, #2A53A9)"
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log(currentPath);
    switch (currentPath) {

    case "/recruiter/profile/addjob/relevance":
      setIsActive("weight");
      break;

    case "/recruiter/profile/addjob/relevance/score":
      setIsActive("score");
      break;

    case "/recruiter/profile/addjob/relevance/criteria":
      setIsActive("criteria");
      break;

    default:
      setIsActive(null);
      break;
    }
  }, [history]);

  const handleTabIconClick = (direction) => {
    const tabs = ["weight", "score", "criteria"];
    let currentIndex = tabs.indexOf(isActive);
  
    if (currentIndex !== -1) {
      let newIndex = direction === "back" ? currentIndex - 1 : currentIndex + 1;
      newIndex = (newIndex + tabs.length) % tabs.length;
      const newActiveTab = tabs[newIndex];
  
      
      setIsActive(newActiveTab);
      navigate(`/recruiter/profile/addjob/relevance/${newActiveTab}`);
    }
  };
  return (
    <div className="LayoutContainer">
      <h4>Relevance Target</h4>
      <div className="LayoutContent">
        <div className="LayoutTabsContainer">
          <div className="LayoutTabs">
            <Link
              className="LayoutTab"
              to={"/recruiter/profile/addjob/relevance"}
            >
              <button
                style={ isActive === "weight" ? activeStyle : {} }
              >
                Criteria and Weight
              </button>
            </Link>
            <Link
              className="LayoutTab"
              to={"/recruiter/profile/addjob/relevance/score"}
            >
              <button
                style={ isActive === "score" ? activeStyle : {} }
              >
                Target Score
              </button>
            </Link>
            <Link
              className="LayoutTab"
              to={"/recruiter/profile/addjob/relevance/criteria"}
            >
              <button
                style={ isActive === "criteria" ? activeStyle : {} }
              >
                Quantify Criteria
              </button>
            </Link>
          </div>
          <div className="TabNavigation">
            <div className="IconWrapper" onClick={() => handleTabIconClick("back")}>
              <MdArrowBackIosNew className="TabIcon" />
            </div>
            <div className="IconWrapper" onClick={() => handleTabIconClick("forward")}>
              <MdArrowForwardIos className="TabIcon" />
            </div>
          </div>
        </div>
        <div className="RelevanceTargetOptions">{tab}</div>
      </div>  
    </div>
  );
};
RelevanceTargetLayout.propTypes = {
  tab: PropTypes.node
};
export default RelevanceTargetLayout;

export const CriteriaWeight = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState([{}]);
  const [selectValues, setSelectValues] = useState(Array.from({ length: fields.length }, () => ""));
  const [totalWeight, setTotalWeight] = useState(0);
  const [state, setState] = useState({ 
    criteria: "",
    weight: ""
  });
  
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isFieldsValid = Object.values(state).every(value => value.trim() !== "");
    const isWeightsValid = fields.every(field => field.weight && field.weight.trim() !== "");
    const isValid = isFieldsValid && isWeightsValid;
    console.log("isFormValid:", isValid);
    setIsFormValid(isValid);

  }, [state, fields, totalWeight]);

  const handleAddField = () => {
    setFields([...fields, {}]);
  };
  const handleSelectChange = (index, value) => {
    const newSelectValues = [...selectValues];
    newSelectValues[index] = value;
    setSelectValues(newSelectValues);
  };
  const handleWeightChange = (index, value) => {
    const newFields = [...fields];
    newFields[index] = { weight: value };
    setFields(newFields);

    const total = newFields.reduce((acc, curr) => {
      return acc + (curr.weight ? parseFloat(curr.weight) : 0);
    }, 0);
    setTotalWeight(total);
  };
  const handleCancelClick = () => {
    navigate("/recruiter/profile/home");
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted. isFormValid:", isFormValid);
    navigate("/recruiter/profile/addjob/relevance/score");
  };

  return (
    <div className="WeightContainer">
      <h6>Define Criteria and Weight</h6>
      <p>Set criteria and allocate weight </p>
      {fields.map((field, index) => (
        <div className="WeightTextFields" key={index}>
          <SelectField 
            label={"Criteria"}
            id={"TextField"}
            className={"TextField"}
            placeholder={"Select Criteria"}
            values={["Education", "Experience", "Skills"]}
            name={"criteria"}
            selectedValue={selectValues[index]}
            onChange={(e) => handleSelectChange(index, e.target.value)}
          />
          <TextField 
            label={
              <span className="LabelWrapper">
                <p>Weights</p>
                <CiCircleInfo 
                  className="InfoIcon" 
                  title="Weight must be summed up to 1 or 100%" 
                />
              </span>
            }
            id={"TextField"}
            className={"TextField"}
            placeholder={"Enter Weight"}
            name={"weight"}
            type={"text"}
            value={fields[index].weight || ""}
            onChange={(e) => handleWeightChange(index, e.target.value)}
          />
        </div>
      ))}
      <span onClick={handleAddField}>
        <p>Add Another</p>
        <IoAddCircleOutline className="AddIcon"/>
      </span>
      <div className="TotalWeight">
        <h4>Total Weight</h4>
        <span className="WeightTotal">
          {totalWeight}%
        </span>
      </div>
      <div className="WeightButtons">
        <button onClick={handleCancelClick}>Cancel</button>
        <button
          onClick={handleSubmit}
          // disabled={!isFormValid}
        >
          Save and Proceed
        </button>
      </div>
    </div>
  );
}; 

export const TargetScore = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    maximum: "",
    target: ""
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/recruiter/profile/addjob/relevance/criteria");
  };
  useEffect(() => {
    const isValid = Object.values(state).every(value => value.trim() !== "");
    setIsFormValid(isValid);
  }, [state]);

  const handleCancelClick = () => {
    navigate("/recruiter/profile/home");
  };

  
  return (
    <div className="TargetContainer">
      <h6>Set Your Target Score </h6>
      <p className="Para">This enables you to set your maximum score and define your target score</p>
      <div className="TargetScores">
        <div className="ScoreWrapper">
          <p>Maximum Score</p>
          <span>
            What is your maximum score?
          </span>
        </div>
        <TextField 
          label={
            <span className="LabelWrapper">
              <p>Enter  Maximum Score</p>
              <CiCircleInfo 
                className="InfoIcon" 
                title="Weight must be summed up to 1 or 100%" 
              />
            </span>
          }
          id={"TextField"}
          className={"TextField"}
          placeholder={"This must be a numerical value only"}
          onChange={handleInputChange}
          name={"maximum"}
          value={state.maximum}
          type={"text"}
        />
      </div>
      <div className="TargetScores">
        <div className="ScoreWrapper">
          <p>Target Score</p>
          <span>
            What is your Target score?
          </span>
        </div>
        <TextField 
          label={
            <span className="LabelWrapper">
              <p>Enter  Target Score</p>
              <CiCircleInfo 
                className="InfoIcon" 
                title="Weight must be summed up to 1 or 100%" 
              />
            </span>
          }
          id={"TextField"}
          className={"TextField"}
          placeholder={"This must be a numerical value only"}
          onChange={handleInputChange}
          type={"text"}
          name={"target"}
          value={state.target}
        />
      </div>
      <div className="TargetButtons">
        <button
          onClick={handleCancelClick}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          Save and Proceed
        </button>
      </div>
    </div>
  );
};

export const Quantify = () => {
  const [selected, setSelected] = useState(null);
  const [fields, setFields] = useState([{}]);
  const [expFields, setExpFields] = useState([{}]);
  const [skillFields, setSkillFields] = useState([{}]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalExpPoints, setTotalExpPoints] = useState(0);
  const navigate = useNavigate();
  const [selectValues, setSelectValues] = useState(Array.from({ 
    length: fields.length 
  }, () => ""));
  const [selectExpValues, setExpSelectValues] = useState(Array.from({ 
    length: expFields.length 
  }, () => ""));

  const [state, setState] = useState({ 
    level: "",
    point: "",
    skill: "",
    experience: ""
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isFieldsValid = Object.values(state).every(value => value.trim() !== "");
    const isExpFieldsValid = Object.values(state).every(value => value.trim() !== "");
    const isSkillFieldsValid = Object.values(state).every(value => value.trim() !== "");
    const isPointsValid = fields.every(field => field.point && field.point.trim() !== "");
    const isValid = isFieldsValid && isPointsValid && isExpFieldsValid && isSkillFieldsValid;
    console.log("isFormValid:", isValid);
    setIsFormValid(isValid);

  }, [state, fields,expFields, skillFields]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const toggle = (index) => {
    if (selected == index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  const handleAddField = () => {
    setFields([...fields, {}]);
  };

  const handleAddExpField = () => {
    setExpFields([...expFields, {}]);
  };

  const handleAddSkillField = () => {
    setSkillFields([...skillFields, {}]);
  };

  const handleSelectChange = (index, value) => {
    const newSelectValues = [...selectValues];
    
    newSelectValues[index] = value;
    setSelectValues(newSelectValues);
  };

  const handleExpSelectChange = (index, value) => {
    const newExpSelectValues = [...selectExpValues];

    newExpSelectValues[index] = value;
    setExpSelectValues(newExpSelectValues);
  };

  const handlePointsChange = (index, value) => {
    const newFields = [...fields];
    newFields[index] = { point: value };
    setFields(newFields);

    const total = newFields.reduce((acc, curr) => {
      return acc + (curr.point ? parseFloat(curr.point) : 0);
    }, 0);
    setTotalPoints(total);
  };

  const handleExpPointsChange = (index, value) => {
    const newFields = [...expFields];
    newFields[index] = { point: value };
    setExpFields(newFields);

    const total = newFields.reduce((acc, curr) => {
      return acc + (curr.point ? parseFloat(curr.point) : 0);
    }, 0);
    setTotalExpPoints(total);
  };
  

  const handleCancelClick = () => {
    navigate("/recruiter/profile/home");
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted. isFormValid:", isFormValid);
    navigate("/recruiter/profile/addjob/review");
  };
  
  return (
    <div className="CriteriaContainer">
      <h6>Quantify your defined criteria</h6>
      <p>Allocate point to your defined criteria</p>
      <span>
        Note: Your maximum score determines the spread of your points. 
        You can not enter a value that exceeds your maximum score. 
        For a given criteria, the system will consider the highest 
        score as quantified for the criteria
      </span>
      <div className="CriteriaAccordionWrapper">
        <div className="CriteriaAccordion">
          <div className="AccordionHeading" onClick={() => toggle(0)}>
            <h4>Education</h4>
            <div className="IconWrapper">
              {selected === 0 ? <p>Collapse</p> : <p>Expand</p>}
              <MdKeyboardArrowDown className={selected === 0 ? "AccordionIcon rotate" : "AccordionIcon"} />
            </div>
          </div>
          <div className={selected === 0 ? "AccordionContent show" : "AccordionContent"}>
            <div className="Text">
              <p>Levels</p>
              <p>Spread your points from 0 to maximum score</p>
            </div>
            
            {fields.map((field, index) => (
              <div className="TextFields" key={index}>
                <SelectField 
                  id={"SelectField"}
                  className={"SelectField"}
                  placeholder={"Levels"}
                  values={["High School", "Bachelors Degree", "Masters Degree"]}
                  name={"levels"}
                  selectedValue={selectValues[index]}
                  onChange={(e) => handleSelectChange(index, e.target.value)}

                />
                <TextField 
                  id={"TextField"}
                  className={"TextField"}
                  placeholder={"0"}
                  onChange={(e) => handlePointsChange(index, e.target.value)}
                  name={"point"}
                  type={"text"}
                  value={fields[index].point || ""}
                />
              </div>
            ))}
            <div className="AddAnother" onClick={handleAddField}>
              <p>Add Another</p>
              <IoAddCircleOutline className="AddIcon" />
            </div>
            <div className="TotalPoint">
              <h4>Total Points</h4>
              <span className="PointsTotal">
                {totalPoints}%
              </span>
            </div>
          </div>
        </div>
        <div className="CriteriaAccordion">
          <div className="AccordionHeading" onClick={() => toggle(1)}>
            <h4>Experience</h4>
            <div className="IconWrapper">
              {selected === 1 ? <p>Collapse</p> : <p>Expand</p>}
              <MdKeyboardArrowDown className={selected === 1 ? "AccordionIcon rotate" : "AccordionIcon"} />
            </div>
          </div>
          <div className={selected === 1 ? "AccordionContent show" : "AccordionContent"}>
            <div className="Text">
              <p>Levels</p>
              <p>Spread your points from 0 to maximum score</p>
            </div>
            
            {expFields.map((field, index) => (
              <div className="TextFields" key={index}>
                <SelectField 
                  id={"SelectField"}
                  className={"SelectField"}
                  placeholder={"Levels"}
                  values={["0 or Entry level", "1 to 2 immediate", "3 to 5 Experienced"]}
                  name={"levels"}
                  selectedValue={selectExpValues[index]}
                  onChange={(e) => handleExpSelectChange(index, e.target.value)}
                />
                <TextField 
                  id={"TextField"}
                  className={"TextField"}
                  placeholder={"0"}
                  onChange={(e) => handleExpPointsChange(index, e.target.value)}
                  name={"point"}
                  type={"text"}
                  value={expFields[index].point || ""}
                />
              </div>
            ))}
            <div className="AddAnother" onClick={handleAddExpField}>
              <p>Add Another</p>
              <IoAddCircleOutline className="AddIcon" />
            </div>
            <div className="TotalPoint">
              <h4>Total Points</h4>
              <span className="PointsTotal">
                {totalExpPoints}%
              </span>
            </div>
          </div>
        </div>
        <div className="CriteriaAccordion">
          <div className="AccordionHeading" onClick={() => toggle(2)}>
            <h4>Skills</h4>
            <div className="IconWrapper">
              {selected === 2 ? <p>Collapse</p> : <p>Expand</p>}
              <MdKeyboardArrowDown className={selected === 2 ? "AccordionIcon rotate" : "AccordionIcon"} />
            </div>
          </div>
          <div className={selected === 2 ? "AccordionContent show" : "AccordionContent"}>
            <div className="Text">
              <p>Skills</p>
              <p>Select level of proficiency needed</p>
            </div>
            
            {skillFields.map((field, index) => (
              <div className="TextFields" key={index}>
                <TextField 
                  className={"TextField"}
                  id={"TextField"}
                  name={"skill"}
                  value={state.skill}
                  onChange={handleInputChange}
                  type={"text"}
                  placeholder={"Enter a Skill"}
                />
                <SelectField 
                  className={"SelectField"}
                  id={"SelectField"}
                  selectedValue={state.experience}
                  name={"experience"}
                  onChange={handleInputChange}
                  values={["Experienced", "Advanced", "Intermediate", "Beginner"]}
                  placeholder={"Proficiency Level"}
                />
              </div>
            ))}
            <div className="AddAnother" onClick={handleAddSkillField}>
              <p>Add Another</p>
              <IoAddCircleOutline className="AddIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className="CriteriaButtons">
        <button
          onClick={handleCancelClick}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
        >
          Save and Proceed
        </button>
      </div>
    </div>
  );
};