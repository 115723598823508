import React, {useState, useEffect} from "react";
import "./JsSideBar.style.scss";
import homeImage from "../../../static/images/icons/home-simple-door.png";
import jobsImage from "../../../static/images/icons/briefcase.png";
import appliedImage from "../../../static/images/icons/history-toggle.png";
import messageImage from "../../../static/images/icons/brand-messenger.png";
import libraryImage from "../../../static/images/icons/books.png";
import coachesImage from "../../../static/images/icons/Group 17.png";
import { Link, useNavigate } from "react-router-dom";
// import { CiHome} from "react-icons/ci";
// import { GoBriefcase} from "react-icons/go";



const JsSideBar = () => {
  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate();
  const hoverStyle = {
    borderBottom: "solid 2px #2E2C2C",
    fontStyle: "bolder",
    color: "#2E2C2C",
    padding: "0px 10px",
  };

  const handleButtonClick = (button) => {
    setIsActive(button);
    if (isActive === "home") {
      navigate("/job_seeker/home");
    }
    if (isActive === "jobs") {
      navigate("/job_seeker/profile/jobs");
    }
    if (isActive === "applied") {
      navigate("/job_seeker/profile/applied_courses/recent");
    }
    if (isActive === "messages") {
      navigate("/job_seeker/profile/messages");
    }
    if (isActive === "library") {
      navigate("/job_seeker/profile/library");
    }
    if (isActive === "coaches") {
      navigate("/job_seeker/profile/coaches/all_coaches");
    }
  };
  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log(currentPath);
    switch (currentPath) {
    case "/job_seeker/home":
      setIsActive("home");
      break;
    case "/job_seeker/profile/jobs":
      setIsActive("jobs");
      break;
    case "/job_seeker/profile/culture":
      setIsActive("culture");
      break;
    case "/job_seeker/profile/applied_courses/recent":
      setIsActive("applied");
      break;
    case "/job_seeker/profile/messages":
      setIsActive("messages");
      break;
    case "/job_seeker/profile/library":
      setIsActive("library");
      break;
    case "/job_seeker/profile/coaches/all_coaches":
      setIsActive("coaches");
      break;
      // Add cases for other pages and button names as needed
    default:
      setIsActive(null);
      break;
    }
  }, [history]);
  return (
    <div className="JsSideContainer">
      <div className="JsSideInnerContainer">
        <div className="JsSideLinks">
          <Link
            to={"/job_seeker/home"}
            className="JsSideLink"
            onClick={() => handleButtonClick("home")}
            style={isActive === "home" ? hoverStyle : { fontSize: "14px" }}
          >
            <img src={homeImage} alt="home" width={24} height={24} />
            <span>Home</span>
          </Link>
        </div>
        <div className="JsSideLinks">
          <Link
            to={"/job_seeker/profile/jobs"}
            className="JsSideLink"
            onClick={() => handleButtonClick("jobs")}
            style={isActive === "jobs" ? hoverStyle : { fontSize: "14px" }}
          >
            <img src={jobsImage} alt="jobs" width={24} height={24} />
            <span>Jobs</span>
          </Link>
        </div>
        <div className="JsSideLinks">
          <Link
            to={"/job_seeker/profile/applied_courses/recent"}
            className="JsSideLink"
            onClick={() => handleButtonClick("applied")}
            style={isActive === "applied" ? hoverStyle : { fontSize: "14px" }}
          >
            <img src={appliedImage} alt="applied" width={24} height={24} />
            <span>Applied</span>
          </Link>
        </div>
        <div className="JsSideLinks">
          <Link
            to={"/job_seeker/profile/messages"}
            className="JsSideLink"
            onClick={() => handleButtonClick("messages")}
            style={isActive === "messages" ? hoverStyle : { fontSize: "14px" }}
          >
            <img src={messageImage} alt="message" width={24} height={24} />
            <span>Message</span>
          </Link>
        </div>
        <div className="JsSideLinks">
          <Link
            to={"/job_seeker/profile/library"}
            className="JsSideLink"
            onClick={() => handleButtonClick("library")}
            style={isActive === "library" ? hoverStyle : { fontSize: "14px" }}
          >
            <img src={libraryImage} alt="library" width={24} height={24} />
            <span>Library</span>
          </Link>
        </div>
        <div className="JsSideLinks">
          <Link
            to={"/job_seeker/profile/coaches/all_coaches"}
            className="JsSideLink"
            onClick={() => handleButtonClick("coaches")}
            style={isActive === "coaches" ? hoverStyle : { fontSize: "14px" }}
          >
            <img src={coachesImage} alt="coaches" width={24} height={24} />
            <span>Coaches</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JsSideBar;