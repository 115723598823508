import React, { useState } from "react";
import {
  ApplicationTop,
  RecruiterApplicationDetails,
  RecruiterViewAllApplicant,
  ViewOptions
} from "../RecruiterJob/RecruiterViewApplicant";
import "./RecruiterAllApplicants.style.scss";
import { CustomPagination } from "../../../Materials/Materials";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { HiDotsHorizontal } from "react-icons/hi";
import PropTypes from "prop-types";

const today = new Date();

let day = today.getDate();
let month = today.getMonth() + 1;
const year = today.getFullYear();

if (day < 10) {
  day = "0" + day;
}
if (month < 10) {
  month = "0" + month;
}
const formattedDate = day + "/" + month + "/" + year;

const columns = [
  { id: "name", label: "Name", minWidth: "auto", align: "center" },
  {
    id: "score",
    label: "Score",
    minWidth: "auto",
    align: "center",
    format: (value) => {
      let color;

      if (value === 50) {
        color = "#2A53A9";
      } else if (value > 50) {
        color = "var(--Alert-Succes-600, #00B374)";
      } else {
        color = "var(--Alert-Danger-700, #C33)";
      }

      return (
        <div className="ScoreContainer">
          <span style={{ color }}>{value}</span>
          <span style={{ color }}>/100</span>
        </div>
      );
    }
  },

  { id: "date", label: "Date Applied", minWidth: "auto", align: "center" },
  { id: "action", label: "Action", minWidth: "auto", align: "center" }
];

function createData(name, score, date, action) {
  return { name, score, date, action };
}

const rows = [
  createData(
    "Amirah Doe",
    60,
    "Above Pass score",
    "Highly Recommended",
    formattedDate
  ),
  createData(
    "John Holt",
    45,
    "Below Pass score",
    "Not Recommended",
    formattedDate
  ),
  createData("Udoh Holt", 50, "On Pass score", "Recommended", formattedDate),
  createData(
    "Usman Dexdee",
    55,
    "Above Pass score",
    "Highly Recommended",
    formattedDate
  )
];

const RecruiterAllApplicants = () => {
  return (
    <div className="AllContainer">
      <ApplicationTop />
      <RecruiterApplicationDetails />
      <RecruiterViewAllApplicant />
      <CustomPagination />
    </div>
  );
};

export default RecruiterAllApplicants;

export const RecommendedApplicants = () => {
  const [view, setView] = useState(false);
  const [clickPosition, setClickPosition] = useState({ y: 0 });

  const handleClickView = () => {
    setView(true);
    setClickPosition({ y: event.clientY });
  };

  const handleClickClose = () => {
    setView(false);
  };
  return (
    <div className="RecContainer">
      <ApplicationTop />
      <RecruiterApplicationDetails />
      <Paper className="RecPaper">
        <Table className="RecTable">
          <TableHead className="RecTableHead">
            <TableRow className="RecTableRow">
              {columns.map((column) => (
                <TableCell
                  className="RecTableCell"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="RecTableBody">
            {rows
              .filter((row) => row.score === 50)
              .map((row) => {
                return (
                  <TableRow
                    className="RecTableBodyRow"
                    hover
                    role="checkbox"
                    key={row.code}
                    tabIndex={-1}
                  >
                    {columns.map((column, colIndex) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          className="RecTableBodyCell"
                          key={column.id}
                          align={column.align}
                        >
                          {colIndex === columns.length - 1 ? (
                            <div 
                              className="ActionContent"
                              style={{ cursor: "pointer" }}
                            >
                              
                              <HiDotsHorizontal
                                className="ActionIcon"
                                onClick={handleClickView}
                              />
                            </div>
                          ) : column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
      <CustomPagination />
      <ViewOptions
        isOpen={view}
        onClose={handleClickClose}
        position={clickPosition}
      />
    </div>
  );
};

export const HighlyRecommendedApplicants = () => {
  const [view, setView] = useState(false);
  const [clickPosition, setClickPosition] = useState({ y: 0 });

  const handleClickView = () => {
    setView(true);
    setClickPosition({ y: event.clientY });
  };

  const handleClickClose = () => {
    setView(false);
  };
  return (
    <div className="RecContainer">
      <ApplicationTop />
      <RecruiterApplicationDetails />
      <Paper className="RecPaper">
        <Table className="RecTable">
          <TableHead className="RecTableHead">
            <TableRow className="RecTableRow">
              {columns.map((column) => (
                <TableCell
                  className="RecTableCell"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="RecTableBody">
            {rows
              .filter((row) => row.score > 50)
              .map((row) => {
                return (
                  <TableRow
                    className="RecTableBodyRow"
                    hover
                    role="checkbox"
                    key={row.code}
                    tabIndex={-1}
                  >
                    {columns.map((column, colIndex) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          className="RecTableBodyCell"
                          key={column.id}
                          align={column.align}
                        >
                          {colIndex === columns.length - 1 ? (
                            <div style={{ cursor: "pointer" }}>
                              <HiDotsHorizontal
                                className="ActionIcon"
                                onClick={handleClickView}
                              />
                            </div>
                          ) : column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
      <CustomPagination />
      <ViewOptions
        isOpen={view}
        onClose={handleClickClose}
        position={clickPosition}
      />
    </div>
  );
};

export const NotRecommendedApplicants = () => {
  const [view, setView] = useState(false);
  const [clickPosition, setClickPosition] = useState({ y: 0 });

  const handleClickView = () => {
    setView(true);
    setClickPosition({ y: event.clientY });
  };

  const handleClickClose = () => {
    setView(false);
  };
  return (
    <div className="RecContainer">
      <ApplicationTop />
      <RecruiterApplicationDetails />
      <Paper className="RecPaper">
        <Table className="RecTable">
          <TableHead className="RecTableHead">
            <TableRow className="RecTableRow">
              {columns.map((column) => (
                <TableCell
                  className="RecTableCell"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="RecTableBody">
            {rows
              .filter((row) => row.score < 50)
              .map((row) => {
                return (
                  <TableRow
                    className="RecTableBodyRow"
                    hover
                    role="checkbox"
                    key={row.code}
                    tabIndex={-1}
                  >
                    {columns.map((column, colIndex) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          className="RecTableBodyCell"
                          key={column.id}
                          align={column.align}
                        >
                          {colIndex === columns.length - 1 ? (
                            <div style={{ cursor: "pointer" }}>
                              <HiDotsHorizontal
                                className="ActionIcon"
                                onClick={handleClickView}
                              />
                            </div>
                          ) : column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
      <CustomPagination />
      <ViewOptions
        isOpen={view}
        onClose={handleClickClose}
        position={clickPosition}
      />
    </div>
  );
};

export const ApplicantBreakdown = ({ isOpen, onClose }) => {
  const columns = [
    { id: "criteria", label: "Criteria", minWidth: "auto", align: "center" },
    { id: "weight", label: "Weight", minWidth: "auto", align: "center" },
    { id: "point", label: "Point", minWidth: "auto", align: "center" },
    { id: "score", label: "Score", minWidth: "auto", align: "center" }
  ];

  function createData(criteria, weight, point, score) {
    return { criteria, weight, point, score };
  }

  const rows = [
    createData("Education", 65, 50, 20),
    createData("Skills", 50, 50, 20),
    createData("Experience", 20, 80, 16)
  ];

  const totalWeight = rows.reduce((acc, row) => (acc += row.weight), 0);
  const totalScore = rows.reduce((acc, row) => (acc += row.score), 0);

  return (
    <>
      {isOpen && (
        <div className="BreakdownContent">
          <div className="Breakdown">
            <h6>View Applicant Breakdown</h6>
            <div className="ApplicantDetails">
              <span>
                <p>Applicant Name:</p>
                <p className="Name">Amirah Doe</p>
              </span>
              <span>
                <p>Date applied</p>
                <p className="Date">{formattedDate}</p>
              </span>
            </div>
            <ApplicantScoreDetails
              rows={rows}
              columns={columns}
              totalWeight={totalWeight}
              totalScore={totalScore}
            />
            <div className="BreakdownActivityButtons">
              <button className="Button" onClick={onClose}>
                Cancel
              </button>
              <button>Send Invite</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
ApplicantBreakdown.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired
};

export const ApplicantScoreDetails = ({
  rows,
  columns,
  totalWeight,
  totalScore
}) => {
  return (
    <div className="ApplicantScoreDistribution">
      <div className="ApplicantPercentScores">
        {rows.map((row) => {
          let bgColor = "var(--Alert-Danger-700, #C33)";
          if (row.weight === 50) {
            bgColor = "var(--Foundation-PRI-pri-500, #2A53A9)";
          } else if (row.weight > 50) {
            bgColor = "#00C781";
          }

          let spanColor = "#782323";
          if (row.weight === 50) {
            spanColor = "var(--Foundation-PRI-pri-700, #1E3B78)";
          } else if (row.weight > 50) {
            spanColor = "var(--Alert-Succes-600, #00B374)";
          }
          return (
            <div className="ApplicantPercentScore" key={row.criteria}>
              <p>{row.criteria}</p>
              <div className="ApplicantScoreWrapper">
                <div
                  className="ApplicantScore"
                  style={{
                    width: `${row.weight}%`,
                    backgroundColor: bgColor
                  }}
                >
                  <span style={{ background: spanColor }}>{row.weight}%</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="ApplicantDistributionTable">
        <Paper className="Paper">
          <Table className="Table">
            <TableHead className="TableHead">
              <TableRow className="TableRow">
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className="TableCell"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="TableBody">
              {rows.map((row) => {
                return (
                  <TableRow className="TableBodyRow" key={row.criteria}>
                    <TableCell className="TableBodyCell" align="center">
                      {row.criteria}
                    </TableCell>
                    <TableCell className="TableBodyCell" align="center">
                      {row.weight}%
                    </TableCell>
                    <TableCell className="TableBodyCell" align="center">
                      {row.point}
                    </TableCell>
                    <TableCell className="TableBodyCell" align="center">
                      {row.score}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        <div className="ApplicantScoreTotal">
          <span className="Max">
            <p>Maximum Score:</p>
            <p className="Score">{totalWeight}%</p>
          </span>
          <span className="App">
            <p>Applicant Score:</p>
            <p className="Scores">{totalScore}</p>
          </span>
        </div>
      </div>
    </div>
  );
};
ApplicantScoreDetails.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      criteria: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
      point: PropTypes.number.isRequired,
      score: PropTypes.number.isRequired
    })
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minWidth: PropTypes.string,
      align: PropTypes.string
    })
  ).isRequired,
  totalWeight: PropTypes.number.isRequired,
  totalScore: PropTypes.number.isRequired
};
