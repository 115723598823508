export const roadmapData = [
  {
    weekNo: "Week 1",
    meetingNo: "5 Meetings",
    lessonNo: "3 Lessons",
    taskNo: "2 Tasks",
  },
  {
    weekNo: "Week 2",
    meetingNo: "3 Meetings",
    lessonNo: "2 Lessons",
    taskNo: "2 Tasks",
  },
  {
    weekNo: "Week 3",
    meetingNo: "5 Meetings",
    lessonNo: "3 Lessons",
    taskNo: "4 Tasks",
  },
  {
    weekNo: "Week 4",
    meetingNo: "5 Meetings",
    lessonNo: "6 Lessons",
    taskNo: "1 Tasks",
  },
];

export const quiz = [
  {
    title: "Module 1 Course Quiz"
  },
  {
    title: "Module 2 Course Quiz"
  },
  {
    title: "Module 3 Course Quiz"
  },
  {
    title: "Module 4 Course Quiz"
  },
  {
    title: "Module 5 Course Quiz"
  }
];

export const modules = [
  {
    title: "Module 1 Resources"
  },
  {
    title: "Module 2 Resources"
  },
  {
    title: "Module 3 Resources"
  },
  {
    title: "Module 4 Resources"
  },
  {
    title: "Module 5 Resources"
  }
];