import React, { useState } from "react";
import { CloseRed } from "../../svgs/svg";
import { MdKeyboardArrowUp } from "react-icons/md";
import PropTypes from "prop-types";

import "./AdminPermission.style.scss";
import { Assign } from "./OptionsModal";

const AdminPermission = ({ onClose }) => {
  const [isAssign, setIsAssign] = useState(false);

  const handleAssignOpen = () => {
    setIsAssign(true);
  };

  const handleAssignCancel = () => {
    setIsAssign(false);
  };

  return (
    <div className="PermissionContainer">
      <div className="PermissionContent">
        <div className="PermTopSection">
          <h6>Permissions</h6>
          <span className="PermIconWrapper" onClick={onClose}>
            <CloseRed />
          </span>
        </div>
        <div className="PermMain">
          <div className="PermTopMain">
            <span className="PermTopWrapper">
              <h6>Role Name</h6>
              <span>Super Admin</span>
            </span>
            <span className="PermTopWrapper">
              <h6>Assigned to</h6>
              <span>
                <p>2 Admins</p>
                <button onClick={handleAssignOpen}>View All</button>
              </span>
            </span>
          </div>
          <div className="PermViewPermission">
            <h6>View Permissions</h6>
            <p>Select actions to view granted action permissions</p>
            <hr className="PermDivider"/>
            <div className="PermPermissions">
              <span>
                <h5>Users</h5>
                <MdKeyboardArrowUp className="PermArrow" />
              </span>
              <span>
                <h5>Resources</h5>
                <MdKeyboardArrowUp className="PermArrow" />
              </span>
              <span>
                <h5>Settings</h5>
                <MdKeyboardArrowUp className="PermArrow" />
              </span>
            </div>
            <div className="PermActivityButtons">
              <button onClick={onClose}>Cancel</button>
              <button>Edit Role</button>
            </div>
          </div>
        </div>
        <Assign 
          isOpen={isAssign}
          onClose={handleAssignCancel}
        />
      </div>
    </div>
  );
};
AdminPermission.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AdminPermission;
