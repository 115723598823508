import React from "react";
import PropTypes from "prop-types";
import "./coachsubpages.style.scss";
const chatCompanion = "Ether";
const date = new Date();
const day = date.getDay();
const hours = date.getHours();
const minutes = date.getMinutes();
const amOrPm = hours >= 12 ? "PM" : "AM";

// Convert to 12-hour format and format the hours and minutes with leading zeros
const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
const formattedMinutes = minutes.toString().padStart(2, "0");
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
import etherLogo from "../../../static/images/logo/Ether Logo.png";
const CoachChatPage = ({ userMessage, chatMessage }) => {
  return (
    <div className="ChatPageContainer">
      <div className="userSection">{userMessage}</div>
      <div className="chatSection">
        <img src={etherLogo} alt="etherlogo" width={20} height={20} />
        <div>
          <span>
            <h2>{chatCompanion}</h2>
            <p>
              {daysOfWeek[day]}{" "}
              {`${formattedHours}:${formattedMinutes} ${amOrPm}`}
            </p>
          </span>

          <p>{chatMessage}</p>
        </div>
      </div>
    </div>
  );
};

CoachChatPage.propTypes = {
  userMessage: PropTypes.string,
  chatMessage: PropTypes.string,
};
export default CoachChatPage;
