import React from "react";
import UserAccordion from "../../../Materials/MaterialsTwo";
import { progressData } from "../../../data/coachProgressData";
import "./coachsubpages.style.scss";
const CoachProgressList = () => {
  return (
    <div className="ProgressListContainer">
      {progressData &&
        progressData?.map((data, index) => (
          <div key={index} className="ProgressListInnerContainer">
            <UserAccordion header={`Week ${index + 1}`} className="ProgressAccordion">
              {data?.videos.map((video, index) => (
                <div key={index}>
                  <a href={video.videoPath}>
                    <p>{video.videoName}</p>
                    <p>{video.videoTime}</p>
                  </a>
                </div>
              ))}
              {data?.readings.map((reading, index) => (
                <div key={index}>
                  <a href={reading.readingPath}>
                    <p>{reading.readingName}</p>
                    <p>{reading.readingTime}</p>
                  </a>
                </div>
              ))}
            </UserAccordion>
          </div>
        ))}
    </div>
  );
};

export default CoachProgressList;
